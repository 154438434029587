import { takeUntil } from "rxjs/operators";
import { Component, HostListener, OnInit } from "@angular/core";
import { Client } from "./client.model";
import { interval, Subject } from "rxjs";
import { ClientService } from "./client.service";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { UserBlockService } from "../services/user-block.service";
import { AppConfig } from "../config";
import { Log } from "../models/log.model";
import { UserRightsService } from "../services/user-rights.service";
import { ConfirmationService } from "primeng/api";
import { StructureClientService } from "../structure-client/structure-client.service";

@Component({
  selector: "app-client",
  templateUrl: "./client.component.html",
  styleUrls: ["./client.component.css"],
})
export class ClientComponent implements OnInit {
  displayDialog: boolean;
  client: Client = {} as Client;
  selectedClient: Client;
  newClient: boolean;
  clients: Client[];
  cols: any[];
  user_block: any = 0;
  lastIDClient: number;
  currentClient: any;
  currentUser: any;
  displayDialogImport = false;
  showDeleteColumn = false;
  importFiles: any[] = [];
  timeoutCounter = 0;

  imgSrc: any;

  listClients: any[];
  selectedClientList: any;

  private onDestroy$ = new Subject();
  selectedStructureSource: any;
  structures: any[];
  constructor(
    private clientService: ClientService,
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    private userRightsService: UserRightsService,
    private confirmationService: ConfirmationService,
    public config: AppConfig,
    public structureClientsService: StructureClientService
  ) {}
  ngOnInit() {
    this.loadData();
    this.currentUser = JSON.parse(localStorage.getItem("user")).data.email;
    this.cols = [
      { field: "name", header: "Libellé: " },
      { field: "description", header: "Commentaires/Précisions: " },
      { field: "dtcreate", header: "Création: " },
      { field: "dtmodif", header: "Modification: " },
    ];
    interval(this.config.RefreshMilisecs)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((x) => {
        if (this.timeoutCounter < this.config.RefreshTimer) {
          this.loadData();
          this.timeoutCounter++;
        }
      });
  }
  @HostListener("document:mousemove")
  onMouseMove() {
    this.timeoutCounter = 0;
  }

  showDialogToAdd() {
    if (this.client != null && this.client.id != null && !this.client) {
      this.closeClients();
    }
    this.newClient = true;
    this.client = {} as Client;
    this.displayDialog = true;
  }
  save() {
    const clients = this.clients ? [...this.clients] : ([] as Client[]);
    if (this.client.name && this.client.description) {
      if (this.newClient) {
        this.client.source = this.selectedStructureSource
          ? this.selectedStructureSource.id
          : 0;
        clients.push(this.client);
        this.clientService.create(this.client).then((res) => {
          if (res.code === 200) {
            let jsonclient = JSON.stringify(this.client);
            this.loadData();
            this.msgs.showSuccess("CLIENT créé!");
            this.trace.createTrace({
              action: "CREATE CLIENT",
              data: jsonclient,
              description: "CREATION CLIENT",
            } as Log);
          } else {
            this.msgs.showError(res.message);
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "CREATE CLIENT",
              data: jsonmsg,
              description: "ERREUR CREATION CLIENT",
            } as Log);
          }
        });
      } else {
        clients[this.clients.indexOf(this.selectedClient)] = this.client;
        this.clientService.update(this.client).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Client mis à jour!");
            this.trace.createTrace({
              action: "UPDATE CLIENT",
              data: JSON.stringify(clients),
              description: "Mise a jour Client",
            } as Log);
          } else {
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "UPDATE CLIENT",
              data: jsonmsg,
              description: "ERREUR MAJ CLIENT",
            } as Log);
          }
          this.userBlock
            .unblockUser(
              "bo_clients",
              this.client ? this.client.id : this.lastIDClient
            )
            .then((data) => {
              console.log("Unblock users");
            });
        });
      }
    } else {
      this.msgs.showError("Merci de remplir tous les champs!");
    }
    this.lastIDClient = this.client.id;
    this.clients = clients;
    this.client = null;
    this.displayDialog = false;
  }
  delete() {
    this.confirmationService.confirm({
      message:
        "Etes-vous sur de vouloir supprimer ce client? Toutes les données du client seront supprimées!",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.clientService.delete(this.client.id).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("CLIENT supprimé!");
            this.trace.createTrace({
              action: "DELETE CLIENT",
              data: JSON.stringify(this.client),
              description: "SUPPRESSION CLIENT",
            } as Log);
          } else {
            this.msgs.showError(res.message);
          }
        });
        this.userBlock
          .unblockUser(
            "bo_clients",
            this.client.id ? this.client.id : this.lastIDClient
          )
          .then((data) => {
            console.log("Unblock users");
          });
        this.lastIDClient = this.client.id;
        const index = this.clients.indexOf(this.selectedClient);
        this.clients = this.clients.filter((val, i) => i !== index);
        this.client = null;
        this.displayDialog = false;
      },
    });
  }

  deleteLine(id) {
    this.confirmationService.confirm({
      message:
        "Etes-vous sur de vouloir supprimer cette lignes? Toutes les données du client seront supprimées!",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.clientService.delete(id).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Client supprimé!");
            this.trace.createTrace({
              action: "DELETE CLIENT",
              data: JSON.stringify(this.client),
              description: "SUPPRESSION CLIENT",
            } as Log);
          } else {
            this.msgs.showError(res.message);
          }
        });
      },
    });
  }

  onRowSelect(event) {
    this.user_block = 0;
    this.newClient = false;
    this.client = this.cloneClient(event.data);
    this.displayDialog = true;
    this.loadLogo();

    this.userBlock
      .verifUserBlock("bo_clients", this.client.id)
      .then((userblock) => {
        if (userblock.data != "" && userblock.data != 0) {
          this.user_block = userblock.data;
          alert("Data en cours d'utilisation par : " + this.user_block);
        } else {
          this.userBlock.blockUser("bo_clients", this.client.id);
          this.lastIDClient = this.client.id;
        }
      });
  }
  cloneClient(r: Client): Client {
    const client = {} as Client;
    for (const prop in r) {
      client[prop] = r[prop];
    }
    return client;
  }
  @HostListener("window:beforeunload")
  closeClients() {
    if (
      (this.user_block == this.currentUser || this.user_block == 0) &&
      !this.newClient &&
      this.client != null
    ) {
      this.userBlock
        .unblockUser(
          "bo_clients",
          this.client ? this.client.id : this.lastIDClient
        )
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
  }

  makeClientList(clients: Client[]) {
    const listClients: any[] = [];
    for (const prop in clients) {
      listClients.push({
        id: clients[prop].id,
        name: clients[prop].name,
      });
    }
    return listClients;
  }

  loadData() {
    this.clientService.get().then((clients) => {
      this.clients = clients.data ? clients.data : ([] as Client[]);
      this.listClients = this.makeClientList(this.clients);
    });
    this.structureClientsService.getAll().then((structures) => {
      if (structures.data == null) {
        this.structures = [] as any[];
      } else {
        this.structures = structures.data;
      }
    });
  }

  onBasicUpload(event) {
    this.loadLogo();
  }

  loadLogo() {
    const d = new Date();
    const n = d.getMilliseconds();
    this.imgSrc = this.config.API_Host + "get/logo/" + this.client.id + "/" + n;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeClients();
  }

  openImportDialog() {
    this.displayDialogImport = true;
  }

  importData(event) {
    this.loadData();
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }
}
