import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { Action } from "./action.model";
import { ActionType } from "./actiontype.model";

@Injectable({
  providedIn: "root",
})
export class ActionService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  getActions(client: any) {
    return this.http
      .get(this.config.API_Host + "actions/get/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getActionTypes(client: any) {
    return this.http
      .get(this.config.API_Host + "actiontypes/get/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getActionsByManager(id_user: any, id_client: any) {
    return this.http
      .get(
        this.config.API_Host +
          "actions/getActionsByManager/" +
          id_user +
          "/" +
          id_client
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  createAction(action: Action) {
    return this.http
      .post(this.config.API_Host + "actions/create", JSON.stringify({ action }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  createActionType(actiontype: ActionType) {
    return this.http
      .post(
        this.config.API_Host + "actiontypes/create",
        JSON.stringify({ actiontype })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  updateAction(action: Action) {
    return this.http
      .post(this.config.API_Host + "actions/update", JSON.stringify({ action }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  updateActionType(actiontype: ActionType) {
    return this.http
      .post(
        this.config.API_Host + "actiontypes/update",
        JSON.stringify({ actiontype })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteAction(id: number) {
    return this.http
      .post(this.config.API_Host + "actions/delete", JSON.stringify({ id: id }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteActionType(id: number) {
    return this.http
      .post(
        this.config.API_Host + "actiontypes/delete",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteAll(id: number) {
    return this.http
      .post(
        this.config.API_Host + "actions/deleteAll",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
