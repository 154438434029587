import { Component, OnInit } from '@angular/core';
import { Client } from "../client/client.model";
import { UserRightsService } from "../services/user-rights.service";
import { UserClientsService } from "../user-clients/user-clients.service";
import { AuthService } from "../services/auth.service";

@Component({
  selector: 'app-action-management',
  templateUrl: './action-management.component.html',
  styleUrls: ['./action-management.component.css']
})
export class ActionManagementComponent implements OnInit {
  client: any;
  selectedClient: any;
  currentUser: any;
  isManager: boolean = false;
  selectedTabIndex: number = 0;

  constructor(
    private userClientService: UserClientsService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.refreshLogin();
    this.currentUser = JSON.parse(localStorage.getItem("user")).data;
    this.isManager = JSON.parse(localStorage.getItem("user")).data.is_manager;
    this.selectedClient = JSON.parse(localStorage.getItem("client"));
    if (!this.selectedClient) {
      this.userClientService.getByUserId(this.currentUser.id).then(res => {
        this.selectedClient = res.data ? res.data[0] : [] as Client[];
      });
    }
  }

  onTabChange(event) {
    this.selectedTabIndex = event.index;
  }
}
