<h1 class="mb-3" style="font-size: 18px !important; text-align: center !important">
  Gestion des plans d'actions
</h1>
<br>
<div class="p-grid p-fluid mx-5">
  <app-plan *ngIf="selectedClient && !isManager"
            [id_client]="selectedClient.id_structure"
            [page]="1"
            [manager_list]="false"
            [header]="false"
            [buttons]="false"
  ></app-plan>

  <p-tabView class="my-tasks" *ngIf="isManager" (onChange)="onTabChange($event)">
    <p-tabPanel header="Mes actions" rightIcon="pi pi-list">
      <ng-container *ngIf="selectedTabIndex === 0">
        <app-plan *ngIf="selectedClient"
                  [id_client]="selectedClient.id_structure"
                  [page]="1"
                  [manager_list]="false"
                  [header]="false"
                  [buttons]="false"
        ></app-plan>
      </ng-container>
    </p-tabPanel>
    <p-tabPanel header="Actions à approuver" rightIcon="pi pi-user">
      <ng-container *ngIf="selectedTabIndex === 1">
        <app-plan *ngIf="selectedClient"
                  [id_client]="selectedClient.id_structure"
                  [page]="1"
                  [manager_list]="true"
                  [header]="false"
                  [buttons]="false"
        ></app-plan>
      </ng-container>
    </p-tabPanel>
  </p-tabView>
</div>
