import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
@Injectable({
  providedIn: "root",
})
export class ProposeScenarioService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  get(proposeScenarioId: number) {
    return this.http
      .get(this.config.API_Host + "propose-scenario/get/" + proposeScenarioId)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getProposedScenarios(userId: number) {
    return this.http
      .get(this.config.API_Host + "propose-scenario/" + userId)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getProposedScenariosToValidate(userId: number) {
    return this.http
      .get(this.config.API_Host + "propose-scenario/to-validate/" + userId)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  create(proposeScenario: any) {
    return this.http
      .post(this.config.API_Host + "propose-scenario", JSON.stringify({ proposeScenario }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  updateScenario(proposeScenario: any) {
    return this.http
      .post(this.config.API_Host + "propose-scenario/update", JSON.stringify({ proposeScenario }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
  updateScenarioStatus(proposeScenario: any) {
    return this.http
      .post(this.config.API_Host + "propose-scenario/updateStatus", JSON.stringify({ proposeScenario }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteScenario(proposeScenario) {
    return this.http
      .post(this.config.API_Host + "propose-scenario/delete", JSON.stringify({ proposeScenario }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
