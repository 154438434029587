import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "./dashboard.service";
import { ParamGraphService } from "../param-graph/param-graph.service";
import { Paramgraph } from "../param-graph/paramgraph.model";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  id = 0;
  id_client = 0;
  type_object = "dashboard";
  private sub: any;

  data: any[] = [];
  labels: any[] = [];

  displayDetailsTable = false;
  dataForDetailsTable: any;

  paramGraphs: Paramgraph[];

  staticColors = [
    "#e6194b",
    "#3cb44b",
    "#ffe119",
    "#4363d8",
    "#f58231",
    "#911eb4",
    "#46f0f0",
    "#f032e6",
    "#bcf60c",
    "#fabebe",
    "#008080",
    "#e6beff",
    "#9a6324",
    "#fffac8",
    "#800000",
    "#aaffc3",
    "#808000",
    "#ffd8b1",
    "#000075",
    "#808080",
    "#ffffff",
    "#000000",
  ];
  rgbaColors = [
    "rgba(203, 65, 84",
    "rgba(43, 108, 196",
    "rgba(113, 188, 120",
    "rgba(239, 222, 205",
    "rgba(24, 167, 181",
    "rgba(253, 217, 181",
    "rgba(48, 186, 143",
    "rgba(120, 219, 226",
    "rgba(135, 169, 107",
    "rgba(255, 164, 116",
    "rgba(250, 231, 181",
    "rgba(159, 129, 112",
    "rgba(253, 124, 110",
    "rgba(172, 229, 238",
    "rgba(31, 117, 254",
    "rgba(162, 162, 208",
    "rgba(102, 153, 204",
    "rgba(13, 152, 186",
    "rgba(115, 102, 189",
    "rgba(222, 93, 131",
    "rgba(203, 65, 84",
    "rgba(180, 103, 77",
    "rgba(255, 127, 73",
    "rgba(234, 126, 93",
    "rgba(176, 183, 198",
    "rgba(255, 255, 153",
    "rgba(28, 211, 162",
    "rgba(255, 170, 204",
    "rgba(221, 68, 146",
    "rgba(29, 172, 214",
    "rgba(188, 93, 88",
    "rgba(221, 148, 117",
    "rgba(154, 206, 235",
    "rgba(255, 188, 217",
    "rgba(253, 219, 109",
    "rgba(239, 205, 184",
    "rgba(110, 81, 96",
    "rgba(206, 255, 29",
    "rgba(109, 174, 129",
    "rgba(195, 100, 197",
    "rgba(204, 102, 102",
    "rgba(231, 198, 151",
    "rgba(252, 217, 117",
    "rgba(168, 228, 160",
    "rgba(149, 145, 140",
    "rgba(28, 172, 120",
    "rgba(17, 100, 180",
    "rgba(240, 232, 145",
    "rgba(255, 29, 206",
    "rgba(178, 236, 93",
    "rgba(93, 118, 203",
    "rgba(202, 55, 103",
    "rgba(59, 176, 143",
    "rgba(254, 254, 34",
    "rgba(252, 180, 213",
    "rgba(255, 244, 79",
    "rgba(255, 189, 136",
    "rgba(246, 100, 175",
    "rgba(170, 240, 209",
    "rgba(205, 74, 76",
    "rgba(237, 209, 156",
    "rgba(151, 154, 170",
    "rgba(255, 130, 67",
    "rgba(200, 56, 90",
    "rgba(239, 152, 170",
    "rgba(253, 188, 180",
    "rgba(26, 72, 118",
    "rgba(197, 75, 140",
    "rgba(25, 116, 210",
    "rgba(255, 163, 67",
    "rgba(186, 184, 108",
    "rgba(255, 117, 56",
    "rgba(255, 43, 43",
    "rgba(248, 213, 104",
    "rgba(230, 168, 215",
    "rgba(65, 74, 76",
    "rgba(255, 110, 74",
    "rgba(28, 169, 201",
    "rgba(255, 207, 171",
    "rgba(197, 208, 230",
    "rgba(253, 221, 230",
    "rgba(21, 128, 120",
    "rgba(252, 116, 253",
    "rgba(247, 143, 167",
    "rgba(142, 69, 133",
    "rgba(116, 66, 200",
    "rgba(157, 129, 186",
    "rgba(254, 78, 218",
    "rgba(255, 73, 108",
    "rgba(214, 138, 89",
    "rgba(113, 75, 35",
    "rgba(255, 72, 208",
    "rgba(227, 37, 107",
    "rgba(238,32 ,77 ",
    "rgba(255, 83, 73",
    "rgba(192, 68, 143",
    "rgba(31, 206, 203",
    "rgba(120, 81, 169",
    "rgba(255, 155, 170",
    "rgba(252, 40, 71",
    "rgba(118, 255, 122",
    "rgba(159, 226, 191",
    "rgba(165, 105, 79",
    "rgba(138, 121, 93",
    "rgba(69, 206, 162",
    "rgba(251, 126, 253",
    "rgba(205, 197, 194",
    "rgba(128, 218, 235",
    "rgba(236, 234, 190",
    "rgba(255, 207, 72",
    "rgba(253, 94, 83",
    "rgba(250, 167, 108",
    "rgba(235, 199, 223",
    "rgba(252, 137, 172",
    "rgba(219, 215, 210",
    "rgba(23, 128, 109",
    "rgba(222, 170, 136",
    "rgba(119, 221, 231",
    "rgba(255, 255, 102",
    "rgba(146, 110, 174",
    "rgba(50, 74, 178",
    "rgba(247, 83, 148",
    "rgba(255, 160, 137",
    "rgba(143, 80, 157",
    "rgba(255, 255, 255",
    "rgba(162, 173, 208",
    "rgba(255, 67, 164",
    "rgba(252, 108, 133",
    "rgba(205, 164, 222",
    "rgba(252, 232, 131",
    "rgba(197, 227, 132",
    "rgba(255, 174, 66",
  ];

  filteredColors = this.staticColors;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dashboardService: DashboardService,
    private paramGraphService: ParamGraphService
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.id_client = params["id_client"];
      this.type_object = params["type_object"];
    });

    this.loadData();
  }

  getDataForGraph(datasets, typegraph, agregat) {
    const labels = Object.keys(datasets).sort();
    const sublabels = this.getUniqueSublabels(datasets);
    const dataset = [];
    const data = [];

    if (typegraph == "radar" || typegraph == "line" || typegraph == "bar") {
      for (const i in sublabels) {
        const randomColor = this.random_rgba(i);

        dataset.push({
          label: sublabels[i],
          backgroundColor: randomColor.opacity,
          borderColor: randomColor.normal,
          pointBackgroundColor: randomColor.normal,
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: randomColor.normal,
          data: [],
        });
      }

      for (const i in datasets) {
        for (const z in sublabels) {
          dataset
            .find((y) => y.label == sublabels[z])
            .data.push(
              datasets[i][sublabels[z]] ? datasets[i][sublabels[z]].value : 0
            );
        }
      }
    } else {
      const statColors = [];
      for (const i in labels) {
        const col = this.random_rgba(i);
        statColors.push(col.normal);
      }
      const data: any = [];

      for (const z in datasets) {
        let sumVal = 0;
        let count = 0;
        for (const d in datasets[z]) {
          sumVal += datasets[z][d].value;
          count++;
        }
        data.push(agregat == "moy" ? sumVal / count : sumVal);
      }
      dataset.push({
        backgroundColor: statColors,
        hoverBackgroundColor: statColors,
        data: data,
      });
    }

    return {
      labels: labels,
      datasets: dataset,
      options: {
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return (
                data.datasets[tooltipItem.datasetIndex].label +
                " : " +
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              );
            },
          },
        },
      },
    };
  }

  getUniqueSublabels(datasets) {
    let subObjects = [];
    const results = [];

    for (const i in datasets) {
      const dt: any[] = datasets[i];
      subObjects = subObjects.concat(this.getObjectUniqueKeys(dt));
    }

    for (const i in subObjects) {
      if (!results.find((z) => z == subObjects[i])) {
        results.push(subObjects[i]);
      }
    }
    return results;
  }

  random_rgba(i) {
    const color = this.rgbaColors[i];

    const colors = {
      normal: color + " , 1)",
      opacity: color + ", 0.2)",
    };

    return colors;
  }

  loadData() {
    this.paramGraphService.get(this.id, this.type_object).then((res) => {
      this.paramGraphs = res.data;
      for (const prop in this.paramGraphs) {
        const configs = this.paramGraphs[prop];
        this.dashboardService
          .getLabelsForGraph(
            this.id,
            this.id_client,
            configs.labels,
            configs.data,
            configs.agregat,
            configs.description,
            configs.type_object,
            configs.id_analyse,
            configs.id_structure
          )
          .then((res) => {
            const data: any[] = JSON.parse(res.data);
            let max = 0;
            for (const i in data) {
              const size = this.getObjectSize(data[i]);
              if (max < size) {
                max = size;
              }
            }

            this.data[prop] = this.getDataForGraph(
              [].sort.call(data),
              configs.typegraph,
              configs.agregat
            );
          });
      }
    });
  }

  getRandomColor2() {
    let length = 6;
    const chars = "0123456789ABCDEF";
    let hex = "#";
    while (length--) {
      hex += chars[(Math.random() * 16) | 0];
    }
    return hex;
  }

  getObjectSize(obj) {
    let size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        size++;
      }
    }
    return size;
  }

  getObjectUniqueKeys(obj) {
    const keys = Object.keys(obj);

    keys.sort(function (a, b) {
      const textA = a.toUpperCase();
      const textB = b.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    return keys;
  }

  displayTable(data) {
    this.dataForDetailsTable = data;
    this.displayDetailsTable = true;
  }

  getRandomColor() {
    return this.staticColors[
      Math.floor(Math.random() * this.filteredColors.length)
    ];
  }
}
