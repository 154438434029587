<br /><br />
<div
  class="container"
  style="
    width: 50%;
    border: 2px solid #be1d2c;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px #be1d2c59;
  "
>
  <h1>Ariskless</h1>
  <br /><br />
  <ul class="nav nav-tabs" id="signin" role="tablist">
    <li class="nav-item">
      <a
        class="nav-link active"
        id="login-tab"
        data-toggle="tab"
        href="#login"
        role="tab"
        aria-controls="login"
        aria-selected="true"
        ><b>SE CONNECTER:</b></a
      >
    </li>
  </ul>
  <br /><br />
  <div class="tab-content" id="sigininContent">
    <div
      class="tab-pane fade show active"
      id="login"
      role="tabpanel"
      aria-labelledby="login-tab"
    >
      <form [formGroup]="aFormGroup">
        <div class="form-group">
          <input
            formControlName="email"
            [autofocus]="true"
            type="email"
            class="form-control"
            name="email"
            id="loginInputEmail1"
            aria-describedby="emailHelp"
            placeholder="E-mail"
          />
        </div>
        <div class="form-group">
          <input
            formControlName="password"
            type="password"
            name="password"
            class="form-control"
            id="loginInputPassword1"
            placeholder="Mot de passe"
          />
        </div>
        <!--<div class="form-check">
            <input type="checkbox" class="form-check-input" id="loginCheck1">
            <label class="form-check-label" for="loginCheck1">Restez connecté!</label>
          </div>-->
<!--        <re-captcha-->
<!--          #captchaElem-->
<!--          [siteKey]="siteKey"-->
<!--          (ngModelChange)="handleSuccess($event)"-->
<!--          formControlName="recaptcha"-->
<!--        >-->
<!--        </re-captcha>-->
        <br />
        <button type="submit" class="btn btn-primary" (click)="login($event)">
          SE CONNECTER
        </button>
        <button
          style="float: right"
          class="btn btn-outline-primary"
          routerLink="/forgottenPassword"
        >
          Mot de passe oublié
        </button>
      </form>
    </div>
  </div>
</div>
