<p-dataView
  #dv
  [value]="traces"
  [paginator]="true"
  [rows]="20"
  paginatorPosition="both"
  filterBy="dtcreate,user_name,log"
  [sortField]="sortField"
  [sortOrder]="sortOrder"
>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="ui-g">
        <div class="p-col-12 ui-md-4">
          <p-dropdown
            appendTo="body"
            [filter]="true"
            [options]="sortOptions"
            [(ngModel)]="sortKey"
            placeholder="Sort By"
            (onChange)="onSortChange($event)"
            [style]="{ 'min-width': '15em' }"
          ></p-dropdown>
        </div>
        <div class="p-col-6 ui-md-4 filter-container">
          <div style="position: relative">
            <input
              type="search"
              pInputText
              placeholder="Rechercher"
              (keyup)="dv.filter($event.target.value)"
            />
          </div>
        </div>
        <div class="p-col-6 ui-md-4" style="text-align: right">
          <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
        </div>
      </div>
    </div>
  </p-header>
  <ng-template let-trace pTemplate="listItem">
    <div class="ui-g" style="padding: 2em; border-bottom: 1px solid #d9d9d9">
      <div class="p-col-12 ui-md-8 trace-details">
        <div class="ui-g">
          <div class="p-col-2 ui-sm-6">ID:</div>
          <div class="p-col-10 ui-sm-6">
            <b>{{ trace.id }}</b>
          </div>

          <div class="p-col-2 ui-sm-6">DSOPER:</div>
          <div class="p-col-10 ui-sm-6">
            <b>{{ trace.user_name }}</b>
          </div>

          <div class="p-col-2 ui-sm-6">LOG:</div>
          <div class="p-col-10 ui-sm-6">
            <b>
              <pre>{{ trace.log }}</pre>
            </b>
          </div>

          <div class="p-col-2 ui-sm-6">Date de création:</div>
          <div class="p-col-10 ui-sm-6">
            <b>{{ trace.dtcreate }}</b>
          </div>
        </div>
      </div>
      <div class="p-col-12 ui-md-1 search-icon">
        <button
          pButton
          type="button"
          icon="fa fa-search"
          (click)="selectTrace($event, trace)"
        ></button>
      </div>
    </div>
  </ng-template>
  <ng-template let-trace pTemplate="gridItem">
    <div style="padding: 0.5em" class="p-col-12 ui-md-3">
      <p-panel [header]="trace.id" [style]="{ 'text-align': 'center' }">
        <hr class="ui-widget-content" style="border-top: 0" />
        <button
          pButton
          type="button"
          icon="fa fa-search"
          (click)="selectTrace($event, trace)"
          style="margin-top: 0"
        ></button>
      </p-panel>
    </div>
  </ng-template>
</p-dataView>

<p-dialog
  appendTo="body"
  [focusOnShow]="false"
  header="Trace Details"
  [(visible)]="displayDialog"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  width="225"
  (onAfterHide)="onDialogHide()"
>
  <div class="ui-g" *ngIf="selectedTrace">
    <div class="p-col-4">LOG:</div>
    <div class="p-col-8">
      <code>{{ selectedTrace.log }}</code>
    </div>
  </div>
</p-dialog>
