import { Component, HostListener, OnInit } from "@angular/core";
import { Client } from "../client/client.model";
import { UserClientsService } from "../user-clients/user-clients.service";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { UserBlockService } from "../services/user-block.service";
import { TypeEvalRiskService } from "../services/type-eval-risk.service";
import { ColumnsService } from "../columns/columns.service";
import { UserRightsService } from "../services/user-rights.service";
import { ConfirmationService } from "primeng/api";
import { Router } from "@angular/router";
import { AppConfig } from "../config";
import { DashboardsService } from "./dashboards.service";
import { interval, Subject } from "rxjs";
import { Log } from "../models/log.model";
import { Dashboard } from "./dashboard.model";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-dashboards",
  templateUrl: "./dashboards.component.html",
  styleUrls: ["./dashboards.component.scss"],
})
export class DashboardsComponent implements OnInit {
  client: any;
  selectedClient: any;
  currentUser: any;
  displayDialog: boolean;
  dashboard: Dashboard = {} as Dashboard;
  selectedDashboard: Dashboard;
  newDashboard: boolean;
  dashboards: Dashboard[] = [] as Dashboard[];
  cols: any[];
  user_block: any = 0;
  lastIDDashboard: number;
  timeoutCounter = 0;
  stopmousemove = false;
  displayDialogImport = false;
  showDeleteColumn = false;
  importFiles: any[] = [];
  colsAC: any[];
  refreshColumns = false;
  autofocus = true;
  private onDestroy$ = new Subject();

  displayDialogCarto = false;
  imgSrc;

  selectedDVLayout = "grid";

  tableLayout = false;

  constructor(
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    private typeEvalRiskService: TypeEvalRiskService,
    private userClientService: UserClientsService,
    private columnService: ColumnsService,
    public userRightsService: UserRightsService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public config: AppConfig,
    private dashboardsService: DashboardsService
  ) {}

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem("user")).data;
    this.loadDataViewFromLocalStorage();
    this.userClientService.getByUserId(user.id).then((res) => {
      this.client = res.data ? res.data : ([] as Client[]);
      if (localStorage.getItem("client")) {
        this.selectedClient = JSON.parse(localStorage.getItem("client"));
      } else {
        this.selectedClient = this.client[0] ? this.client[0] : 0;
      }
      this.loadData();
    });

    this.currentUser = JSON.parse(localStorage.getItem("user")).data.email;

    this.cols = [
      { field: "name", header: "Libellé: " },
      { field: "description", header: "Commentaires/Précisions: " },
      { field: "nameClient", header: "Structure client: " },
      { field: "dtcreate", header: "Création: " },
      { field: "dtmodif", header: "Modification: " },
    ];

    interval(this.config.RefreshMilisecs)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((x) => {
        if (this.timeoutCounter < this.config.RefreshTimer && this.client) {
          this.loadData();
          this.timeoutCounter++;
        }
      });
  }

  loadDataViewFromLocalStorage() {
    const currentSelectedDVLayout = localStorage.getItem("selectedDVLayout");
    const currentTableLayout = localStorage.getItem("tableLayout");

    if (currentSelectedDVLayout) {
      this.selectedDVLayout = currentSelectedDVLayout;
    } else {
      this.selectedDVLayout = "grid";
      localStorage.setItem("selectedDVLayout", this.selectedDVLayout);
    }

    if (typeof currentTableLayout != "undefined") {
      this.tableLayout = currentTableLayout == "true" ? true : false;
    } else {
      this.tableLayout = false;
      localStorage.setItem("tableLayout", String(this.tableLayout));
    }
  }

  changeDVLayout(event) {
    this.selectedDVLayout = event.layout;
    localStorage.setItem("selectedDVLayout", this.selectedDVLayout);
  }

  changeTableLayout() {
    this.tableLayout = !this.tableLayout;
    localStorage.setItem("tableLayout", String(this.tableLayout));
  }

  changeClientCache() {
    localStorage.setItem("client", JSON.stringify(this.selectedClient));
    this.dashboards = null;
  }

  @HostListener("document:mousemove")
  onMouseMove() {
    if (this.stopmousemove == false) {
      this.timeoutCounter = 0;
    }
  }

  showDialogToAdd() {
    if (
      this.dashboard != null &&
      this.dashboard.id != null &&
      !this.dashboard
    ) {
      this.closeDashboards();
    }
    this.newDashboard = true;
    this.dashboard = {} as Dashboard;

    this.displayDialog = true;
  }

  save() {
    const analyses = this.dashboards
      ? [...this.dashboards]
      : ([] as Dashboard[]);
    const jsonanalyse = JSON.stringify(this.dashboard);
    this.dashboard.id_client = this.selectedClient.code;
    if (this.dashboard.name && this.dashboard.description) {
      if (this.newDashboard) {
        analyses.push(this.dashboard);
        this.dashboardsService.create(this.dashboard).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Dashboard créé!");
            this.trace.createTrace({
              action: "CREATE DASHBOARD",
              data: jsonanalyse,
              description: "CREATION DASHBOARD",
            } as Log);
          } else {
            this.msgs.showError(res.message);
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "CREATE DASHBOARD",
              data: jsonmsg,
              description: "ERREUR CREATION DASHBOARD",
            } as Log);
          }
        });
      } else {
        analyses[this.dashboards.indexOf(this.selectedDashboard)] =
          this.dashboard;
        this.dashboardsService.update(this.dashboard).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Dashboard mise à jour!");
            this.trace.createTrace({
              action: "UPDATE DASHBOARD",
              data: JSON.stringify(analyses),
              description: "Mise a jour analyses",
            } as Log);
          } else {
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "UPDATE DASHBOARD",
              data: jsonmsg,
              description: "ERREUR MAJ DASHBOARD",
            } as Log);
          }
          this.userBlock
            .unblockUser(
              "bo_dashboards",
              this.dashboard ? this.dashboard.id : this.lastIDDashboard
            )
            .then((data) => {
              console.log("Unblock users");
            });
        });
      }
    } else {
      this.msgs.showError("Merci de remplir tous les champs!");
    }
    this.lastIDDashboard = this.dashboard.id;
    this.dashboards = analyses;
    this.dashboard = null;
    this.displayDialog = false;
  }
  delete() {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer cette ligne?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.dashboardsService.delete(this.dashboard.id).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Dashboard supprimée!");
            this.trace.createTrace({
              action: "DELETE DASHBOARD",
              data: JSON.stringify(this.dashboard),
              description: "SUPPRESSION DASHBOARD",
            } as Log);
          } else {
            this.msgs.showError(res.message);
          }
        });
        this.userBlock
          .unblockUser(
            "bo_dashboards",
            this.dashboard.id ? this.dashboard.id : this.lastIDDashboard
          )
          .then((data) => {
            console.log("Unblock users");
          });
        this.lastIDDashboard = this.dashboard.id;
        const index = this.dashboards.indexOf(this.selectedDashboard);
        this.dashboards = this.dashboards.filter((val, i) => i !== index);
        this.dashboard = null;
        this.displayDialog = false;
      },
    });
  }

  onRowSelect(event) {
    this.refreshColumns = true;
    this.user_block = 0;
    this.newDashboard = false;
    this.dashboard = this.cloneDashboard(event);
    this.displayDialog = true;

    this.userBlock
      .verifUserBlock("bo_dashboards", this.dashboard.id)
      .then((userblock) => {
        if (userblock.data != "" && userblock.data != 0) {
          this.user_block = userblock.data;
          alert("Data en cours d'utilisation par : " + this.user_block);
        } else {
          this.userBlock.blockUser("bo_dashboards", this.dashboard.id);
          this.lastIDDashboard = this.dashboard.id;
        }
      });
  }
  cloneDashboard(r: Dashboard): Dashboard {
    const analyse = {} as Dashboard;
    for (const prop in r) {
      analyse[prop] = r[prop];
    }
    return analyse;
  }
  @HostListener("window:beforeunload")
  closeDashboards() {
    if (
      (this.user_block == this.currentUser || this.user_block == 0) &&
      !this.newDashboard &&
      this.dashboard != null
    ) {
      this.userBlock
        .unblockUser(
          "bo_dashboards",
          this.dashboard ? this.dashboard.id : this.lastIDDashboard
        )
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
    this.selectedDashboard = {} as Dashboard;
    this.stopmousemove = false;
    this.refreshColumns = false;
  }

  loadData() {
    this.dashboardsService
      .get(this.selectedClient.code)
      .then(
        (dashboards) =>
          (this.dashboards = dashboards.data
            ? dashboards.data
            : ([] as Dashboard[]))
      );
  }

  makeClientList(clients: Client[]) {
    const listClients: any[] = [];
    for (const prop in clients) {
      listClients.push({
        id: clients[prop].id,
        name: clients[prop].name,
      });
    }
    return listClients;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeDashboards();
  }

  stopRefresh() {
    this.timeoutCounter = this.config.RefreshTimer;
    this.stopmousemove = true;
  }

  goToDashboard(id) {
    this.router.navigate([
      "/dashboard",
      id,
      this.selectedClient.code,
      "dashboard",
    ]);
  }

  deleteDashboardLine(id) {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer cette ligne?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        if (this.dashboards.length == 1) {
          this.dashboards = [] as Dashboard[];
        }
        this.dashboardsService.delete(id).then((res) => {
          this.loadData();
        });
      },
    });
  }

  importData(event) {
    this.loadData();
  }

  openImportDialog() {
    this.displayDialogImport = true;
  }

  redirectToCarto() {
    const d = new Date();
    const n = d.getMilliseconds();
    this.imgSrc =
      this.config.API_Host + "get/carto/" + this.selectedClient.code + "/" + n;
    this.displayDialogCarto = true;
  }
}
