
import {combineAll, takeUntil} from 'rxjs/operators';
import {Component, HostListener, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Column} from './column.model';
import {ObjectTypes} from '../models/object_types';
import {ColumnsService} from './columns.service';
import {interval, Observable, Subject} from 'rxjs';
import {AppConfig} from '../config';
import {MsgService} from '../services/msg.service';
import {UserClientsService} from '../user-clients/user-clients.service';
import {ColorRangeService} from '../color-range/color-range.service';
import {List} from '../list-object/list.model';
import {ListService} from '../list-object/list.service';
import {RiskService} from '../risk/risk.service';
import {FormulaService} from '../formula-object/formula.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import saveAs from 'file-saver';
import {UserRightsService} from '../services/user-rights.service';
import {AnalyseLineService} from '../services/analyse-line.service';
import {AnalyseLine} from '../models/analyseline.model';
import {UserGroupService} from '../user-group/user-group.service';

@Component({
  selector: 'app-columns',
  templateUrl: './columns.component.html',
  styleUrls: ['./columns.component.css'],
  encapsulation : ViewEncapsulation.None,
})
export class ColumnsComponent implements OnInit {

    columns: Column[] = [] as Column[];
    @Input() id_analyse: number;
    @Input() id_client: number;
    @Input() refresh;
    typeObject: any[] = [];
    colorRanges: any[] = [];
    dateColors: any[] = [{label : 'Aucune' , name : 'none'}, {label : 'Date expirée' , name : 'expired_date'}];
    objects: any[] = [];
    cols: any[];
    timeoutCounter = 0;
    private onDestroy$ = new Subject();
    stopmousemove = false;
    displayDialogImport = false;
    importFiles: any[] = [];
    analyseLines: AnalyseLine[] = [] as AnalyseLine[];

    listLists: any[];
    listGroups: any[];
    listRisks: any[];
    listFormulas: any[];

    loadingScreen = false;

    constructor(
      private objectTypes: ObjectTypes,
      private columnService: ColumnsService,
      private colorRangeService: ColorRangeService,
      private listService: ListService,
      private riskService: RiskService,
      private formulaService: FormulaService,
      public config: AppConfig,
      private userClientsService: UserClientsService,
      private confirmationService: ConfirmationService,
      private userRightsService: UserRightsService,
      private analyseLineService: AnalyseLineService,
      private userGroupService: UserGroupService,
      private msgs: MsgService
  ) {
    this.typeObject = objectTypes.types;
  }

  ngOnInit() {
      const user = JSON.parse(localStorage.getItem('user')).data;
      this.loadData();
      interval(200).pipe(takeUntil(this.onDestroy$)).subscribe(x => {
          if (this.refresh) {
              this.columns = [] as Column[];
              this.loadData();
              this.refresh = false;
          }
      });
      interval(this.config.RefreshMilisecs).pipe(takeUntil(this.onDestroy$)).subscribe(x => {
          if (this.timeoutCounter < this.config.RefreshTimer) {
              this.loadData();
              this.timeoutCounter++;
          }
      });


      this.cols = [
          { field: 'name', header: 'Nom de la colonne: ' },
          { field: 'description', header: 'Description :' },
          { field: 'group_name', header: 'Nom du groupe de colonnes: ' },
          { field: 'group_color', header: 'Couleur entête du groupe de colonnes: ' },
          { field: 'type_object', header: 'Type Objet: ' },
          { field: 'id_subobject', header: 'Type valeur liste: ' },
          { field: 'color_range', header: 'Gamme de couleur: ' },
          { field: 'pixel', header: 'Taille colonne (px): ' },
          { field: 'authorized_groups', header: 'Groupes autorisés: ' },
          { field: 'form', header: 'Formulaire: ' },
          { field: 'dtmodif', header: 'Date: ' }
      ];
  }

  @HostListener('document:mousemove')
  onMouseMove() {
    if (this.stopmousemove == false) {
      this.timeoutCounter = 0;
    }
  }

  addLigne() {
    const newIndex = this.columns.length > 0 ? Math.max.apply(Math, this.columns.map(function(o) { return o.order_num; })) + 1 : 1;
    const newcolumn = {} as Column;
    newcolumn.order_num = newIndex;
    const options = {
      idLigne : 'undefined',
      name : 'order_num',
      value : newIndex,
        id_client : this.id_client,
        id_analyse: this.id_analyse
    };
    this.columns.push(newcolumn);
    this.columnService.addupdate(options).then(res => {
          this.loadData();
    });

    localStorage.removeItem('workspace_selectedColumns_' + this.id_analyse + '_' + this.id_client);
  }

  loadData() {
    this.columnService.get(this.id_client, this.id_analyse).then(res => {
      this.columns =  res.data ? res.data : [] as Column[];
      this.columns = this.columns.map(column => {
        // Création des propriétés utiles au multiselect userGroup
        const authorized_groups_array = column.authorized_groups ?
          column.authorized_groups.split(',').map(value => parseInt(value, 10)) : [];
        const authorized_groups_label = column.authorized_groups && this.listGroups ?
          this.listGroups.filter(group => authorized_groups_array.includes(group.value)).map(group => group.label).join(', ') : '';
        return {...column, authorized_groups_array, authorized_groups_label};
      });
      this.loadingScreen = false;
    });
    this.colorRangeService.getColorRangesForList(this.id_client).then(res => {
        this.colorRanges = res.data;
        this.colorRanges.unshift(
            {label : 'Aucune' , name : 'none'}
        );
    });

    this.listService.getListForList(this.id_client).then(res => {
        this.listLists = res.data;
        this.listLists.unshift(
            {label : 'Aucune' , name : 'none'}
        );
    });

    this.riskService.getRiskForList(this.id_client).then(res => {
        this.listRisks = res.data;
        this.listRisks.unshift(
            {label : 'Aucune' , name : 'none'}
        );
    });

    this.formulaService.getFormulaForList(this.id_client, this.id_analyse).then(res => {
        this.listFormulas = res.data;
        this.listFormulas.unshift(
            {label : 'Aucune' , name : 'none'}
        );
    });

    this.userGroupService.getUserGroups(this.id_client).then(res => {
      this.listGroups =  res ? res : [];
      this.listGroups = this.listGroups.map((group: any) => ({ label: group.name, value: group.id }));
    });

    this.analyseLineService.get(this.id_analyse).then(res => {
        this.analyseLines =  res.data ? res.data : [] as AnalyseLine[];
        this.loadingScreen = false;
    });
  }

  loadListObjects(typeObject) {
      const table = (this.objectTypes.types.find(i => i.label == typeObject)) ? (this.objectTypes.types.find(i => i.label == typeObject).name) : 0;


      if (table === 0) {
        this.msgs.showError('Selectionner un type d\'objet!');
      } else {
        this.columnService.getColumnObjects(table).then(res => {
          this.objects = res.data;
          this.objects.unshift({label : 'Choisir un objet', value: ''});
        });
      }

  }


  changeValue(options) {

      console.log(options);
      options.id_client = this.id_client;
      options.id_analyse = this.id_analyse;
      if (options.name == 'id_subobject' && !options.value) {
          options.value = 0;
      }

      if (options.name === 'authorized_groups') {
        options.value = options.value.join(',');
      }

      if (options.name == 'type_object' && this.analyseLines.length > 0) {
          this.confirmationService.confirm({
              message: 'Etes-vous sur de vouloir modifier le type d\'objet pour cette colonne? <br> <b><u>ATTENTION: Toutes les données de cette colonne seront suprimées!</u></b>',
              header: 'Confirmation modification type d\'objet',
              icon: 'pi pi-info-circle',
              accept: () => {
                this.loadingScreen = true;
                  this.columnService.addupdate(options).then(res => {
                      this.loadData();
                      this.stopmousemove = false;
                      if (res.code == 23000) {
                          this.msgs.showError('Le nom de la colonne existe déjà!');
                      }
                      this.loadingScreen = false;
                  });
                  localStorage.removeItem('workspace_selectedColumns_' + this.id_analyse + '_' + this.id_client);
              },
              reject: () => {
                  this.loadData();
                  this.loadingScreen = false;
              }
          });
      } else {
          this.loadingScreen = true;
          this.columnService.addupdate(options).then(res => {

              this.loadData();
              this.stopmousemove = false;
              if (res.code == 23000) {
                  this.msgs.showError('Le nom de la colonne existe déjà!');
              }
              this.loadingScreen = false;
          });
          localStorage.removeItem('workspace_selectedColumns_' + this.id_analyse + '_' + this.id_client);
      }

  }

  deleteLine(id) {
    if (this.columns.length == 1) {
      this.columns = [] as Column[];
    }
    this.columnService.delete(id, this.id_analyse, this.id_client).then(res => {
        this.loadData();
        localStorage.removeItem('workspace_selectedColumns_' + this.id_analyse + '_' + this.id_client);
    });
  }


      loadSelectedValue (array, value) {
            let searchVal = null;

            if (array) {
                searchVal = array.find(i => i.value == value);
            }
            return searchVal ? searchVal : {value: '', label: ''};
      }

    stopRefresh() {
      this.timeoutCounter = this.config.RefreshTimer;
      this.stopmousemove = true;
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }

    openImportDialog() {
        this.displayDialogImport = true;
    }

    importData(event) {
        this.loadData();
        this.displayDialogImport = false;
    }

    deleteAllLines() {
        this.confirmationService.confirm({
            message: 'Etes-vous sur de vouloir supprimer toutes les lignes? <br> <b><u>ATTENTION: Toutes les données de votre analyse seront suprimées!</u></b>',
            header: 'Confirmation suppression',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.columnService.deleteAll(this.id_analyse).then(res => {
                    this.loadData();
                    this.msgs.showSuccess('Ligne supprimée!');
                });
            }
        });
    }


    exportCsv() {


        const headerFirst = this.cols.map(i => {
                    return i.header;
            }
        );
        headerFirst.unshift('Order');
        const header = headerFirst.join(';');
        const data = this.columns.map(column => {
            if (column.id_subobject !== 0) {
                switch (column.type_object) {
                    case 'List':
                        column.id_subobject = this.loadSelectedValue(this.listLists, column.id_subobject);
                        break;
                    case 'Formule':
                        column.id_subobject = this.loadSelectedValue(this.listFormulas, column.id_subobject);
                        break;
                    case 'Risk':
                        column.id_subobject = this.loadSelectedValue(this.listRisks, column.id_subobject);
                        break;
                }
            }
            return (column.order_num ? column.order_num : '') + ';'
                + (column.name ? column.name : '') + ';'
                + (column.description ? column.description : '') + ';'
                + (column.group_name ? column.group_name : '') + ';'
                + (column.group_color ? column.group_color : '') + ';'
                + (column.type_object ? column.type_object : '') + ';'
                + (column.id_subobject && column.id_subobject.label ? column.id_subobject.label : '') + ';'
                + (column.color_range ? column.color_range : '') + ';'
                + (column.pixel ? column.pixel : '') + ';'
                + (column.form ? column.form : '') + ';'
                + (column.dtcreate ? column.dtcreate : '');
        });

        data.unshift(header);
        const csvArray = data.join('\r\n');

        const blob = new Blob(['\ufeff', csvArray], {type: 'text/csv'});
        saveAs(blob, 'param_analyse.csv');
    }


}
