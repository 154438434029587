import { Component, Input, OnInit } from "@angular/core";
import { UserGroup } from "./user-group.model";
import { UserGroupService } from "./user-group.service";
import { User } from "../users/user.model";
import { UsersService } from "../users/users.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { Table } from "primeng/table";

@Component({
  providers: [MessageService],
  selector: "app-user-group",
  styleUrls: ["./user-group.component.css"],
  templateUrl: "./user-group.component.html",
})
export class UserGroupComponent implements OnInit {
  displayDialog: boolean;
  editUserGroupMode: boolean;
  selectedGroup: UserGroup = new UserGroup();
  user: User = {} as User;
  groups: UserGroup[] = [];
  users: User[] = [];
  cols: any[];
  messages: [];
  @Input("clients") clients: any;

  constructor(
    private userService: UsersService,
    private groupService: UserGroupService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    // Récupération des groupes et petite manip pour recherche sur noms des utilisateurs
    this.groupService.getUserGroups(this.clients).then((groups) => {
      this.groups = groups.map((group) => ({
        ...group,
        usernames: group.users
          .reduce((acc, user) => acc + user.fullname + " ", "")
          .trim(),
      }));
    });

    this.userService.getUsersByIdClient(this.clients).then((response) => {
      const users = response.data;
      this.users = users.map((user: any) => ({
        label: user.lastname + " " + user.firstname,
        value: user.id,
      }));
    });
    this.cols = [
      { field: "name", header: "Nom du groupe", width: "22%" },
      { field: "description", header: "Description", width: "30%" },
      { field: "users", header: "Utilisateurs", width: "40%" },
      { field: "", header: "Actions", width: "8%" },
    ];
  }

  showDialogToAdd() {
    this.selectedGroup = new UserGroup();
    this.displayDialog = true;
    this.editUserGroupMode = false;
  }

  showDialogToUpdate(group: UserGroup) {
    this.selectedGroup = { ...group };
    this.selectedGroup.users = this.selectedGroup.users.map((user) => user.id);
    this.displayDialog = true;
    this.editUserGroupMode = true;
  }

  hideDialog() {
    this.displayDialog = false;
  }

  saveGroup() {
    this.selectedGroup.id_structure = this.clients;
    if (this.selectedGroup.id) {
      this.groupService.updateUserGroup(this.selectedGroup).then((response) => {
        this.messageService.add({
          severity: "success",
          summary: "Succès",
          detail: response.message,
          life: 3000,
        });
        this.refreshGroups();
      });
    } else {
      this.groupService.createUserGroup(this.selectedGroup).then((response) => {
        this.messageService.add({
          severity: "success",
          summary: "Succès",
          detail: response.message,
          life: 3000,
        });
        this.refreshGroups();
      });
    }
    this.displayDialog = false;
  }

  deleteGroup(group: UserGroup) {
    this.confirmationService.confirm({
      message: "Êtes-vous sûr de vouloir supprimer ce groupe?",
      accept: () => {
        this.groupService
          .deleteUserGroup(this.clients, group)
          .then((response) => {
            this.messageService.add({
              severity: "success",
              summary: "Succès",
              detail: response.message,
              life: 3000,
            });
            this.refreshGroups();
          });
      },
    });
  }

  refreshGroups() {
    this.groupService
      .getUserGroups(this.clients)
      .then((groups) => (this.groups = groups));
  }
}
