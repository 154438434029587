import { Injectable } from "@angular/core";
import { ApiResponse } from "../models/api-response.model";
import { AppConfig } from "../config";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class WorkspaceDetailsService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  getCurrentWorkspace(id_workspace) {
    return this.http
      .get(
        this.config.API_Host +
          "workspace_details/getcurrentworkspace/" +
          id_workspace
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getColumnsForAnalyse(id_analyse) {
    return this.http
      .get(
        this.config.API_Host +
          "workspace_details/getcolumnsforanalyse/" +
          id_analyse
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getNameAnalyse(id_analyse) {
    return this.http
      .get(
        this.config.API_Host + "workspace_details/getnameanalyse/" + id_analyse
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
