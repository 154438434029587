import { Injectable } from '@angular/core';
import {User} from '../users/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserRightsService {
  currentUser;

  constructor() {
    this.currentUser = JSON.parse(localStorage.getItem('user')).data;

  }

  getUserAdmin() {
    return JSON.parse(localStorage.getItem('user')).data.admin;
  }

  getUserCreate() {
    return JSON.parse(localStorage.getItem('user')).data.create;
  }

  getUserRead() {
    return JSON.parse(localStorage.getItem('user')).data.read;
  }

  getUserUpdate() {
    return JSON.parse(localStorage.getItem('user')).data.update;
  }

  getUserDelete() {
    return JSON.parse(localStorage.getItem('user')).data.delete;
  }

  getUserEmail() {
    return JSON.parse(localStorage.getItem('user')).data.email;
  }

  getUserAnalyse() {
    return JSON.parse(localStorage.getItem('user')).data.analyse === 1;
  }
  getUserDashboard() {
    return JSON.parse(localStorage.getItem('user')).data.dashboard === 1;
  }

  // vérifie que l'utilisateur connecté ait accès à la liste de groupe
  // prend en paramètre : id, tableau d'ids, ou ids séparé par virgule
  userInGroups(ids) {
    if (ids === undefined || ids === '' || this.getUserAdmin() === 1) {
      return true;
    }
    const dataGroupsLocalStorage = JSON.parse(localStorage.getItem('user')).data.groups;
    const userGroups = dataGroupsLocalStorage || [];
    const groupIds = Array.isArray(ids) ? ids : ids.split(',');
    for (let i = 0; i < userGroups.length; i++) {
      if (groupIds.includes(userGroups[i].id_group.toString())) {
        return true;
      }
    }
    return false;
  }
}
