import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  getLabelsForGraph(
    id_object,
    id_client,
    id_column_labels,
    id_column_data,
    agregat,
    description,
    type_object,
    id_analyse,
    id_structure
  ) {
    return this.http
      .post(
        this.config.API_Host + "dashboard/getLabels",
        JSON.stringify({
          id_analyse: id_analyse,
          id_client: id_client,
          labels: id_column_labels,
          data: id_column_data,
          agregat: agregat,
          description: description,
          type_object: type_object,
          id_object: id_object,
          id_structure: id_structure,
        })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
