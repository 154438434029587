import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { Analyse } from "./analyse.model";

@Injectable({
  providedIn: "root",
})
export class AnalyseService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  get(id_client: number) {
    return this.http
      .get(this.config.API_Host + "analyses/get/" + id_client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getById(id_analyse: number) {
    return this.http
      .get(this.config.API_Host + "analyses/getById/" + id_analyse)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  create(analyse: Analyse) {
    return this.http
      .post(
        this.config.API_Host + "analyses/create",
        JSON.stringify({ analyse })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  update(analyse: Analyse) {
    return this.http
      .post(
        this.config.API_Host + "analyses/update",
        JSON.stringify({ analyse })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  delete(id: number) {
    return this.http
      .post(
        this.config.API_Host + "analyses/delete",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  archivate(id: number) {
    return this.http
      .post(
        this.config.API_Host + "analyses/archivate",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getActionPerson(id_client: number) {
    return this.http
      .get(this.config.API_Host + "plan/getActionsPersons/" + id_client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  clientHasChildren(id_client: number) {
    return this.http
      .get(this.config.API_Host + "parent-analyse/hasChildren/" + id_client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
