<div class="flex align-items-center justify-content-between m-2">
  <button
    pButton
    type="button"
    icon="pi pi-plus"
    label="Nouveau groupe"
    (click)="showDialogToAdd()"
    class="p-button-sm mr-2"
  ></button>
  <span class="p-input-icon-left">
    <i class="pi pi-search"></i>
    <input
      type="text"
      pInputText
      (input)="dt.filterGlobal($event.target.value, 'contains')"
      placeholder="Rechercher..."
      maxlength="400px"
    />
  </span>
</div>
<p-table
  #dt
  [columns]="cols"
  [value]="groups"
  [paginator]="true"
  [rows]="10"
  [rowsPerPageOptions]="[5, 10, 20]"
  [responsive]="true"
  [scrollable]="true"
  [globalFilterFields]="['name', 'description', 'usernames']"
>
  <ng-template pTemplate="header">
    <tr>
      <th
        *ngFor="let col of cols"
        [style]="'width:' + col.width"
        [pSortableColumn]="col.header"
      >
        {{ col.header }}
        <p-columnFilter
          *ngIf="col.header === 'users'"
          type="text"
          field="getUsersFullName"
          matchMode="contains"
          display="menu"
        ></p-columnFilter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-group>
    <tr>
      <td [style]="'width:' + cols[0].width">{{ group.name }}</td>
      <td [style]="'width:' + cols[1].width">{{ group.description }}</td>
      <td [style]="'width:' + cols[2].width" class="list-group-user">
        <ul>
          <li *ngFor="let user of group.users">
            {{ user.fullname }}
          </li>
        </ul>
      </td>
      <td [style]="'width:' + cols[3].width + ';margin: auto'">
        <button
          pButton
          type="button"
          icon="pi pi-pencil"
          (click)="showDialogToUpdate(group)"
          class="p-button-rounded mx-2"
        ></button>
        <button
          pButton
          type="button"
          icon="pi pi-trash"
          (click)="deleteGroup(group)"
          class="p-button-rounded p-button-danger"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-toast></p-toast>

<!-- Modal pour l'ajout et la modification de groupe d'utilisateurs -->
<p-dialog
  header="{{
    editUserGroupMode
      ? 'Modifier le groupe d\'utilisateurs'
      : 'Ajouter un groupe d\'utilisateurs'
  }}"
  [(visible)]="displayDialog"
  [responsive]="true"
  [modal]="true"
  styleClass="user-group-modal"
>
  <form #userGroupForm="ngForm">
    <div class="form-group row">
      <label for="name" class="col-sm-3">Nom du groupe</label>
      <input
        pInputText
        id="name"
        type="text"
        name="name"
        class="col-sm-7"
        [(ngModel)]="selectedGroup.name"
        required
      />
    </div>
    <div class="form-group row">
      <label for="description" class="col-sm-3">Description</label>
      <input
        pInputText
        id="description"
        type="text"
        name="description"
        class="col-sm-7"
        [(ngModel)]="selectedGroup.description"
        required
      />
    </div>
    <div class="form-group row">
      <label for="groupUsers" class="col-sm-3">Utilisateurs</label>
      <div class="col-sm-7">
        <p-multiSelect
          class="select-user"
          id="groupUsers"
          name="users"
          [(ngModel)]="selectedGroup.users"
          [options]="users"
          [optionLabel]="user.firstname"
          [displaySelectedLabel]="true"
          [maxSelectedLabels]="3"
          [appendTo]="'body'"
          [showToggleAll]="true"
          [filter]="true"
          filterPlaceHolder="Rechercher un utilisateur"
        >
        </p-multiSelect>
      </div>
    </div>
  </form>
  <div class="p-dialog-footer">
    <button
      pButton
      type="button"
      label="Annuler"
      (click)="hideDialog()"
      class="p-button-sm p-button-secondary"
    ></button>
    <button
      pButton
      type="submit"
      label="{{ editUserGroupMode ? 'Modifier' : 'Ajouter' }}"
      [disabled]="!userGroupForm.valid"
      (click)="saveGroup()"
      class="p-button-sm p-button-primary"
    ></button>
  </div>
</p-dialog>
