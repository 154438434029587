import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { ColorRange } from "./colorRange.model";
import { interval, Subject, Observable, BehaviorSubject } from "rxjs";
import { ColorRangeService } from "./color-range.service";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { UserBlockService } from "../services/user-block.service";
import { AppConfig } from "../config";
import { Log } from "../models/log.model";
import { takeUntil } from "rxjs/operators";
import { ConfirmationService } from "primeng/api";
import { DataService } from "../services/data.service";
import { UserRightsService } from "../services/user-rights.service";

@Component({
  selector: "app-color-range",
  templateUrl: "./color-range.component.html",
  styleUrls: ["./color-range.component.css"],
})
export class ColorRangeComponent implements OnInit, OnChanges {
  displayDialog: boolean;
  colorRange: ColorRange = {} as ColorRange;
  selectedColorRange: ColorRange;
  newColorRange: boolean;
  colorRanges: ColorRange[];
  cols: any[];
  user_block: any = 0;
  lastIDColorRange: number;
  currentColorRange: any;
  currentUser: any;
  displayDialogImport = false;
  showDeleteColumn = false;
  importFiles: any[] = [];
  timeoutCounter = 0;
  @Input("clients") clients: any;
  private messageSource = new BehaviorSubject("");
  activatedComp: any;
  private onDestroy$ = new Subject();
  constructor(
    private colorRangeService: ColorRangeService,
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    private confirmationService: ConfirmationService,
    private userRightsService: UserRightsService,
    private data: DataService,
    public config: AppConfig
  ) {}
  ngOnInit() {
    this.data.currentMessage.subscribe(
      (message) => (this.activatedComp = message)
    );
    if (this.clients && this.activatedComp == "Couleurs") {
      this.loadData();

      this.currentUser = JSON.parse(localStorage.getItem("user")).data.email;
      this.cols = [
        { field: "group_name", header: "Libellé Group: " },
        { field: "name", header: "Libellé: " },
        { field: "color", header: "Couleur: " },
        { field: "range_from", header: "De: " },
        { field: "range_to", header: "A: " },
      ];
      interval(this.config.RefreshMilisecs)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((x) => {
          if (this.timeoutCounter < this.config.RefreshTimer && this.clients) {
            this.loadData();
            this.timeoutCounter++;
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.clients;
    if (currentItem) {
      this.loadData();
    }
  }
  @HostListener("document:mousemove")
  onMouseMove() {
    this.timeoutCounter = 0;
  }

  showDialogToAdd() {
    if (
      this.colorRange != null &&
      this.colorRange.id != null &&
      !this.colorRange
    ) {
      this.closeColorRanges();
    }
    this.newColorRange = true;
    this.colorRange = {} as ColorRange;
    this.colorRange.color = "#ff0000";
    this.displayDialog = true;
  }
  save() {
    const colorRanges = this.colorRanges
      ? [...this.colorRanges]
      : ([] as ColorRange[]);
    this.colorRange.id_client = this.clients;
    const jsoncolorRange = JSON.stringify(this.colorRange);
    if (this.colorRange.name && this.colorRange.group_name) {
      if (this.newColorRange) {
        colorRanges.push(this.colorRange);
        this.colorRangeService.createColorRange(this.colorRange).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Range couleur créé!");
            this.trace.createTrace({
              action: "CREATE COLOR RANGE",
              data: jsoncolorRange,
              description: "CREATION COLOR RANGE",
            } as Log);
          } else {
            this.msgs.showError(res.message);
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "CREATE COLOR RANGE",
              data: jsonmsg,
              description: "ERREUR CREATION COLOR RANGE",
            } as Log);
          }
        });
      } else {
        colorRanges[this.colorRanges.indexOf(this.selectedColorRange)] =
          this.colorRange;
        this.colorRangeService.updateColorRange(this.colorRange).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Risque mise à jour!");
            this.trace.createTrace({
              action: "UPDATE RISK",
              data: JSON.stringify(colorRanges),
              description: "Mise a jour risques",
            } as Log);
          } else {
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "UPDATE RISK",
              data: jsonmsg,
              description: "ERREUR MAJ RISQUES",
            } as Log);
          }
          this.userBlock
            .unblockUser(
              "bo_color_range",
              this.colorRange ? this.colorRange.id : this.lastIDColorRange
            )
            .then((data) => {
              console.log("Unblock users");
            });
        });
      }
    } else {
      this.msgs.showError("Merci de remplir tous les champs!");
    }
    this.lastIDColorRange = this.colorRange.id;
    this.colorRanges = colorRanges;
    this.colorRange = null;
    this.displayDialog = false;
  }
  delete() {
    this.colorRangeService.deleteColorRange(this.colorRange.id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Risque supprimé!");
        this.trace.createTrace({
          action: "DELETE RISK",
          data: JSON.stringify(this.colorRange),
          description: "SUPPRESSION RISQUE",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.userBlock
      .unblockUser(
        "bo_color_range",
        this.colorRange.id ? this.colorRange.id : this.lastIDColorRange
      )
      .then((data) => {
        console.log("Unblock users");
      });
    this.lastIDColorRange = this.colorRange.id;
    const index = this.colorRanges.indexOf(this.selectedColorRange);
    this.colorRanges = this.colorRanges.filter((val, i) => i !== index);
    this.colorRange = null;
    this.displayDialog = false;
  }

  deleteLine(id) {
    this.colorRangeService.deleteColorRange(id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Couleur supprimé!");
        this.trace.createTrace({
          action: "DELETE COLOR RANGE",
          data: JSON.stringify(this.colorRange),
          description: "SUPPRESSION COULEUR",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
  }
  onRowSelect(event) {
    this.user_block = 0;
    this.newColorRange = false;
    this.colorRange = this.cloneColorRange(event.data);
    this.displayDialog = true;
    this.userBlock
      .verifUserBlock("bo_color_range", this.colorRange.id)
      .then((userblock) => {
        if (userblock.data != "" && userblock.data != 0) {
          this.user_block = userblock.data;
          alert("Data en cours d'utilisation par : " + this.user_block);
        } else {
          this.userBlock.blockUser("bo_color_range", this.colorRange.id);
          this.lastIDColorRange = this.colorRange.id;
        }
      });
  }
  cloneColorRange(r: ColorRange): ColorRange {
    const colorRange = {} as ColorRange;
    for (const prop in r) {
      colorRange[prop] = r[prop];
    }
    return colorRange;
  }
  @HostListener("window:beforeunload")
  closeColorRanges() {
    if (
      (this.user_block == this.currentUser || this.user_block == 0) &&
      !this.newColorRange &&
      this.colorRange != null
    ) {
      this.userBlock
        .unblockUser(
          "bo_color_range",
          this.colorRange ? this.colorRange.id : this.lastIDColorRange
        )
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
  }

  loadData() {
    this.colorRangeService
      .getColorRanges(this.clients)
      .then(
        (colorRanges) =>
          (this.colorRanges = colorRanges.data
            ? colorRanges.data
            : ([] as ColorRange[]))
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeColorRanges();
  }

  openImportDialog() {
    this.displayDialogImport = true;
  }

  importData(event) {
    this.loadData();
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }

  deleteAllLines() {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer toutes les lignes?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.colorRangeService.deleteAll(this.clients).then((res) => {
          this.loadData();
          this.msgs.showSuccess("Ligne supprimée!");
        });
      },
    });
  }
}
