import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { List } from "./list.model";

@Injectable({
  providedIn: "root",
})
export class ListService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  get(client: number) {
    return this.http
      .get(this.config.API_Host + "lists/get/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  create(list: List) {
    return this.http
      .post(this.config.API_Host + "lists/create", JSON.stringify({ list }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  update(list: List) {
    return this.http
      .post(this.config.API_Host + "lists/update", JSON.stringify({ list }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  delete(id: number) {
    return this.http
      .post(this.config.API_Host + "lists/delete", JSON.stringify({ id: id }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  async getListForList(client: number) {
    return this.http
      .get(this.config.API_Host + "lists/getlistforlist/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteAll(id: number) {
    return this.http
      .post(
        this.config.API_Host + "lists/deleteAll",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
