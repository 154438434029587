<p-fileUpload
  name="file"
  url="{{ config.API_Host }}import/carto/{{ clients }}"
  (onUpload)="myUploader($event)"
  maxFileSize="1000000"
  chooseLabel="Sélectionner"
  uploadLabel="Importer"
  cancelLabel="Annuler"
></p-fileUpload>

<img *ngIf="showImg" [src]="imgSrc" />
