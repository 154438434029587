import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { Theme } from "../theme/theme.model";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  getThemes(client: number) {
    return this.http
      .get(this.config.API_Host + "themes/get/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  createTheme(theme: Theme) {
    return this.http
      .post(this.config.API_Host + "themes/add", JSON.stringify({ theme }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  updateTheme(theme: Theme) {
    return this.http
      .post(this.config.API_Host + "themes/update", JSON.stringify({ theme }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteTheme(id: number) {
    return this.http
      .post(this.config.API_Host + "themes/delete", JSON.stringify({ id: id }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteAll(id: number) {
    return this.http
      .post(
        this.config.API_Host + "themes/deleteAll",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
