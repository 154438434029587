import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { List } from "./list.model";
import { BehaviorSubject, interval, Subject } from "rxjs";
import { ListService } from "./list.service";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { UserBlockService } from "../services/user-block.service";
import { AppConfig } from "../config";
import { Log } from "../models/log.model";
import { takeUntil } from "rxjs/operators";
import { ConfirmationService } from "primeng/api";
import { DataService } from "../services/data.service";
import { UserRightsService } from "../services/user-rights.service";
import saveAs from "file-saver";

@Component({
  selector: "app-list-object",
  templateUrl: "./list-object.component.html",
  styleUrls: ["./list-object.component.css"],
})
export class ListObjectComponent implements OnInit, OnChanges {
  displayDialog: boolean;
  list: List = {} as List;
  selectedList: List;
  newList: boolean;
  lists: List[];
  cols: any[];
  user_block: any = 0;
  lastIDList: number;
  currentList: any;
  currentUser: any;
  timeoutCounter = 0;
  list_data: string[];
  displayDialogImport = false;
  showDeleteColumn = false;
  importFiles: any[] = [];
  @Input("clients") clients: any;

  private messageSource = new BehaviorSubject("");
  activatedComp: any;

  private onDestroy$ = new Subject();
  constructor(
    private listService: ListService,
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    private confirmationService: ConfirmationService,
    private userRightsService: UserRightsService,
    private data: DataService,
    public config: AppConfig
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.clients;
    if (currentItem) {
      this.loadData();
    }
  }

  ngOnInit() {
    this.data.currentMessage.subscribe(
      (message) => (this.activatedComp = message)
    );
    if (this.clients && this.activatedComp == "Listes") {
      this.currentUser = JSON.parse(localStorage.getItem("user")).data.email;
      this.cols = [
        { field: "name", header: "Libellé: " },
        { field: "description", header: "Commentaires/Précisions: " },
        { field: "dtcreate", header: "Création: " },
        { field: "dtmodif", header: "Modification: " },
      ];
      interval(this.config.RefreshMilisecs)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((x) => {
          if (this.timeoutCounter < this.config.RefreshTimer && this.clients) {
            this.loadData();
            this.timeoutCounter++;
          }
        });
    }
  }
  @HostListener("document:mousemove")
  onMouseMove() {
    this.timeoutCounter = 0;
  }

  showDialogToAdd() {
    if (this.list != null && this.list.id != null && !this.list) {
      this.closeLists();
    }
    this.newList = true;
    this.list_data = [];
    this.list = {} as List;
    this.displayDialog = true;
  }
  save() {
    const lists = this.lists ? [...this.lists] : ([] as List[]);
    this.list.id_client = this.clients;
    const jsonlist = JSON.stringify(this.list);
    this.list.value = JSON.stringify(this.list_data);
    if (this.list.name) {
      if (this.newList) {
        lists.push(this.list);
        this.listService.create(this.list).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Risque créé!");
            this.trace.createTrace({
              action: "CREATE RISK",
              data: jsonlist,
              description: "CREATION RISQUE",
            } as Log);
          } else {
            this.msgs.showError(res.message);
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "CREATE RISK",
              data: jsonmsg,
              description: "ERREUR CREATION RISQUE",
            } as Log);
          }
        });
      } else {
        lists[this.lists.indexOf(this.selectedList)] = this.list;
        this.listService.update(this.list).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Risque mise à jour!");
            this.trace.createTrace({
              action: "UPDATE RISK",
              data: JSON.stringify(lists),
              description: "Mise a jour risques",
            } as Log);
          } else {
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "UPDATE RISK",
              data: jsonmsg,
              description: "ERREUR MAJ RISQUES",
            } as Log);
          }
          this.userBlock
            .unblockUser(
              "bo_list_objects",
              this.list ? this.list.id : this.lastIDList
            )
            .then((data) => {
              console.log("Unblock users");
            });
        });
      }
    } else {
      this.msgs.showError("Merci de remplir tous les champs!");
    }
    this.lastIDList = this.list.id;
    this.lists = lists;
    this.list = null;
    this.displayDialog = false;
  }
  delete() {
    this.listService.delete(this.list.id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Risque supprimé!");
        this.trace.createTrace({
          action: "DELETE RISK",
          data: JSON.stringify(this.list),
          description: "SUPPRESSION RISQUE",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.userBlock
      .unblockUser(
        "bo_list_objects",
        this.list.id ? this.list.id : this.lastIDList
      )
      .then((data) => {
        console.log("Unblock users");
      });
    this.lastIDList = this.list.id;
    const index = this.lists.indexOf(this.selectedList);
    this.lists = this.lists.filter((val, i) => i !== index);
    this.list = null;
    this.displayDialog = false;
  }

  deleteLine(id) {
    this.listService.delete(id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Liste supprimé!");
        this.trace.createTrace({
          action: "DELETE LIST",
          data: JSON.stringify(this.list),
          description: "SUPPRESSION LISTE",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.userBlock
      .unblockUser(
        "bo_list_objects",
        this.list.id ? this.list.id : this.lastIDList
      )
      .then((data) => {
        console.log("Unblock users");
      });
    this.lastIDList = this.list.id;
    const index = this.lists.indexOf(this.selectedList);
    this.lists = this.lists.filter((val, i) => i !== index);
    this.list = null;
    this.displayDialog = false;
  }
  onRowSelect(event) {
    this.user_block = 0;
    this.newList = false;
    this.list = this.cloneList(event.data);
    this.list_data = JSON.parse(this.list.value);
    this.displayDialog = true;
    this.userBlock
      .verifUserBlock("bo_list_objects", this.list.id)
      .then((userblock) => {
        if (userblock.data != "" && userblock.data != 0) {
          this.user_block = userblock.data;
          alert("Data en cours d'utilisation par : " + this.user_block);
        } else {
          this.userBlock.blockUser("bo_list_objects", this.list.id);
          this.lastIDList = this.list.id;
        }
      });
  }
  cloneList(r: List): List {
    const list = {} as List;
    for (const prop in r) {
      list[prop] = r[prop];
    }
    return list;
  }
  @HostListener("window:beforeunload")
  closeLists() {
    if (
      (this.user_block == this.currentUser || this.user_block == 0) &&
      !this.newList &&
      this.list != null
    ) {
      this.userBlock
        .unblockUser(
          "bo_list_objects",
          this.list ? this.list.id : this.lastIDList
        )
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
  }

  loadData() {
    this.listService
      .get(this.clients)
      .then((lists) => (this.lists = lists.data ? lists.data : ([] as List[])));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeLists();
  }

  openImportDialog() {
    this.displayDialogImport = true;
  }

  importData(event) {
    this.loadData();
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }

  deleteAllLines() {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer toutes les lignes?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.listService.deleteAll(this.clients).then((res) => {
          this.loadData();
          this.msgs.showSuccess("Ligne supprimée!");
        });
      },
    });
  }

  exportCsv() {
    const header = "Libellé:;Commentaires/Précisions:;Liste";
    const data = this.lists.map((i) => {
      const values = JSON.parse(i.value);
      return i.name + ";" + i.description + ";" + values.join("|");
    });

    data.unshift(header);
    const csvArray = data.join("\r\n");

    const blob = new Blob(["\ufeff", csvArray], { type: "text/csv" });
    saveAs(blob, "listes.csv");
  }
}
