<div class="p-grid p-fluid">
  <div class="p-col-6 p-offset-2">
    <h3 class="first">Profile:</h3>
  </div>
  <div class="p-col-6 p-md-6 p-offset-2">
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon">Login: </span>
      <input
        [(ngModel)]="userconfigmodule.email"
        type="text"
        pInputText
        placeholder="Login"
      />
    </div>
  </div>
  <div class="p-col-6 p-md-6 p-offset-2">
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon ui-md-2">Password: </span>
      <input
        [(ngModel)]="userconfigmodule.password"
        [type]="showPassword === true ? 'text' : 'password'"
        pInputText
        placeholder="Password"
      />
      <button class="ui-inputgroup-addon" (click)="changeShowPassword()">
        <i
          [ngClass]="showPassword === true ? 'fa fa-eye' : ' fa fa-eye-slash'"
        ></i>
      </button>
    </div>
  </div>
  <div class="p-col-6 p-md-6 p-offset-2">
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon ui-md-2">Prénom: </span>
      <input
        [(ngModel)]="userconfigmodule.firstname"
        type="text"
        pInputText
        placeholder="Prénom"
      />
    </div>
  </div>
  <div class="p-col-6 p-md-6 p-offset-2">
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon ui-md-2">Nom: </span>
      <input
        [(ngModel)]="userconfigmodule.lastname"
        type="text"
        pInputText
        placeholder="Nom"
      />
    </div>
  </div>

  <div class="p-col-6 p-md-6 p-offset-2">
    <button
      pButton
      type="button"
      class="p-button-raised p-button-success"
      icon="pi pi-check"
      iconPos="left"
      label="Sauvegarder"
      (click)="saveProfileInfo()"
    ></button>
  </div>
</div>
