import { Host, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiResponse } from "../models/api-response.model";
import { Log } from "../models/log.model";
import { Trace } from "./trace.model";
import { AppConfig } from "../config";

@Injectable()
export class TracesService {
  currentUser;

  constructor(private http: HttpClient, public config: AppConfig) {}

  getTraces() {
    return this.http
      .get(this.config.API_Host + "traces/getTraces")
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  createTrace(log: Log) {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    const trace: Trace = {
      id: 0,
      user_id: this.currentUser.data.id,
      user_name: this.currentUser.data.email,
      log: log,
      dtcreate: "",
    };

    return this.http
      .post(
        this.config.API_Host + "traces/createtrace",
        JSON.stringify({ trace })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
