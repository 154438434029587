import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { User } from "../users/user.model";
import { Theme } from "../theme/theme.model";
import { BehaviorSubject, interval, Observable, Subject } from "rxjs";
import { UsersService } from "../users/users.service";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { UserBlockService } from "../services/user-block.service";
import { ThemeService } from "../theme/theme.service";
import { AppConfig } from "../config";
import { Log } from "../models/log.model";
import { Subcategory } from "../models/subcategory";
import { Subtheme } from "./subtheme.model";
import { SubthemeService } from "./subtheme.service";
import { takeUntil } from "rxjs/operators";
import { ConfirmationService } from "primeng/api";
import { DataService } from "../services/data.service";
import { UserRightsService } from "../services/user-rights.service";

@Component({
  selector: "app-subtheme",
  templateUrl: "./subtheme.component.html",
  styleUrls: ["./subtheme.component.css"],
})
export class SubthemeComponent implements OnInit, OnChanges {
  subThemes: Subtheme[] = [] as Subtheme[];
  subTheme: Subtheme = {} as Subtheme;
  selectedSubTheme: Subtheme;
  newSubTheme: boolean;
  displayDialog: boolean;
  lastIDSubTheme;

  currentSubTheme: any;
  users: User[];

  filteredUsers: any;

  user_block = 0;

  cols: any[];
  themes: Theme[];
  listThemes: any[];

  currentUser: any;

  displayDialogImport = false;
  showDeleteColumn = false;
  importFiles: any[] = [];

  selectedThemeList: any;

  @Input("clients") clients: any;

  timeoutCounter = 0;
  private onDestroy$ = new Subject();

  private messageSource = new BehaviorSubject("");
  activatedComp: string;

  constructor(
    private subThemeService: SubthemeService,
    private userService: UsersService,
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    private themeService: ThemeService,
    private confirmationService: ConfirmationService,
    private userRightsService: UserRightsService,
    private data: DataService,
    public config: AppConfig
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.clients;
    if (currentItem) {
      this.loadData();
    }
  }

  ngOnInit() {
    this.data.currentMessage.subscribe(
      (message) => (this.activatedComp = message)
    );
    if (this.clients && this.activatedComp == "Sous-Thémes") {
      this.loadData();

      this.currentUser = JSON.parse(localStorage.getItem("user")).data.email;
      this.cols = [
        { field: "name_theme", header: "Type sous-thème: " },
        { field: "name", header: "Libellé: " },
        { field: "description", header: "Commentaires/Précisions: " },
        { field: "dtcreate", header: "Creation: " },
        { field: "dtmodif", header: "Modification: " },
      ];
      this.currentSubTheme = JSON.parse(
        localStorage.getItem("user")
      ).data.email;
      interval(this.config.RefreshMilisecs)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((x) => {
          if (this.timeoutCounter < this.config.RefreshTimer && this.clients) {
            this.loadData();
            this.timeoutCounter++;
          }
        });
    }
  }

  @HostListener("document:mousemove")
  onMouseMove() {
    this.timeoutCounter = 0;
  }

  save() {
    const subThemes = this.subThemes ? [...this.subThemes] : ([] as Subtheme[]);
    this.subTheme.id_theme = this.selectedThemeList
      ? this.selectedThemeList.id
      : 0;
    this.subTheme.id_client = this.clients;
    if (this.subTheme.name) {
      if (this.newSubTheme) {
        subThemes.push(this.subTheme);
        this.subThemeService.create(this.subTheme).then((res) => {
          const jsonquery = JSON.stringify(this.subTheme);
          if (res.code === 200) {
            this.msgs.showSuccess("Données créée!");
            this.trace.createTrace({
              action: "CREATE RISK DATA",
              data: jsonquery,
              description: "CREATION RISQUE DATA",
            } as Log);
          } else {
            this.msgs.showError(res.message);
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "CREATE RISK DATA",
              data: jsonmsg,
              description: "ERREUR CREATION RISQUE DATA",
            } as Log);
          }
        });
        this.displayDialog = false;
        this.loadData();
      } else {
        subThemes[this.subThemes.indexOf(this.selectedSubTheme)] =
          this.subTheme;
        this.subThemeService.save(this.subTheme).then((res) => {
          if (res.code === 200) {
            this.msgs.showSuccess("Risque data mise à jour!");
            this.trace.createTrace({
              action: "UPDATE RISK DATA",
              data: JSON.stringify(this.subTheme),
              description: "Mise a jour données risque",
            } as Log);
          } else {
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "UPDATE RISK DATA",
              data: jsonmsg,
              description: "ERREUR MAJ RISK DATA",
            } as Log);
          }
          this.userBlock
            .unblockUser(
              "bo_subthemes",
              this.subTheme ? this.subTheme.id : this.lastIDSubTheme
            )
            .then((data) => {
              console.log("Unblock query");
            });
        });
        this.displayDialog = false;
        this.loadData();
      }
    } else {
      this.msgs.showError("Merci de remplir les champs obligatoires!");
    }
  }

  delete() {
    this.subThemeService.delete(this.subTheme.id).then((res) => {
      if (res.code === 200) {
        this.msgs.showSuccess("Risque data supprimée!");
        this.trace.createTrace({
          action: "DELETE USER CATEGORY",
          data: JSON.stringify(this.subTheme),
          description: "SUPPRESSION RISQUE DATA",
        } as Log);
        this.loadData();
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.subTheme = null;
    this.displayDialog = false;
  }

  deleteLine(id) {
    this.subThemeService.delete(id).then((res) => {
      if (res.code === 200) {
        this.msgs.showSuccess("Risque data supprimée!");
        this.trace.createTrace({
          action: "DELETE USER CATEGORY",
          data: JSON.stringify(this.subTheme),
          description: "SUPPRESSION RISQUE DATA",
        } as Log);
        this.loadData();
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.subTheme = null;
    this.displayDialog = false;
  }

  showDialogToAdd() {
    if (this.subTheme != null && !this.subTheme) {
      this.closeSubThemes();
    }
    this.newSubTheme = true;
    this.subTheme = {} as Subtheme;
    this.displayDialog = true;
  }

  onRowSelect(event) {
    this.user_block = 0;
    this.newSubTheme = false;
    this.subTheme = this.cloneSubTheme(event.data);
    this.displayDialog = true;
    this.selectedThemeList = {} as Theme;
    this.selectedThemeList = {
      id: this.themes.find((item) => item.id === this.subTheme.id_theme)
        ? this.themes.find((item) => item.id === this.subTheme.id_theme).id
        : 0,
      name: this.themes.find((item) => item.id === this.subTheme.id_theme)
        ? this.themes.find((item) => item.id === this.subTheme.id_theme).name
        : "",
    };
    this.userBlock
      .verifUserBlock("bo_subthemes", this.subTheme.id)
      .then((userblock) => {
        if (userblock.data != "" && userblock.data != 0) {
          this.user_block = userblock.data;
          alert("Data en cours d'utilisation par : " + this.user_block);
        } else {
          this.userBlock.blockUser("bo_subthemes", this.subTheme.id);
          this.lastIDSubTheme = this.subTheme.id;
        }
      });
  }

  cloneSubTheme(r: Subtheme): Subtheme {
    const subTheme = {} as Subtheme;
    for (const prop in r) {
      subTheme[prop] = r[prop];
    }
    return subTheme;
  }

  loadData() {
    this.subThemeService.get(this.clients).then((subThemes) => {
      this.subThemes = subThemes.data ? subThemes.data : ([] as Subtheme[]);
    });

    this.userService.getUsers().then((users) => {
      this.users = users.data;
    });
    this.themeService.getThemes(this.clients).then((themes) => {
      if (themes.data == null) {
        this.themes = [] as Theme[];
      } else {
        this.themes = themes.data;
        this.listThemes = this.makeThemeList(this.themes);
      }
    });
  }

  @HostListener("window:beforeunload")
  closeSubThemes() {
    if (
      (this.user_block == this.currentSubTheme || this.user_block == 0) &&
      !this.newSubTheme &&
      this.subTheme != null
    ) {
      this.userBlock
        .unblockUser(
          "bo_subthemes",
          this.subTheme ? this.subTheme.id : this.lastIDSubTheme
        )
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
  }

  makeThemeList(themes: Theme[]) {
    const listThemes: any[] = [];
    for (const prop in themes) {
      listThemes.push({
        id: themes[prop].id,
        name: themes[prop].name,
      });
    }
    return listThemes;
  }

  makeSubcatList(data: Subcategory[]) {
    const listSubcats: any[] = [];
    for (const prop in data) {
      listSubcats.push({
        id: data[prop].id,
        name: data[prop].name,
      });
    }
    return listSubcats;
  }

  filterUsers(event) {
    this.filteredUsers = [];
    for (let i = 0; i < this.users.length; i++) {
      const user = this.users[i].email;
      if (user.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredUsers.push(user);
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeSubThemes();
  }

  openImportDialog() {
    this.displayDialogImport = true;
  }

  importData(event) {
    this.loadData();
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }

  deleteAllLines() {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer toutes les lignes?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        if (this.subThemes.length > 0) {
          this.subThemeService
            .deleteAll(this.subThemes[0].id_client)
            .then((res) => {
              this.loadData();
              this.msgs.showSuccess("Lignes supprimée!");
            });
        }
      },
    });
  }
}
