import { Component, OnInit } from "@angular/core";
import { TracesService } from "./traces.service";
import { Trace } from "./trace.model";
import { SelectItem } from "primeng/api";

@Component({
  selector: "app-traces",
  templateUrl: "./traces.component.html",
  styleUrls: ["./traces.component.css"],
})
export class TracesComponent implements OnInit {
  traces: Trace[];
  selectedTrace: Trace;

  displayDialog: boolean;

  sortOptions: SelectItem[];

  sortKey: string;

  sortField: string;

  sortOrder: number;

  constructor(private tracesService: TracesService) {}

  ngOnInit() {
    this.tracesService
      .getTraces()
      .then((traces) => (this.traces = traces.data))
      .then((data) => {});

    this.sortOptions = [
      { label: "Newest First", value: "!dtcreate" },
      { label: "Oldest First", value: "dtcreate" },
    ];
  }

  selectTrace(event: Event, trace: Trace) {
    this.selectedTrace = trace;
    this.displayDialog = true;
    event.preventDefault();
  }

  onSortChange(event) {
    const value = event.value;

    if (value.indexOf("!") === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  onDialogHide() {
    this.selectedTrace = null;
  }
}
