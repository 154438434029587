import { ActionType } from "../action/actiontype.model";

export enum StatusPlan {
  TODO = 1,
  INPROGRES25 = 2,
  INPROGRES50 = 3,
  INPROGRES75 = 4,
  DONE = 5,
}
export class ActionPersonLine {
  id: number;
  id_analyse: number;
  analyse_name: string;
  analyse_line_order_num: number;
  id_analyse_line: number;
  responsible_name: string;
  responsible_job: string;
  id_action: number;
  budget: number;
  conso: number;
  approved: boolean;
  recursion: number;
  action_name: string;
  action_description: string;
  action_types: ActionType[];
  persons_name: string;
  status: StatusPlan;
  description: string;
  optionalLabel: string;
  max_date: Date;
  dt_create: Date;
  dt_modif: Date;
}
