import { Component, OnInit } from '@angular/core';
import { UserRightsService } from '../services/user-rights.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(public userRightsService: UserRightsService, private router: Router) {
  }

  ngOnInit() {
    if (!this.userRightsService.getUserAnalyse()) {
      this.router.navigate(['/']);
    }
  }

}
