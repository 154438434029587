import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { User } from "./user.model";
import { ApiResponse } from "../models/api-response.model";
import { AppConfig } from "../config";

@Injectable()
export class UsersService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  getUsers() {
    return this.http
      .get(this.config.API_Host + "users/getUsers")
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getUsersByIdClient(clientId: number) {
    return this.http
      .get(this.config.API_Host + "users/getUsers/" + clientId)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  createUser(user: User) {
    return this.http
      .post(this.config.API_Host + "users/adduser", JSON.stringify({ user }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  updateUser(user: User) {
    return this.http
      .post(this.config.API_Host + "users/updateuser", JSON.stringify({ user }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteUser(id: number) {
    return this.http
      .post(this.config.API_Host + "users/deluser", JSON.stringify({ id: id }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
