<div class="p-grid f-fluid">
  <div class="p-col-6 p-offset-3">
    <h5 class="first">Mot de passe oublié:</h5>
  </div>
  <div class="p-col-6 p-offset-3">
    <div class="ui-inputgroup">
      <input
        [autofocus]="true"
        [(ngModel)]="email"
        type="email"
        pInputText
        placeholder="Email"
        required
      />
    </div>
  </div>
<!--  <br />-->
<!--  <div class="p-col-6 p-offset-3">-->
<!--    <form [formGroup]="aFormGroup">-->
<!--      <re-captcha-->
<!--        #captchaElem-->
<!--        [siteKey]="siteKey"-->
<!--        (ngModelChange)="handleSuccess($event)"-->
<!--        formControlName="recaptcha"-->
<!--      >-->
<!--      </re-captcha>-->
<!--    </form>-->
<!--  </div>-->
  <div class="p-col-6 p-offset-3">
    <button
      pButton
      type="button"
      class="p-button-raised p-button-success"
      icon="pi pi-check"
      iconPos="left"
      label="Envoyer la demande"
      (click)="sendConfirmationMail()"
    ></button>
  </div>
</div>
