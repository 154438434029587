<div *ngIf="isManager">
  <h2 style="font-size: 18px !important; text-align: center !important">
    Gestion des propositions de scénarios
  </h2>
  <br>
  <div class="p-grid p-fluid mx-5">
    <app-validate-scenario [validation_table]="true">
    </app-validate-scenario>
  </div>
</div>

<div [ngStyle]="isManager ? {'margin-top': '6rem'} : {}">
  <h2 style="font-size: 18px !important; text-align: center !important">
    Vos propositions de scénarios
  </h2>
  <br>
  <div class="p-grid p-fluid mx-5">
  <app-validate-scenario [validation_table]="false">
  </app-validate-scenario>
  </div>
</div>
