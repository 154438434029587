import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { Action } from "../action/action.model";
import { BehaviorSubject, interval, Subject } from "rxjs";
import { ActionService } from "../action/action.service";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { UserBlockService } from "../services/user-block.service";
import { ConfirmationService } from "primeng/api";
import { UserRightsService } from "../services/user-rights.service";
import { DataService } from "../services/data.service";
import { AppConfig } from "../config";
import { takeUntil } from "rxjs/operators";
import { Log } from "../models/log.model";
import { Person } from "../person/person.model";
import { PersonService } from "../person/person.service";
import { ActionType } from "./actiontype.model";
import saveAs from "file-saver";
import { LIST_PERIODICITY } from "../constants/list-periodicity.constant";

@Component({
  selector: "app-action",
  templateUrl: "./action.component.html",
  styleUrls: ["./action.component.css"],
})
export class ActionComponent implements OnInit, OnDestroy, OnChanges {
  displayDialog: boolean;
  displayDialogImport: boolean;
  displayActionType: boolean;
  action: Action = {} as Action;
  selectedAction: Action;
  selectedResponsible: Person;
  selectedPersons: Person[];
  selectedActionTypeList: ActionType[];
  selectedPeriodicity: any;
  actionTypes: ActionType[] = [] as ActionType[];
  listPersons: Person[];
  listPeriodicity = LIST_PERIODICITY;
  newAction: boolean;
  actions: Action[];
  cols: any[];
  user_block: any = 0;
  lastIDAction: number;
  currentAction: any;
  currentUser: any;
  timeoutCounter = 0;
  importFiles: any[] = [];
  showDeleteColumn = false;

  private onDestroy$ = new Subject();

  @Input("clients") clients: any;

  private messageSource = new BehaviorSubject("");
  activatedComp: string;

  constructor(
    private actionService: ActionService,
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    private confirmationService: ConfirmationService,
    public userRightsService: UserRightsService,
    private personService: PersonService,
    private data: DataService,
    public config: AppConfig
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.clients;
    if (currentItem) {
      this.loadData();
    }
  }

  ngOnInit() {
    this.data.currentMessage.subscribe(
      (message) => (this.activatedComp = message)
    );
    if (
      (this.clients || this.clients === 0) &&
      this.activatedComp == "Actions"
    ) {
      this.actionService.getActionTypes(this.clients).then((data) => {
        this.actionTypes = data.data ? data.data : [];
      });
      this.loadData();
      this.currentUser = JSON.parse(localStorage.getItem("user")).data.email;
      this.cols = [
        { field: "name", header: "Libellé: " },
        { field: "description", header: "Commentaires/Précisions: " },
        { field: "responsible_name", header: "Nom responsable: " },
        { field: "responsible_job", header: "Poste responsable: " },
        { field: "persons_name", header: "Contributeurs : " },
        { field: "budget", header: "Budget: " },
        { field: "action_types", header: "Types d'actions: " },
        { field: "recursion", header: "Récurrence: " },
        { field: "dtcreate", header: "Création: " },
        { field: "dtmodif", header: "Modification: " },
        { field: "analyse_line_ids", header: "Scénarios liés: " },
      ];

      interval(this.config.RefreshMilisecs)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((x) => {
          if (this.timeoutCounter < this.config.RefreshTimer && this.clients) {
            this.loadData();
            this.timeoutCounter++;
          }
        });
    }
  }
  @HostListener("document:mousemove")
  onMouseMove() {
    this.timeoutCounter = 0;
  }

  showDialogToAdd() {
    if (this.action != null && this.action.id != null && !this.action) {
      this.closeActions();
    }
    this.newAction = true;
    this.action = {} as Action;
    this.displayDialog = true;
  }
  save() {
    const actions = this.actions ? [...this.actions] : ([] as Action[]);
    this.action.id_client = this.clients;
    this.action.id_responsible = this.selectedResponsible.id;
    this.action.action_types = this.selectedActionTypeList;
    this.action.recursion = this.selectedPeriodicity ? this.selectedPeriodicity.value : 0;
    this.action.persons = this.selectedPersons && this.selectedPersons.length > 0 ? this.selectedPersons.map(person => person.id).join(',') : '';
    const jsonaction = JSON.stringify(this.action);
    if (this.action.name && this.action.description) {
      if (this.newAction) {
        this.actionService.createAction(this.action).then((res) => {
          if (res.code === 200) {
            actions.push(this.action);
            this.loadData();
            this.msgs.showSuccess("Action créé!");
            this.trace.createTrace({
              action: "CREATE ACTION",
              data: jsonaction,
              description: "CREATION ACTION",
            } as Log);
          } else {
            this.msgs.showError(res.message);
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "CREATE ACTION",
              data: jsonmsg,
              description: "ERREUR CREATION ACTION",
            } as Log);
          }
        });
      } else {
        this.actionService.updateAction(this.action).then((res) => {
          if (res.code === 200) {
            actions[this.actions.indexOf(this.selectedAction)] = this.action;
            this.loadData();
            this.msgs.showSuccess("ACTION mise à jour!");
            this.trace.createTrace({
              action: "UPDATE ACTION",
              data: JSON.stringify(actions),
              description: "Mise a jour actions",
            } as Log);
          } else {
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "UPDATE ACTION",
              data: jsonmsg,
              description: "ERREUR MAJ ACTION",
            } as Log);
          }
          this.userBlock
            .unblockUser(
              "bo_actions",
              this.action ? this.action.id : this.lastIDAction
            )
            .then((data) => {
              console.log("Unblock users");
            });
        });
      }
    } else {
      this.msgs.showError("Merci de remplir tous les champs!");
    }
    this.lastIDAction = this.action.id;
    this.actions = actions;
    this.action = null;
    this.displayDialog = false;
  }
  delete() {
    this.actionService.deleteAction(this.action.id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Actionédure supprimé!");
        this.trace.createTrace({
          action: "DELETE PROC",
          data: JSON.stringify(this.action),
          description: "SUPPRESSION PROC",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.userBlock
      .unblockUser(
        "bo_actions",
        this.action.id ? this.action.id : this.lastIDAction
      )
      .then((data) => {
        console.log("Unblock users");
      });
    this.lastIDAction = this.action.id;
    const index = this.actions.indexOf(this.selectedAction);
    this.actions = this.actions.filter((val, i) => i !== index);
    this.action = null;
    this.displayDialog = false;
  }

  deleteLine(id) {
    this.actionService.deleteAction(id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Action supprimé!");
        this.trace.createTrace({
          action: "DELETE ACTION",
          data: JSON.stringify(this.action),
          description: "SUPPRESSION ACTION",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
  }

  deleteActionType(id) {
    this.actionService.deleteActionType(id).then((i) => {
      this.actionService.getActionTypes(this.clients).then((data) => {
        this.actionTypes = data.data ? data.data : [];
      });
    });
  }

  onRowSelect(event) {
    this.user_block = 0;
    this.newAction = false;
    this.action = this.cloneAction(event.data);
    this.selectedResponsible = this.listPersons.find((i) => {
      return i.id === this.action.id_responsible
    });
    this.selectedPeriodicity = this.listPeriodicity.find((i) => {
      return i.value === this.action.recursion
    });
    const ids = new Set(this.action.persons ? this.action.persons.split(",").map(Number) : []);
    this.selectedPersons = this.listPersons.filter((i) => ids.has(i.id));

    this.selectedActionTypeList = (this.action && this.action.action_types)
      ? this.actionTypes.filter((x) => this.action.action_types.some((i) => i.id === x.id))
      : [];

    this.displayDialog = true;
    this.userBlock
      .verifUserBlock("bo_actions", this.action.id)
      .then((userblock) => {
        if (userblock.data != "" && userblock.data != 0) {
          this.user_block = userblock.data;
          alert("Data en cours d'utilisation par : " + this.user_block);
        } else {
          this.userBlock.blockUser("bo_actions", this.action.id);
          this.lastIDAction = this.action.id;
        }
      });
  }
  cloneAction(r: Action): Action {
    const action = {} as Action;
    for (const prop in r) {
      action[prop] = r[prop];
    }
    return action;
  }
  @HostListener("window:beforeunload")
  closeActions() {
    if (
      (this.user_block == this.currentUser || this.user_block == 0) &&
      !this.newAction &&
      this.action != null
    ) {
      this.userBlock
        .unblockUser(
          "bo_actions",
          this.action ? this.action.id : this.lastIDAction
        )
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
  }

  addActionType() {
    const actionType = {} as ActionType;
    actionType.id_client = this.clients;
    actionType.name = "Modifier le nom";
    this.actionService.createActionType(actionType).then((res) => {
      this.actionService.getActionTypes(this.clients).then((data) => {
        this.actionTypes = data.data ? data.data : [];
      });
    });
  }

  updateActionType(actionType: ActionType) {
    this.actionService.updateActionType(actionType).then((res) => {
      this.actionService.getActionTypes(this.clients).then((data) => {
        this.actionTypes = data.data ? data.data : [];
      });
    });
  }

  async loadData() {
    this.listPersons = await this.personService
      .getPersons(this.clients)
      .then((data) => {
        return data.data ? data.data : [];
      });

    this.actions = await this.actionService.getActions(this.clients).then((data) => {
      return data.data ? data.data.map(action => {
        // Création des propriétés utiles pour les personnes liées à l'action
        const persons_array = action.persons ?
          action.persons.split(',').map(value => parseInt(value, 10)) : [];
        const persons_name = action.persons && this.listPersons ?
          this.listPersons.filter(person => persons_array.includes(person.id)).map(person => `${person.first_name} ${person.last_name}`).join(', ') : '';
        return {...action, persons_array, persons_name};
      }) : [];
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeActions();
  }

  openImportDialog() {
    this.displayDialogImport = true;
  }

  openActionTypeDialog() {
    this.displayActionType = true;
  }

  importData(event) {
    this.loadData();
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }

  deleteAllLines() {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer toutes les lignes?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.actionService.deleteAll(this.clients).then((res) => {
          this.loadData();
          this.msgs.showSuccess("Ligne supprimée!");
        });
      },
    });
  }

  getListActionTypes(rowData) {
    return rowData ? rowData.map((i) => i.name).join(" / ") : '';
  }

  getRecursionLabel(recursion) {
    const matchingPeriodicity = this.listPeriodicity.find(periodicity => periodicity.value === recursion);
    if (matchingPeriodicity) {
      return matchingPeriodicity.label;
    } else {
      return "Aucune";
    }
  }

  exportData() {
    const header =
      "Libellé:;Commentaires/Précisions:;Nom responsable:;Poste responsable;Budget:;Types d'actions:;Contributeurs:;Récurrence:;Scénarios liés:";

    const result = this.actions.map((i) => {
      return (
        i.name +
        ";" + i.description +
        ";" + i.responsible_name +
        ";" + i.responsible_job +
        ";" + i.budget +
        ";" + (i.action_types !== null ? i.action_types.map((z) => z.name).join(", ") : "") +
        ";" + i.persons_name +
        ";" + i.recursion +
        ";" + i.analyse_line_ids
      );
    });
    result.unshift(header);
    const csvArray = result.join("\r\n");

    const blob = new Blob(["\ufeff", csvArray], { type: "text/csv" });
    saveAs(blob, "export_actions.csv");
  }
}
