import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { Formula } from "./formula.model";
import { BehaviorSubject, interval, Subject } from "rxjs";
import { FormulaService } from "./formula.service";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { UserBlockService } from "../services/user-block.service";
import { AppConfig } from "../config";
import { Log } from "../models/log.model";
import { OperatorTypes } from "../models/operator_types";
import { takeUntil } from "rxjs/operators";
import { ConfirmationService } from "primeng/api";
import saveAs from "file-saver";
import { DataService } from "../services/data.service";
import { UserRightsService } from "../services/user-rights.service";

@Component({
  selector: "app-formula-object",
  templateUrl: "./formula-object.component.html",
  styleUrls: ["./formula-object.component.css"],
})
export class FormulaObjectComponent implements OnInit, OnChanges {
  displayDialog: boolean;
  formula: Formula = {} as Formula;
  selectedFormula: Formula;
  newFormula: boolean;
  formulas: Formula[];
  cols: any[];
  user_block: any = 0;
  lastIDFormula: number;
  currentFormula: any;
  currentUser: any;
  timeoutCounter = 0;
  private onDestroy$ = new Subject();
  showDeleteColumn = false;
  displayDialogImport = false;
  importFiles: any[] = [];
  actionDoneCheckbox = false;

  operator_types: OperatorTypes = new OperatorTypes();

  formulaObjects: any[] = [];

  analyseObjects: any[] = [];
  selectedAnalyse: any;

  valueObjects: any[] = [];

  toggleLoading = false;

  @Input("clients") clients: any;
  private messageSource = new BehaviorSubject("");
  activatedComp: any;

  constructor(
    private formulaService: FormulaService,
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    private confirmationService: ConfirmationService,
    private userRightsService: UserRightsService,
    private data: DataService,
    public config: AppConfig
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.clients;
    if (currentItem) {
      this.loadData();
    }
  }

  ngOnInit() {
    this.data.currentMessage.subscribe(
      (message) => (this.activatedComp = message)
    );
    if (this.clients && this.activatedComp == "Formules") {
      this.loadData();

      this.currentUser = JSON.parse(localStorage.getItem("user")).data.email;
      this.cols = [
        { field: "name", header: "Libellé: " },
        { field: "description", header: "Commentaires/Précisions: " },
        { field: "name_analyse", header: "Nom analyse: " },
        { field: "dtcreate", header: "Création: " },
        { field: "dtmodif", header: "Modification: " },
      ];
      interval(this.config.RefreshMilisecs)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((x) => {
          if (this.timeoutCounter < this.config.RefreshTimer && this.clients) {
            this.loadData();
            this.timeoutCounter++;
          }
        });
    }
  }

  @HostListener("document:mousemove")
  onMouseMove() {
    this.timeoutCounter = 0;
  }

  showDialogToAdd() {
    if (this.formula != null && this.formula.id != null && !this.formula) {
      this.closeFormulas();
    }
    this.newFormula = true;
    this.valueObjects = [];
    this.formula = {} as Formula;
    this.displayDialog = true;
  }

  save() {
    const formulas = this.formulas ? [...this.formulas] : ([] as Formula[]);
    const jsonformula = JSON.stringify(this.formula);
    this.formula.id_client = this.clients;
    const nomExistant: boolean = this.checkIfFormulaNameExists();
    this.formula.id_analyse =
      typeof this.selectedAnalyse !== "undefined"
        ? this.selectedAnalyse.value
        : this.analyseObjects.length > 0
        ? this.analyseObjects[0].value
        : 0;
    this.formula.value = JSON.stringify(this.valueObjects);
    this.formula.action_done = this.actionDoneCheckbox ? 1 : 0;

    if (this.formula.name && this.formula.description) {
      if (this.newFormula) {
        if (nomExistant) {
          this.msgs.showError("Le nom de la formule existe déjà!");
        } else {
          formulas.push(this.formula);
          this.formulaService.create(this.formula).then((res) => {
            if (res.code === 200) {
              this.loadData();
              this.msgs.showSuccess("Risque créé!");
              this.trace.createTrace({
                action: "CREATE RISK",
                data: jsonformula,
                description: "CREATION RISQUE",
              } as Log);
            } else if (res.code == 23000) {
              this.msgs.showError("Le nom de la formule existe déjà!");
            } else {
              this.msgs.showError(res.message);
              const jsonmsg = JSON.stringify(res.message);
              this.trace.createTrace({
                action: "CREATE RISK",
                data: jsonmsg,
                description: "ERREUR CREATION RISQUE",
              } as Log);
            }
          });
          this.lastIDFormula = this.formula.id;
          this.formulas = formulas;
          this.formula = null;
          this.displayDialog = false;
        }
      } else {
        formulas[this.formulas.indexOf(this.selectedFormula)] = this.formula;
        this.formulaService.update(this.formula).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Formule mise à jour!");
            this.trace.createTrace({
              action: "UPDATE FORMULE",
              data: JSON.stringify(formulas),
              description: "Mise a jour formule",
            } as Log);
          } else {
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "UPDATE Formule",
              data: jsonmsg,
              description: "ERREUR MAJ Formule",
            } as Log);
          }
          this.userBlock
            .unblockUser(
              "bo_formula_objects",
              this.formula ? this.formula.id : this.lastIDFormula
            )
            .then((data) => {
              console.log("Unblock users");
            });
        });
        this.lastIDFormula = this.formula.id;
        this.formulas = formulas;
        this.formula = null;
        this.displayDialog = false;
      }
    } else {
      this.msgs.showError("Merci de remplir tous les champs!");
    }
  }

  checkIfFormulaNameExists() {
    if (this.formula) {
      for (const prop in this.formulas) {
        if (this.formulas[prop].name == this.formula.name) {
          return true;
        }
      }
    }
    return false;
  }

  delete() {
    this.formulaService.delete(this.formula.id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Risque supprimé!");
        this.trace.createTrace({
          action: "DELETE RISK",
          data: JSON.stringify(this.formula),
          description: "SUPPRESSION RISQUE",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.userBlock
      .unblockUser(
        "bo_formula_objects",
        this.formula.id ? this.formula.id : this.lastIDFormula
      )
      .then((data) => {
        console.log("Unblock users");
      });
    this.lastIDFormula = this.formula.id;
    const index = this.formulas.indexOf(this.selectedFormula);
    this.formulas = this.formulas.filter((val, i) => i !== index);
    this.formula = null;
    this.displayDialog = false;
  }

  onRowSelect(event) {
    this.user_block = 0;
    this.newFormula = false;
    this.formula = this.cloneFormula(event.data);
    console.log(this.formula);
    this.actionDoneCheckbox = this.formula.action_done === 1 ? true : false;

    this.selectedAnalyse =
      this.formula.id_analyse == 0
        ? this.analyseObjects[0]
        : this.analyseObjects.find((i) => {
            this.toggleLoading = true;
            return i.value == this.formula.id_analyse;
          });

    this.displayDialog = true;
    this.valueObjects = JSON.parse(this.formula.value);
    this.userBlock
      .verifUserBlock("bo_formula_objects", this.formula.id)
      .then((userblock) => {
        if (userblock.data != "" && userblock.data != 0) {
          this.user_block = userblock.data;
          alert("Data en cours d'utilisation par : " + this.user_block);
        } else {
          this.userBlock.blockUser("bo_formula_objects", this.formula.id);
          this.lastIDFormula = this.formula.id;
        }
      });
    this.toggleLoading = false;
  }

  cloneFormula(r: Formula): Formula {
    const formula = {} as Formula;
    for (const prop in r) {
      formula[prop] = r[prop];
    }
    return formula;
  }

  @HostListener("window:beforeunload")
  closeFormulas() {
    if (
      (this.user_block == this.currentUser || this.user_block == 0) &&
      !this.newFormula &&
      this.formula != null
    ) {
      this.userBlock
        .unblockUser(
          "bo_formula_objects",
          this.formula ? this.formula.id : this.lastIDFormula
        )
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
  }

  addLine() {
    this.valueObjects.push({
      selectedValue: {},
      selectedOperateur: {},
    });
  }

  deleteLine(id) {
    this.formulaService.delete(id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Risque supprimé!");
        this.trace.createTrace({
          action: "DELETE RISK",
          data: JSON.stringify(this.formula),
          description: "SUPPRESSION RISQUE",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });

    this.lastIDFormula = this.formula.id;
    const index = this.formulas.indexOf(this.selectedFormula);
    this.formulas = this.formulas.filter((val, i) => i !== index);
    this.formula = null;
    this.displayDialog = false;
  }

  async loadData() {
    this.formulaService
      .getColumnsForFormula(this.clients)
      .then((formulaObjects) => {
        this.formulaObjects = formulaObjects.data ? formulaObjects.data : [];
        this.makeListAnalyse();
      });
    this.formulaService.get(this.clients).then((formulas) => {
      this.formulas = formulas.data ? formulas.data : ([] as Formula[]);
    });
  }

  makeListAnalyse() {
    this.toggleLoading = true;
    const array = this.formulaObjects.map((i) => {
      return { name: i.name_analyse, value: i.id_analyse };
    });
    const result = [];
    const map = new Map();
    for (const item of array) {
      if (!map.has(item.value)) {
        map.set(item.value, true); // set any value to Map
        result.push({
          value: item.value,
          name: item.name,
        });
      }
    }
    this.analyseObjects = result;
    this.toggleLoading = false;
  }

  filterFormulaObjects() {
    const filteredData = this.formulaObjects.filter((i) => {
      return (
        i.id_analyse ===
        (this.selectedAnalyse
          ? this.selectedAnalyse.value
          : this.formulaObjects[0].id_analyse)
      );
    });
    return filteredData;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeFormulas();
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }

  deleteAllLines() {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer toutes les lignes?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.formulaService.deleteAll(this.clients).then((res) => {
          this.loadData();
          this.msgs.showSuccess("Ligne supprimée!");
        });
      },
    });
  }

  openImportDialog() {
    this.displayDialogImport = true;
  }

  importData(event) {
    this.loadData();
    console.log(event);
  }

  exportCsv() {
    const header = this.cols
      .map((i) => {
        return i.header;
      })
      .join(";");

    const data = this.formulas.map((formula) => {
      const formulaCalc = JSON.parse(formula.value);
      let formulaResult = "";
      let foundColumnObj;

      for (const i of formulaCalc) {
        const foundOperator = this.operator_types.types.filter((oper) => {
          return oper.value == i.selectedOperateur;
        })[0];
        foundColumnObj = this.formulaObjects.filter((formObj) => {
          return formObj.id == i.selectedValue;
        })[0];
        formulaResult +=
          (foundOperator ? foundOperator.label : "") +
          (foundOperator ? foundOperator.label : "") +
          (foundColumnObj ? foundColumnObj.label : "");
      }

      return (
        formula.name +
        ";" +
        formula.description +
        ";" +
        (foundColumnObj ? foundColumnObj.name_analyse : "") +
        ";" +
        formulaResult
      );
    });

    data.unshift(header);
    const csvArray = data.join("\r\n");

    const blob = new Blob(["\ufeff", csvArray], { type: "text/csv" });
    saveAs(blob, "formules.csv");
  }
}
