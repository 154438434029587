import { Component, OnInit } from "@angular/core";
import { User } from "../users/user.model";
import { Workspace } from "./workspace.model";
import { WorkspaceService } from "./workspace.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-workspace",
  templateUrl: "./workspace.component.html",
  styleUrls: ["./workspace.component.css"],
})
export class WorkspaceComponent implements OnInit {
  workspaces: Workspace[] = [] as Workspace[];
  analysesForLists: any = [];
  currentUser: User;

  listDES: any;
  selectedDES: any = [];
  listRI: any;
  selectedRI: any = [];
  listPA: any;
  selectedPA: any = [];
  listRR: any;
  selectedRR: any = [];

  listClients: any;
  selectedClients: any = [];

  constructor(
    private workspaceService: WorkspaceService,
    private router: Router
  ) {}

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem("user")).data;
    this.loadData();
  }

  loadData() {
    this.workspaceService
      .getAnalyses(this.currentUser.id)
      .then((analysesForLists) => {
        this.analysesForLists = analysesForLists.data;
        this.makeListsAnalyses(analysesForLists.data);
        this.workspaceService
          .getClients(this.currentUser.id)
          .then((clients) => {
            this.listClients = clients.data;
            this.workspaceService
              .get(this.currentUser.id)
              .then((workspaces) => {
                this.workspaces = workspaces.data;
                this.makeSelectedAnalyses(workspaces.data);
              });
          });
      });
  }

  makeListsAnalyses(analysesForLists) {
    const listDES: any[] = [];
    const listRI: any[] = [];
    const listPA: any[] = [];
    const listRR: any[] = [];
    for (const prop in analysesForLists) {
      switch (analysesForLists[prop].name_type) {
        case "DES":
          listDES.push({
            id: analysesForLists[prop].id,
            name: analysesForLists[prop].name,
          });
          break;
        case "RI":
          listRI.push({
            id: analysesForLists[prop].id,
            name: analysesForLists[prop].name,
          });
          break;
        case "PA":
          listPA.push({
            id: analysesForLists[prop].id,
            name: analysesForLists[prop].name,
          });
          break;
        case "RR":
          listRR.push({
            id: analysesForLists[prop].id,
            name: analysesForLists[prop].name,
          });
          break;
      }
    }
    this.listDES = listDES;
    this.listRR = listRR;
    this.listPA = listPA;
    this.listRI = listRI;
  }

  makeSelectedAnalyses(workspaces) {
    this.selectedDES = [];
    this.selectedRI = [];
    this.selectedPA = [];
    this.selectedRR = [];
    this.selectedClients = [];
    for (const prop in workspaces) {
      this.selectedDES[workspaces[prop].id] = {
        id: this.listDES.find(
          (item) => item.id === workspaces[prop].id_analyse_des
        )
          ? this.listDES.find(
              (item) => item.id === workspaces[prop].id_analyse_des
            ).id
          : 0,
        name: this.listDES.find(
          (item) => item.id === workspaces[prop].id_analyse_des
        )
          ? this.listDES.find(
              (item) => item.id === workspaces[prop].id_analyse_des
            ).name
          : "None",
      };
      this.selectedRI[workspaces[prop].id] = {
        id: this.listRI.find(
          (item) => item.id === workspaces[prop].id_analyse_ri
        )
          ? this.listRI.find(
              (item) => item.id === workspaces[prop].id_analyse_ri
            ).id
          : 0,
        name: this.listRI.find(
          (item) => item.id === workspaces[prop].id_analyse_ri
        )
          ? this.listRI.find(
              (item) => item.id === workspaces[prop].id_analyse_ri
            ).name
          : "None",
      };
      this.selectedPA[workspaces[prop].id] = {
        id: this.listPA.find(
          (item) => item.id === workspaces[prop].id_analyse_pa
        )
          ? this.listPA.find(
              (item) => item.id === workspaces[prop].id_analyse_pa
            ).id
          : 0,
        name: this.listPA.find(
          (item) => item.id === workspaces[prop].id_analyse_pa
        )
          ? this.listPA.find(
              (item) => item.id === workspaces[prop].id_analyse_pa
            ).name
          : "None",
      };
      this.selectedRR[workspaces[prop].id] = {
        id: this.listRR.find(
          (item) => item.id === workspaces[prop].id_analyse_rr
        )
          ? this.listRR.find(
              (item) => item.id === workspaces[prop].id_analyse_rr
            ).id
          : 0,
        name: this.listRR.find(
          (item) => item.id === workspaces[prop].id_analyse_rr
        )
          ? this.listRR.find(
              (item) => item.id === workspaces[prop].id_analyse_rr
            ).name
          : "None",
      };

      this.selectedClients[workspaces[prop].id] = {
        id: this.listClients.find(
          (item) => item.id === workspaces[prop].id_client
        )
          ? this.listClients.find(
              (item) => item.id === workspaces[prop].id_client
            ).id
          : 0,
        name: this.listClients.find(
          (item) => item.id === workspaces[prop].id_client
        )
          ? this.listClients.find(
              (item) => item.id === workspaces[prop].id_client
            ).name
          : "None",
      };
    }
    /* this.selectedRR = {
            id: ((this.typeEvalRisks.find(item => item.id === this.analyse.id_type)) ? (this.typeEvalRisks.find(item => item.id === this.analyse.id_type)).id : 0),
            name: ((this.typeEvalRisks.find(item => item.id === this.analyse.id_type)) ? (this.typeEvalRisks.find(item => item.id === this.analyse.id_type)).name : '')
        };
        */
  }

  addWorkspace() {
    const workspace = {} as Workspace;
    workspace.name = "Nouveau workspace";
    const options = {
      idLigne: "undefined",
      name: "name",
      value: "Nouveau workspace",
      id_user: this.currentUser.id,
    };

    this.workspaceService.addupdate(options).then((res) => {
      this.loadData();
    });
  }

  changeValues(options) {
    this.workspaceService.addupdate(options).then((res) => {
      this.loadData();
    });
  }

  delete(id) {
    this.workspaceService.delete(id).then((res) => {
      this.loadData();
    });
  }

  goToDetails(id) {
    this.router.navigate(["/workspace_details", id]);
  }
}
