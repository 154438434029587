import { Component, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ActionPersonLine, StatusPlan } from "./plan.model";
import { Person } from "../person/person.model";
import { Action } from "../action/action.model";
import { ActionService } from "../action/action.service";
import { PersonService } from "../person/person.service";
import { UserRightsService } from "../services/user-rights.service";
import { PlanService } from "./plan.service";
import { ConfirmationService } from "primeng/api";
import { MsgService } from "../services/msg.service";
import { Log } from "../models/log.model";
import { TracesService } from "../traces/traces.service";
import { UIChart } from "primeng/chart";
import saveAs from "file-saver";
import { ActionType } from "../action/actiontype.model";
import { LIST_PERIODICITY } from "../constants/list-periodicity.constant";

@Component({
  selector: "app-plan",
  templateUrl: "./plan.component.html",
  styleUrls: ["./plan.component.css"],
  styles: [
    `
      :host ::ng-deep .p-overlaypanel {
        top: 100px !important;
        left: 110px !important;
        width: 450px !important;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None
})
export class PlanComponent implements OnInit {
  @Input() id_analyse = 0;
  @Input() id_line = 0;
  @Input() id_client = 0;
  @Input() page = 0;
  @Input() manager_list = false;
  @Input() header = true;
  @Input() buttons = true;
  @Input() edit_button = true;
  @Input() disabledEditing : boolean = false;

  @ViewChild("chart") chart: UIChart;

  personList: Person[];
  selectedResponsible: Person;
  selectedPersons: Person[];
  selectedPeriodicity: any;
  actionList: Action[];
  showDeleteColumn = false;
  showExportActionDialog = false;
  private sub: any;
  actionPersonLines: ActionPersonLine[] = [] as ActionPersonLine[];
  managerActionPersonLines: ActionPersonLine[] = [] as ActionPersonLine[];
  dateObject: Date;
  action: Action = {} as Action;
  id_user: number;
  ids_persons: [];
  basicData: any;
  basicOptions: any;
  selectedActionTypeList: ActionType[];
  actionTypes: ActionType[] = [] as ActionType[];
  listPeriodicity = LIST_PERIODICITY;
  isAdmin: boolean = false;
  loading: boolean;

  statusesArray = [
    { label: "A faire / 0%", value: StatusPlan.TODO, percent: 0 },
    { label: "En cours / 25%", value: StatusPlan.INPROGRES25, percent: 25 },
    { label: "En cours / 50%", value: StatusPlan.INPROGRES50, percent: 50 },
    { label: "En cours / 75%", value: StatusPlan.INPROGRES75, percent: 75 },
    { label: "Fait / 100%", value: StatusPlan.DONE, percent: 100 },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private actionService: ActionService,
    private personService: PersonService,
    private userRightsService: UserRightsService,
    private confirmationService: ConfirmationService,
    private msgs: MsgService,
    private planService: PlanService,
    private trace: TracesService
  ) {}

  ngOnInit(): void {
    this.id_user = JSON.parse(localStorage.getItem("user")).data.id;
    this.ids_persons = JSON.parse(localStorage.getItem("user")).data.persons_ids;

    this.basicData = {
      labels: [] as string[],
      datasets: [
        {
          label: "Progress par personne",
          backgroundColor: "#42A5F5",
          data: [],
        },
      ],
    };

    this.basicOptions = {
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              suggestedMax: 100,
            },
          },
        ],
      },
    };
    this.sub = this.route.params.subscribe((params) => {
      if (
        (this.id_client != 0 && this.id_line != 0 && this.page == 0) ||
        this.page == 1
      ) {
        this.personService.getPersons(this.id_client).then((data) => {
          this.personList = data.data ? data.data : [];
        });
        this.actionService.getActionTypes(this.id_client).then((data) => {
          this.actionTypes = data.data ? data.data : [];
        });
        this.loadData();
      }
    });
  }

  addLine() {
    this.actionPersonLines.push({} as ActionPersonLine);
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }

  deleteLine(id: number) {
    this.planService.delete(id).then((res) => {
      this.msgs.showSuccess("Ligne supprimée!");
    });
    this.loadData();
  }

  deleteAllLines() {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer toutes les lignes?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.planService.deleteAll(this.id_line).then((res) => {
          this.loadData();
          this.msgs.showSuccess("Ligne supprimée!");
        });
      },
    });
  }

  findStatusLabel(search) {
    if (this.statusesArray) {
      return search
        ? this.statusesArray.find((item) => item.value === Number(search)).label
        : "";
    } else {
      return "";
    }
  }
  findPeriodicityLabel(search) {
    if (this.listPeriodicity && this.listPeriodicity.length > 0) {
      const periodicity = this.listPeriodicity.find(p => p.value == search);
      if (periodicity && periodicity.label) {
        return periodicity.label;
      }
    }
    return '';
  }

  loadData() {
    this.loading = true;
    if (this.page === 0) {
      this.actionService.getActions(this.id_client).then((res) => {
        this.actionList = res.data;
        this.planService.get(this.id_line).then((res2) => {
          this.actionPersonLines = res2.data
            ? res2.data
            : ([] as ActionPersonLine[]);
          this.loading = false;
        });
      });
    } else {
      this.actionService
        .getActionsByManager(this.id_user, this.id_client).then((res) => {
          this.actionList = res.data;
        });

      this.planService.getPlan(this.id_user).then((res) => {
        this.actionPersonLines = res.data
          ? res.data.my_tasks
          : ([] as ActionPersonLine[]);
        this.managerActionPersonLines = res.data
          ? typeof res.data.manager !== 'undefined' ? res.data.manager : [] as ActionPersonLine[]
          : ([] as ActionPersonLine[]);
        this.basicData.labels = this.actionPersonLines.map((action) => {
          return action.responsible_name + " / " + action.responsible_job;
        });
        this.basicData.labels = this.basicData.labels.filter(function (
          elem,
          index,
          self
        ) {
          return index === self.indexOf(elem);
        });

        this.loading = false;

        this.basicData.labels.forEach((label) => {
          let score = 0;
          const personScore = this.actionPersonLines.filter((i) => {
            return i.responsible_name + " / " + i.responsible_job === label;
          });
          personScore.forEach((z) => {
            score += this.findStatusPercent(z.status);
          });
          this.basicData.datasets[0].data.push(score / personScore.length);
        });
        if (this.chart) {
          this.chart.refresh();
        }
      });
    }
  }

  saveAction() {
    this.action.id_client = this.id_client;
    this.action.id_responsible = this.selectedResponsible.id;
    this.action.action_types = this.selectedActionTypeList;
    this.action.recursion = this.selectedPeriodicity ? this.selectedPeriodicity.value : 0;
    this.action.persons = this.selectedPersons && this.selectedPersons.length > 0 ? this.selectedPersons.map(person => person.id).join(',') : '';

    const jsonaction = JSON.stringify(this.action);
    if (this.action.name && this.action.description) {
      this.actionService.createAction(this.action).then((res) => {
        if (res.code === 200) {
          this.loadData();
          this.msgs.showSuccess("Action créé!");
          this.trace.createTrace({
            action: "CREATE ACTION",
            data: jsonaction,
            description: "CREATION ACTION",
          } as Log);
        } else {
          this.msgs.showError(res.message);
          const jsonmsg = JSON.stringify(res.message);
          this.trace.createTrace({
            action: "CREATE ACTION",
            data: jsonmsg,
            description: "ERREUR CREATION ACTION",
          } as Log);
        }
      });
    }
    this.action = {} as Action;
    this.loadData();
    this.addLine();
  }

  onRowEditInit(actionPersonLine: ActionPersonLine) {}

  onRowEditSave(actionPersonLine: ActionPersonLine) {
    if (!actionPersonLine.id) {
      actionPersonLine.id = 0;
    }
    if (this.page === 0) {
      actionPersonLine.id_analyse_line = this.id_line;
      actionPersonLine.id_analyse = this.id_analyse;
    }
    if (
      actionPersonLine.id_action &&
      actionPersonLine.max_date
    ) {
      this.planService.createupdate(actionPersonLine).then((res) => {
        this.msgs.showSuccess("Ligne ajoutée!");
        this.loadData();
      });
    } else {
      this.msgs.showError("Merci de remplir tous les champs!");
    }
  }

  onRowEditCancel(actionPersonLine: ActionPersonLine, index: number) {}

  getDaysBetween(endDate) {
    const diff = Math.abs(Date.parse(endDate) - Date.now());
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return Date.parse(endDate) < Date.now() ? 0 - diffDays : diffDays;
  }

  exportCsvPlan() {
    let actionPersonLines = this.manager_list ? this.managerActionPersonLines : this.actionPersonLines;
    const header = "Nom action;Description action;Responsable;Poste;Commentaire;Contributeurs;Progression;Approbation;Date butoir;Récurrence;Date de création";
    if (this.selectedActionTypeList && this.selectedActionTypeList.length > 0) {
      actionPersonLines = actionPersonLines.filter((z) =>
        z.action_types.some((i) =>
          this.selectedActionTypeList.map((x) => x.id).includes(i.id)
        )
      );
    }
    const result = actionPersonLines.map((val) => {
      return [
        val.action_name,
        val.action_description,
        val.responsible_name,
        val.responsible_job,
        val.description,
        val.persons_name,
        this.statusesArray.find((z) => z.value === val.status)?.label ?? '',
        val.approved,
        val.max_date instanceof Date ? val.max_date.toLocaleDateString() : val.max_date,
        val.recursion,
        val.dt_create instanceof Date ? val.dt_create.toLocaleDateString() : val.dt_create
      ].map((value) =>
        typeof value === "string" ? `"${value.replace(/"/g, '""')}"` : value
      ).join(";");
    });

    result.unshift(header);
    const csvArray = result.join("\r\n");

    const blob = new Blob(["\ufeff", csvArray], { type: "text/csv" });
    saveAs(blob, "export_actions.csv");
  }

  tranformDate(dt) {
    let result;
    if (typeof dt !== "object" && dt.indexOf("T") > -1) {
      result = new Date(Date.parse(dt));
    } else {
      if (typeof dt === "string" && dt.indexOf("/") > -1) {
        const str = dt.split("/");

        const year = Number(str[2]);
        const month = Number(str[1]) - 1;
        const date = Number(str[0]);

        result = new Date(year, month, date);
      }
    }

    if (
      result &&
      typeof result.getMonth === "function" &&
      Object.prototype.toString.call(result) === "[object Date]"
    ) {
      return result;
    }

    return false;
  }

  findStatusPercent(search) {
    if (this.statusesArray) {
      return search
        ? this.statusesArray.find((item) => item.value === Number(search))
            .percent
        : 0;
    } else {
      return 0;
    }
  }

  openExportAction() {
    this.showExportActionDialog = true;
  }

  approveAction(event, actionPersonLine: ActionPersonLine) {
    this.planService.approveAction(actionPersonLine).then((res) => {
      this.msgs.showSuccess("Action " + (actionPersonLine.approved ? '' : 'dés') + "approuvée!");
      this.loadData();
    });
  }

  goToAnalyse(action) {
    this.router.navigate(['/workspace_details', action.id_analyse, action.id_structure]);
  }
}
