<p-fieldset legend="Graphiques" [collapsed]="true" [toggleable]="true" *ngIf="header">
  <div class="card">
    <p-chart
      #chart
      type="horizontalBar"
      [data]="basicData"
      [options]="basicOptions"
      width="80vh"
      height="40vh"
      *ngIf="page === 1"
    ></p-chart>
  </div>
</p-fieldset>

<p-overlayPanel
  appendTo="body"
  #op
  [dismissable]="false"
  [showCloseIcon]="true"
>
  <ng-template pTemplate>
    <div class="p-fluid" *ngIf="action">
      <div class="p-field p-grid" *ngIf="false">
        <div class="p-col-4">
          <label for="id">ID: </label>
        </div>
        <div class="p-col-8">
          <input pInputText id="id" [(ngModel)]="action.id" disabled />
        </div>
      </div>
      <div class="p-field p-grid">
        <div class="p-col-4">
          <label for="name">Libellé: </label>
        </div>
        <div class="p-col-8">
          <input
            pInputText
            id="name"
            [autofocus]="true"
            [(ngModel)]="action.name"
            required
          />
        </div>
      </div>
      <div class="p-field p-grid">
        <div class="p-col-4">
          <label for="description">Commentaires/Précisions: </label>
        </div>
        <div class="p-col-8">
          <input
            pInputText
            id="description"
            [(ngModel)]="action.description"
            required
          />
        </div>
      </div>
      <div class="p-field p-grid">
        <div class="p-col-4">
          <label for="budget">Budget: </label>
        </div>
        <div class="p-col-8">
          <input pInputText id="budget" [(ngModel)]="action.budget" required />
        </div>
      </div>
      <div class="p-field p-grid">
        <div class="p-col-4">
          <label>Responsable: </label>
        </div>
        <div class="p-col-8">
          <p-dropdown
            appendTo="body"
            [filter]="true"
            [dataKey]="id"
            [options]="personList"
            [(ngModel)]="selectedResponsible"
            placeholder="Sélectionner un responsable"
            optionLabel="optionalValue"
            [showClear]="true"
            required
          ></p-dropdown>
        </div>
      </div>
      <div class="p-field p-grid">
        <div class="p-col-4">
          <label>Contributeurs: </label>
        </div>
        <div class="p-col-8">
          <p-multiSelect
            appendTo="body"
            [filter]="true"
            [options]="personList"
            [(ngModel)]="selectedPersons"
            placeholder="Sélectionner des personnes"
            optionLabel="optionalValue"
            [style]="{'width':'100%', 'margin-left': '0'}"
          ></p-multiSelect>
        </div>
      </div>
      <div class="p-field p-grid">
        <div class="p-col-4">
          <label>Type d'action: </label>
        </div>
        <div class="p-col-8">
          <p-multiSelect
            appendTo="body"
            [filter]="true"
            [dataKey]="id"
            [options]="actionTypes"
            [(ngModel)]="selectedActionTypeList"
            placeholder="Sélectionner un type"
            optionLabel="name"
            [style]="{'width':'100%', 'margin-left': '0'}"
          ></p-multiSelect>
        </div>
      </div>
      <div class="p-field p-grid">
        <div class="p-col-4">
          <label>Récurrence: </label>
        </div>
        <div class="p-col-8">
          <p-dropdown
            appendTo="body"
            [filter]="true"
            [dataKey]="value"
            [options]="listPeriodicity"
            [(ngModel)]="selectedPeriodicity"
            placeholder="Sélectionner une périodicité"
            optionLabel="label"
            [showClear]="true"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-helper-clearfix">
        <button
          type="button"
          *ngIf="userRightsService.getUserUpdate() == 1"
          pButton
          icon="fa fa-check"
          (click)="saveAction(); op.hide()"
          label="Sauvegarder"
        ></button>
      </div>
    </p-footer>
  </ng-template>
</p-overlayPanel>

<p-table
  #dt
  [value]="manager_list ? managerActionPersonLines : actionPersonLines"
  dataKey="id"
  editMode="row"
  [globalFilterFields]="[
      'max_date',
      'responsible_name',
      'responsible_job',
      'action_name',
      'action_description',
      'status.label',
      'optionalLabel',
      'description',
      'analyse_name'

    ]"
  [rows]="20"
  [loading]="loading"
  [ngStyle]="{ 'opacity': (manager_list ? managerActionPersonLines.length : actionPersonLines.length) == 0  && page == 1 ? '0.2' : '1' }"
>
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-ai-center p-jc-between">
      <div class="p-d-flex">
        <button *ngIf="buttons && userRightsService.getUserCreate() == 1" type="button" pButton
                pTooltip="Ajouter une ligne" tooltipPosition="top" icon="fa fa-plus"
                class="p-button-icon-only p-button-rounded p-button-text p-mr-2 p-button-sm" (click)="addLine()"></button>
        <button *ngIf="buttons && userRightsService.getUserDelete() == 1" type="button" pButton
                pTooltip="Suppression ligne par ligne" tooltipPosition="top" icon="fa fa-close"
                class="p-button-icon-only p-button-rounded p-button-text p-mr-2 p-button-danger p-button-sm" (click)="showDelete()"></button>
        <button *ngIf="buttons && userRightsService.getUserCreate() == 1" type="button" pButton
                pTooltip="Créer une action" tooltipPosition="top" icon="fa fa-plus"
                class="p-button-icon-only p-button-rounded p-button-text p-mr-2 p-button-success p-button-sm" (click)="op.toggle($event)"></button>
        <button *ngIf="userRightsService.getUserRead() == 1" type="button" pButton
                pTooltip="Exporter les actions" tooltipPosition="top" icon="pi pi-file-excel"
                class="p-button-icon-only p-button-rounded p-button-text p-mr-2 p-button-success p-button-sm" (click)="openExportAction()"></button>
      </div>
      <span class="p-input-icon-right" style="width: 200px;">
        <i class="pi pi-search"></i>
        <input
          type="text"
          pInputText
          size="25"
          placeholder="Recherche"
          (input)="dt.filterGlobal($event.target.value, 'contains')"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3em" *ngIf="showDeleteColumn">X</th>
      <th pSortableColumn="responsible_name">Porteur de l'action<p-sortIcon field="responsible_name"></p-sortIcon></th>
      <th *ngIf="page === 1" pSortableColumn="analyse_name">Réference<p-sortIcon field="analyse_name"></p-sortIcon></th>
      <th pSortableColumn="status">Statuts<p-sortIcon field="status"></p-sortIcon></th>
      <th>Commentaire</th>
      <th>Contributeurs</th>
      <th>Consommation</th>
      <th>Budget</th>
      <th>% Budget</th>
      <th pSortableColumn="max_date">Date butoir<p-sortIcon field="max_date"></p-sortIcon></th>
      <th>Jours restants</th>
      <th>Dernière modification</th>
      <th style="width: 8rem">Approbation</th>
      <th *ngIf="edit_button === true" style="width: 8rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-actionPersonLine let-editing="editing" let-ri="rowIndex">
    <tr [pEditableRow]="actionPersonLine">
      <td style="width: 3em; padding: 5px" *ngIf="showDeleteColumn">
        <button
          type="button"
          style="height: 2em"
          pButton
          (click)="deleteLine(actionPersonLine.id)"
          class="p-button-danger"
          icon="fa fa-close"
        ></button>
      </td>
      <td style="text-align: center">
        <p-cellEditor>
          <ng-template *ngIf="page === 0" pTemplate="input">
            <p-dropdown
              [filter]="true"
              appendTo="body"
              [options]="actionList"
              [(ngModel)]="actionPersonLine.id_action"
              [style]="{ width: '100%' }"
              optionLabel="optionalLabel"
              optionValue="id"
              placeholder="Selectionner une action"
            ></p-dropdown>
          </ng-template>
          <ng-template *ngIf="page === 1" pTemplate="input">
            <div *ngIf="actionPersonLine && actionPersonLine.action_name">
              <p-chip
                [label]="actionPersonLine.action_name"
                [icon]="actionPersonLine.recursion !== 0 ? 'pi pi-refresh' : 'pi pi-star'"
                [styleClass]="actionPersonLine.recursion !== 0 ? 'custom-chip-recursive' : 'custom-chip'"
                styleClass="p-mr-2  custom-chip"
              ></p-chip
              ><br />
              {{
              actionPersonLine.responsible_name +
              " : " +
              actionPersonLine.responsible_job
              }}<br />
              {{ actionPersonLine.action_description }}
            </div>
          </ng-template>
          <ng-template pTemplate="output">
            <div *ngIf="actionPersonLine && actionPersonLine.action_name"
                 [title]="actionPersonLine.recursion !== 0 ? 'Récurrence : ' + findPeriodicityLabel(actionPersonLine.recursion)  + '' : ''">
              <p-chip [label]="actionPersonLine.action_name" [styleClass]="actionPersonLine.recursion !== 0 ? 'custom-chip-recursive' : 'custom-chip'">
                <span
                  class="p-mr-2"
                  [ngClass]="actionPersonLine.recursion !== 0 ? 'pi pi-refresh' : 'pi pi-star'"
                > </span>
              </p-chip
              ><br />
              {{
              actionPersonLine.responsible_name +
              " : " +
              actionPersonLine.responsible_job
              }}<br />
              {{ actionPersonLine.action_description }}
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
      <td *ngIf="page === 1" style="text-align: center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <div *ngFor="let analyse of actionPersonLine.analyses">
              <a class="p-jc-center" (click)="goToAnalyse(analyse)" style="cursor: pointer;">{{ analyse.name }}</a><br />
              Scénario : {{ analyse.order_nums.join(', ') }}
            </div>
          </ng-template>
          <ng-template pTemplate="output">
            <div *ngFor="let analyse of actionPersonLine.analyses">
              <a class="p-jc-center" (click)="goToAnalyse(analyse)" style="cursor: pointer;">{{ analyse.name }}</a><br />
              Scénario : {{ analyse.order_nums.join(', ') }}
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
      <td style="text-align: center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              [filter]="true"
              appendTo="body"
              [options]="statusesArray"
              [(ngModel)]="actionPersonLine.status"
              [style]="{ width: '100%' }"
              placeholder="Selectionner un status"
            ></p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            <div *ngIf="actionPersonLine.status">
              <p-tag
                styleClass="p-mr-2  custom-chip"
                [value]="findStatusLabel(actionPersonLine.status)"
                [severity]="
                    actionPersonLine.status == 5
                      ? 'success'
                      : actionPersonLine.status == 1
                      ? 'danger'
                      : 'warning'
                  "
                [rounded]="true"
              ></p-tag>
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
      <td style="text-align: center">
        <p-cellEditor>
          <ng-template pTemplate="input">
              <textarea
                [rows]="5"
                [cols]="30"
                pInputTextarea
                autoResize="autoResize"
                [(ngModel)]="actionPersonLine.description"
              ></textarea>
          </ng-template>
          <ng-template pTemplate="output">
            <div *ngIf="actionPersonLine.description">
              {{ actionPersonLine.description }}
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
      <td style="text-align: center">
        <p-cellEditor disabled="disabled">
          <ng-template pTemplate="input">
              {{ actionPersonLine.persons_name }}
          </ng-template>
          <ng-template pTemplate="output">
            {{ actionPersonLine.persons_name }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td style="text-align: center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText [(ngModel)]="actionPersonLine.conso" />
          </ng-template>
          <ng-template pTemplate="output">
            <div *ngIf="actionPersonLine.conso">
              {{ actionPersonLine.conso }}
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
      <td style="text-align: center">
        <p-cellEditor disabled="disabled">
          <ng-template pTemplate="input">
            <input disabled pInputText [(ngModel)]="actionPersonLine.budget" />
          </ng-template>
          <ng-template pTemplate="output">
            <div *ngIf="actionPersonLine.budget">
              {{ actionPersonLine.budget }}
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
      <td style="text-align: center">
        <p-cellEditor disabled="disabled">
          <ng-template pTemplate="input">
            <div>
              {{ (actionPersonLine.budget ? actionPersonLine.conso / actionPersonLine.budget : 0) | percent }}
            </div>
          </ng-template>
          <ng-template pTemplate="output">
            <div *ngIf="actionPersonLine.budget">
              {{ actionPersonLine.conso / actionPersonLine.budget | percent }}
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
      <td style="text-align: center">
        <p-cellEditor disabled="disabled">
          <ng-template pTemplate="input">
            <p-calendar
              appendTo="body"
              [(ngModel)]="actionPersonLine.max_date"
              dateFormat="yy-mm-dd"
              dataType="string"
            ></p-calendar>
          </ng-template>
          <ng-template pTemplate="output">
            {{ actionPersonLine.max_date ? (actionPersonLine.max_date | date: 'dd/MM/yyyy') : '' }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td style="text-align: center">
        <div *ngIf="actionPersonLine.max_date">
          {{ getDaysBetween(actionPersonLine.max_date) }}
        </div>
      </td>
      <td style="text-align: center">
        <p-cellEditor disabled="disabled">
          <ng-template pTemplate="input">
            <input
              pInputText
              [disabled]="true"
              type="text"
              [(ngModel)]="actionPersonLine.dt_modif"
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ actionPersonLine.dt_modif && actionPersonLine.dt_modif !== '0000-00-00 00:00:00' ? (actionPersonLine.dt_modif | date: 'dd/MM/yyyy HH:mm:ss') : '' }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td style="text-align: center">
        <p-cellEditor disabled="disabled">
          <ng-template pTemplate="input">
            <p-inputSwitch [disabled]="(page === 1 && !manager_list && !isAdmin && this.ids_persons && !ids_persons.includes(actionPersonLine.id_responsible_manager)) || (page === 0 && this.ids_persons && !ids_persons.includes(actionPersonLine.id_responsible_manager))" [(ngModel)]="actionPersonLine.approved"></p-inputSwitch>
          </ng-template>
          <ng-template pTemplate="output">
            <p-inputSwitch [disabled]="(page === 1 && !manager_list && !isAdmin && this.ids_persons && !ids_persons.includes(actionPersonLine.id_responsible_manager)) || (page === 0 && this.ids_persons && !ids_persons.includes(actionPersonLine.id_responsible_manager))"
                           [ngStyle]="{'pointer-events': (page === 1 && !manager_list && !isAdmin && this.ids_persons && !ids_persons.includes(actionPersonLine.id_responsible_manager)) || (page === 0 && this.ids_persons && !ids_persons.includes(actionPersonLine.id_responsible_manager)) ? 'none' : 'all'}"
                           (click)="approveAction($event, actionPersonLine)" [(ngModel)]="actionPersonLine.approved"></p-inputSwitch>
          </ng-template>
        </p-cellEditor>
      </td>
      <td *ngIf="edit_button === true" style="text-align: center">
        <button
          *ngIf="!editing || disabledEditing"
          pButton
          pRipple
          type="button"
          pInitEditableRow
          icon="pi pi-pencil"
          (click)="onRowEditInit(actionPersonLine)"
          class="p-button-rounded p-button-text"
        ></button>
        <button
          *ngIf="editing"
          pButton
          pRipple
          type="button"
          pSaveEditableRow
          icon="pi pi-check"
          (click)="onRowEditSave(actionPersonLine)"
          class="p-button-rounded p-button-text p-button-success p-mr-2"
        ></button>
        <button
          *ngIf="editing"
          pButton
          pRipple
          type="button"
          pCancelEditableRow
          icon="pi pi-times"
          (click)="onRowEditCancel(actionPersonLine, ri)"
          class="p-button-rounded p-button-text p-button-danger"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>
<div *ngIf="(manager_list ? managerActionPersonLines.length : actionPersonLines.length) == 0 && page == 1" class="p-d-flex p-jc-center">
  <span style="font-size: 28px !important; opacity: 0.6;">Aucune action à afficher</span>
</div>
<p-dialog
  header="Exports actions"
  [(visible)]="showExportActionDialog"
  appendTo="body"
  [modal]="true"
>
  <p-header>
    <p-button type="button" (onClick)="exportCsvPlan()" class="p-button-success"
      >Exporter la liste des actions</p-button
    >
  </p-header>
  <ng-template pTemplate="body">
    <p-multiSelect
      [style]="{ width: '100%' }"
      appendTo="body"
      [filter]="true"
      [options]="actionTypes"
      [(ngModel)]="selectedActionTypeList"
      placeholder="Sélectionner un type"
      optionLabel="name"
    ></p-multiSelect>
  </ng-template>
</p-dialog>
