<p-table [value]="proposedScenarios" dataKey="id" editMode="row" [paginator]="true" [rows]="10" [ngStyle]="proposedScenarios && proposedScenarios.length == 0 ? {'opacity': '0.2'} : {}">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 15%">Description</th>
      <th style="width: 15%">Conséquences probable</th>
      <th style="width: 15%">Mesures à mettre en place</th>
      <th style="width: 10%">Analyse concernée</th>
      <th style="width: 7%">Proposé par</th>
      <th style="width: 7%">Proposé le</th>
      <th style="width: 12%">Commentaire</th>
      <th [ngStyle]="{'width': validation_table ? '17%' : '10%'}">Validation</th>
      <th *ngIf="!validation_table" style="width: 7%"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-scenario let-editing="editing" let-ri="rowIndex">
    <tr [pEditableRow]="scenario">
      <td style="white-space: pre-line">
        <p-cellEditor>
          <ng-template pTemplate="output">
            {{ scenario.description }}
          </ng-template>
          <ng-template pTemplate="input">
            <textarea pInputTextarea id="description" [(ngModel)]="scenario.description" rows="5"></textarea>
          </ng-template>
        </p-cellEditor>
      </td>
      <td style="white-space: pre-line">
        <p-cellEditor>
          <ng-template pTemplate="output">
           {{ scenario.consequences }}
          </ng-template>
          <ng-template pTemplate="input">
            <textarea pInputTextarea id="consequences" [(ngModel)]="scenario.consequences" rows="5"></textarea>
          </ng-template>
        </p-cellEditor>
      </td>
      <td style="white-space: pre-line">
        <p-cellEditor>
          <ng-template pTemplate="output">
            {{ scenario.action_plan }}
          </ng-template>
          <ng-template pTemplate="input">
            <textarea pInputTextarea id="action_plan" [(ngModel)]="scenario.action_plan" rows="5"></textarea>
          </ng-template>
        </p-cellEditor>
      </td>
      <td>{{ scenario.analyse_name }}</td>
      <td>{{ scenario.name_proposer }}</td>
      <td>{{ scenario.dt_create | date: 'dd/MM/yyyy à HH:mm:ss' }}</td>
      <td>
        <ng-container *ngIf="!validation_table">
          {{ scenario.comment || 'Aucun commentaire' }}
        </ng-container>
        <ng-container *ngIf="validation_table">
          <textarea id="commentaire" name="commentaire" [(ngModel)]="scenario.comment" rows="3" placeholder="Entrez un commentaire" (blur)="updateStatus(scenario)"> </textarea>
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="!validation_table">
          <p-tag [severity]="scenario.status === 1 ? 'success' : scenario.status === -1 ? 'danger' : 'warning'">
            {{scenario.status === 1 ? 'Validé' : scenario.status === -1 ? 'Refusé' : 'En attente'}}
          </p-tag>
        </ng-container>
        <ng-container *ngIf="validation_table">
          <p-selectButton class="validation-select-button" name="status" [options]="validationScenarioOptions" [(ngModel)]="scenario.status" optionLabel="label" optionValue="value" (onChange)="updateColorOnSelectButtonInit(); updateStatus(scenario);"></p-selectButton>
          <a *ngIf="scenario.status === 1" class="m-2 p-d-flex p-jc-center" (click)="goToAnalyse(scenario)" style="cursor: pointer;">Aller à l'analyse</a>
        </ng-container>
      </td>
      <td *ngIf="!validation_table" style="text-align: center">
        <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" class="p-button-rounded p-button-text"></button>
        <button *ngIf="!editing" pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" (click)="deleteScenario(scenario)"></button>
        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(scenario)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger"></button>
      </td>
    </tr>
  </ng-template>
</p-table>

<div *ngIf="proposedScenarios && proposedScenarios.length == 0" class="p-d-flex p-jc-center">
  <span style="font-size: 24px !important; opacity: 0.6;">{{ validation_table ? 'Vous n\'avez aucun scénario à valider' : 'Vous n\'avez proposé aucun scénario' }}</span>
</div>

<p-toast></p-toast>
