import { takeUntil } from "rxjs/operators";
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { User } from "./user.model";
import { UsersService } from "./users.service";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { Log } from "../models/log.model";
import { UserBlockService } from "../services/user-block.service";
import { interval, Subject } from "rxjs";

import { AppConfig } from "../config";
import { UserRightsService } from "../services/user-rights.service";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
  host: { "window:beforeunload": "closeUsers" },
})
export class UsersComponent implements OnInit, OnDestroy {
  displayDialog: boolean;
  user: User = {} as User;
  selectedUser: User;
  newUser: boolean;
  users: User[];
  cols: any[];
  user_block: any = 0;
  lastIDUser: number;
  currentUser: any;
  displayDialogImport = false;
  showDeleteColumn = false;
  importFiles: any[] = [];
  timeoutCounter = 0;
  private onDestroy$ = new Subject();
  all = "*";
  constructor(
    private userService: UsersService,
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    private userRightsService: UserRightsService,
    private confirmationService: ConfirmationService,
    public config: AppConfig
  ) {}
  ngOnInit() {
    this.loadData();
    this.currentUser = this.userRightsService.getUserEmail();
    this.cols = [
      { field: "email", header: "Email" },
      { field: "firstname", header: "Prénom" },
      { field: "lastname", header: "Nom" },
      { field: "valide", header: "Actif" },
      { field: "create", header: "Création" },
      { field: "read", header: "Lecture" },
      { field: "update", header: "Mise à jour" },
      { field: "delete", header: "Suppression" },
      { field: "analyse", header: "Accès analyse" },
      { field: "dashboard", header: "Accès dashboard" },
      { field: "admin", header: "Admin" },
      { field: "super_user", header: "Super user" },
    ];
    interval(this.config.RefreshMilisecs)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((x) => {
        if (this.timeoutCounter < this.config.RefreshTimer) {
          this.loadData();
          this.timeoutCounter++;
        }
      });
  }
  @HostListener("document:mousemove")
  onMouseMove() {
    this.timeoutCounter = 0;
  }

  showDialogToAdd() {
    if (this.user != null && this.user.id != null && !this.user) {
      this.closeUsers();
    }
    this.newUser = true;
    this.user = {} as User;
    this.displayDialog = true;
  }
  save() {
    const users = [...this.users];
    const jsonuser = JSON.stringify(this.user);
    if (this.user.email == this.userRightsService.getUserEmail()) {
      const newjsonUser = JSON.parse(localStorage.getItem("user"));
      newjsonUser.data = this.user;
      localStorage.setItem("user", JSON.stringify(newjsonUser));
    }
    if (this.user.email) {
      if (this.newUser) {
        users.push(this.user);
        this.userService.createUser(this.user).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Compte utilisateur créé!");
            this.trace.createTrace({
              action: "CREATE USER",
              data: jsonuser,
              description: "CREATION UTILISATEUR",
            } as Log);
          } else {
            this.msgs.showError(res.message);
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "CREATE USER",
              data: jsonmsg,
              description: "ERREUR CREATION UTILISATEUR",
            } as Log);
          }
        });
      } else {
        users[this.users.indexOf(this.selectedUser)] = this.user;
        this.userService.updateUser(this.user).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Compte utilisateur mise à jour!");
            this.trace.createTrace({
              action: "UPDATE USER",
              data: JSON.stringify(users),
              description: "Mise a jour utilisateur",
            } as Log);
          } else {
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "UPDATE USER",
              data: jsonmsg,
              description: "ERREUR MAJ UTILISATEUR",
            } as Log);
          }
          this.userBlock
            .unblockUser("bo_users", this.user ? this.user.id : this.lastIDUser)
            .then((data) => {
              console.log("Unblock users");
            });
        });
      }
    } else {
      this.msgs.showError("Merci de remplir tous les champs!");
    }
    this.lastIDUser = this.user.id;
    this.users = users;
    this.user = null;
    this.displayDialog = false;
  }
  delete() {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer cette ligne?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.userService.deleteUser(this.user.id).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Compte utilisateur supprimé!");
            this.trace.createTrace({
              action: "DELETE USER",
              data: this.user,
              description: "SUPPRESSION UTILISATEUR",
            } as Log);
          } else {
            this.msgs.showError(res.message);
          }
        });
        this.userBlock
          .unblockUser(
            "bo_users",
            this.user.id ? this.user.id : this.lastIDUser
          )
          .then((data) => {
            console.log("Unblock users");
          });
        this.lastIDUser = this.user.id;
        const index = this.users.indexOf(this.selectedUser);
        this.users = this.users.filter((val, i) => i !== index);
        this.user = null;
        this.displayDialog = false;
      },
    });
  }

  deleteLine(id) {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer cette ligne?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.userService.deleteUser(id).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Compte utilisateur supprimé!");
            this.trace.createTrace({
              action: "DELETE USER",
              data: this.user,
              description: "SUPPRESSION UTILISATEUR",
            } as Log);
          } else {
            this.msgs.showError(res.message);
          }
        });
        this.userBlock
          .unblockUser(
            "bo_users",
            this.user.id ? this.user.id : this.lastIDUser
          )
          .then((data) => {
            console.log("Unblock users");
          });
        this.lastIDUser = this.user.id;
        const index = this.users.indexOf(this.selectedUser);
        this.users = this.users.filter((val, i) => i !== index);
        this.user = null;
        this.displayDialog = false;
      },
    });
  }

  onRowSelect(event) {
    this.user_block = 0;
    this.newUser = false;
    this.user = this.cloneUser(event.data);
    this.displayDialog = true;
    this.userBlock
      .verifUserBlock("bo_users", this.user.id)
      .then((userblock) => {
        if (userblock.data != "" && userblock.data != 0) {
          this.user_block = userblock.data;
          alert("Data en cours d'utilisation par : " + this.user_block);
        } else {
          this.userBlock.blockUser("bo_users", this.user.id);
          this.lastIDUser = this.user.id;
        }
      });
  }
  cloneUser(u: User): User {
    const user = {} as User;
    for (const prop in u) {
      user[prop] = u[prop];
    }
    return user;
  }
  @HostListener("window:beforeunload")
  closeUsers() {
    if (
      (this.user_block == this.currentUser || this.user_block == 0) &&
      !this.newUser &&
      this.user != null
    ) {
      this.userBlock
        .unblockUser("bo_users", this.user ? this.user.id : this.lastIDUser)
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
  }

  loadData() {
    this.userService.getUsers().then((users) => (this.users = users.data));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeUsers();
  }

  openImportDialog() {
    this.displayDialogImport = true;
  }

  importData(event) {
    this.loadData();
    console.log(event);
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }
}
