<p-table
  exportFilename="couleurs"
  csvSeparator=";"
  #dt
  [columns]="cols"
  [value]="descs"
  selectionMode="single"
  [(selection)]="selectedDesc"
  (onRowSelect)="onRowSelect($event)"
  [paginator]="true"
  [rows]="15"
  [responsive]="true"
>
  <ng-template pTemplate="caption">
    <div style="text-align: right">
      <i class="fa fa-search" style="margin: 4px 4px 0 0"></i>
      <input
        type="text"
        pInputText
        size="50"
        placeholder="Global Filter"
        (input)="dt.filterGlobal($event.target.value, 'contains')"
        style="width: auto"
      />
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        {{ col.header }}
        <p-sortIcon
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        ></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData">
      <td *ngFor="let col of columns">
        <div
          *ngIf="
            col.field == 'create' ||
              col.field == 'read' ||
              col.field == 'update' ||
              col.field == 'delete' ||
              col.field == 'valide' ||
              col.field == 'admin';
            else normalContent
          "
        >
          <input
            type="checkbox"
            id="checkbox"
            [checked]="rowData[col.field] ? 'checked' : ''"
            binary="true"
            disabled
          />
        </div>
        <ng-template #normalContent>
          {{ rowData[col.field] }}
        </ng-template>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="summary" let-rowData>
    <div style="text-align: left">
      <button
        type="button"
        pButton
        icon="fa fa-plus"
        (click)="showDialogToAdd()"
        label="Ajouter"
      ></button>
    </div>
  </ng-template>
</p-table>

<p-dialog
  appendTo="body"
  [focusOnShow]="false"
  header="Details"
  (onHide)="closeDescs()"
  [(visible)]="displayDialog"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
>
  <div class="p-fluid" *ngIf="desc">
    <div class="p-field p-grid" *ngIf="!newDesc">
      <div class="p-col-4">
        <label for="id">ID: </label>
      </div>
      <div class="p-col-8">
        <input pInputText id="id" [(ngModel)]="desc.id" disabled />
      </div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col-4">
        <label for="name">Libellé: </label>
      </div>
      <div class="p-col-8">
        <input pInputText id="name" [(ngModel)]="desc.name" required />
      </div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col-4">
        <label for="name">Commentaires/Précisions: </label>
      </div>
      <div class="p-col-8">
        <input
          pInputText
          id="description"
          [(ngModel)]="desc.description"
          required
        />
      </div>
    </div>
    <div class="p-field p-grid" *ngIf="!newDesc">
      <div class="p-col-4">
        <label for="dtcreate">Date de création: </label>
      </div>
      <div class="p-col-8">
        <input pInputText id="dtcreate" [(ngModel)]="desc.dtcreate" disabled />
      </div>
    </div>
    <div class="p-field p-grid" *ngIf="!newDesc">
      <div class="p-col-4">
        <label for="dtmodif">Date de modification: </label>
      </div>
      <div class="p-col-8">
        <input pInputText id="dtmodif" [(ngModel)]="desc.dtmodif" disabled />
      </div>
    </div>
  </div>
  <p-footer>
    <div
      *ngIf="user_block == 0 || user_block == currentUser"
      class="ui-dialog-buttonpane ui-helper-clearfix"
    >
      <button
        type="button"
        pButton
        icon="fa fa-close"
        (click)="delete()"
        label="Supprimer"
      ></button>
      <button
        type="button"
        pButton
        icon="fa fa-check"
        (click)="save()"
        label="Sauvegarder"
      ></button>
    </div>
  </p-footer>
</p-dialog>
<p-progressSpinner *ngIf="!descs"></p-progressSpinner>
