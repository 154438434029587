import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { AnalyseLine } from "../models/analyseline.model";

@Injectable({
  providedIn: "root",
})
export class AnalyseLineService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  async get(id: number) {
    return this.http
      .get(this.config.API_Host + "analyselines/get/" + id)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => {
        for (const d in data.data) {
          data.data[d].values = JSON.parse(data.data[d].vals);
        }
        return data;
      });
  }

  addupdate(options) {
    return this.http
      .post(
        this.config.API_Host + "analyselines/addupdate",
        JSON.stringify(options)
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  addduplicateline(id) {
    return this.http
      .post(
        this.config.API_Host + "analyselines/addduplicateline",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  async changeOrder(options) {
    const res = await this.http
      .post(
        this.config.API_Host + "analyselines/changeOrder",
        JSON.stringify(options)
      )
      .toPromise();
    const data = <ApiResponse>res;
    return data;
  }

  async validateLine(id) {
    const res = await this.http
      .post(
        this.config.API_Host + "analyselines/validateLine",
        JSON.stringify({ id: id })
      )
      .toPromise();
    const data = <ApiResponse>res;
    return data;
  }

  delete(id: number) {
    return this.http
      .post(
        this.config.API_Host + "analyselines/delete",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteAll(id: number) {
    return this.http
      .post(
        this.config.API_Host + "analyselines/deleteAll",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteAllDuplicates(id: number) {
    return this.http
      .post(
        this.config.API_Host + "analyselines/deleteAllDuplicates",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getListObject(table, value, id_user, header?, id_object?) {
    return this.http
      .post(
        this.config.API_Host + "analyselines/get-object-list",
        JSON.stringify({
          table: table,
          value: value,
          id_client: id_user,
          header: header,
          id_object: id_object,
        })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getListObjectForm(table, value, id_user, header?) {
    return this.http
      .post(
        this.config.API_Host + "analyselines/getlistobject",
        JSON.stringify({
          table: table,
          value: value,
          id_client: id_user,
          header: header,
        })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  async getParentAnalyseLines(id_client: number) {
    return this.http
      .get(this.config.API_Host + "parent-analyse/getColumns/" + id_client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
    }

}
