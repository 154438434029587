import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { TypeEvalRisk } from "../models/typeevalrisk.model";

@Injectable({
  providedIn: "root",
})
export class TypeEvalRiskService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  getTypeEvalRisks() {
    return this.http
      .get(this.config.API_Host + "typeevalrisks/get")
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  createTypeEvalRisk(typeevalrisk: TypeEvalRisk) {
    return this.http
      .post(
        this.config.API_Host + "typeevalrisks/add",
        JSON.stringify({ typeevalrisk })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  updateTypeEvalRisk(typeevalrisk: TypeEvalRisk) {
    return this.http
      .post(
        this.config.API_Host + "typeevalrisks/update",
        JSON.stringify({ typeevalrisk })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteTypeEvalRisk(id: number) {
    return this.http
      .post(
        this.config.API_Host + "typeevalrisks/delete",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
