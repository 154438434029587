import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { UserClient } from "./userclient.model";

@Injectable({
  providedIn: "root",
})
export class UserClientsService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  get() {
    return this.http
      .get(this.config.API_Host + "userclients/get")
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  create(userClient: UserClient) {
    return this.http
      .post(
        this.config.API_Host + "userclients/add",
        JSON.stringify({ userClient })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  update(userClient: UserClient) {
    return this.http
      .post(
        this.config.API_Host + "userclients/update",
        JSON.stringify({ userClient })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  delete(id: number) {
    return this.http
      .post(
        this.config.API_Host + "userclients/delete",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getByUserId(id: number) {
    return this.http
      .get(this.config.API_Host + "userclients/getByUserId/" + id)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getByStructureId(id: number, id_structure: number) {
    return this.http
      .get(
        this.config.API_Host +
          "userclients/getByStructureId/" +
          id +
          "/" +
          id_structure
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
