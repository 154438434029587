import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { ColorRange } from "./colorRange.model";

@Injectable({
  providedIn: "root",
})
export class ColorRangeService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  getColorRanges(client: number) {
    return this.http
      .get(this.config.API_Host + "colorRange/get/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  createColorRange(colorRange: ColorRange) {
    return this.http
      .post(
        this.config.API_Host + "colorRange/add",
        JSON.stringify({ colorRange })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  updateColorRange(colorRange: ColorRange) {
    return this.http
      .post(
        this.config.API_Host + "colorRange/update",
        JSON.stringify({ colorRange })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteColorRange(id: number) {
    return this.http
      .post(
        this.config.API_Host + "colorRange/delete",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getColorRangesForList(client: number) {
    return this.http
      .get(this.config.API_Host + "colorRange/getcolorrangesforlist/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteAll(id: number) {
    return this.http
      .post(
        this.config.API_Host + "colorRange/deleteAll",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
