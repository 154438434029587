import { Injectable } from "@angular/core";
import { ApiResponse } from "../models/api-response.model";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";

@Injectable({
  providedIn: "root",
})
export class MappingValidationService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  get(id_client, id_analyse) {
    return this.http
      .get(
        this.config.API_Host +
          "get/validationMapping/" +
          id_client +
          "/" +
          id_analyse
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  update(id_client, id_analyse, mapping) {
    return this.http
      .post(
        this.config.API_Host +
          "update/validationMapping/" +
          id_client +
          "/" +
          id_analyse,
        JSON.stringify({ mapping })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
