import { Component, OnInit } from "@angular/core";
import { MsgService } from "../services/msg.service";
import { ForgottenPasswordService } from "./forgotten-password.service";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-forgotten-password",
  templateUrl: "./forgotten-password.component.html",
  styleUrls: ["./forgotten-password.component.css"],
})
export class ForgottenPasswordComponent implements OnInit {
  email: string;
  siteKey = "6LfhEq0UAAAAANMTRu9dK0VFd2ahnyJNY8x0Ccqh";
  lang = "fr";
  captcha = "";
  aFormGroup: FormGroup;

  constructor(
    private msgService: MsgService,
    private forgottenPasswordService: ForgottenPasswordService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.aFormGroup = this.formBuilder.group({
      email: ["", Validators.required],
    });
  }

  sendConfirmationMail() {
    if (this.email) {
      // if (this.captcha !== "") {
        this.forgottenPasswordService
          .sendForgottenPassword(this.email)
          .then((data) => {
            if (data.code === 200) {
              this.msgService.showSuccess(
                "Un email avec les instructions pour changer votre mot de passe a été envoyé."
              );
              this.router.navigate(["/login"]);
            } else {
              this.msgService.showError(data.message);
            }
            this.captcha = "";
          });
      // } else {
      //   this.msgService.showError(
      //     "Merci de saisir votre email et votre mot de passe, ainsi que la captcha!"
      //   );
      // }
    } else {
      this.msgService.showError("Le champ email est obligatoire!");
    }
  }

  handleSuccess(event) {
    this.captcha = event;
  }
}
