import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { Proc } from "./proc.model";

@Injectable({
  providedIn: "root",
})
export class ProcService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  getProcs(client: any) {
    return this.http
      .get(this.config.API_Host + "procs/get/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  createProc(proc: Proc) {
    return this.http
      .post(this.config.API_Host + "procs/create", JSON.stringify({ proc }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  updateProc(proc: Proc) {
    return this.http
      .post(this.config.API_Host + "procs/update", JSON.stringify({ proc }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteProc(id: number) {
    return this.http
      .post(this.config.API_Host + "procs/delete", JSON.stringify({ id: id }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteAll(id: number) {
    return this.http
      .post(
        this.config.API_Host + "procs/deleteAll",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
