<h3>Workspaces:</h3>
<div style="text-align: center">
  <button
    type="button"
    pButton
    class="p-button-success"
    icon="fa fa-plus"
    (click)="addWorkspace()"
    label="Ajouter"
  ></button>
</div>
<div class="ui-g">
  <p-card
    *ngFor="let workspace of workspaces"
    class="p-col-12 ui-md-6 ui-lg-3"
    header="Advanced Card"
    subheader="Subheader"
    styleClass="ui-card-shadow"
  >
    <p-header>
      <p-inplace closable="closable">
        <span pInplaceDisplay> Nom de workspace: {{ workspace.name }} </span>
        <span pInplaceContent>
          <input
            type="text"
            value="Nom de workspace"
            [(ngModel)]="workspace.name"
            pInputText
            (change)="
              changeValues({
                idLigne: workspace.id,
                name: 'name',
                value: workspace.name,
                id_user: currentUser.id,
                type: 'name'
              })
            "
          />
        </span>
      </p-inplace>
    </p-header>
    <div>
      <div class="p-field p-grid">
        <div class="p-col-4">
          <label>DES: </label>
        </div>
        <div class="p-col-8">
          <p-dropdown
            appendTo="body"
            [filter]="true"
            [dataKey]="id"
            [options]="listDES"
            [(ngModel)]="selectedDES[workspace.id]"
            placeholder="DES"
            optionLabel="name"
            (onChange)="
              changeValues({
                idLigne: workspace.id,
                name: 'id_analyse_des',
                value: selectedDES[workspace.id].id,
                id_user: currentUser.id,
                type: 'analyse'
              })
            "
          ></p-dropdown>
        </div>
      </div>
      <div class="p-field p-grid">
        <div class="p-col-4">
          <label>RI: </label>
        </div>
        <div class="p-col-8">
          <p-dropdown
            appendTo="body"
            [filter]="true"
            [dataKey]="id"
            [options]="listRI"
            [(ngModel)]="selectedRI[workspace.id]"
            placeholder="RI"
            optionLabel="name"
            (onChange)="
              changeValues({
                idLigne: workspace.id,
                name: 'id_analyse_ri',
                value: selectedRI[workspace.id].id,
                id_user: currentUser.id,
                type: 'analyse'
              })
            "
          ></p-dropdown>
        </div>
      </div>
      <div class="p-field p-grid">
        <div class="p-col-4">
          <label>PA: </label>
        </div>
        <div class="p-col-8">
          <p-dropdown
            appendTo="body"
            [filter]="true"
            [dataKey]="id"
            [options]="listPA"
            [(ngModel)]="selectedPA[workspace.id]"
            placeholder="PA"
            optionLabel="name"
            (onChange)="
              changeValues({
                idLigne: workspace.id,
                name: 'id_analyse_pa',
                value: selectedPA[workspace.id].id,
                id_user: currentUser.id,
                type: 'analyse'
              })
            "
          ></p-dropdown>
        </div>
      </div>
      <div class="p-field p-grid">
        <div class="p-col-4">
          <label>RR: </label>
        </div>
        <div class="p-col-8">
          <p-dropdown
            appendTo="body"
            [filter]="true"
            [dataKey]="id"
            [options]="listRR"
            [(ngModel)]="selectedRR[workspace.id]"
            placeholder="RR"
            optionLabel="name"
            (onChange)="
              changeValues({
                idLigne: workspace.id,
                name: 'id_analyse_rr',
                value: selectedRR[workspace.id].id,
                id_user: currentUser.id,
                type: 'analyse'
              })
            "
          ></p-dropdown>
        </div>
      </div>
      <div class="p-field p-grid">
        <div class="p-col-4">
          <label>Clients: </label>
        </div>
        <div class="p-col-8">
          <p-dropdown
            appendTo="body"
            [filter]="true"
            [dataKey]="id"
            [options]="listClients"
            [(ngModel)]="selectedClients[workspace.id]"
            placeholder="RR"
            optionLabel="name"
            (onChange)="
              changeValues({
                idLigne: workspace.id,
                name: 'id_client',
                value: selectedClients[workspace.id].id,
                id_user: currentUser.id,
                type: 'client'
              })
            "
          ></p-dropdown>
        </div>
      </div>
    </div>
    <p-footer>
      <button
        type="button"
        style="height: 2em"
        pButton
        (click)="goToDetails(workspace.id)"
        type="button"
        label="Accès Workspace"
        icon="fa fa-check"
        style="margin-right: 0.25em"
      ></button>
      <button
        type="button"
        style="height: 2em"
        pButton
        (click)="delete(workspace.id)"
        type="button"
        icon="fa fa-close"
        class="p-button-danger"
        style="margin-right: 0.25em"
      ></button>
    </p-footer>
  </p-card>
</div>
