import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { Column } from "./column.model";

@Injectable({
  providedIn: "root",
})
export class ColumnsService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  get(id_client, id_analyse) {
    return this.http
      .get(this.config.API_Host + "columns/get/" + id_client + "/" + id_analyse)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getAll(id_client) {
    return this.http
      .get(this.config.API_Host + "columns/getAll/" + id_client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getStructure(id_structure) {
    return this.http
      .get(this.config.API_Host + "columns/getStructure/" + id_structure)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getColumnObjects(table) {
    return this.http
      .post(
        this.config.API_Host + "columns/getColumnObjects",
        JSON.stringify({ table })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  addupdate(options) {
    return this.http
      .post(this.config.API_Host + "columns/addupdate", JSON.stringify(options))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  delete(id: number, id_analyse: number, id_client: number) {
    return this.http
      .post(
        this.config.API_Host + "columns/delete",
        JSON.stringify({ id: id, id_analyse: id_analyse, id_client: id_client })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteAll(id: number) {
    return this.http
      .post(
        this.config.API_Host + "columns/deleteAll",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
