import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProposeScenarioService } from "./propose-scenario.service";
import { MessageService } from "primeng/api";

@Component({
  selector: 'app-propose-scenario',
  templateUrl: './propose-scenario.component.html',
  styleUrls: ['./propose-scenario.component.css']
})
export class ProposeScenarioComponent implements OnInit {

  proposeScenarioForm: FormGroup;
  @Input() id_analyse;
  @Input() id_client = 0;
  @Output() proposeScenarioSuccess = new EventEmitter<boolean>();


  constructor(
    private formBuilder: FormBuilder,
    private proposeScenarioService: ProposeScenarioService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.proposeScenarioForm = this.formBuilder.group({
      description: ['', Validators.required],
      consequences: [''],
      action_plan: ['']
    });
  }

  proposeScenario() {
    const data = this.proposeScenarioForm.value;
    data.id_structure = this.id_client;
    data.id_analyse = this.id_analyse;
    data.id_proposer = JSON.parse(localStorage.getItem("user")).data.id;

    this.proposeScenarioService.create(this.proposeScenarioForm.value).then((response) => {
      if (response) {
        this.messageService.add({
          severity: "success",
          summary: "Succès",
          detail: "Proposition de scénario envoyée",
          life: 3000,
        });

        this.proposeScenarioForm.reset();

        this.proposeScenarioSuccess.emit(true);
      }
    });
  }

}
