import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DataService } from "../services/data.service";

@Component({
  selector: "app-risk-container",
  templateUrl: "./risk-container.component.html",
  styleUrls: ["./risk-container.component.css"],
})
export class RiskContainerComponent implements OnInit {
  @Input("clients") clients: any;
  activatedComp: any;

  private messageSource = new BehaviorSubject("");

  constructor(private data: DataService) {}

  ngOnInit() {
    this.data.currentMessage.subscribe(
      (message) => (this.activatedComp = message)
    );
  }
}
