import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from ".//app-routing.module";
import { LoginComponent } from "./login/login.component";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { MsgComponent } from "./msg/msg.component";
import { MsgService } from "./services/msg.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthService } from "./services/auth.service";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { HomeComponent } from "./home/home.component";
import { AuthGuard } from "./guards/auth.guard";
import { TableModule } from "primeng/table";
import { UsersComponent } from "./users/users.component";
import { DialogModule } from "primeng/dialog";
import { UsersService } from "./users/users.service";
import { ButtonModule } from "primeng/button";
import { TracesService } from "./traces/traces.service";
import { TracesComponent } from "./traces/traces.component";
import { DataViewModule } from "primeng/dataview";
import { PanelModule } from "primeng/panel";
import { AdminGuard } from "./guards/admin.guard";
import { AppConfig } from "./config";
import { ChipsModule } from "primeng/chips";
import { UserBlockService } from "./services/user-block.service";
import { RiskComponent } from "./risk/risk.component";
import { RiskDataComponent } from "./risk-data/risk-data.component";
import { RiskContainerComponent } from "./risk-container/risk-container.component";
import { TabViewModule } from "primeng/tabview";
import { ProcComponent } from "./proc/proc.component";
import { AnalyseComponent } from "./analyse/analyse.component";
import { ClientComponent } from "./client/client.component";
import { DescObjectComponent } from "./desc-object/desc-object.component";
import { ListObjectComponent } from "./list-object/list-object.component";
import { FormulaObjectComponent } from "./formula-object/formula-object.component";
import { DataTableContainerComponent } from "./data-table-container/data-table-container.component";
import { UserConfigurationComponent } from "./user-configuration/user-configuration.component";
import { UserConfigurationService } from "./services/user-configuration.service";
import { TypeEvalRiskComponent } from "./type-eval-risk/type-eval-risk.component";
import { ColumnsComponent } from "./columns/columns.component";
import { ObjectTypes } from "./models/object_types";
import { ColumnsService } from "./columns/columns.service";
import { AnalyseColumnsService } from "./services/analyse-columns.service";
import { WorkspaceComponent } from "./workspace/workspace.component";
import { WorkspaceService } from "./workspace/workspace.service";
import { UserClientsComponent } from "./user-clients/user-clients.component";
import { UserClientsService } from "./user-clients/user-clients.service";
import { WorkspaceDetailsComponent } from "./workspace-details/workspace-details.component";
import { WorkspaceDetailsService } from "./workspace-details/workspace-details.service";
import { ColorRangeComponent } from "./color-range/color-range.component";
import { ColorRangeService } from "./color-range/color-range.service";
import { ThemeComponent } from "./theme/theme.component";
import { SubthemeComponent } from "./subtheme/subtheme.component";
import { ThemeService } from "./theme/theme.service";
import { SubthemeService } from "./subtheme/subtheme.service";
import { ProbabilityComponent } from "./probability/probability.component";
import { ProbabilityService } from "./probability/probability.service";
import { HttpClientModule } from "@angular/common/http";
import { DataService } from "./services/data.service";
import { SetPasswordComponent } from "./set-password/set-password.component";
import { SetPasswordService } from "./set-password/set-password.service";
import { ForgottenPasswordComponent } from "./forgotten-password/forgotten-password.component";
import { AutoFocusDirective } from "./directives/auto-focus.directive";
import { ParamGraphComponent } from "./param-graph/param-graph.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { StructureClientComponent } from "./structure-client/structure-client.component";
import { StructureClientService } from "./structure-client/structure-client.service";
import { CartoComponent } from "./carto/carto.component";
import { CartoService } from "./carto/carto.service";
import { MappingValidationComponent } from "./mapping-validation/mapping-validation.component";
import { DashboardsComponent } from "./dashboards/dashboards.component";
import { DashboardsService } from "./dashboards/dashboards.service";
import {
  RECAPTCHA_LANGUAGE,
  RecaptchaFormsModule,
  RecaptchaModule,
} from "ng-recaptcha";
import { DropdownModule } from "primeng/dropdown";
import { ToolbarModule } from "primeng/toolbar";
import { CheckboxModule } from "primeng/checkbox";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { CardModule } from "primeng/card";
import { InplaceModule } from "primeng/inplace";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { PasswordModule } from "primeng/password";
import { AccordionModule } from "primeng/accordion";
import { ColorPickerModule } from "primeng/colorpicker";
import { MultiSelectModule } from "primeng/multiselect";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { FileUploadModule } from "primeng/fileupload";
import { CalendarModule } from "primeng/calendar";
import { TooltipModule } from "primeng/tooltip";
import { ChartModule } from "primeng/chart";
import { TabMenuModule } from "primeng/tabmenu";
import { MegaMenuModule } from "primeng/megamenu";
import { ToastModule } from "primeng/toast";
import { OrganizationChartModule } from "primeng/organizationchart";
import { TreeModule } from "primeng/tree";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ConfirmationService, MessageService } from "primeng/api";
import { InputSwitchModule } from "primeng/inputswitch";
import { PersonComponent } from "./person/person.component";
import { ActionComponent } from "./action/action.component";
import { ActionService } from "./action/action.service";
import { PlanComponent } from "./plan/plan.component";
import { FocusTrapModule } from "primeng/focustrap";
import { PlanService } from "./plan/plan.service";
import { TagModule } from "primeng/tag";
import { ChipModule } from "primeng/chip";
import { FieldsetModule } from "primeng/fieldset";
import { UserGroupComponent } from "./user-group/user-group.component";
import { UserGroupService } from "./user-group/user-group.service";
import { RippleModule } from "primeng/ripple";
import { ActionManagementComponent } from './action-management/action-management.component';
import { ParentAnalyseComponent } from './parent-analyse/parent-analyse.component';
import { ProposeScenarioComponent } from './propose-scenario/propose-scenario.component';
import { ProposeScenarioService } from "./propose-scenario/propose-scenario.service";
import { ValidateScenarioComponent } from './validate-scenario/validate-scenario.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ManagementScenarioProposalsComponent } from './management-scenario-proposals/management-scenario-proposals.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MsgComponent,
    PageNotFoundComponent,
    HomeComponent,
    UsersComponent,
    TracesComponent,
    RiskComponent,
    RiskDataComponent,
    RiskContainerComponent,
    ProcComponent,
    AnalyseComponent,
    ClientComponent,
    DescObjectComponent,
    ListObjectComponent,
    FormulaObjectComponent,
    DataTableContainerComponent,
    UserConfigurationComponent,
    TypeEvalRiskComponent,
    ColumnsComponent,
    WorkspaceComponent,
    UserClientsComponent,
    WorkspaceDetailsComponent,
    ColorRangeComponent,
    ThemeComponent,
    SubthemeComponent,
    ProbabilityComponent,
    SetPasswordComponent,
    ForgottenPasswordComponent,
    AutoFocusDirective,
    ParamGraphComponent,
    DashboardComponent,
    StructureClientComponent,
    CartoComponent,
    MappingValidationComponent,
    DashboardsComponent,
    PersonComponent,
    ActionComponent,
    PlanComponent,
    UserGroupComponent,
    ActionManagementComponent,
    ProposeScenarioComponent,
    ValidateScenarioComponent,
    ManagementScenarioProposalsComponent,
    ParentAnalyseComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    MessagesModule,
    MessageModule,
    HttpClientModule,
    FormsModule,
    TableModule,
    DialogModule,
    ButtonModule,
    DataViewModule,
    DropdownModule,
    PanelModule,
    CheckboxModule,
    ToolbarModule,
    AutoCompleteModule,
    ChipsModule,
    TabViewModule,
    ProgressSpinnerModule,
    CardModule,
    InplaceModule,
    InputTextModule,
    InputTextareaModule,
    PasswordModule,
    AccordionModule,
    ColorPickerModule,
    MultiSelectModule,
    ConfirmDialogModule,
    CalendarModule,
    FileUploadModule,
    TooltipModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ReactiveFormsModule,
    ChartModule,
    TabMenuModule,
    MegaMenuModule,
    ToastModule,
    OrganizationChartModule,
    TreeModule,
    OverlayPanelModule,
    InputSwitchModule,
    FocusTrapModule,
    TagModule,
    ChipModule,
    FieldsetModule,
    RippleModule,
    SelectButtonModule,
  ],
  providers: [
    MsgService,
    AuthService,
    AuthGuard,
    AdminGuard,
    UsersService,
    UserClientsService,
    TracesService,
    UserBlockService,
    UserConfigurationService,
    ColumnsService,
    AnalyseColumnsService,
    WorkspaceService,
    WorkspaceDetailsService,
    ColorRangeService,
    ConfirmationService,
    ThemeService,
    SubthemeService,
    ProbabilityService,
    DataService,
    SetPasswordService,
    AppConfig,
    ObjectTypes,
    MessageService,
    StructureClientService,
    CartoService,
    DashboardsService,
    ActionService,
    PlanService,
    UserGroupService,
    ProposeScenarioService,
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: "fr", // use French language
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
