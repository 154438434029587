<div class="p-grid p-fluid">
  <div
    *ngIf="!valideToken"
    class="p-col-6 p-offset-3"
    style="text-align: center"
  >
    <h1>
      Changement de mot de passe non autorisé! <br />
      Merci de contacter votre administrateur système.
    </h1>
  </div>
  <div *ngIf="valideToken" class="p-col-6 p-offset-3">
    <div class="p-col-6 p-offset-3">
      <h5 class="first">Changement de mot de passe:</h5>
    </div>
    <div class="p-col-6 p-offset-3">
      <div class="ui-inputgroup">
        <input
          [(ngModel)]="password"
          [type]="showPassword === true ? 'text' : 'password'"
          pInputText
          placeholder="Mot de passe"
          required
        />
        <button class="ui-inputgroup-addon" (click)="changeShowPassword()">
          <i
            [ngClass]="showPassword === true ? 'fa fa-eye' : ' fa fa-eye-slash'"
          ></i>
        </button>
      </div>
    </div>
    <div class="p-col-6 p-offset-3">
      <div class="ui-inputgroup">
        <input
          [(ngModel)]="secondpassword"
          [type]="showPassword === true ? 'text' : 'password'"
          pInputText
          placeholder="Répéter le mot de passe"
          required
        />
        <button class="ui-inputgroup-addon" (click)="changeShowPassword()">
          <i
            [ngClass]="showPassword === true ? 'fa fa-eye' : ' fa fa-eye-slash'"
          ></i>
        </button>
      </div>
    </div>
    <div class="p-col-6 p-offset-3">
      <div class="p-field-checkbox">
        <p-checkbox
          [binary]="true"
          [(ngModel)]="rgpdChecked"
          inputId="rgpd"
          required
        ></p-checkbox>
        <label for="rgpd">{{ rgpdMessage }}</label>
      </div>
    </div>
    <div class="p-col-6 p-offset-3">
      <button
        pButton
        type="button"
        class="p-button-raised p-button-success"
        icon="pi pi-check"
        iconPos="left"
        label="Sauvegarder"
        (click)="savePasswordInfo()"
      ></button>
    </div>
  </div>
</div>
