import { Component, HostListener, OnInit } from "@angular/core";
import { interval, Observable, Subject } from "rxjs";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { UserBlockService } from "../services/user-block.service";
import { AppConfig } from "../config";
import { Log } from "../models/log.model";
import { TypeEvalRisk } from "../models/typeevalrisk.model";
import { TypeEvalRiskService } from "../services/type-eval-risk.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-type-eval-risk",
  templateUrl: "./type-eval-risk.component.html",
  styleUrls: ["./type-eval-risk.component.css"],
})
export class TypeEvalRiskComponent implements OnInit {
  displayDialog: boolean;
  typeevalrisk: TypeEvalRisk = {} as TypeEvalRisk;
  selectedTypeEvalRisk: TypeEvalRisk;
  newTypeEvalRisk: boolean;
  typeevalrisks: TypeEvalRisk[];
  cols: any[];
  user_block: any = 0;
  lastIDTypeEvalRisk: number;
  currentTypeEvalRisk: any;
  currentUser: any;
  timeoutCounter = 0;
  private onDestroy$ = new Subject();
  constructor(
    private typeevalriskService: TypeEvalRiskService,
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    public config: AppConfig
  ) {}
  ngOnInit() {
    this.loadData();
    this.currentUser = JSON.parse(localStorage.getItem("user")).data.email;
    this.cols = [
      { field: "name", header: "Nom:" },
      { field: "description", header: "Description:" },
      { field: "dtcreate", header: "Création:" },
      { field: "dtmodif", header: "Modification:" },
    ];
    interval(this.config.RefreshMilisecs)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((x) => {
        if (this.timeoutCounter < this.config.RefreshTimer) {
          this.loadData();
          this.timeoutCounter++;
        }
      });
  }
  @HostListener("document:mousemove")
  onMouseMove() {
    this.timeoutCounter = 0;
  }

  showDialogToAdd() {
    if (
      this.typeevalrisk != null &&
      this.typeevalrisk.id != null &&
      !this.typeevalrisk
    ) {
      this.closeTypeEvalRisks();
    }
    this.newTypeEvalRisk = true;
    this.typeevalrisk = {} as TypeEvalRisk;
    this.displayDialog = true;
  }
  save() {
    const typeevalrisks = this.typeevalrisks
      ? [...this.typeevalrisks]
      : ([] as TypeEvalRisk[]);
    const jsonrisk = JSON.stringify(this.typeevalrisk);
    if (this.typeevalrisk.name && this.typeevalrisk.description) {
      if (this.newTypeEvalRisk) {
        typeevalrisks.push(this.typeevalrisk);
        this.typeevalriskService
          .createTypeEvalRisk(this.typeevalrisk)
          .then((res) => {
            if (res.code === 200) {
              this.loadData();
              this.msgs.showSuccess("Type de risque créé!");
              this.trace.createTrace({
                action: "CREATE TYPE EVAL RISK",
                data: jsonrisk,
                description: "CREATION TYPE EVAL RISK",
              } as Log);
            } else {
              this.msgs.showError(res.message);
              const jsonmsg = JSON.stringify(res.message);
              this.trace.createTrace({
                action: "CREATE TYPE EVAL RISK",
                data: jsonmsg,
                description: "ERREUR CREATION TYPE DE RISQUE",
              } as Log);
            }
          });
      } else {
        typeevalrisks[this.typeevalrisks.indexOf(this.selectedTypeEvalRisk)] =
          this.typeevalrisk;
        this.typeevalriskService
          .updateTypeEvalRisk(this.typeevalrisk)
          .then((res) => {
            if (res.code === 200) {
              this.loadData();
              this.msgs.showSuccess("Risque mise à jour!");
              this.trace.createTrace({
                action: "UPDATE TYPE EVAL RISK",
                data: JSON.stringify(typeevalrisks),
                description: "Mise a jour type de risque",
              } as Log);
            } else {
              const jsonmsg = JSON.stringify(res.message);
              this.trace.createTrace({
                action: "UPDATE TYPE EVAL RISK",
                data: jsonmsg,
                description: "ERREUR MAJ TYPE DE RISQUE",
              } as Log);
            }
            this.userBlock
              .unblockUser(
                "bo_type_eval_risk",
                this.typeevalrisk
                  ? this.typeevalrisk.id
                  : this.lastIDTypeEvalRisk
              )
              .then((data) => {
                console.log("Unblock users");
              });
          });
      }
    } else {
      this.msgs.showError("Merci de remplir tous les champs!");
    }
    this.lastIDTypeEvalRisk = this.typeevalrisk.id;
    this.typeevalrisks = typeevalrisks;
    this.typeevalrisk = null;
    this.displayDialog = false;
  }
  delete() {
    this.typeevalriskService
      .deleteTypeEvalRisk(this.typeevalrisk.id)
      .then((res) => {
        if (res.code === 200) {
          this.loadData();
          this.msgs.showSuccess("Type de risque supprimé!");
          this.trace.createTrace({
            action: "DELETE TYPE EVAL RISK",
            data: JSON.stringify(this.typeevalrisk),
            description: "SUPPRESSION TYPE DE RISQUE",
          } as Log);
        } else {
          this.msgs.showError(res.message);
        }
      });
    this.userBlock
      .unblockUser(
        "bo_type_eval_risk",
        this.typeevalrisk.id ? this.typeevalrisk.id : this.lastIDTypeEvalRisk
      )
      .then((data) => {
        console.log("Unblock users");
      });
    this.lastIDTypeEvalRisk = this.typeevalrisk.id;
    const index = this.typeevalrisks.indexOf(this.selectedTypeEvalRisk);
    this.typeevalrisks = this.typeevalrisks.filter((val, i) => i !== index);
    this.typeevalrisk = null;
    this.displayDialog = false;
  }
  onRowSelect(event) {
    this.user_block = 0;
    this.newTypeEvalRisk = false;
    this.typeevalrisk = this.cloneTypeEvalRisk(event.data);
    this.displayDialog = true;
    this.userBlock
      .verifUserBlock("bo_type_eval_risk", this.typeevalrisk.id)
      .then((userblock) => {
        if (userblock.data != "" && userblock.data != 0) {
          this.user_block = userblock.data;
          alert("Data en cours d'utilisation par : " + this.user_block);
        } else {
          this.userBlock.blockUser("bo_type_eval_risk", this.typeevalrisk.id);
          this.lastIDTypeEvalRisk = this.typeevalrisk.id;
        }
      });
  }
  cloneTypeEvalRisk(r: TypeEvalRisk): TypeEvalRisk {
    const risk = {} as TypeEvalRisk;
    for (const prop in r) {
      risk[prop] = r[prop];
    }
    return risk;
  }
  @HostListener("window:beforeunload")
  closeTypeEvalRisks() {
    if (
      (this.user_block == this.currentUser || this.user_block == 0) &&
      !this.newTypeEvalRisk &&
      this.typeevalrisk != null
    ) {
      this.userBlock
        .unblockUser(
          "bo_type_eval_risk",
          this.typeevalrisk ? this.typeevalrisk.id : this.lastIDTypeEvalRisk
        )
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
  }

  loadData() {
    this.typeevalriskService.getTypeEvalRisks().then((typeevalrisks) => {
      this.typeevalrisks = typeevalrisks.data;
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeTypeEvalRisks();
  }
}
