import { takeUntil } from "rxjs/operators";
import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { Log } from "../models/log.model";
import { UserBlockService } from "../services/user-block.service";
import { BehaviorSubject, interval, Subject } from "rxjs";

import { AppConfig } from "../config";
import { Risk } from "./risk.model";
import { RiskService } from "./risk.service";
import { Confirmation, ConfirmationService } from "primeng/api";
import { DataService } from "../services/data.service";
import { UserRightsService } from "../services/user-rights.service";

@Component({
  selector: "app-risk",
  templateUrl: "./risk.component.html",
  styleUrls: ["./risk.component.css"],
  host: { "window:beforeunload": "closeRisks" },
})
export class RiskComponent implements OnInit, OnDestroy, OnChanges {
  displayDialog: boolean;
  risk: Risk = {} as Risk;
  selectedRisk: Risk;
  newRisk: boolean;
  risks: Risk[];
  cols: any[];
  user_block: any = 0;
  lastIDRisk: number;
  currentRisk: any;
  currentUser: any;
  displayDialogImport = false;
  showDeleteColumn = false;
  importFiles: any[] = [];
  timeoutCounter = 0;
  @Input("clients") clients: any;
  @Input("activatedComp") activatedComp: any;

  private onDestroy$ = new Subject();
  constructor(
    private riskService: RiskService,
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    private confirmationService: ConfirmationService,
    private userRightsService: UserRightsService,
    private data: DataService,
    public config: AppConfig
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.clients;
    if (currentItem) {
      this.loadData();
    }
  }

  ngOnInit() {
    this.data.currentMessage.subscribe(
      (message) => (this.activatedComp = message)
    );
    if (this.clients && this.activatedComp == "Risk") {
      this.loadData();

      this.currentUser = JSON.parse(localStorage.getItem("user")).data.email;
      this.cols = [
        { field: "name", header: "Libellé: " },
        { field: "description", header: "Commentaires/Précisions: " },
        { field: "dtcreate", header: "Création: " },
        { field: "dtmodif", header: "Modification: " },
      ];
      interval(this.config.RefreshMilisecs)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((x) => {
          if (this.timeoutCounter < this.config.RefreshTimer && this.clients) {
            this.loadData();
            this.timeoutCounter++;
          }
        });
    }
  }
  @HostListener("document:mousemove")
  onMouseMove() {
    this.timeoutCounter = 0;
  }

  showDialogToAdd() {
    if (this.risk != null && this.risk.id != null && !this.risk) {
      this.closeRisks();
    }
    this.newRisk = true;
    this.risk = {} as Risk;
    this.displayDialog = true;
  }
  save() {
    const risks = this.risks ? [...this.risks] : ([] as Risk[]);
    this.risk.id_client = this.clients;
    this.risk.description = this.risk.description ? this.risk.description : "";
    const jsonrisk = JSON.stringify(this.risk);
    if (this.risk.name) {
      if (this.newRisk) {
        risks.push(this.risk);
        this.riskService.createRisk(this.risk).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Risque créé!");
            this.trace.createTrace({
              action: "CREATE RISK",
              data: jsonrisk,
              description: "CREATION RISQUE",
            } as Log);
          } else {
            this.msgs.showError(res.message);
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "CREATE RISK",
              data: jsonmsg,
              description: "ERREUR CREATION RISQUE",
            } as Log);
          }
        });
      } else {
        risks[this.risks.indexOf(this.selectedRisk)] = this.risk;
        this.riskService.updateRisk(this.risk).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Risque mise à jour!");
            this.trace.createTrace({
              action: "UPDATE RISK",
              data: JSON.stringify(risks),
              description: "Mise a jour risques",
            } as Log);
          } else {
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "UPDATE RISK",
              data: jsonmsg,
              description: "ERREUR MAJ RISQUES",
            } as Log);
          }
          this.userBlock
            .unblockUser("bo_risk", this.risk ? this.risk.id : this.lastIDRisk)
            .then((data) => {
              console.log("Unblock users");
            });
        });
      }
    } else {
      this.msgs.showError("Merci de remplir tous les champs!");
    }
    this.lastIDRisk = this.risk.id;
    this.risks = risks;
    this.risk = null;
    this.displayDialog = false;
  }
  delete() {
    this.riskService.deleteRisk(this.risk.id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Risque supprimé!");
        this.trace.createTrace({
          action: "DELETE RISK",
          data: JSON.stringify(this.risk),
          description: "SUPPRESSION RISQUE",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.userBlock
      .unblockUser("bo_risk", this.risk.id ? this.risk.id : this.lastIDRisk)
      .then((data) => {
        console.log("Unblock users");
      });
    this.lastIDRisk = this.risk.id;
    const index = this.risks.indexOf(this.selectedRisk);
    this.risks = this.risks.filter((val, i) => i !== index);
    this.risk = null;
    this.displayDialog = false;
  }

  deleteLine(id) {
    this.riskService.deleteRisk(id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Risque supprimé!");
        this.trace.createTrace({
          action: "DELETE RISK",
          data: JSON.stringify(this.risk),
          description: "SUPPRESSION RISQUE",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.userBlock
      .unblockUser("bo_risk", this.risk.id ? this.risk.id : this.lastIDRisk)
      .then((data) => {
        console.log("Unblock users");
      });
    this.lastIDRisk = this.risk.id;
    const index = this.risks.indexOf(this.selectedRisk);
    this.risks = this.risks.filter((val, i) => i !== index);
    this.risk = null;
    this.displayDialog = false;
  }
  onRowSelect(event) {
    this.user_block = 0;
    this.newRisk = false;
    this.risk = this.cloneRisk(event.data);
    this.displayDialog = true;
    this.userBlock.verifUserBlock("bo_risk", this.risk.id).then((userblock) => {
      if (userblock.data != "" && userblock.data != 0) {
        this.user_block = userblock.data;
        alert("Data en cours d'utilisation par : " + this.user_block);
      } else {
        this.userBlock.blockUser("bo_risk", this.risk.id);
        this.lastIDRisk = this.risk.id;
      }
    });
  }
  cloneRisk(r: Risk): Risk {
    const risk = {} as Risk;
    for (const prop in r) {
      risk[prop] = r[prop];
    }
    return risk;
  }
  @HostListener("window:beforeunload")
  closeRisks() {
    if (
      (this.user_block == this.currentUser || this.user_block == 0) &&
      !this.newRisk &&
      this.risk != null
    ) {
      this.userBlock
        .unblockUser("bo_risk", this.risk ? this.risk.id : this.lastIDRisk)
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
  }

  loadData() {
    this.riskService
      .getRisks(this.clients)
      .then((risks) => (this.risks = risks.data ? risks.data : ([] as Risk[])));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeRisks();
  }

  openImportDialog() {
    this.displayDialogImport = true;
  }

  importData(event) {
    this.loadData();
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }

  deleteAllLines() {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer toutes les lignes?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.riskService.deleteAll(this.clients).then((res) => {
          this.loadData();
          this.msgs.showSuccess("Ligne supprimée!");
        });
      },
    });
  }
}
