<div class="offset-5">
  Client :
  <p-dropdown
    appendTo="body"
    (onChange)="changeClientCache()"
    optionLabel="label"
    [filter]="true"
    [options]="clients"
    [(ngModel)]="selectedClient"
  ></p-dropdown>
</div>
<br /><br />
<p-tabView [activeIndex]="index ? index : 0" (onChange)="changeObject($event)">
  <p-tabPanel header="Processus" name="Processus">
    <app-proc
      *ngIf="activatedComp == 'Processus' && clients"
      [clients]="selectedClient ? selectedClient.code : 0"
    ></app-proc>
  </p-tabPanel>
  <p-tabPanel header="Carto processus" name="Carto">
    <app-carto
      *ngIf="activatedComp == 'Carto' && clients"
      [clients]="selectedClient ? selectedClient.code : 0"
    ></app-carto>
  </p-tabPanel>
  <p-tabPanel header="Thémes" name="Thémes">
    <app-theme
      *ngIf="activatedComp == 'Thémes' && clients"
      [clients]="selectedClient ? selectedClient.code : 0"
    ></app-theme>
  </p-tabPanel>
  <p-tabPanel header="Sous-Thémes" name="Sous-Thémes">
    <app-subtheme
      *ngIf="activatedComp == 'Sous-Thémes' && clients"
      [clients]="selectedClient ? selectedClient.code : 0"
    ></app-subtheme>
  </p-tabPanel>
  <p-tabPanel header="Gravité des impacts" name="Risk">
    <app-risk-container
      *ngIf="activatedComp == 'Risk' && clients"
      [clients]="selectedClient ? selectedClient.code : 0"
    ></app-risk-container>
  </p-tabPanel>
  <p-tabPanel header="Probabilité" name="Probabilité">
    <app-probability
      *ngIf="activatedComp == 'Probabilité' && clients"
      [clients]="selectedClient ? selectedClient.code : 0"
    ></app-probability>
  </p-tabPanel>
  <!--<p-tabPanel header="Tables des descriptions">
    <app-desc-object [clients] = "selectedClient ? selectedClient.code : 0.code"></app-desc-object>
  </p-tabPanel>-->
  <p-tabPanel header="Listes" name="Listes">
    <app-list-object
      *ngIf="activatedComp == 'Listes' && clients"
      [clients]="selectedClient ? selectedClient.code : 0"
    ></app-list-object>
  </p-tabPanel>
  <p-tabPanel header="Formules" name="Formules">
    <app-formula-object
      *ngIf="activatedComp == 'Formules' && clients"
      [clients]="selectedClient ? selectedClient.code : 0"
    ></app-formula-object>
  </p-tabPanel>
  <p-tabPanel header="Couleurs" name="Couleurs">
    <app-color-range
      *ngIf="activatedComp == 'Couleurs' && clients"
      [clients]="selectedClient ? selectedClient.code : 0"
    ></app-color-range>
  </p-tabPanel>
  <p-tabPanel header="Actions" name="Actions">
    <app-action
      *ngIf="activatedComp == 'Actions' && clients"
      [clients]="selectedClient ? selectedClient.code : 0"
    ></app-action>
  </p-tabPanel>
  <p-tabPanel header="Personnes" name="Persons">
    <app-person
      *ngIf="activatedComp == 'Persons' && clients"
      [clients]="selectedClient ? selectedClient.code : 0"
    ></app-person>
  </p-tabPanel>
  <p-tabPanel header="Groupes d'utilisateurs" name="UserGroup">
    <app-user-group
      *ngIf="activatedComp == 'UserGroup' && clients"
      [clients]="selectedClient ? selectedClient.code : 0"
    ></app-user-group>
  </p-tabPanel>
  <!--  <p-tabPanel header="Mapping de Validation" name="MappingValidation">-->
  <!--    <app-mapping-validation-->
  <!--      *ngIf="activatedComp == 'MappingValidation' && clients"-->
  <!--      [clients]="selectedClient ? selectedClient.code : 0"-->
  <!--    ></app-mapping-validation>-->
  <!--  </p-tabPanel>-->
</p-tabView>
