import { Injectable } from "@angular/core";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { Probability } from "./probability.model";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ProbabilityService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  get(client: number) {
    return this.http
      .get(this.config.API_Host + "probability/get/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  create(probability: Probability) {
    return this.http
      .post(
        this.config.API_Host + "probability/create",
        JSON.stringify({ probability })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  save(probability: Probability) {
    return this.http
      .post(
        this.config.API_Host + "probability/update",
        JSON.stringify({ probability })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  delete(id) {
    return this.http
      .post(
        this.config.API_Host + "probability/del",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res);
  }

  deleteAll(id: number) {
    return this.http
      .post(
        this.config.API_Host + "probability/deleteAll",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
