import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Desc } from "./desc.model";
import { interval, Subject } from "rxjs";
import { DescService } from "./desc.service";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { UserBlockService } from "../services/user-block.service";
import { AppConfig } from "../config";
import { Log } from "../models/log.model";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-desc-object",
  templateUrl: "./desc-object.component.html",
  styleUrls: ["./desc-object.component.css"],
})
export class DescObjectComponent implements OnInit {
  displayDialog: boolean;
  desc: Desc = {} as Desc;
  selectedDesc: Desc;
  newDesc: boolean;
  descs: Desc[] = [] as Desc[];
  cols: any[];
  user_block: any = 0;
  lastIDDesc: number;
  currentDesc: any;
  currentUser: any;
  timeoutCounter = 0;
  @Input("clients") clients: any;
  private onDestroy$ = new Subject();
  constructor(
    private descService: DescService,
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    public config: AppConfig
  ) {}
  ngOnInit() {
    if (this.clients) {
      this.loadData();
    }
    this.currentUser = JSON.parse(localStorage.getItem("user")).data.email;
    this.cols = [
      { field: "name", header: "Libellé: " },
      { field: "description", header: "Commentaires/Précisions: " },
      { field: "dtcreate", header: "Création: " },
      { field: "dtmodif", header: "Modification: " },
    ];
    interval(this.config.RefreshMilisecs)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((x) => {
        if (this.timeoutCounter < this.config.RefreshTimer && this.clients) {
          this.loadData();
          this.timeoutCounter++;
        }
      });
  }
  @HostListener("document:mousemove")
  onMouseMove() {
    this.timeoutCounter = 0;
  }

  showDialogToAdd() {
    if (this.desc != null && this.desc.id != null && !this.desc) {
      this.closeDescs();
    }
    this.newDesc = true;
    this.desc = {} as Desc;
    this.displayDialog = true;
  }
  save() {
    const descs = this.descs ? [...this.descs] : ([] as Desc[]);
    const jsondesc = JSON.stringify(this.desc);
    this.desc.id_client = this.clients;
    if (this.desc.name && this.desc.description) {
      if (this.newDesc) {
        descs.push(this.desc);
        this.descService.create(this.desc).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Risque créé!");
            this.trace.createTrace({
              action: "CREATE RISK",
              data: jsondesc,
              description: "CREATION RISQUE",
            } as Log);
          } else {
            this.msgs.showError(res.message);
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "CREATE RISK",
              data: jsonmsg,
              description: "ERREUR CREATION RISQUE",
            } as Log);
          }
        });
      } else {
        descs[this.descs.indexOf(this.selectedDesc)] = this.desc;
        this.descService.update(this.desc).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Risque mise à jour!");
            this.trace.createTrace({
              action: "UPDATE RISK",
              data: JSON.stringify(descs),
              description: "Mise a jour risques",
            } as Log);
          } else {
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "UPDATE RISK",
              data: jsonmsg,
              description: "ERREUR MAJ RISQUES",
            } as Log);
          }
          this.userBlock
            .unblockUser("bo_desc", this.desc ? this.desc.id : this.lastIDDesc)
            .then((data) => {
              console.log("Unblock users");
            });
        });
      }
    } else {
      this.msgs.showError("Merci de remplir tous les champs!");
    }
    this.lastIDDesc = this.desc.id;
    this.descs = descs;
    this.desc = null;
    this.displayDialog = false;
  }
  delete() {
    this.descService.delete(this.desc.id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Risque supprimé!");
        this.trace.createTrace({
          action: "DELETE RISK",
          data: JSON.stringify(this.desc),
          description: "SUPPRESSION RISQUE",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.userBlock
      .unblockUser("bo_desc", this.desc.id ? this.desc.id : this.lastIDDesc)
      .then((data) => {
        console.log("Unblock users");
      });
    this.lastIDDesc = this.desc.id;
    const index = this.descs.indexOf(this.selectedDesc);
    this.descs = this.descs.filter((val, i) => i !== index);
    this.desc = null;
    this.displayDialog = false;
  }
  onRowSelect(event) {
    this.user_block = 0;
    this.newDesc = false;
    this.desc = this.cloneDesc(event.data);
    this.displayDialog = true;
    this.userBlock.verifUserBlock("bo_desc", this.desc.id).then((userblock) => {
      if (userblock.data != "" && userblock.data != 0) {
        this.user_block = userblock.data;
        alert("Data en cours d'utilisation par : " + this.user_block);
      } else {
        this.userBlock.blockUser("bo_desc", this.desc.id);
        this.lastIDDesc = this.desc.id;
      }
    });
  }
  cloneDesc(r: Desc): Desc {
    const desc = {} as Desc;
    for (const prop in r) {
      desc[prop] = r[prop];
    }
    return desc;
  }
  @HostListener("window:beforeunload")
  closeDescs() {
    if (
      (this.user_block == this.currentUser || this.user_block == 0) &&
      !this.newDesc &&
      this.desc != null
    ) {
      this.userBlock
        .unblockUser("bo_desc", this.desc ? this.desc.id : this.lastIDDesc)
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
  }

  loadData() {
    this.descService
      .get(this.clients)
      .then((descs) => (this.descs = descs.data ? descs.data : ([] as Desc[])));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeDescs();
  }
}
