<p-table
  csvSeparator=";"
  #dt
  [columns]="cols"
  [value]="formulas"
  selectionMode="single"
  [(selection)]="selectedFormula"
  (onRowSelect)="onRowSelect($event)"
  [paginator]="true"
  [rows]="15"
  [responsive]="true"
>
  <ng-template pTemplate="caption">
    <div style="text-align: right">
      <button
        type="button"
        *ngIf="userRightsService.getUserCreate() == 1"
        pButton
        pTooltip="Ajouter des lignes"
        tooltipPosition="top"
        icon="fa fa-plus"
        (click)="showDialogToAdd()"
        style="float: left"
      ></button>
      <button
        type="button"
        *ngIf="userRightsService.getUserDelete() == 1"
        pButton
        pTooltip="Suppression ligne par ligne"
        tooltipPosition="top"
        icon="fa fa-close"
        iconPos="left"
        class="p-button-danger"
        (click)="showDelete()"
        style="float: left"
      ></button>
      <button
        type="button"
        *ngIf="userRightsService.getUserDelete() == 1"
        pButton
        pTooltip="Supprimer toutes les lignes"
        tooltipPosition="top"
        iconPos="left"
        icon="fa fa-trash"
        style="float: left"
        class="p-button-danger"
        (click)="deleteAllLines()"
      ></button>
      <button
        type="button"
        *ngIf="userRightsService.getUserCreate() == 1"
        pButton
        pTooltip="Importer"
        tooltipPosition="top"
        icon="fa fa-download"
        iconPos="left"
        class="p-button-info"
        (click)="openImportDialog()"
        style="float: left"
      ></button>
      <button
        type="button"
        *ngIf="userRightsService.getUserRead() == 1"
        pButton
        pTooltip="Exporter"
        tooltipPosition="top"
        icon="fa fa-upload"
        iconPos="left"
        class="p-button-info"
        (click)="exportCsv()"
        style="float: left"
      ></button>
      <i class="fa fa-search" style="margin: 4px 4px 0 0"></i>
      <input
        type="text"
        pInputText
        size="50"
        placeholder="Global Filter"
        (input)="dt.filterGlobal($event.target.value, 'contains')"
        style="width: auto"
      />
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3em" *ngIf="showDeleteColumn">X</th>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        {{ col.header }}
        <p-sortIcon
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        ></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData">
      <td style="width: 3em; padding: 5px" *ngIf="showDeleteColumn">
        <button
          type="button"
          style="height: 2em"
          pButton
          (click)="deleteLine(rowData.id)"
          class="p-button-danger"
          icon="fa fa-close"
        ></button>
      </td>
      <td *ngFor="let col of columns">
        <div
          *ngIf="
            col.field == 'create' ||
              col.field == 'read' ||
              col.field == 'update' ||
              col.field == 'delete' ||
              col.field == 'valide' ||
              col.field == 'admin';
            else normalContent
          "
        >
          <input
            type="checkbox"
            id="checkbox"
            [checked]="rowData[col.field] ? 'checked' : ''"
            binary="true"
            disabled
          />
        </div>
        <ng-template #normalContent>
          {{ rowData[col.field] }}
        </ng-template>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="summary" let-rowData>
    <div style="text-align: left">
      <button
        type="button"
        pButton
        icon="fa fa-plus"
        (click)="showDialogToAdd()"
        label="Ajouter"
      ></button>
    </div>
  </ng-template>
</p-table>

<p-dialog
  [resizable]="true"
  appendTo="body"
  header="Details"
  (onHide)="closeFormulas()"
  [(visible)]="displayDialog"
  showEffect="fade"
  [modal]="true"
  [blockScroll]="true"
>
  <p-tabView>
    <p-tabPanel header="Description de la formule">
      <div class="p-fluid" *ngIf="formula">
        <div class="p-field p-grid" *ngIf="!newFormula">
          <div class="p-col-4">
            <label for="id">ID: </label>
          </div>
          <div class="p-col-8">
            <input pInputText id="id" [(ngModel)]="formula.id" disabled />
          </div>
        </div>
        <div class="p-field p-grid">
          <div class="p-col-4">
            <label for="name">Libellé: </label>
          </div>
          <div class="p-col-8">
            <input
              pInputText
              [autofocus]="true"
              id="name"
              [(ngModel)]="formula.name"
              required
            />
          </div>
        </div>
        <div class="p-field p-grid">
          <div class="p-col-4">
            <label for="name">Commentaires/Précisions: </label>
          </div>
          <div class="p-col-8">
            <input
              pInputText
              id="description"
              [(ngModel)]="formula.description"
              required
            />
          </div>
        </div>
        <div class="p-field p-grid">
          <div class="p-col-4">
            <label for="action_done">Plan réalisé 100%: </label>
          </div>
          <div class="p-col-8">
            <p-checkbox
              id="action_done"
              [(ngModel)]="actionDoneCheckbox"
              [binary]="true"
              required
            ></p-checkbox>
          </div>
        </div>
        <div class="p-field p-grid" *ngIf="!newFormula">
          <div class="p-col-4">
            <label for="dtcreate">Date de création: </label>
          </div>
          <div class="p-col-8">
            <input
              pInputText
              id="dtcreate"
              [(ngModel)]="formula.dtcreate"
              disabled
            />
          </div>
        </div>
        <div class="p-field p-grid" *ngIf="!newFormula">
          <div class="p-col-4">
            <label for="dtmodif">Date de modification: </label>
          </div>
          <div class="p-col-8">
            <input
              pInputText
              id="dtmodif"
              [(ngModel)]="formula.dtmodif"
              disabled
            />
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Contenu de la formule">
      <div style="text-align: center">
        Sélectionner une analyse:
        <p-dropdown
          appendTo="body"
          [filter]="true"
          [options]="analyseObjects"
          [style]="{ width: '100%' }"
          [(ngModel)]="selectedAnalyse"
          optionLabel="name"
        ></p-dropdown>
      </div>
      <br />
      <div class="ui-inputgroup" *ngFor="let ln of valueObjects; let i = index">
        <table>
          <tr>
            <td>
              <button
                type="button"
                style="height: 2em"
                pButton
                (click)="valueObjects.splice(i, 1)"
                class="p-button-danger"
                icon="fa fa-close"
              ></button>
            </td>
            <td>
              <p-dropdown
                *ngIf="i !== 0"
                appendTo="body"
                [filter]="true"
                [options]="operator_types.types"
                [(ngModel)]="valueObjects[i].selectedOperateur"
                [style]="{ width: '100%' }"
                placeholder="Valeur :"
              ></p-dropdown>
            </td>
            <td>
              <p-dropdown
                appendTo="body"
                [filter]="true"
                [options]="filterFormulaObjects()"
                [(ngModel)]="valueObjects[i].selectedValue"
                [style]="{ width: '100%' }"
                placeholder="Colonne :"
              ></p-dropdown>
            </td>
          </tr>
        </table>
      </div>
      <div class="ui-inputgroup">
        <button
          type="button"
          pButton
          icon="fa fa-check"
          (click)="addLine()"
          label="Ajouter"
        ></button>
      </div>
      <br />
    </p-tabPanel>
  </p-tabView>
  <p-footer>
    <div
      *ngIf="user_block == 0 || user_block == currentUser"
      class="ui-dialog-buttonpane ui-helper-clearfix"
    >
      <button
        type="button"
        *ngIf="userRightsService.getUserDelete() == 1"
        pButton
        icon="fa fa-close"
        (click)="delete()"
        label="Supprimer"
      ></button>
      <button
        type="button"
        *ngIf="userRightsService.getUserUpdate() == 1"
        pButton
        icon="fa fa-check"
        (click)="save()"
        label="Sauvegarder"
      ></button>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  appendTo="body"
  [focusOnShow]="false"
  header="Importation de données"
  [(visible)]="displayDialogImport"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
>
  <h3 class="first">Importer la table des formules</h3>
  <h5>Format de fichier:</h5>
  <p>
    Libellé ; Commentaires - précisions; Libellé analyse ; Formule (Ex. Libellé
    colonne1 + Libellé colonne 2 / Libellé colonne 3)
  </p>
  <p>Séparateur: ";"</p>
  <p-fileUpload
    name="file"
    url="{{ config.API_Host }}import/{{ clients }}/bo_formula_objects"
    (onUpload)="importData($event)"
    accept=".csv"
    maxFileSize="1000000"
  >
    <ng-template pTemplate="content">
      <ul *ngIf="importFiles.length">
        <li *ngFor="let file of importFiles">
          {{ file.name }} - {{ file.size }} bytes
        </li>
      </ul>
    </ng-template>
  </p-fileUpload>
</p-dialog>
<p-progressSpinner *ngIf="!formulas || toggleLoading"></p-progressSpinner>
