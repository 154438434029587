import { Component, Injectable, OnInit } from "@angular/core";
import { MsgService } from "../services/msg.service";
import { Message } from "primeng/api";

@Component({
  selector: "msg",
  templateUrl: "msg.component.html",
})
export class MsgComponent implements OnInit {
  msgs: Message[] = [];
  message: any;

  constructor(public msg: MsgService) {}
  ngOnInit() {}
}
