<!-- Navigation -->
<nav
  *ngIf="connected == 1"
  class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top"
  id="mainNav"
>
  <div class="container">
    <a class="navbar-brand js-scroll-trigger" routerLink="/"><img src="../assets/images/logo-ariskan.png" style="width: 150px; float: left" alt="Responsive image"></a>

    <!--
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto">
        <li *ngIf="connected==1" class="nav-item">
          <a class="nav-link js-scroll-trigger" routerLink="/">Analyses</a>
        </li>
        <li *ngIf="connected==1" class="nav-item">
          <a class="nav-link js-scroll-trigger" routerLink="/params/datatable">Data table</a>
        </li>
        <li *ngIf="connected==1 && admin==1" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarParameters" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Paramètres généraux
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item"  routerLink="/params/users">Utilisateurs</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="/params/client">Clients</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item"  routerLink="/params/userclient">Utilisateurs - Clients</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item"  routerLink="/params/datatable">Data table</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="/params/traces">Traces</a>
          </div>
        </li>
        <li *ngIf="connected==1" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarUserConfiguration" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="glyphicon glyphicon-user"></span> 
            {{username}}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item"  routerLink="/params/userprofile">Paramètrages</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>
    </div>-->
    <p-megaMenu
      [style]="{ float: 'right' }"
      [model]="items"
      styleClass="p-grid p-justify-end"
    ></p-megaMenu>
  </div>
</nav>
<br /><br /><br /><br />
<msg></msg>
<p-confirmDialog
  #cd
  styleClass="confirmDialog"
  [ngStyle]="{ width: '425px', 'z-index': '10000 !important' }"
>
  <p-footer>
    <button
      type="button"
      pButton
      icon="fa fa-times"
      label="Non"
      (click)="cd.reject()"
    ></button>
    <button
      type="button"
      pButton
      icon="fa fa-check"
      label="Oui"
      (click)="cd.accept()"
    ></button>
  </p-footer>
</p-confirmDialog>
<br /><br />
<router-outlet></router-outlet>
