import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { AnalyseService } from "../analyse/analyse.service";
import { Column } from "../columns/column.model";
import { Analyse } from "../analyse/analyse.model";
import { ColumnsService } from "../columns/columns.service";
import { MsgService } from "../services/msg.service";
import { MappingValidationService } from "./mapping-validation.service";

@Component({
  selector: "app-mapping-validation",
  templateUrl: "./mapping-validation.component.html",
  styleUrls: ["./mapping-validation.component.css"],
})
export class MappingValidationComponent implements OnInit, OnChanges {
  @Input("clients") clients: any;
  analyseObjects: Analyse[] = [];
  selectedAnalyse: Analyse;

  validationMapping: any[];

  mappings: any[] = [];

  columns: Column[];

  constructor(
    private analyseService: AnalyseService,
    private columnService: ColumnsService,
    private msgs: MsgService,
    private mappingValidationService: MappingValidationService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.mappings = [];
    this.analyseService.get(this.clients).then((res) => {
      this.analyseObjects = res.data;
      this.selectedAnalyse = this.selectedAnalyse
        ? this.selectedAnalyse
        : this.analyseObjects[0];
      this.columnService
        .get(this.clients, this.selectedAnalyse.id)
        .then((resCol) => {
          this.columns = resCol.data;
          this.mappingValidationService
            .get(this.clients, this.selectedAnalyse.id)
            .then((resVM) => {
              this.validationMapping = resVM.data;
              this.makeMapping();
            });
        });
    });
  }

  makeMapping() {
    if (this.validationMapping) {
      this.validationMapping.forEach((i) => {
        const colStart = this.columns.find((e) => e.id === i.id_col_start);
        const colEnd = this.columns.find((e) => e.id === i.id_col_end);
        this.mappings.push({
          validationStart: colStart,
          validationEnd: colEnd,
        });
      });
    }
  }

  reloadData() {
    this.loadData();
  }

  addLine() {
    this.mappings.push({
      validationStart: {},
      validationEnd: {},
    });
  }

  saveMapping() {
    let error = false;
    this.mappings.forEach((n) => {
      if (n.validationStart.type_object !== n.validationEnd.type_object) {
        this.msgs.showError(
          "Les colonnes " +
            n.validationStart.name +
            " et " +
            n.validationEnd.name +
            " doivent avoir le même type!"
        );
        error = true;
      }
    });

    if (!error) {
      this.mappingValidationService
        .update(this.clients, this.selectedAnalyse.id, this.mappings)
        .then((res) => {
          this.loadData();
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.clients;
    if (currentItem) {
      this.loadData();
    }
  }

  makeDefaultValues() {
    console.log(this.columns);
  }
}
