<p-table #dt *ngIf="analyseLines"  csvSeparator=";" [columns]="selectedColumns" [paginator]="true" [value]="filteredAnalyseLines" [rows]="50" dataKey="id"
         selectionMode="multiple" [(selection)]="filteredAnalyseLines" [totalRecords]="filteredAnalyseLines.length" [rowsPerPageOptions]="[10,25,50, 100]"
         [globalFilterFields]="['display_value']" >
  <ng-template pTemplate="caption" class="workspaceClass">
    <div class="p-d-flex p-ai-center p-jc-between">
      <div class="p-d-flex">
        <button type="button" pTooltip="Exporter" tooltipPosition="top" pButton icon="fa fa-upload"
                class="p-button-info"  (click)="exportCsv()"></button>
        <button type="button" pTooltip="Recharger" tooltipPosition="top" pButton icon="fa fa-refresh"
                class="p-button-warning" (click)="loadData()"></button>
        <button type="button" pTooltip="Activer les filtres des colonnes" tooltipPosition="top" pButton icon="fa fa-filter"
                class="p-button-warning" (click)="showColFilters()"></button>
<!--        // Fonctionne mais pas nécessaire : -->
<!--        <p-multiSelect appendTo="body" (onChange)="reorderCols(false)" [options]="headerColumns"-->
<!--                       [(ngModel)]="selectedColumns" [filter]="true"-->
<!--                       optionLabel="name"-->
<!--                       selectedItemsLabel="{0} colonnes sélectionnées" [style]="{minWidth: '150px'}"-->
<!--                       defaultLabel="Choix colonnes">-->
<!--        </p-multiSelect>-->
<!--        <p-multiSelect appendTo="body" (onChange)="reorderCols(true)" [options]="headerGroups"-->
<!--                       [(ngModel)]="selectedGroups" [filter]="true"-->
<!--                       optionLabel="name"-->
<!--                       selectedItemsLabel="{0} groupes sélectionnées" [style]="{minWidth: '150px'}"-->
<!--                       defaultLabel="Choix groups colonnes">-->
<!--        </p-multiSelect>-->
      </div>
      <div style="padding-top: 8px; padding-left: 50px;">Analyse mère consolidée {{ clientName }}</div>
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input pInputText type="text" size="50" (input)="searchKeyword($event.target.value)" placeholder="Recherche" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="colgroup" let-selectedColumns>
    <colgroup appendTo="body">
      <col style="width: 4em">
      <col style="width: 10em">
      <col *ngFor="let col of selectedColumns" [ngStyle]="{'width': col.pixel ? col.pixel + 'px' : '150px'}" >
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-selectedColumns>
    <tr *ngIf="selectedGroups" class="groupCol">
      <th rowspan="2">ID</th>
      <th rowspan="2">Analyse</th>
      <th *ngFor="let selectedGroup of colGroups" [attr.colspan]="selectedGroup.columns"
          [style.background-color]="selectedGroup.color" [style.text-align]="'center'" [style.border]="'none'">
        {{ selectedGroup.name }}
      </th>
    </tr>
    <tr>
      <th *ngFor="let column of selectedColumns"
          [style.background-color]="column.group_color" [style.text-align]="'center'" [style.border]="'none'">
        {{ column.name }}
      </th>
    </tr>
    <tr class="colHidden" [ngStyle]="{'display': colFilters ? 'contents' :'none'}">
      <th></th>
      <th>
        <div class="ui-inputgroup">
          <input class="offset-1 searchFilters" type="text" [(ngModel)]="searchParams['Analyse source']" placeholder="Rechercher + Entrer" size="30" pInputText (keyup.enter)="searchInAnalyseByColumn()">
        </div>
      </th>
      <th *ngFor="let column of selectedColumns"
          [style.background-color]="column.group_color" [style.text-align]="'center'" [style.border]="'none'">
        <div class="ui-inputgroup">
          <input class="offset-1 searchFilters" type="text" [(ngModel)]="searchParams[column.name]" placeholder="Rechercher + Entrer" size="30" pInputText (keyup.enter)="searchInAnalyseByColumn()">
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-analyseLine>
    <tr>
      <td>{{ analyseLine.order_num }}</td>
      <td>
        <a [routerLink]="['/workspace_details', analyseLine.id_analyse, analyseLine.id_structure]">
          Accéder à l'analyse<br/>
          {{ analyseLine.name_analyse }}<br/>
          {{ analyseLine.name_client }}
        </a>
      </td>
      <td *ngFor="let data of getVisibleValues(analyseLine.values)" [ngClass]="data.missingValue ? 'missing-column' : ''" [ngStyle]="data.color">
        <ng-container *ngIf="data.isPlan">
          <div style="text-align: center">
            <div style="float: left;">{{ analyseLine.nb_actions }}</div>
            <div>{{ analyseLine.status_actions }}%</div>
            <button style="height: 3em" type="button" pTooltip="Voir les actions" tooltipPosition="top" pButton icon="pi pi-book" (click)="openPlanDialog(analyseLine)"></button>
          </div>
        </ng-container>
        <ng-container *ngIf="data.isFormula">
          <div style="text-align: right">{{ data.colorName }}</div>
        </ng-container>
        <ng-container *ngIf="data.isRisk || data.isProbability">
          <div style="text-align: right">{{ data.colorName }}</div>
          {{ data.value.id }}<br/>
          {{ data.value.name }}
        </ng-container>
        <ng-container *ngIf="data.isBool">
          <p-inputSwitch disabled [(ngModel)]="data.value"></p-inputSwitch>
        </ng-container>
        <ng-container *ngIf="data.isOther">
          {{ data.displayValue }}
        </ng-container>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog header="Plan d'action" [(visible)]="showPlanDialog" appendTo="body" [modal]="true">
  <div style="margin-bottom: 5em" class="p-fluid" *ngIf="showPlanDialog">
    <app-plan [id_line]="analyseLineId" [id_client]="id_client" [id_analyse]="analyseId" [page]=0 [buttons]=false [edit_button]="false"></app-plan>
  </div>
</p-dialog>
