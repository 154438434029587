<div class="offset-5">
  Client :
  <p-dropdown
    appendTo="body"
    optionLabel="label"
    (onChange)="loadData()"
    [filter]="true"
    [options]="clients"
    [(ngModel)]="selectedClient"
  ></p-dropdown>
</div>
<br /><br />
<p-organizationChart [value]="data">
  <ng-template let-node pTemplate="structureClient">
    <div
      style="font-size: 16px; font-weight: bolder"
      class="node-header p-corner-top"
    >
      {{ node.label }}
      <button
        style="height: 1.5rem"
        type="button"
        *ngIf="userRightsService.getUserCreate() == 1"
        pButton
        icon="fa fa-plus"
        (click)="add(node)"
      ></button>
      <button
        style="height: 1.5rem"
        type="button"
        *ngIf="userRightsService.getUserUpdate() == 1"
        pButton
        icon="fa fa-list"
        class="p-button-warning"
        (click)="update(node)"
      ></button>
      <button
        style="height: 1.5rem"
        type="button"
        *ngIf="userRightsService.getUserDelete() == 1 && isNotFirst(node)"
        pButton
        icon="fa fa-close"
        class="p-button-danger"
        (click)="delete(node.id)"
      ></button>
    </div>
  </ng-template>
</p-organizationChart>
<p-dialog
  appendTo="body"
  [focusOnShow]="false"
  header="Ajout membre structure client "
  (onHide)="closeDialog()"
  [(visible)]="displayDialog"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
>
  <div class="p-fluid">
    <div class="p-field p-grid">
      <div class="p-col-4">
        <label for="title">Nom : </label>
      </div>
      <div class="p-col-8">
        <input pInputText id="title" [(ngModel)]="title" />
      </div>
    </div>
  </div>
  <div class="p-field p-grid" *ngIf="newStructure && isSuperUser">
    <div class="p-col-4">
      <label for="source">Importer les paramètres à partir du client: </label>
    </div>
    <div class="p-col-8">
      <p-dropdown id="source" [options]="structures" [(ngModel)]="selectedStructureSource" optionLabel="label" [dataKey]="id" [filter]="true" filterBy="label" [showClear]="true" placeholder="Sélectionner un client" appendTo="body"></p-dropdown>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button
        type="button"
        *ngIf="userRightsService.getUserUpdate() == 1"
        pButton
        icon="fa fa-check"
        (click)="save()"
        label="Sauvegarder"
      ></button>
    </div>
  </p-footer>
</p-dialog>
