<div *ngIf="userRightsService.getUserDashboard()">
<h1 style="font-size: 18px !important; text-align: center !important;">Paramètrages des dashboards</h1>
<br>
<div style="width: 100%; text-align: center" >Mode Table <p-inputSwitch (onChange)="changeTableLayout()" ariaLabelledBy="View Table" ></p-inputSwitch></div>
<div *ngIf="tableLayout">
<p-table csvSeparator=";" #dt [columns]="cols" [value]="dashboards" selectionMode="single" [(selection)]="selectedDashboard" (onRowSelect)="goToDashboard($event.data.id)" [paginator]="true" [rows]="15" [responsive]="true">
  <ng-template pTemplate="caption">
    <div class="p-d-flex">
      <button pButton pRipple *ngIf="userRightsService.getUserCreate() == 1" pTooltip="Ajouter" tooltipPosition="top" icon="fa fa-plus" (click)="showDialogToAdd()" style="float:left"></button>
      <!--<button pButton pRipple *ngIf="userRightsService.getUserCreate() == 1" pTooltip="Importer" tooltipPosition="top" icon="fa fa-download" iconPos="left" class="p-button-info" (click)="openImportDialog()" style="float:left"></button>
      -->
      <button pButton pRipple *ngIf="userRightsService.getUserRead() == 1" pTooltip="Exporter" tooltipPosition="top" icon="fa fa-upload" iconPos="left" class="p-button-info" (click)="dt.exportCSV()" style="float:left"></button>

      <div class="offset-3" >
        Client : <p-dropdown appendTo="body" (onChange)="changeClientCache()" optionLabel="name" [filter]="true" [options]="client" [(ngModel)]="selectedClient"></p-dropdown>
      </div>

      <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input type="text" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width:3em;" *ngIf="showDeleteColumn">X</th>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        {{col.header}}
        <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon></th>
      <th>Actions:</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData" [style]="{'background-color' : (rowData.valid ? '#6fcc03a6' : 'white')}">

      <td *ngFor="let col of columns">
        <div *ngIf="col.field=='create' || col.field=='read' || col.field=='update' || col.field=='delete' || col.field=='valide' || col.field=='admin'; else normalContent">
          <input type="checkbox" id="checkbox" [checked]="(rowData[col.field] ? 'checked' : '')" binary="true" disabled />
        </div>
        <ng-template #normalContent>
          {{rowData[col.field]}}
        </ng-template>
      </td>
      <td>
        <button pButton pRipple *ngIf="userRightsService.getUserDelete() == 1" type="button" class="p-button-rounded p-button-danger" icon="fa fa-close" (click)="deleteDashboardLine(rowData.id)"></button>
        <button pButton pRipple *ngIf="userRightsService.getUserRead() == 1" (click)="onRowSelect(rowData)" class="p-button-rounded p-button-warning" pTooltip="Paramétrages dashboard" type="button" icon="fa fa-gear"></button>
        <button pButton pRipple *ngIf="userRightsService.getUserRead() == 1" (click)="goToDashboard(rowData.id)" class="p-button-rounded p-button-success" pTooltip="Dashboard" type="button" icon="fa fa-bar-chart"></button>

      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="summary" let-rowData>
    <div style="text-align:left">
      <button type="button" pButton icon="fa fa-plus" *ngIf="userRightsService.getUserCreate() == 1" (click)="showDialogToAdd()" label="Ajouter"></button>
    </div>
  </ng-template>

</p-table>
</div>



<div *ngIf="!tableLayout">
  <p-dataView #dv [value]="dashboards" [paginator]="true" [rows]="9" filterBy="name" (onChangeLayout)="changeDVLayout($event)"
              [layout]="selectedDVLayout">

    <ng-template pTemplate="header">
      <button pButton pRipple *ngIf="userRightsService.getUserCreate() == 1" pTooltip="Ajouter" tooltipPosition="top" icon="fa fa-plus" (click)="showDialogToAdd()" style="float:left"></button>
      <!--<button pButton pRipple *ngIf="userRightsService.getUserCreate() == 1" pTooltip="Importer" tooltipPosition="top" icon="fa fa-download" iconPos="left"  (click)="openImportDialog()" style="float:left"></button>
      -->
      <button pButton pRipple *ngIf="userRightsService.getUserRead() == 1" pTooltip="Exporter" tooltipPosition="top" icon="fa fa-upload" iconPos="left"  (click)="dv.exportCSV()" style="float:left"></button>
      <button pButton pRipple *ngIf="userRightsService.getUserRead() == 1" pTooltip="Cartographie" tooltipPosition="top" icon="pi pi-sitemap" iconPos="left" class="p-button-success" (click)="redirectToCarto()" style="float:left"></button>

      <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
        <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                    <i class="pi pi-search"></i>
                    <input type="search" pInputText placeholder="Search by Name" (input)="dv.filter($event.target.value)">
                </span>
        <div>
          Client : <p-dropdown appendTo="body" (onChange)="changeClientCache()" optionLabel="name" [filter]="true" [options]="client" [(ngModel)]="selectedClient"></p-dropdown>
        </div>
        <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
      </div>
    </ng-template>

    <ng-template let-dashboard pTemplate="listItem">
      <div class="p-col-12">
        <div class="dashboard-list-item">
          <img style="text-align: center" [src]="'assets/images/dashboard.jpg'"/>
          <div class="dashboard-list-detail" (click)="goToDashboard(dashboard.id)">
            <div class="dashboard-name">{{dashboard.name}}</div>
            <div class="dashboard-description">{{dashboard.description}}</div>
            <div class="dashboard-nomClient">{{dashboard.nameClient}}</div>
          </div>
          <div class="dashboard-list-action">
            <button pButton pRipple *ngIf="userRightsService.getUserDelete() == 1" type="button" class="p-button-rounded p-button-danger" icon="fa fa-close" (click)="deleteDashboardLine(dashboard.id)"></button>
            <button pButton pRipple *ngIf="userRightsService.getUserRead() == 1" (click)="onRowSelect(dashboard)" class="p-button-rounded p-button-warning" pTooltip="Paramétrages dashboard" type="button" icon="fa fa-gear"></button>
            <button pButton pRipple *ngIf="userRightsService.getUserRead() == 1" (click)="goToDashboard(dashboard.id)" class="p-button-rounded p-button-success" pTooltip="Graphiques" type="button" icon="fa fa-bar-chart"></button>
            </div>
        </div>
      </div>
    </ng-template>
    <ng-template let-dashboard pTemplate="gridItem">
      <div class="p-col-12 p-md-4">
        <div class="dashboard-grid-item card">
          <div class="dashboard-grid-item-top">
            <div>
              <i class="pi pi-tag dashboard-category-icon"></i>
              <span class="dashboard-category">Dashboard</span>
            </div>
          </div>
          <div class="dashboard-grid-item-content" (click)="goToDashboard(dashboard.id)">
            <img style="text-align: center" [src]="'assets/images/dashboard.jpg'"/>
            <div class="dashboard-name">{{dashboard.name}}</div>
            <div class="dashboard-description">{{dashboard.description}}</div>
            <div class="dashboard-description">{{dashboard.nameClient}}</div>
          </div>
          <div class="dashboard-grid-item-bottom">
            <button pButton pRipple *ngIf="userRightsService.getUserDelete() == 1" type="button" class="p-button-rounded p-button-danger" icon="fa fa-close" (click)="deleteDashboardLine(dashboard.id)"></button>
            <button pButton pRipple *ngIf="userRightsService.getUserRead() == 1" (click)="onRowSelect(dashboard)" class="p-button-rounded p-button-warning" pTooltip="Paramétrages dashboard" type="button" icon="fa fa-gear"></button>
            <button pButton pRipple *ngIf="userRightsService.getUserRead() == 1" (click)="goToDashboard(dashboard.id)" class="p-button-rounded p-button-success" pTooltip="Graphiques" type="button" icon="fa fa-bar-chart"></button>
          </div>
        </div>
      </div>
    </ng-template>

  </p-dataView>
</div>
</div>

<p-dialog appendTo="body" [focusOnShow]="false" [header]=" 'Detail / ' + (dashboard ? dashboard.name : '')" (onHide)="closeDashboards()" [(visible)]="displayDialog" showEffect="fade" [modal]="true">
  <p-tabView>
    <p-tabPanel header="Paramètrage Dashboard" leftIcon="pi pi-calendar">
      <div class="p-fluid" *ngIf="dashboard">
        <div class="p-field p-grid" *ngIf="!newDashboard">
          <div class="p-col-4">
            <label for="id">ID: </label>
          </div>
          <div class="p-col-8">
            <input pInputText id="id" [(ngModel)]="dashboard.id" disabled/>
          </div>
        </div>
        <div class="p-field p-grid">
          <div class="p-col-4">
            <label for="name">Libellé: </label>
          </div>
          <div class="p-col-8">
            <input #nameInput pInputText id="name" [(ngModel)]="dashboard.name" required autoFocus/>
          </div>
        </div>
        <div class="p-field p-grid">
          <div class="p-col-4">
            <label for="name">Commentaires/Précisions: </label>
          </div>
          <div class="p-col-8">
            <input pInputText id="description" [(ngModel)]="dashboard.description" required/>
          </div>
        </div>
        <div class="p-field p-grid" *ngIf="!newDashboard">
          <div class="p-col-4">
            <label for="dtcreate">Date de création: </label>
          </div>
          <div class="p-col-8">
            <input pInputText id="dtcreate" [(ngModel)]="dashboard.dtcreate" disabled />
          </div>
        </div>
        <div class="p-field p-grid" *ngIf="!newDashboard">
          <div class="p-col-4">
            <label for="dtmodif">Date de modification: </label>
          </div>
          <div class="p-col-8">
            <input pInputText id="dtmodif" [(ngModel)]="dashboard.dtmodif" disabled />
          </div>
        </div>
      </div>

    </p-tabPanel>
    <p-tabPanel header="Graphiques" leftIcon="pi pi-chart-bar" *ngIf="!newDashboard">
      <app-param-graph [refresh]="refreshColumns" [id]="dashboard ? dashboard.id : 0" [id_client]="selectedClient ? selectedClient.code : 0" type_object="dashboard"></app-param-graph>
    </p-tabPanel>
  </p-tabView>



  <p-footer>
    <div *ngIf="user_block == 0 || user_block == currentUser" class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" *ngIf="userRightsService.getUserUpdate() == 1" pButton icon="fa fa-check" (click)="save()" label="Sauvegarder"></button>
    </div>
  </p-footer>
</p-dialog>
<p-progressSpinner *ngIf="!dashboards"></p-progressSpinner>

<p-dialog appendTo="body" [focusOnShow]="false" header="Importation de données" [(visible)]="displayDialogImport" [responsive]="true" showEffect="fade" [modal]="true">
  <h3 class="first">Importation dashboards</h3>
  <h5>Format de fichier:</h5>
  <p>Nom ; Description</p>
  <p>Séparateur standard: ";"</p>
  <p-fileUpload name="file" url="{{config.API_Host}}import/{{selectedClient ? selectedClient.code : 0}}/bo_dashboards" (onUpload)="importData($event)"
                accept=".csv" maxFileSize="1000000">
    <ng-template pTemplate="content">
      <ul *ngIf="importFiles.length">
        <li *ngFor="let file of importFiles">{{file.name}} - {{file.size}} bytes</li>
      </ul>
    </ng-template>
  </p-fileUpload>
</p-dialog>
