import {takeUntil} from 'rxjs/operators';
import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {Analyse} from './analyse.model';
import {interval, Subject, Observable} from 'rxjs';
import {AnalyseService} from './analyse.service';
import {MsgService} from '../services/msg.service';
import {TracesService} from '../traces/traces.service';
import {UserBlockService} from '../services/user-block.service';
import {AppConfig} from '../config';
import {Log} from '../models/log.model';
import {TypeEvalRisk} from '../models/typeevalrisk.model';
import {TypeEvalRiskService} from '../services/type-eval-risk.service';
import {Client} from '../client/client.model';
import {AnalyseColumn} from '../models/analysecolumn.model';
import {Column} from '../columns/column.model';
import {ColumnsService} from '../columns/columns.service';
import {AnalyseColumnsService} from '../services/analyse-columns.service';
import {UserClientsService} from '../user-clients/user-clients.service';
import {Router} from '@angular/router';
import {ConfirmationService} from 'primeng/api';
import {UserRightsService} from '../services/user-rights.service';
import {ColumnsComponent} from '../columns/columns.component';
import {CartoService} from "../carto/carto.service";
import saveAs from 'file-saver';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-analyse',
  templateUrl: './analyse.component.html',
  styleUrls: ['./analyse.component.scss']
})
export class AnalyseComponent implements OnInit {

    displayDialog: boolean;
    analyse: Analyse = {} as Analyse;
    selectedAnalyse: Analyse;
    newAnalyse: boolean = false;
    analyses: Analyse[] = [] as Analyse[];
    responseAnalyses: Analyse[] = [] as Analyse[];
    cols: any[];
    user_block: any = 0;
    lastIDAnalyse: number;
    currentUser: any;
    timeoutCounter = 0;
    stopmousemove = false;

    typeEvalRisks: TypeEvalRisk[];
    selectedTypeEvalRiskList: any;
    listTypeEvalRisks: any;

    client: any;
    selectedClient: any;
    selectedClientName: string = "";

    columns: Column[];

    displayDialogImport = false;

    showDeleteColumn = false;
    importFiles: any[] = [];

    analyseColonnes: AnalyseColumn[] = [];
    colsAC: any[];

    refreshColumns = false;

    autofocus = true;

    displayDialogCarto = false;
    displayPlanManagement = false;
    imgSrc;
    imgLogo;

    selectedDVLayout = 'grid';

    tableLayout: boolean = false;
    clientHasChildrens: number = 0;

    private onDestroy$ = new Subject();
    constructor(
        private analyseService: AnalyseService,
        private msgs: MsgService,
        private trace: TracesService,
        private userBlock: UserBlockService,
        private typeEvalRiskService: TypeEvalRiskService,
        private userClientService: UserClientsService,
        private columnService: ColumnsService,
        private analyseColumnsService: AnalyseColumnsService,
        private userRightsService: UserRightsService,
        private confirmationService: ConfirmationService,
        private cartoService: CartoService,
        private router: Router,
        public config: AppConfig,
        private route: ActivatedRoute
    ) { }
    ngOnInit() {
        const user = JSON.parse(localStorage.getItem('user')).data;

        this.loadDataViewFromLocalStorage();

        //Si on vient de la home pour plan d'actions on ouvre la modal
        this.route.queryParams.subscribe(params => {
          this.displayPlanManagement = params['displayPlanManagement'] === 'true';
        });

        this.userClientService.getByUserId(user.id).then(res => {
            this.client = res.data ? res.data : [] as Client[];

            if (localStorage.getItem('client')) {
                this.selectedClient = JSON.parse(localStorage.getItem('client'));
            } else {
                this.selectedClient = this.client[0] ? this.client[0] : 0;
            }
            this.selectedClientName = this.selectedClient.name;
            this.loadData();
        });

        this.currentUser = JSON.parse(localStorage.getItem('user')).data.email;
        this.cols = [
            { field: 'name', header: 'Libellé: ' },
            { field: 'description', header: 'Commentaires/Précisions: ' },
            { field: 'nameClient', header: 'Client: ' },
            { field: 'dtcreate', header: 'Création: ' },
            { field: 'dtmodif', header: 'Modification: ' },
        ];

        this.colsAC = [
            { field: 'order_num', header: 'Ordre' },
            { field: 'id_column', header: 'Colonne' },
            { field: 'color_range', header: 'Couleur' },
            { field: 'actions', header: 'Actions' }
        ];
        // interval(this.config.RefreshMilisecs).pipe(takeUntil(this.onDestroy$)).subscribe(x => {
        //     if (this.timeoutCounter < this.config.RefreshTimer && this.client) {
        //         this.loadData();
        //         this.timeoutCounter++;
        //     }
        // });
    }
    @HostListener('document:mousemove')
    onMouseMove() {
        if (this.stopmousemove === false) {
            this.timeoutCounter = 0;
        }
    }

    showDialogToAdd() {
        this.newAnalyse = true;
        if (this.analyse != null && this.analyse.id != null && !this.analyse) {
            this.closeAnalyses();
        }

        this.analyse = {} as Analyse;

        this.displayDialog = true;

    }

    loadDataViewFromLocalStorage() {
      const currentSelectedDVLayout = localStorage.getItem('selectedDVLayout');
      const currentTableLayout = localStorage.getItem('tableLayout');

      if (currentSelectedDVLayout) {
        this.selectedDVLayout = currentSelectedDVLayout;
      } else {
        this.selectedDVLayout = 'grid';
        localStorage.setItem('selectedDVLayout', this.selectedDVLayout);
      }

      if (typeof currentTableLayout !== 'undefined') {
        this.tableLayout = currentTableLayout === 'true';
      } else {
        this.tableLayout = false;
        localStorage.setItem('tableLayout', String(this.tableLayout));
      }
    }

    changeDVLayout(event) {
      this.selectedDVLayout = event.layout;
      localStorage.setItem('selectedDVLayout', this.selectedDVLayout);
    }

    changeTableLayout() {
      this.tableLayout = !this.tableLayout;
      localStorage.setItem('tableLayout', String(this.tableLayout));
    }


    save() {
        const analyses = (this.analyses) ? [...this.analyses] : [] as Analyse[];
        const jsonanalyse = JSON.stringify(this.analyse);
        this.analyse.id_client = this.selectedClient.code;
        this.analyse.id_type = (this.selectedTypeEvalRiskList) ? this.selectedTypeEvalRiskList.id : 0;
        if (this.analyse.name && this.analyse.description) {
            if (this.newAnalyse) {
                analyses.push(this.analyse);
                this.analyseService.create(this.analyse).then(res => {
                    if (res.code === 200) {
                        this.loadData();
                        this.msgs.showSuccess('Analyse créé!');
                        this.trace.createTrace({
                            'action': 'CREATE ANALYSE',
                            'data' : jsonanalyse,
                            'description' : 'CREATION ANALYSE'
                        } as Log);
                    } else {
                        this.msgs.showError(res.message);
                        const jsonmsg = JSON.stringify(res.message);
                        this.trace.createTrace({
                            'action': 'CREATE ANALYSE',
                            'data' : jsonmsg,
                            'description' : 'ERREUR CREATION ANALYSE'
                        } as Log);
                    }
                });
            } else {
                analyses[this.analyses.indexOf(this.selectedAnalyse)] = this.analyse;
                this.analyseService.update(this.analyse).then(res => {
                    if (res.code === 200) {
                        this.loadData();
                        this.msgs.showSuccess('Analyse mise à jour!');
                        this.trace.createTrace({
                            'action': 'UPDATE ANALYSE',
                            'data' : JSON.stringify(analyses),
                            'description' : 'Mise a jour analyses'
                        } as Log);
                    } else {
                        const jsonmsg = JSON.stringify(res.message);
                        this.trace.createTrace({
                            'action': 'UPDATE ANALYSE',
                            'data' : jsonmsg,
                            'description' : 'ERREUR MAJ ANALYSE'
                        } as Log);
                    }
                    this.userBlock.unblockUser('bo_analyse', ((this.analyse) ? this.analyse.id : this.lastIDAnalyse)).then(
                        data => {
                            console.log('Unblock users');
                        }
                    );
                });
            }
        } else {
            this.msgs.showError('Merci de remplir tous les champs!');
        }
        this.lastIDAnalyse = this.analyse.id;
        this.analyses = analyses;
        this.analyse = null;
        this.displayDialog = false;
    }

    loadLogo() {
      const d = new Date();
      const n = d.getMilliseconds();
      this.imgLogo = this.config.API_Host + 'get/logo/' + this.selectedClient.id_client + '/' + n;
    }

    delete() {

        this.confirmationService.confirm({
            message: 'Etes-vous sur de vouloir supprimer cette ligne?',
            header: 'Confirmation suppression',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.analyseService.delete(this.analyse.id).then(
                    res => {
                        if (res.code === 200) {
                            this.loadData();
                            this.msgs.showSuccess('Analyse supprimée!');
                            this.trace.createTrace({
                                'action': 'DELETE ANALYSE',
                                'data' : JSON.stringify(this.analyse),
                                'description' : 'SUPPRESSION ANALYSE'
                            } as Log);
                        } else {
                            this.msgs.showError(res.message);
                        }
                    }
                );
                this.userBlock.unblockUser('bo_analyse', ((this.analyse.id) ? this.analyse.id : this.lastIDAnalyse)).then(
                    data => {
                        console.log('Unblock users');
                    }
                );
                this.lastIDAnalyse = this.analyse.id;
                const index = this.analyses.indexOf(this.selectedAnalyse);
                this.analyses = this.analyses.filter((val, i) => i !== index);
                this.analyse = null;
                this.displayDialog = false;
            }
        });
    }

    onRowSelect(event) {
        this.newAnalyse = false;
        this.refreshColumns = true;
        this.user_block = 0;
        this.analyseColonnes = [];
        this.analyse = this.cloneAnalyse(event);
        this.displayDialog = true;
        this.selectedTypeEvalRiskList = {
            id: ((this.typeEvalRisks.find(item => item.id === this.analyse.id_type)) ? (this.typeEvalRisks.find(item => item.id === this.analyse.id_type)).id : 0),
            name: ((this.typeEvalRisks.find(item => item.id === this.analyse.id_type)) ? (this.typeEvalRisks.find(item => item.id === this.analyse.id_type)).name : '')
        };

        this.userBlock.verifUserBlock('bo_analyse', this.analyse.id)
            .then(userblock => {
                if (userblock.data != '' && userblock.data != 0) {
                    this.user_block = userblock.data;
                    alert('Data en cours d\'utilisation par : ' + this.user_block);
                } else {
                    this.userBlock.blockUser('bo_analyse', this.analyse.id);
                    this.lastIDAnalyse = this.analyse.id;
                }
            });
    }
    cloneAnalyse(r: Analyse): Analyse {
        const analyse = {} as Analyse;
        for (const prop in r) {
            analyse[prop] = r[prop];
        }
        return analyse;
    }
    @HostListener('window:beforeunload')
    closeAnalyses() {
        if ((this.user_block == this.currentUser || this.user_block == 0) && !this.newAnalyse && this.analyse != null) {
            this.userBlock.unblockUser('bo_analyse', ((this.analyse) ? this.analyse.id : this.lastIDAnalyse)).then(
                data => {
                    console.log('Unblock user');
                }
            );
        }
        this.loadData();
        this.analyseColonnes = [] as AnalyseColumn[];
        this.selectedAnalyse = {} as Analyse;
        this.stopmousemove = false;
        this.refreshColumns = false;
    }


    loadData() {
      this.analyseService.get(this.selectedClient.code).then(analyses => {
        this.analyseService.clientHasChildren(this.selectedClient.code).then(client => {
          this.responseAnalyses = analyses.data ? analyses.data : [] as Analyse[];
          this.clientHasChildrens = client.data.hasChild;
          if (this.clientHasChildrens) {
            // Ajouter une analyse parent si le client a des enfants
            let parentAnalyse = new Analyse();
            parentAnalyse.id = 0;
            parentAnalyse.name = 'Analyse mère';
            parentAnalyse.description = "Analyse mère";
            parentAnalyse.nameClient = this.selectedClient.name;
            parentAnalyse.id_client = this.selectedClient.code;
            parentAnalyse.parent = true;
            this.responseAnalyses.unshift(parentAnalyse);
          }
          this.analyses = this.responseAnalyses
        });
      });

      this.typeEvalRiskService.getTypeEvalRisks().then(typeevalrisks => {
          if (typeevalrisks.data == null) {
              this.typeEvalRisks = [] as TypeEvalRisk[];
          } else {
              this.typeEvalRisks = typeevalrisks.data;
              this.listTypeEvalRisks = this.makeTypeEvalRiskList(this.typeEvalRisks);
          }
      });
      this.loadLogo();
    }

    makeTypeEvalRiskList(typeevalrisks: TypeEvalRisk[]) {

        const listTypeEvalRisks: any[] = [];
        for (const prop in typeevalrisks) {
            listTypeEvalRisks.push({
                id: typeevalrisks[prop].id,
                name: typeevalrisks[prop].name
            });
        }
        return listTypeEvalRisks;
    }

    makeClientList(clients: Client[]) {

        const listClients: any[] = [];
        for (const prop in clients) {
            listClients.push({
                id: clients[prop].id,
                name: clients[prop].name
            });
        }
        return listClients;
    }

    makeColumnList(columns: Column[]) {

        const listColumns: any[] = [];
        listColumns.push({
           label: 'Choisir la colonne'
        });
        for (const prop in columns) {
            listColumns.push({
                value: columns[prop].id,
                label: columns[prop].name
            });
        }
        return listColumns;
    }

    addColumn() {

        const newIndex = this.analyseColonnes.length > 0 ? Math.max.apply(Math, this.analyseColonnes.map(function(o) { return o.order_num; })) + 1 : 1;
        const newAnalyseColumn = {} as AnalyseColumn;
        newAnalyseColumn.order_num = newIndex;
        newAnalyseColumn.id_analyse = this.selectedAnalyse.id;
        this.analyseColonnes.push(newAnalyseColumn);
        const options = {
            idLigne : 'undefined',
            name : 'order_num',
            value : newIndex,
            id_analyse : this.selectedAnalyse.id
        };

        this.analyseColumnsService.addupdate(options).then(res => {
            this.loadData();
        });
        localStorage.removeItem('workspace_selectedColumns_' + this.selectedAnalyse.id + '_' + this.selectedClient.code
        );

    }


    ngOnDestroy() {
        this.onDestroy$.next();
        this.closeAnalyses();
    }

    stopRefresh() {
        this.timeoutCounter = this.config.RefreshTimer;
        this.stopmousemove = true;
    }

    changeValue(options) {
        options.id_analyse = this.selectedAnalyse.id;

        this.analyseColumnsService.addupdate(options).then(res => {
            this.loadData();
        });

        this.stopmousemove = false;
        localStorage.removeItem('workspace_selectedColumns_' + this.selectedAnalyse.id + '_' + this.selectedClient.code);
    }

    deleteLine(id) {
        if (this.analyseColonnes.length == 1) {
            this.columns = [] as Column[];
        }
        this.analyseColumnsService.delete(id).then(res => {
            this.loadData();
        });
        localStorage.removeItem('workspace_selectedColumns_' + this.selectedAnalyse.id + '_' + this.selectedClient.code);
    }

    deleteAnalyseLine(id) {
        this.confirmationService.confirm({
            message: 'Etes-vous sur de vouloir supprimer cette ligne?',
            header: 'Confirmation suppression',
            icon: 'pi pi-info-circle',
            accept: () => {
                if (this.analyses.length == 1) {
                    this.analyses = [] as Analyse[];
                }
                this.analyseService.delete(id).then(res => {
                    this.loadData();
                });
            }
        });
    }

    goToDetails(event) {
      if (event.data.parent) {
        return this.goToParentAnalyse(this.selectedClient.code)
      }
      this.router.navigate(['/workspace_details', event.data.id, this.selectedClient.code]);
    }

    goToDetailsCard(analyse) {
      this.router.navigate(['/workspace_details', analyse.id, analyse.id_client ? analyse.id_client : this.selectedClient.code]);
    }

    goToParamGraph(id) {
        this.router.navigate(['/param_graph', id, this.selectedClient.code, "analyse"]);
    }

    goToDashboard(id) {
        this.router.navigate(['/dashboard', id, this.selectedClient.code, "analyse"]);
    }

    changeClientCache() {
        localStorage.setItem('client', JSON.stringify(this.selectedClient));
        this.analyses = null;
        this.loadData();
    }

    openImportDialog() {
        this.displayDialogImport = true;
    }

    importData(event) {
        this.loadData();
    }

    showDelete() {
        this.showDeleteColumn = !this.showDeleteColumn;
    }

    archivate(id) {
        this.analyseService.archivate(id).then(res => {
            console.log(res);
        });
    }


    redirectToCarto() {
      const d = new Date();
      const n = d.getMilliseconds();
      this.imgSrc = this.config.API_Host + 'get/carto/' + this.selectedClient.code + '/' + n;
      this.displayDialogCarto = true;

    }

  exportActionPerson() {
    this.analyseService.getActionPerson(this.selectedClient.code).then(actionPerson => {

      const group: any[] = actionPerson.data.reduce((r, a) => {
        r[a.name] = [...r[a.name] || [], a];
        return r;
      }, {});

      const result = [];
      for (const [key, value] of Object.entries(group)) {
        let subResult = {
          name : '',
          nbActions : 0,
          nbDone : 0,
          nbExpired : 0
        };
        subResult.name = key;
        subResult.nbActions = value.length;
        value.forEach(z => {
          const dateOne = new Date(z.max_date);
          const dateNow = new Date();
          if (dateNow >= dateOne) {
            subResult.nbExpired++;
          }
          if (z.status === 5) {
            subResult.nbDone++;
          }
        });
        result.push(subResult);
      }
      const columnsToExport = 'Nom;Nombre d\'actions attribuées;Nombre d\'actions réalisée;Nombre d\'actions en retard';
      const data = result.map(i => {
        return i.name + ';' + i.nbActions + ';' + i.nbDone + ';' + i.nbExpired;
      });
      data.unshift(columnsToExport);
      const csvArray = data.join('\r\n');

      const blob = new Blob(['\ufeff', csvArray], {type: 'text/csv'});
      saveAs(blob, 'actions_personnes.csv');

    });
  }

  showActionManagement() {
    this.displayPlanManagement = true;
  }

  goToParentAnalyse(id: number) {
    this.router.navigate(['/parent-analyse', id]);
  }
}

