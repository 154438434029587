import { takeUntil } from "rxjs/operators";
import { Component, HostListener, OnInit } from "@angular/core";
import { UserClient } from "./userclient.model";
import { interval, Observable, Subject } from "rxjs";
import { UserClientsService } from "./user-clients.service";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { UserBlockService } from "../services/user-block.service";
import { AppConfig } from "../config";
import { Log } from "../models/log.model";
import { ClientService } from "../client/client.service";
import { UsersService } from "../users/users.service";
import { User } from "../users/user.model";
import { Client } from "../client/client.model";
import { RiskData } from "../risk-data/risk-data.model";
import { UserRightsService } from "../services/user-rights.service";
import { StructureClientService } from "../structure-client/structure-client.service";

@Component({
  selector: "app-user-clients",
  templateUrl: "./user-clients.component.html",
  styleUrls: ["./user-clients.component.css"],
})
export class UserClientsComponent implements OnInit {
  displayDialog: boolean;
  userClient: UserClient = {} as UserClient;
  selectedUserClient: UserClient;
  newUserClient: boolean;
  userClients: UserClient[] = [] as UserClient[];
  cols: any[];
  user_block: any = 0;
  lastIDUser: number;
  currentUser: any;
  timeoutCounter = 0;
  private onDestroy$ = new Subject();

  displayDialogImport = false;
  showDeleteColumn = false;
  importFiles: any[] = [];
  users: User[];
  listUsers: any[];
  selectedUserList: any;

  clients: any[];
  listClients: any[];
  selectedClientList: any;

  structures: any[];
  listStructures: any[];
  selectedStructureList: any;

  userClientExist = false;

  constructor(
    private userClientService: UserClientsService,
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    private clientService: ClientService,
    private structureClientsService: StructureClientService,
    private userRightsService: UserRightsService,
    private userService: UsersService,
    public config: AppConfig
  ) {}
  ngOnInit() {
    this.loadData();
    this.currentUser = JSON.parse(localStorage.getItem("user")).data.email;
    this.cols = [
      { field: "name_user", header: "Nom utilisateur" },
      { field: "name_client", header: "Nom client" },
      { field: "dtcreate", header: "Creation" },
      { field: "dtmodif", header: "Modification" },
    ];
    /* interval(this.config.RefreshMilisecs).pipe(takeUntil(this.onDestroy$)).subscribe(x => {
            if (this.timeoutCounter < this.config.RefreshTimer) {
                this.loadData();
                this.timeoutCounter++;
            }
        });*/
  }
  @HostListener("document:mousemove")
  onMouseMove() {
    this.timeoutCounter = 0;
  }

  showDialogToAdd() {
    if (
      this.userClient != null &&
      this.userClient.id != null &&
      !this.userClient
    ) {
      this.closeUsers();
    }
    this.newUserClient = true;
    this.userClient = {} as UserClient;
    this.displayDialog = true;
  }
  save() {
    const userClients = this.userClients
      ? [...this.userClients]
      : ([] as UserClient[]);
    this.userClient.id_user = this.selectedUserList
      ? this.selectedUserList.id
      : 0;
    this.userClient.id_client = this.selectedClientList
      ? this.selectedClientList.id
      : 0;
    this.userClient.id_structure = this.selectedStructureList
      ? this.selectedStructureList.id
      : 0;

    const jsonuser = JSON.stringify(this.userClient);
    if (
      this.userClients &&
      this.checkIfUserClientExist(this.userClients, this.userClient)
    ) {
      this.msgs.showError("La combinaison utilisateur - client existe déjà!");
    } else {
      if (this.userClient.id_user && this.userClient.id_client) {
        if (this.newUserClient) {
          userClients.push(this.userClient);
          this.userClientService.create(this.userClient).then((res) => {
            if (res.code === 200) {
              this.loadData();
              this.msgs.showSuccess("Compte utilisateur créé!");
              this.trace.createTrace({
                action: "CREATE USER",
                data: jsonuser,
                description: "CREATION UTILISATEUR",
              } as Log);
            } else {
              this.msgs.showError(res.message);
              const jsonmsg = JSON.stringify(res.message);
              this.trace.createTrace({
                action: "CREATE USER",
                data: jsonmsg,
                description: "ERREUR CREATION UTILISATEUR",
              } as Log);
            }
          });
        } else {
          userClients[this.userClients.indexOf(this.selectedUserClient)] =
            this.userClient;
          this.userClientService.update(this.userClient).then((res) => {
            if (res.code === 200) {
              this.loadData();
              this.msgs.showSuccess("Compte utilisateur mise à jour!");
              this.trace.createTrace({
                action: "UPDATE USER",
                data: JSON.stringify(userClients),
                description: "Mise a jour utilisateur",
              } as Log);
            } else {
              const jsonmsg = JSON.stringify(res.message);
              this.trace.createTrace({
                action: "UPDATE USER",
                data: jsonmsg,
                description: "ERREUR MAJ UTILISATEUR",
              } as Log);
            }
            this.userBlock
              .unblockUser(
                "bo_users_clients",
                this.userClient ? this.userClient.id : this.lastIDUser
              )
              .then((data) => {
                console.log("Unblock userClients");
              });
          });
        }
      } else {
        this.msgs.showError("Merci de remplir tous les champs!");
      }
      this.lastIDUser = this.userClient.id;
      this.userClients = userClients;
      this.userClient = null;
      this.displayDialog = false;
      this.loadData();
    }
  }
  delete() {
    this.userClientService.delete(this.userClient.id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Association supprimée!");
        this.trace.createTrace({
          action: "DELETE USER CLIENT",
          data: this.userClient,
          description: "SUPPRESSION UTILISATEUR CLIENT",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.userBlock
      .unblockUser(
        "bo_users_clients",
        this.userClient.id ? this.userClient.id : this.lastIDUser
      )
      .then((data) => {
        console.log("Unblock userClients");
      });
    this.lastIDUser = this.userClient.id;
    const index = this.userClients.indexOf(this.selectedUserClient);
    this.userClients = this.userClients.filter((val, i) => i !== index);
    this.userClient = null;
    this.displayDialog = false;
  }

  deleteLine(id) {
    this.userClientService.delete(id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Association supprimée!");
        this.trace.createTrace({
          action: "DELETE USER CLIENT",
          data: this.userClient,
          description: "SUPPRESSION UTILISATEUR CLIENT",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.userBlock
      .unblockUser(
        "bo_users_clients",
        this.userClient.id ? this.userClient.id : this.lastIDUser
      )
      .then((data) => {
        console.log("Unblock userClients");
      });
    this.lastIDUser = this.userClient.id;
    const index = this.userClients.indexOf(this.selectedUserClient);
    this.userClients = this.userClients.filter((val, i) => i !== index);
    this.userClient = null;
    this.displayDialog = false;
  }

  onRowSelect(event) {
    this.user_block = 0;
    this.newUserClient = false;
    this.userClient = this.cloneUser(event.data);

    this.selectedUserList = {
      id: this.users.find((item) => item.id === this.userClient.id_user)
        ? this.users.find((item) => item.id === this.userClient.id_user).id
        : 0,
      name: this.users.find((item) => item.id === this.userClient.id_user)
        ? this.users.find((item) => item.id === this.userClient.id_user).email
        : "",
    };

    this.selectedClientList = this.clients.find(
      (item) => item.id === this.userClient.id_client
    )
      ? this.clients.find((item) => item.id === this.userClient.id_client)
      : {};

    this.selectedStructureList = this.structures.find(
      (item) => item.id === this.userClient.id_structure
    )
      ? this.structures.find((item) => item.id === this.userClient.id_structure)
      : {};

    this.userBlock
      .verifUserBlock("bo_users_clients", this.userClient.id)
      .then((userblock) => {
        if (userblock.data != "" && userblock.data != 0) {
          this.user_block = userblock.data;
          alert("Data en cours d'utilisation par : " + this.user_block);
        } else {
          this.userBlock.blockUser("bo_users_clients", this.userClient.id);
          this.lastIDUser = this.userClient.id;
        }
      });
    this.displayDialog = true;
  }
  cloneUser(u: UserClient): UserClient {
    const userClient = {} as UserClient;
    for (const prop in u) {
      userClient[prop] = u[prop];
    }
    return userClient;
  }
  @HostListener("window:beforeunload")
  closeUsers() {
    if (
      (this.user_block == this.currentUser || this.user_block == 0) &&
      !this.newUserClient &&
      this.userClient != null
    ) {
      this.userBlock
        .unblockUser(
          "bo_users_clients",
          this.userClient ? this.userClient.id : this.lastIDUser
        )
        .then((data) => {
          console.log("Unblock userClient");
        });
    }
    this.loadData();
  }

  loadData() {
    this.userClientService
      .get()
      .then((userClients) => (this.userClients = userClients.data));
    this.structureClientsService.getAll().then((structures) => {
      if (structures.data == null) {
        this.structures = [] as any[];
      } else {
        this.structures = structures.data;
        this.listStructures = this.list_to_tree(this.structures);
      }
    });

    this.clientService.get().then((clients) => {
      this.clients = clients.data;
      this.listClients = this.clients;
    });

    this.userService.getUsers().then((users) => {
      if (users.data == null) {
        this.users = [] as User[];
      } else {
        this.users = users.data;
        this.listUsers = this.makeUserList(this.users);
      }
    });
  }

  makeClientList(clients: any[]) {
    const listClients: any[] = [];
    for (const prop in clients) {
      listClients.push({
        id: clients[prop].id,
        name: clients[prop].label,
      });
    }
    return listClients;
  }

  makeUserList(users: User[]) {
    const listUsers: any[] = [];
    for (const prop in users) {
      listUsers.push({
        id: users[prop].id,
        name: users[prop].email,
      });
    }
    return listUsers;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeUsers();
  }

  openImportDialog() {
    this.displayDialogImport = true;
  }

  importData(event) {
    this.loadData();
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }

  list_to_tree(list) {
    let map = {},
      node,
      roots = [],
      i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      node.name = list[i].label;
      node.expanded = true;
      if (node.id_parent !== null) {
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node.id_parent]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  nodeSelect(event) {
    this.msgs.showInfo({
      severity: "info",
      summary: "Node Selected",
      detail: event.node.label,
    });
  }

  nodeUnselect(event) {
    this.msgs.showInfo({
      severity: "info",
      summary: "Node Unselected",
      detail: event.node.label,
    });
  }

  checkIfUserClientExist(userClients, userclient): boolean {
    return userClients.some(
      (el) =>
        el.id_structure === userclient.id_structure &&
        el.id_user === userclient.id_user
    );
  }

  getStructuresForClient() {
    return this.listStructures.filter(
      (i) => i.id_client == this.selectedClientList.id
    );
  }
}
