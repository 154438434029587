<p-tabView>
  <p-tabPanel header="Impacts" [selected]="true">
    <app-risk [clients]="clients" [activatedComp]="activatedComp"></app-risk>
  </p-tabPanel>
  <p-tabPanel header="Gravités">
    <app-risk-data
      [clients]="clients"
      [activatedComp]="activatedComp"
    ></app-risk-data>
  </p-tabPanel>
</p-tabView>
