<p-table
  csvSeparator=";"
  #dt
  [columns]="cols"
  [value]="paramGraphs"
  [paginator]="true"
  [rows]="5"
  [scrollHeight]="true"
  [responsive]="true"
>
  <ng-template pTemplate="caption">
    <div class="ui-helper-clearfix">
      <button
        type="button"
        *ngIf="userRightsService.getUserCreate() == 1"
        pButton
        pTooltip="Ajouter"
        tooltipPosition="top"
        icon="fa fa-plus"
        (click)="addLigne()"
        style="float: left"
      ></button>
      <button
        type="button"
        *ngIf="userRightsService.getUserDelete() == 1"
        pButton
        pTooltip="Supprimer toutes les lignes"
        tooltipPosition="top"
        iconPos="left"
        icon="fa fa-trash"
        style="float: left"
        class="p-button-danger"
        (click)="deleteAllLines()"
      ></button>
      <button
        type="button"
        *ngIf="userRightsService.getUserCreate() == 1"
        pButton
        pTooltip="Importer"
        tooltipPosition="top"
        icon="fa fa-download"
        iconPos="left"
        class="p-button-info"
        (click)="openImportDialog()"
        style="float: left"
      ></button>
      <button
        [disabled]="paramGraphs.length == 0"
        pTooltip="Exporter"
        *ngIf="userRightsService.getUserRead() == 1"
        tooltipPosition="top"
        type="button"
        pButton
        icon="fa fa-upload"
        class="p-button-info"
        iconPos="left"
        (click)="exportCsv()"
        style="float: left"
      ></button>
    </div>
    <div style="text-align: right">
      <i class="fa fa-search" style="margin: 4px 4px 0 0"></i>
      <input
        type="text"
        pInputText
        size="50"
        placeholder="Global Filter"
        (input)="dt.filterGlobal($event.target.value, 'contains')"
        style="width: auto"
      />
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3em !important">N:</th>
      <th width="100%" *ngFor="let col of columns">
        {{ col.header }}
        <p-sortIcon
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        ></p-sortIcon>
      </th>
      <th style="width: 3em !important">X</th>
    </tr>
  </ng-template>
  <ng-template *ngIf="paramGraphs" pTemplate="body" let-rowData>
    <tr>
      <td pEditableColumn style="max-width: 3em" (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              *ngIf="userRightsService.getUserUpdate() == 1"
              #changeOrder
              pInputText
              type="text"
              [(ngModel)]="rowData.order_num"
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'order_num',
                  value: changeOrder.value
                })
              "
              (change)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'order_num',
                  value: changeOrder.value
                })
              "
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.order_num }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td
        pEditableColumn
        (click)="stopRefresh()"
        *ngIf="type_object == 'dashboard'"
      >
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              *ngIf="userRightsService.getUserUpdate() == 1"
              #changeSTRADM
              appendTo="body"
              placeholder="Structure: "
              (onClick)="
                selectedStructureClient = getObjectFromCode(
                  structureClients,
                  rowData.id_structure
                )
              "
              (onChange)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'id_structure',
                  value: changeSTRADM.value.code
                })
              "
              optionLabel="name"
              [filter]="true"
              [options]="structureClients"
              [(ngModel)]="selectedStructureClient"
            ></p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ getNameFromCode(structureClients, rowData.id_structure) }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td
        pEditableColumn
        (click)="stopRefresh()"
        *ngIf="type_object == 'dashboard'"
      >
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              *ngIf="userRightsService.getUserUpdate() == 1"
              #changeAnalyse
              appendTo="body"
              placeholder="Analyse: "
              (onClick)="
                selectedStructureClient = getObjectFromId(
                  analyses,
                  rowData.id_analyse
                );
                this.reloadAnalyses(rowData.id_structure)
              "
              (onChange)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'id_analyse',
                  value: changeAnalyse.value.id
                })
              "
              optionLabel="name"
              [filter]="true"
              [options]="analyses"
              [(ngModel)]="selectedAnalyse"
            ></p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ getNameFromId(analyses, rowData.id_analyse) }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              *ngIf="userRightsService.getUserUpdate() == 1"
              #changedName
              pInputText
              type="text"
              [(ngModel)]="rowData.name"
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'name',
                  value: changedName.value
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'name',
                  value: changedName.value
                })
              "
              (change)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'name',
                  value: changedName.value
                })
              "
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.name }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              *ngIf="userRightsService.getUserUpdate() == 1"
              #changedDescription
              pInputText
              type="text"
              [(ngModel)]="rowData.description"
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'description',
                  value: changedDescription.value
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'description',
                  value: changedDescription.value
                })
              "
              (change)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'description',
                  value: changedDescription.value
                })
              "
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.description }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              *ngIf="userRightsService.getUserUpdate() == 1"
              appendTo="body"
              placeholder="Type Graphique: "
              [filter]="true"
              #selectedTypeGraph
              [options]="typesGraphs"
              [(ngModel)]="rowData.typegraph"
              [style]="{ width: '100%' }"
              (onChange)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'typegraph',
                  value: selectedTypeGraph.selectedOption.name
                })
              "
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'typegraph',
                  value: selectedTypeGraph.selectedOption.name
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'typegraph',
                  value: selectedTypeGraph.selectedOption.name
                })
              "
            >
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ getLabelFromName(typesGraphs, rowData.typegraph) }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              *ngIf="userRightsService.getUserUpdate() == 1"
              appendTo="body"
              placeholder="Type agregation: "
              [filter]="true"
              #selectedAgregat
              [options]="typesAgregats"
              [(ngModel)]="rowData.agregat"
              [style]="{ width: '100%' }"
              (onChange)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'agregat',
                  value: selectedAgregat.selectedOption.name
                })
              "
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'agregat',
                  value: selectedAgregat.selectedOption.name
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'agregat',
                  value: selectedAgregat.selectedOption.name
                })
              "
            >
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ getLabelFromName(typesAgregats, rowData.agregat) }}
          </ng-template>
        </p-cellEditor>
      </td>

      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              *ngIf="userRightsService.getUserUpdate() == 1"
              appendTo="body"
              dataKey="id"
              optionLabel="name"
              placeholder="Données de regroupement: "
              [filter]="true"
              #selectedLabels
              [options]="columnsToShow"
              [(ngModel)]="selectedLabel"
              [style]="{ width: '100%' }"
              (onFocus)="
                columnsToShow = getColumnsFromIdAnalyse(
                  type_object == 'dashboard'
                    ? rowData.id_analyse
                    : rowData.id_object
                )
              "
              (onClick)="
                selectedLabel = getObjectFromId(
                  columnsToShow,
                  rowData.labels
                )[0];
                columnsToShow = getColumnsFromIdAnalyse(
                  type_object == 'dashboard'
                    ? rowData.id_analyse
                    : rowData.id_object
                )
              "
              (onChange)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'labels',
                  value: selectedLabels.selectedOption.id
                })
              "
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'labels',
                  value: selectedLabels.selectedOption.id
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'labels',
                  value: selectedLabels.selectedOption.id
                })
              "
            >
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{
              getNameFromId(
                getColumnsFromIdAnalyse(
                  type_object == "dashboard"
                    ? rowData.id_analyse
                    : rowData.id_object
                ),
                rowData.labels
              )
            }}
          </ng-template>
        </p-cellEditor>
      </td>

      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              *ngIf="userRightsService.getUserUpdate() == 1"
              appendTo="body"
              dataKey="id"
              optionLabel="name"
              placeholder="Données: "
              [filter]="true"
              #selectedDatas
              [options]="formulasToShow"
              [(ngModel)]="selectedData"
              [style]="{ width: '100%' }"
              (onFocus)="
                formulasToShow = getFormulasFromIdAnalyse(
                  type_object == 'dashboard'
                    ? rowData.id_analyse
                    : rowData.id_object
                )
              "
              (onClick)="
                selectedData = getObjectFromId(columnsToShow, rowData.data)[0];
                formulasToShow = getFormulasFromIdAnalyse(
                  type_object == 'dashboard'
                    ? rowData.id_analyse
                    : rowData.id_object
                )
              "
              (onChange)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'data',
                  value: selectedDatas.selectedOption.id
                })
              "
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'data',
                  value: selectedDatas.selectedOption.id
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'data',
                  value: selectedDatas.selectedOption.id
                })
              "
            >
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{
              getNameFromId(
                getColumnsFromIdAnalyse(
                  type_object == "dashboard"
                    ? rowData.id_analyse
                    : rowData.id_object
                ),
                rowData.data
              )
            }}
          </ng-template>
        </p-cellEditor>
      </td>

      <td style="width: 3em !important">
        <button
          pButton
          type="button"
          *ngIf="userRightsService.getUserDelete() == 1"
          class="p-button-rounded p-button-danger"
          icon="fa fa-close"
          (click)="deleteLine(rowData.id)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary" let-rowData>
    <div style="text-align: left">
      <button
        type="button"
        *ngIf="userRightsService.getUserCreate() == 1"
        pButton
        icon="fa fa-plus"
        (click)="addLigne()"
        label="Ajout"
      ></button>
    </div>
  </ng-template>
</p-table>
<p-dialog
  styleClass="import"
  appendTo="body"
  header="Importation de données"
  [(visible)]="displayDialogImport"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
>
  <h3 class="first">Importation analyses</h3>
  <h5>Format de fichier:</h5>
  <p>
    Ordre ; Nom de la graphique ; Description ; Nom de la graphique ; Agrégation
  </p>
  <p>Séparateur standard: ";"</p>
  <p-fileUpload
    name="file"
    url="{{ config.API_Host }}import/{{
      id_client ? id_client : 0
    }}/bo_param_graph/{{ id ? id : 0 }}/{{
      type_object ? type_object : 'analyse'
    }}"
    (onUpload)="importData($event)"
    accept=".csv"
    maxFileSize="1000000"
  >
    <ng-template pTemplate="content">
      <ul *ngIf="importFiles.length">
        <li *ngFor="let file of importFiles">
          {{ file.name }} - {{ file.size }} bytes
        </li>
      </ul>
    </ng-template>
  </p-fileUpload>
</p-dialog>
<p-progressSpinner *ngIf="!paramGraphs || loadingScreen"></p-progressSpinner>
