import { Injectable, OnInit } from "@angular/core";
import { MsgService } from "./msg.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { AppConfig } from "../config";
import { HttpClient } from "@angular/common/http";
import { ApiResponse } from "../models/api-response.model";

@Injectable()
export class AuthService implements OnInit {
  private connected = new BehaviorSubject(0);
  private admin = new BehaviorSubject(0);
  connectedSubject = this.connected.asObservable();
  adminSubject = this.admin.asObservable();
  value: any;
  returnUrl: any;

  constructor(
    private http: HttpClient,
    private msg: MsgService,
    private router: Router,
    private route: ActivatedRoute,
    public config: AppConfig
  ) {
    this.isLoggedIn();
  }

  ngOnInit() {}

  login(email: string, password: string) {
    return this.http
      .post(
        this.config.API_Host + "users/login",
        JSON.stringify({ email: email, password: password })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => {
        // login successful if there's a jwt token in the response
        const user = data;
        if (user) {
          user.token = "no-token-yet";

          if (user && user.token && data.code == 200 && data.data) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            user.data.delete = user.data.d;
            user.data.create = user.data.c;
            user.data.update = user.data.u;
            user.data.read = user.data.r;
            localStorage.setItem("user", JSON.stringify(user));
            this.changeConnected(1);
            this.changeAdmin(<number>user.data.admin);
            this.msg.showSuccess("Vous êtes connecté!");
          } else {
            this.msg.showError(data.message);
          }
        }
      });
  }

  logout(email) {
    return this.http
      .post(
        this.config.API_Host + "users/logout",
        JSON.stringify({ email: email })
      )
      .toPromise()
      .then((res) => {
        localStorage.removeItem("user");
        this.changeConnected(0);
      });
  }
  changeConnected(connected: number) {
    this.connected.next(connected);
  }
  changeAdmin(admin: number) {
    this.admin.next(admin);
  }

  isLoggedIn() {
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    if (window.localStorage) {
      this.value = JSON.parse(localStorage.getItem("user"));
      if (!this.value) {
        this.changeConnected(0);
        this.changeAdmin(0);
        this.router.navigate(["/login"]);
      } else {
        this.changeConnected(1);
        if (<number>this.value.data.admin == 1) {
          this.changeAdmin(1);
        } else {
          this.changeAdmin(0);
        }
      }
    }
  }

  refreshLogin() {
    return this.http
      .post(this.config.API_Host + "users/getUserData",
        JSON.stringify({ email: JSON.parse(localStorage.getItem('user')).data.email,
          password: JSON.parse(localStorage.getItem('user')).data.password })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => {
        // login successful if there's a jwt token in the response
        const user = data;
        if (user) {
          if (user && data.code == 200 && data.data) {
            user.data.delete = user.data.d;
            user.data.create = user.data.c;
            user.data.update = user.data.u;
            user.data.read = user.data.r;
            localStorage.setItem("user", JSON.stringify(user));
            this.changeAdmin(<number>user.data.admin);
          } else {
            this.msg.showError(data.message);
          }
        }
      });
  }
}
