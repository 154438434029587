import {
  AfterViewInit,
  Component,
  HostListener,
  OnChanges,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "./services/auth.service";
import { Location } from "@angular/common";
import { AppConfig } from "./config";
import { MegaMenuItem } from "primeng/api";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  connected: any;
  admin: any;
  super_user: any;
  analyse: any;
  dashboard: any;
  returnUrl: any;
  username: any = "";
  title: string;
  currentPage: string;
  items: MegaMenuItem[];
  activeItem: MegaMenuItem;

  constructor(
    private router: Router,
    private auth: AuthService,
    private location: Location,
    public config: AppConfig
  ) {
    if (location.path().includes("/setPassword")) {
      this.router.navigate([location.path()]);
    } else if (location.path().includes("/forgottenPassword")) {
      this.router.navigate([location.path()]);
    }
  }

  ngOnInit() {
    this.auth.connectedSubject.subscribe((connected) => {
      this.connected = connected;
      this.setUsernameInfo();
    });
    this.auth.adminSubject.subscribe((admin) => {
      this.admin = admin;
      this.setUsernameInfo();
      this.initMenuItems();
    });
    this.setUsernameInfo();
    this.title = this.config.AppTitle;
  }
  /*
  ngOnChanges() {
    this.auth.connected.subscribe(connected => this.connected = connected);
  }
*/
  logout() {
    const userinfo = JSON.parse(localStorage.getItem("user"));
    this.connected = 0;
    this.admin = 0;
    this.auth.logout(userinfo.data.email);
    localStorage.removeItem("user");
    this.router.navigate(["/login"]);
  }

  setUsernameInfo() {
    const userinfo = JSON.parse(localStorage.getItem("user"));
    if (userinfo) {
      this.username = userinfo.data.email;
      this.analyse = userinfo.data.analyse;
      this.dashboard = userinfo.data.dashboard;
      this.super_user = userinfo.data.super_user;
    }
  }

  initMenuItems() {
    this.items = [];

    this.items.push({
      label: "Actions",
      icon: "fa fa-list",
      routerLink: ["/actions"],
    });

    if (this.dashboard == 1) {
      this.items.push({
        label: "Dashboards",
        icon: "pi pi-fw pi-chart-line",
        routerLink: ["/dashboards"],
      });
    }

    if (this.analyse == 1) {
      this.items.push({
        label: "Analyses",
        icon: "pi pi-fw pi-desktop",
        routerLink: ["/analyses"],
      });
    }
    if (this.super_user == 1) {
      this.items.push({
        label: "Paramètrages",
        icon: "fa fa-cogs",
        items: [
          [
            {
              label: "Paramètrages",
              items: [
                { label: "Utilisateurs", routerLink: ["/params/users"] },
                { label: "Clients", routerLink: ["/params/client"] },
                {
                  label: "Structure Clients",
                  routerLink: ["/params/structureclient"],
                },
                {
                  label: "Utilisateurs - Clients",
                  routerLink: ["/params/userclient"],
                },
                { label: "Data table", routerLink: ["/params/datatable"] },
              ],
            },
          ],
        ],
      });
    } else if (this.admin == 1) {
      this.items.push({
        label: "Paramètrages",
        icon: "fa fa-cogs",
        items: [
          [
            {
              label: "Paramètrages",
              items: [
                {
                  label: "Structure Clients",
                  routerLink: ["/params/structureclient"],
                },
                { label: "Data table", routerLink: ["/params/datatable"] },
              ],
            },
          ],
        ],
      });
    }

    this.items.push({
      label: this.username,
      icon: "my-margin-left pi pi-fw pi-sign-out",
      items: [
        [
          {
            label: "Paramètrages",
            items: [
              { label: "Paramètres", routerLink: ["/params/userprofile"] },
              { label: "Logout", command: () => this.logout() },
            ],
          },
        ],
      ],
    });

    this.activeItem = this.items[0];
  }
}
