import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { User } from "../users/user.model";
import { Subcategory } from "../models/subcategory";
import { interval, Observable, Subject } from "rxjs";
import { UsersService } from "../users/users.service";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { UserBlockService } from "../services/user-block.service";
import { AppConfig } from "../config";
import { Log } from "../models/log.model";
import { RiskData } from "./risk-data.model";
import { Risk } from "../risk/risk.model";
import { RiskService } from "../risk/risk.service";
import { RiskDataService } from "./risk-data.service";
import { takeUntil } from "rxjs/operators";
import { ConfirmationService } from "primeng/api";
import { UserRightsService } from "../services/user-rights.service";

@Component({
  selector: "app-risk-data",
  templateUrl: "./risk-data.component.html",
  styleUrls: ["./risk-data.component.css"],
})
export class RiskDataComponent implements OnInit, OnChanges {
  riskDatas: RiskData[] = [] as RiskData[];
  riskData: RiskData = {} as RiskData;
  selectedRiskData: RiskData;
  newRiskData: boolean;
  displayDialog: boolean;
  lastIDRiskData;

  currentRiskData: any;
  users: User[];

  filteredUsers: any;

  user_block = 0;

  cols: any[];
  risks: Risk[];
  listRisks: any[];

  currentUser: any;

  displayDialogImport = false;
  showDeleteColumn = false;
  importFiles: any[] = [];

  selectedRiskList: any;

  @Input("clients") clients: any;
  @Input("activatedComp") activatedComp: any;

  timeoutCounter = 0;
  private onDestroy$ = new Subject();

  constructor(
    private riskDataService: RiskDataService,
    private userService: UsersService,
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    private riskService: RiskService,
    private confirmationService: ConfirmationService,
    private userRightsService: UserRightsService,
    public config: AppConfig
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.clients;
    if (currentItem) {
      this.loadData();
    }
  }

  ngOnInit() {
    if (this.clients && this.activatedComp == "Risk") {
      this.loadData();

      this.currentUser = JSON.parse(localStorage.getItem("user")).data.email;
      this.cols = [
        { field: "name_risk", header: "Type d'impact: " },
        { field: "name", header: "Niveau: " },
        { field: "description", header: "Commentaires/Précisions: " },
        { field: "value", header: "Poids: " },
        { field: "dtcreate", header: "Creation: " },
        { field: "dtmodif", header: "Modification: " },
      ];
      this.currentRiskData = JSON.parse(
        localStorage.getItem("user")
      ).data.email;
      interval(this.config.RefreshMilisecs)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((x) => {
          if (this.timeoutCounter < this.config.RefreshTimer && this.clients) {
            this.loadData();
            this.timeoutCounter++;
          }
        });
    }
  }

  @HostListener("document:mousemove")
  onMouseMove() {
    this.timeoutCounter = 0;
  }

  save() {
    const riskDatas = this.riskDatas ? [...this.riskDatas] : ([] as RiskData[]);
    this.riskData.id_risk = this.selectedRiskList
      ? this.selectedRiskList.id
      : 0;
    this.riskData.id_client = this.clients;
    this.riskData.description = this.riskData.description
      ? this.riskData.description
      : "";
    if (this.riskData.name) {
      if (this.newRiskData) {
        riskDatas.push(this.riskData);
        this.riskDataService.create(this.riskData).then((res) => {
          const jsonquery = JSON.stringify(this.riskData);
          if (res.code === 200) {
            this.msgs.showSuccess("Données créée!");
            this.trace.createTrace({
              action: "CREATE RISK DATA",
              data: jsonquery,
              description: "CREATION RISQUE DATA",
            } as Log);
          } else {
            this.msgs.showError(res.message);
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "CREATE RISK DATA",
              data: jsonmsg,
              description: "ERREUR CREATION RISQUE DATA",
            } as Log);
          }
        });
        this.displayDialog = false;
        this.loadData();
      } else {
        riskDatas[this.riskDatas.indexOf(this.selectedRiskData)] =
          this.riskData;
        this.riskDataService.save(this.riskData).then((res) => {
          if (res.code === 200) {
            this.msgs.showSuccess("Risque data mise à jour!");
            this.trace.createTrace({
              action: "UPDATE RISK DATA",
              data: JSON.stringify(this.riskData),
              description: "Mise a jour données risque",
            } as Log);
          } else {
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "UPDATE RISK DATA",
              data: jsonmsg,
              description: "ERREUR MAJ RISK DATA",
            } as Log);
          }
          this.userBlock
            .unblockUser(
              "bo_risk_data",
              this.riskData ? this.riskData.id : this.lastIDRiskData
            )
            .then((data) => {
              console.log("Unblock query");
            });
        });
        this.displayDialog = false;
        this.loadData();
      }
    } else {
      this.msgs.showError("Merci de remplir les champs obligatoires!");
    }
  }

  delete() {
    this.riskDataService.delete(this.riskData.id).then((res) => {
      if (res.code === 200) {
        this.msgs.showSuccess("Risque data supprimée!");
        this.trace.createTrace({
          action: "DELETE USER CATEGORY",
          data: JSON.stringify(this.riskData),
          description: "SUPPRESSION RISQUE DATA",
        } as Log);
        this.loadData();
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.riskData = null;
    this.displayDialog = false;
  }

  deleteLine(id) {
    this.riskDataService.delete(id).then((res) => {
      if (res.code === 200) {
        this.msgs.showSuccess("Risque data supprimée!");
        this.trace.createTrace({
          action: "DELETE USER CATEGORY",
          data: JSON.stringify(this.riskData),
          description: "SUPPRESSION RISQUE DATA",
        } as Log);
        this.loadData();
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.riskData = null;
    this.displayDialog = false;
  }

  showDialogToAdd() {
    if (this.riskData != null && !this.riskData) {
      this.closeRiskDatas();
    }
    this.newRiskData = true;
    this.riskData = {} as RiskData;
    this.displayDialog = true;
  }

  onRowSelect(event) {
    this.user_block = 0;
    this.newRiskData = false;
    this.riskData = this.cloneRiskData(event.data);
    this.displayDialog = true;
    this.selectedRiskList = {} as Risk;
    this.selectedRiskList = {
      id: this.risks.find((item) => item.id === this.riskData.id_risk)
        ? this.risks.find((item) => item.id === this.riskData.id_risk).id
        : 0,
      name: this.risks.find((item) => item.id === this.riskData.id_risk)
        ? this.risks.find((item) => item.id === this.riskData.id_risk).name
        : "",
    };
    this.userBlock
      .verifUserBlock("bo_risk_data", this.riskData.id)
      .then((userblock) => {
        if (userblock.data != "" && userblock.data != 0) {
          this.user_block = userblock.data;
          alert("Data en cours d'utilisation par : " + this.user_block);
        } else {
          this.userBlock.blockUser("bo_risk_data", this.riskData.id);
          this.lastIDRiskData = this.riskData.id;
        }
      });
  }

  cloneRiskData(r: RiskData): RiskData {
    const riskData = {} as RiskData;
    for (const prop in r) {
      riskData[prop] = r[prop];
    }
    return riskData;
  }

  loadData() {
    this.riskDataService.get(this.clients).then((riskDatas) => {
      this.riskDatas = riskDatas.data ? riskDatas.data : ([] as RiskData[]);
    });

    this.userService.getUsers().then((users) => {
      this.users = users.data;
    });
    this.riskService.getRisks(this.clients).then((risks) => {
      if (risks.data == null) {
        this.risks = [] as Risk[];
      } else {
        this.risks = risks.data;
        this.listRisks = this.makeRiskList(this.risks);
      }
    });
  }

  @HostListener("window:beforeunload")
  closeRiskDatas() {
    if (
      (this.user_block == this.currentRiskData || this.user_block == 0) &&
      !this.newRiskData &&
      this.riskData != null
    ) {
      this.userBlock
        .unblockUser(
          "bo_risk_data",
          this.riskData ? this.riskData.id : this.lastIDRiskData
        )
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
  }

  makeRiskList(risks: Risk[]) {
    const listRisks: any[] = [];
    for (const prop in risks) {
      listRisks.push({
        id: risks[prop].id,
        name: risks[prop].name,
      });
    }
    return listRisks;
  }

  makeSubcatList(data: Subcategory[]) {
    const listSubcats: any[] = [];
    for (const prop in data) {
      listSubcats.push({
        id: data[prop].id,
        name: data[prop].name,
      });
    }
    return listSubcats;
  }

  filterUsers(event) {
    this.filteredUsers = [];
    for (let i = 0; i < this.users.length; i++) {
      const user = this.users[i].email;
      if (user.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredUsers.push(user);
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeRiskDatas();
  }

  openImportDialog() {
    this.displayDialogImport = true;
  }

  importData(event) {
    this.loadData();
    console.log(event);
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }

  deleteAllLines() {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer toutes les lignes?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.riskDataService.deleteAll(this.clients).then((res) => {
          this.loadData();
          this.msgs.showSuccess("Ligne supprimée!");
        });
      },
    });
  }
}
