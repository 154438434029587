import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { ProposeScenarioService } from "../propose-scenario/propose-scenario.service";
import { CommonModule } from '@angular/common';
import { ActionPersonLine } from "../plan/plan.model";
import { MessageService } from "primeng/api";
import { Router } from '@angular/router';

@Component({
  selector: 'app-validate-scenario',
  templateUrl: './validate-scenario.component.html',
  styleUrls: ['./validate-scenario.component.css']
})
export class ValidateScenarioComponent implements OnInit {
  @Input() validation_table = false;
  id_user: number;
  proposedScenarios: [];

  validationScenarioOptions = [
    { label: "Validé", value: 1 },
    { label: "En attente", value: 0},
    { label: "Refusé", value: -1}
  ];
  editing: boolean = false;
  constructor(
    private proposeScenarioService: ProposeScenarioService,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id_user = JSON.parse(localStorage.getItem("user")).data.id;

    if (this.validation_table) {
      this.proposeScenarioService.getProposedScenariosToValidate(this.id_user).then((response) => {
        this.proposedScenarios = response.data;
      });
    } else {
      this.proposeScenarioService.getProposedScenarios(this.id_user).then((response) => {
        this.proposedScenarios = response.data;
      });
    }
  }
  ngAfterViewChecked() {
    this.updateColorOnSelectButtonInit();
  }

  updateStatus(scenario) {
    this.updateColorOnSelectButtonInit();
    this.proposeScenarioService.updateScenarioStatus(scenario).then(response => {
      this.messageService.add({severity:'success', summary: 'Success', detail:'Le statut du scénario et le commentaire ont été mis à jour avec succès.'});
    }, error => {
      console.error("Une erreur s'est produite lors de la mise à jour du statut du scénario.", error);
    });
  }

  getStatusLabel(status: number): string {
    const option = this.validationScenarioOptions.find(option => option.value === status);
    return option ? option.label : "";
  }

  onRowEditSave(scenario) {
    this.proposeScenarioService.updateScenario(scenario).then(response => {
      this.messageService.add({severity:'success', summary: 'Success', detail:'Votre scénario a été mis à jour avec succès.'});
    }, error => {
      console.error("Une erreur s'est produite lors de la mise à jour de votre scénario.", error);
    });
  }

  updateColorOnSelectButtonInit() {
    const selectButtons = document.querySelectorAll('.validation-select-button');

    selectButtons.forEach(selectButton => {
      const valideChild: HTMLElement = selectButton.querySelector('[aria-label="Validé"]');
      const refuseChild: HTMLElement = selectButton.querySelector('[aria-label="Refusé"]');
      const pendingChild: HTMLElement = selectButton.querySelector('[aria-label="En attente"]');

      if (valideChild.classList.contains('p-highlight')) {
        valideChild.style.backgroundColor = 'green';
        refuseChild.style.backgroundColor = '';
      }
      if (refuseChild.classList.contains('p-highlight')) {
        refuseChild.style.backgroundColor = 'rgb(220, 0, 0)';
        valideChild.style.backgroundColor = '';
      }
      if (pendingChild.classList.contains('p-highlight')) {
        refuseChild.style.backgroundColor = '';
        valideChild.style.backgroundColor = '';
      }
    });
  }

  deleteScenario(scenario) {
    if (confirm('Êtes-vous sûr de vouloir supprimer ce scénario?')) {
      this.proposeScenarioService.deleteScenario(scenario).then(response => {
        this.messageService.add({severity:'success', summary: 'Success', detail:'Votre scénario a été supprimé avec succès.'});
      }, error => {
        console.error("Une erreur s'est produite lors de la suppression de votre scénario.", error);
      });
    }
  }

  goToAnalyse(scenario) {
    this.router.navigate(['/workspace_details', scenario.id_analyse, scenario.id_structure]);
  }
}
