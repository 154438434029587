import { Injectable } from "@angular/core";

@Injectable()
export class ObjectTypes {
  public types: any;

  constructor() {
    this.types = [
      {
        label: "Choisir Type",
        name: "none",
      },
      {
        label: "Texte",
        name: "bo_desc_objects",
        value: "description",
      },
      {
        label: "Nombre",
        name: "bo_desc_objects",
        value: "description",
      },
      {
        label: "Date",
        name: "bo_desc_objects",
        value: "name",
      },
      {
        label: "Formule",
        name: "bo_formula_objects",
        value: "value",
      },
      {
        label: "Theme",
        name: "bo_themes",
        value: "name",
      },
      {
        label: "Sous-Theme",
        name: "bo_subthemes",
        value: "name",
      },
      {
        label: "Probabilité",
        name: "bo_probability",
        value: "value",
      },
      {
        label: "List",
        name: "bo_list_objects",
        value: "value",
      },
      {
        label: "Processus",
        name: "bo_proc",
        value: "name",
      },
      {
        label: "Risk",
        name: "bo_risk_data",
        value: "value",
      },
      {
        label: "Plan",
        name: "bo_actions_persons_lines",
        value: "value",
      },
      {
        label: "Booléen",
        name: "bo_desc_objects",
        value: "name",
      },
    ];
  }
}
