<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="error-template">
        <h1>Oops! Hacker, Hacker, la page n'est pas trouvée.</h1>
        <h2>404 Not Found</h2>
        <div class="error-details">
          Merci de contacter le support de l'application.
        </div>
      </div>
    </div>
  </div>
</div>
