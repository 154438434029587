import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { Paramgraph } from "./paramgraph.model";

@Injectable({
  providedIn: "root",
})
export class ParamGraphService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  get(id_object: any, type_object: any) {
    return this.http
      .get(
        this.config.API_Host +
          "paramGraphs/get/" +
          id_object +
          "/" +
          type_object
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  create(paramGraph: Paramgraph) {
    return this.http
      .post(
        this.config.API_Host + "paramGraphs/create",
        JSON.stringify({ paramGraph })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  update(paramGraph: Paramgraph) {
    return this.http
      .post(
        this.config.API_Host + "paramGraphs/update",
        JSON.stringify({ paramGraph })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  delete(id: number) {
    return this.http
      .post(
        this.config.API_Host + "paramGraphs/delete",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteAll(id: number) {
    return this.http
      .post(
        this.config.API_Host + "paramGraphs/deleteAll",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  addupdate(options) {
    return this.http
      .post(
        this.config.API_Host + "paramGraphs/addupdate",
        JSON.stringify(options)
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
