import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { MsgService } from "../services/msg.service";
import { SetPasswordService } from "./set-password.service";

@Component({
  selector: "app-set-password",
  templateUrl: "./set-password.component.html",
  styleUrls: ["./set-password.component.css"],
})
export class SetPasswordComponent implements OnInit {
  token: string;
  showPassword = false;
  password: string;
  secondpassword: string;
  valideToken = false;
  rgpdChecked = false;
  rgpdMessage =
    "Madame, Monsieur,\n" +
    " \n" +
    "Vous allez créer votre compte ARISKLESS. \n" +
    "Les données collectées avec votre consentement sont : Nom, Prénom, Fonction et adresse email.\n" +
    " \n" +
    "Conformément au Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016, relatif au règlement général de la protection des données personnelles (RGPD) et à la Loi n°2018-493 du 20 juin 2018 mettant à jour la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, le client et ses représentants, disposent d’un droit d’accès, de modification, de rectification des données à caractère personnel le concernant en écrivant à contact@ariskan.fr. \n" +
    " \n" +
    "ARISKAN conservera les données à caractère personnel liées à la prestation pour une période n’excédant pas la durée d’engagement contractuel de la prestation.\n" +
    " \n" +
    "Les collaborateurs d’ARISKAN sont sensibilisés aux principes de protection des données. Ils ont accès uniquement aux informations nécessaires à leur activité. Les données sensibles font l’objet d’habilitations et de contrôles spécifiques.\n" +
    " \n" +
    "Par principe ARISKAN, n’effectue pas de transfert de données à caractère personnel hors de l’Union Européenne.\n" +
    " \n" +
    "Nous vous remercions pour votre confiance et nous tenons à votre entière disposition pour toute information plus précise que vous pourriez souhaiter.\n" +
    " \n" +
    " \n" +
    "L’équipe ARISKAN. \n";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private setPasswordService: SetPasswordService,
    private msg: MsgService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.token = decodeURIComponent(params["token"]);
      this.setPasswordService.checkIfTokenIsValide(this.token).then((data) => {
        if (data.code == 200) {
          this.valideToken = true;
        }
      });
    });
  }

  changeShowPassword() {
    this.showPassword = !this.showPassword;
  }

  savePasswordInfo() {
    if (this.password && this.secondpassword && this.rgpdChecked) {
      if (this.password == this.secondpassword) {
        this.setPasswordService
          .changePassword(this.token, this.password)
          .then((data) => {
            this.msg.showSuccess("Le nouveau mot de passe est activé");
            this.router.navigate(["/login"]);
          });
      } else {
        this.msg.showError(
          "Merci de verifier si les deux mots de passe sont identiques"
        );
      }
    } else {
      this.msg.showError("Tous les champs sont obligatoires");
    }
  }
}
