import { Injectable } from "@angular/core";

declare let g_apiUrl: string;

@Injectable()
export class AppConfig {
  public API_Host =
    g_apiUrl !== "__API_URL__" ? g_apiUrl : "http://localhost:8080/";
  public RefreshTimer = 2;
  public RefreshMilisecs = 5000;
  public AppTitle = "Ariskan";

  constructor() {}
}
