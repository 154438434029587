import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { UserGroup } from "./user-group.model";
import { ApiResponse } from "../models/api-response.model";

@Injectable({
  providedIn: "root",
})
export class UserGroupService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  getUserGroups(client: number) {
    return this.http
      .get(this.config.API_Host + "structureClients/" + client + "/user-groups")
      .toPromise()
      .then((res) => <ApiResponse>res)
      .catch(this.handleError);
  }

  getUserGroupsByUser(userId: number) {
    return this.http.get(this.config.API_Host + 'user-groups/user/' + userId)
      .toPromise()
      .then(res => <ApiResponse>res)
      .catch(this.handleError);
  }

  createUserGroup(userGroup: UserGroup) {
    return this.http
      .post(this.config.API_Host + "user-groups", userGroup)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .catch(this.handleError);
  }

  updateUserGroup(userGroup: UserGroup): Promise<ApiResponse> {
    return this.http
      .post(
        this.config.API_Host + "user-groups/update/" + userGroup.id,
        userGroup
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .catch(this.handleError);
  }

  deleteUserGroup(client: number, userGroup: UserGroup): Promise<ApiResponse> {
    return this.http
      .post(
        this.config.API_Host + "user-groups/delete/" + userGroup.id,
        userGroup
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .catch(this.handleError);
  }

  deleteUserFromGroup(userId: number, groupId: number): Promise<ApiResponse> {
    return this.http
      .delete(
        this.config.API_Host +
          "user-groups/delete/" +
          groupId +
          "/user/" +
          userId
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error);
    return Promise.reject(error.message || error);
  }
}
