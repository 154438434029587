import { Component, Input, OnInit } from '@angular/core';
import { UserClientsService } from "../user-clients/user-clients.service";
import { Client } from "../client/client.model";

@Component({
  selector: 'app-management-scenario-proposals',
  templateUrl: './management-scenario-proposals.component.html',
  styleUrls: ['./management-scenario-proposals.component.css']
})
export class ManagementScenarioProposalsComponent implements OnInit {

  @Input() id_client;
  currentUser: any;
  isManager: boolean = false;

  constructor(
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("user")).data;
    this.isManager = JSON.parse(localStorage.getItem("user")).data.is_manager;
  }
}
