<p-table
  csvSeparator=";"
  #dt
  [columns]="cols"
  [value]="columns"
  [paginator]="true"
  [rows]="5"
  [scrollHeight]="true"
  [responsive]="true"
>
  <ng-template pTemplate="caption">
    <div class="ui-helper-clearfix">
      <button
        type="button"
        *ngIf="userRightsService.getUserCreate() == 1"
        pButton
        pTooltip="Ajouter"
        tooltipPosition="top"
        icon="fa fa-plus"
        (click)="addLigne()"
        style="float: left"
      ></button>
      <button
        type="button"
        *ngIf="userRightsService.getUserDelete() == 1"
        pButton
        pTooltip="Supprimer toutes les lignes"
        tooltipPosition="top"
        iconPos="left"
        icon="fa fa-trash"
        style="float: left"
        class="p-button-danger"
        (click)="deleteAllLines()"
      ></button>
      <button
        type="button"
        *ngIf="userRightsService.getUserCreate() == 1"
        pButton
        pTooltip="Importer"
        tooltipPosition="top"
        icon="fa fa-download"
        iconPos="left"
        class="p-button-info"
        (click)="openImportDialog()"
        style="float: left"
      ></button>
      <button
        [disabled]="columns.length == 0"
        pTooltip="Exporter"
        *ngIf="userRightsService.getUserRead() == 1"
        tooltipPosition="top"
        type="button"
        pButton
        icon="fa fa-upload"
        class="p-button-info"
        iconPos="left"
        (click)="exportCsv()"
        style="float: left"
      ></button>
    </div>
    <div style="text-align: right">
      <i class="fa fa-search" style="margin: 4px 4px 0 0"></i>
      <input
        type="text"
        pInputText
        size="50"
        placeholder="Global Filter"
        (input)="dt.filterGlobal($event.target.value, 'contains')"
        style="width: auto"
      />
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3em !important">N:</th>
      <th width="100%" *ngFor="let col of columns">
        {{ col.header }}
        <p-sortIcon
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        ></p-sortIcon>
      </th>
      <th style="width: 3em !important">X</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              *ngIf="userRightsService.getUserUpdate() == 1"
              #changeOrder
              pInputText
              type="text"
              [(ngModel)]="rowData.order_num"
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'order_num',
                  value: changeOrder.value
                })
              "
              (change)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'order_num',
                  value: changeOrder.value
                })
              "
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.order_num }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              *ngIf="userRightsService.getUserUpdate() == 1"
              #changedName
              pInputText
              type="text"
              [(ngModel)]="rowData.name"
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'name',
                  value: changedName.value
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'name',
                  value: changedName.value
                })
              "
              (change)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'name',
                  value: changedName.value
                })
              "
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.name }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              *ngIf="userRightsService.getUserUpdate() == 1"
              #changedDescription
              pInputText
              type="text"
              [(ngModel)]="rowData.description"
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'description',
                  value: changedDescription.value
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'description',
                  value: changedDescription.value
                })
              "
              (change)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'description',
                  value: changedDescription.value
                })
              "
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.description }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              *ngIf="userRightsService.getUserUpdate() == 1"
              #changedGroupName
              pInputText
              type="text"
              [(ngModel)]="rowData.group_name"
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'group_name',
                  value: changedGroupName.value
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'group_name',
                  value: changedGroupName.value
                })
              "
              (change)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'group_name',
                  value: changedGroupName.value
                })
              "
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.group_name }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-colorPicker
              *ngIf="userRightsService.getUserUpdate() == 1"
              id="color"
              [(ngModel)]="rowData.group_color"
            ></p-colorPicker>
            <button
              type="button"
              style="width: 1.5em"
              class="p-button-info"
              pButton
              icon="fa fa-check"
              (click)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'group_color',
                  value: rowData.group_color
                })
              "
            ></button>
            <button
              type="button"
              style="width: 1.5em"
              class="p-button-danger"
              pButton
              icon="fa fa-trash"
              (click)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'group_color',
                  value: ''
                })
              "
            ></button>
          </ng-template>
          <ng-template pTemplate="output">
            <span [ngStyle]="{ 'background-color': rowData.group_color }">{{
              rowData.group_color
            }}</span>
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              *ngIf="userRightsService.getUserUpdate() == 1"
              appendTo="body"
              [filter]="true"
              #selectedObjectType
              [options]="typeObject"
              [(ngModel)]="rowData.type_object"
              [style]="{ width: '100%' }"
              (onChange)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'type_object',
                  value: selectedObjectType.selectedOption.label
                })
              "
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'type_object',
                  value: selectedObjectType.selectedOption.label
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'type_object',
                  value: selectedObjectType.selectedOption.label
                })
              "
            >
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.type_object }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td
        *ngIf="
          rowData.type_object != 'Risk' &&
          rowData.type_object != 'List' &&
          rowData.type_object != 'Formule'
        "
      ></td>
      <td
        pEditableColumn
        (click)="stopRefresh()"
        *ngIf="rowData.type_object == 'Risk'"
      >
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              *ngIf="userRightsService.getUserUpdate() == 1"
              appendTo="body"
              [filter]="true"
              #selectedTypeListRisk
              [options]="listRisks"
              [(ngModel)]="rowData.id_subobject"
              [style]="{ width: '100%' }"
              (onChange)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'id_subobject',
                  value: selectedTypeListRisk.selectedOption.value
                })
              "
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'id_subobject',
                  value: selectedTypeListRisk.selectedOption.value
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'id_subobject',
                  value: selectedTypeListRisk.selectedOption.value
                })
              "
            >
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ loadSelectedValue(listRisks, rowData.id_subobject).label }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td
        pEditableColumn
        (click)="stopRefresh()"
        *ngIf="rowData.type_object == 'List'"
      >
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              *ngIf="userRightsService.getUserUpdate() == 1"
              appendTo="body"
              [filter]="true"
              #selectedTypeListList
              [options]="listLists"
              [(ngModel)]="rowData.id_subobject"
              [style]="{ width: '100%' }"
              (onChange)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'id_subobject',
                  value: selectedTypeListList.selectedOption.value
                })
              "
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'id_subobject',
                  value: selectedTypeListList.selectedOption.value
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'id_subobject',
                  value: selectedTypeListList.selectedOption.value
                })
              "
            >
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ loadSelectedValue(listLists, rowData.id_subobject).label }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td
        pEditableColumn
        (click)="stopRefresh()"
        *ngIf="rowData.type_object == 'Formule'"
      >
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              *ngIf="userRightsService.getUserUpdate() == 1"
              appendTo="body"
              [filter]="true"
              #selectedTypeListFormule
              [options]="listFormulas"
              [(ngModel)]="rowData.id_subobject"
              [style]="{ width: '100%' }"
              (onChange)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'id_subobject',
                  value: selectedTypeListFormule.selectedOption.value
                })
              "
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'id_subobject',
                  value: selectedTypeListFormule.selectedOption.value
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'id_subobject',
                  value: selectedTypeListFormule.selectedOption.value
                })
              "
            >
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ loadSelectedValue(listFormulas, rowData.id_subobject).label }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor
          *ngIf="
            rowData.type_object == 'Risk' ||
            rowData.type_object == 'Probabilité' ||
            rowData.type_object == 'Formule' ||
            rowData.type_object == 'Nombre'
          "
        >
          <ng-template pTemplate="input">
            <p-dropdown
              *ngIf="userRightsService.getUserUpdate() == 1"
              appendTo="body"
              [filter]="true"
              #selectedColorRange
              [options]="colorRanges"
              [(ngModel)]="rowData.color_range"
              [style]="{ width: '100%' }"
              (onChange)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'color_range',
                  value: selectedColorRange.selectedOption.label
                })
              "
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'color_range',
                  value: selectedColorRange.selectedOption.label
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'color_range',
                  value: selectedColorRange.selectedOption.label
                })
              "
            >
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.color_range }}
          </ng-template>
        </p-cellEditor>
        <p-cellEditor *ngIf="rowData.type_object == 'Date'">
          <ng-template pTemplate="input">
            <p-dropdown
              *ngIf="userRightsService.getUserUpdate() == 1"
              appendTo="body"
              [filter]="true"
              #selectedColorRange
              [options]="dateColors"
              [(ngModel)]="rowData.color_range"
              [style]="{ width: '100%' }"
              (onChange)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'color_range',
                  value: selectedColorRange.selectedOption.label
                })
              "
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'color_range',
                  value: selectedColorRange.selectedOption.label
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'color_range',
                  value: selectedColorRange.selectedOption.label
                })
              "
            >
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.color_range }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              *ngIf="userRightsService.getUserUpdate() == 1"
              #changedPixel
              pInputText
              type="text"
              [(ngModel)]="rowData.pixel"
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'pixel',
                  value: changedPixel.value
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'pixel',
                  value: changedPixel.value
                })
              "
              (change)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'pixel',
                  value: changedPixel.value
                })
              "
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.pixel }}
          </ng-template>
        </p-cellEditor>
      </td>

      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-multiSelect *ngIf="userRightsService.getUserUpdate() == 1" appendTo="body" [filter]="true" #selectedAuthorizedGroups
                           [options]="listGroups" [(ngModel)]="rowData.authorized_groups_array" [style]="{'width':'100%', 'margin-left': '0'}"
                           (onChange)="changeValue({idLigne: rowData.id, name: 'authorized_groups', value: selectedAuthorizedGroups.value})"
            ></p-multiSelect>
          </ng-template>
          <ng-template pTemplate="output" *ngIf="rowData.authorized_groups_label">
            {{ rowData.authorized_groups_label | slice:0:20 }}{{ rowData.authorized_groups_label.length > 20 ? '...' : '' }}
          </ng-template>
        </p-cellEditor>
      </td>

      <td pEditableColumn (click)="stopRefresh()">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-checkbox
              *ngIf="userRightsService.getUserUpdate() == 1"
              #changedForm
              pInputText
              type="text"
              [(ngModel)]="rowData.form"
              [binary]="true"
              (keydown.enter)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'form',
                  value: changedForm.checked
                })
              "
              (keydown.tab)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'form',
                  value: changedForm.checked
                })
              "
              (onChange)="
                changeValue({
                  idLigne: rowData.id,
                  name: 'form',
                  value: changedForm.checked
                })
              "
            ></p-checkbox>
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.form ? "Oui" : "Non" }}
          </ng-template>
        </p-cellEditor>
      </td>

      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              *ngIf="userRightsService.getUserUpdate() == 1"
              #changedDescription
              pInputText
              type="text"
              [(ngModel)]="rowData.dtmodif"
              disabled
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.dtmodif }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <button
          pButton
          type="button"
          *ngIf="userRightsService.getUserDelete() == 1"
          class="p-button-rounded p-button-danger"
          icon="fa fa-close"
          (click)="deleteLine(rowData.id)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary" let-rowData>
    <div style="text-align: left">
      <button
        type="button"
        *ngIf="userRightsService.getUserCreate() == 1"
        pButton
        icon="fa fa-plus"
        (click)="addLigne()"
        label="Ajout"
      ></button>
    </div>
  </ng-template>
</p-table>
<p-dialog
  styleClass="import"
  appendTo="body"
  header="Importation de données"
  [(visible)]="displayDialogImport"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
>
  <h3 class="first">Importation analyses</h3>
  <h5>Format de fichier:</h5>
  <p>
    Nom de la colonne ; Description ; Nom du groupe de la colonne ; Couleur
    entête ; Type d'objet ; Type d'objet liste ; Gamme de couleur; Pixel;
    Formulaire (true, false)/(1, 0)
  </p>
  <p>Séparateur standard: ";"</p>
  <p-fileUpload
    name="file"
    url="{{ config.API_Host }}import/{{
      id_client ? id_client : 0
    }}/bo_column_objects/{{ id_analyse ? id_analyse : 0 }}"
    (onUpload)="importData($event)"
    accept=".csv"
    maxFileSize="1000000"
  >
    <ng-template pTemplate="content">
      <ul *ngIf="importFiles.length">
        <li *ngFor="let file of importFiles">
          {{ file.name }} - {{ file.size }} bytes
        </li>
      </ul>
    </ng-template>
  </p-fileUpload>
</p-dialog>
<p-progressSpinner *ngIf="!columns || loadingScreen"></p-progressSpinner>
