import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UserClientsService } from "../user-clients/user-clients.service";
import { DataService } from "../services/data.service";

@Component({
  selector: "app-data-table-container",
  templateUrl: "./data-table-container.component.html",
  styleUrls: ["./data-table-container.component.css"],
})
export class DataTableContainerComponent implements OnInit {
  clients: any[];
  selectedClient: any;
  index: any;
  activatedComp = "Processus";

  @Output() messageEvent = new EventEmitter<string>();

  constructor(
    private userClientService: UserClientsService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem("user")).data;
    this.dataService.changeMessage("Processus");
    this.userClientService.getByUserId(user.id).then((res) => {
      this.clients = res.data;
      if (localStorage.getItem("client")) {
        this.selectedClient = JSON.parse(localStorage.getItem("client"));
      } else {
        this.selectedClient = this.clients ? this.clients[0] : 0;
      }
    });
  }

  changeClientCache() {
    localStorage.setItem("client", JSON.stringify(this.selectedClient));
  }

  changeObject(value) {
    this.index = value.index;
    let activatedComp = "Processus";
    switch (this.index) {
      case 0:
        activatedComp = "Processus";
        this.activatedComp = "Processus";
        break;
      case 1:
        activatedComp = "Carto";
        this.activatedComp = "Carto";
        break;
      case 2:
        activatedComp = "Thémes";
        this.activatedComp = "Thémes";
        break;
      case 3:
        activatedComp = "Sous-Thémes";
        this.activatedComp = "Sous-Thémes";
        break;
      case 4:
        activatedComp = "Risk";
        this.activatedComp = "Risk";
        break;
      case 5:
        activatedComp = "Probabilité";
        this.activatedComp = "Probabilité";
        break;
      case 6:
        activatedComp = "Listes";
        this.activatedComp = "Listes";
        break;
      case 7:
        activatedComp = "Formules";
        this.activatedComp = "Formules";
        break;
      case 8:
        activatedComp = "Couleurs";
        this.activatedComp = "Couleurs";
        break;
      case 9:
        activatedComp = "Actions";
        this.activatedComp = "Actions";
        break;
      case 10:
        activatedComp = "Persons";
        this.activatedComp = "Persons";
        break;
      case 11:
        activatedComp = "UserGroup";
        this.activatedComp = "UserGroup";
        break;
      // case 9:
      //   activatedComp = "MappingValidation";
      //   this.activatedComp = "MappingValidation";
      //   break;
      default:
        activatedComp = "Processus";
        this.activatedComp = "Processus";
        break;
    }
    this.dataService.changeMessage(activatedComp);
  }
}
