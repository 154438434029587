import { Injectable } from "@angular/core";

@Injectable()
export class OperatorTypes {
  public types: any;

  constructor() {
    this.types = [
      {
        label: "+",
        value: "plus",
      },
      {
        label: "-",
        value: "minus",
      },
      {
        label: "/",
        value: "division",
      },
      {
        label: "*",
        value: "multiplication",
      },
    ];
  }
}
