import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { Proc } from "./proc.model";
import { BehaviorSubject, interval, Subject } from "rxjs";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { UserBlockService } from "../services/user-block.service";
import { AppConfig } from "../config";
import { Log } from "../models/log.model";
import { ProcService } from "./proc.service";
import { takeUntil } from "rxjs/operators";
import { ConfirmationService } from "primeng/api";
import { DataService } from "../services/data.service";
import { UserRightsService } from "../services/user-rights.service";

@Component({
  selector: "app-proc",
  templateUrl: "./proc.component.html",
  styleUrls: ["./proc.component.css"],
})
export class ProcComponent implements OnInit, OnChanges {
  displayDialog: boolean;
  displayDialogImport: boolean;
  proc: Proc = {} as Proc;
  selectedProc: Proc;
  newProc: boolean;
  procs: Proc[];
  cols: any[];
  user_block: any = 0;
  lastIDProc: number;
  currentProc: any;
  currentUser: any;
  timeoutCounter = 0;
  importFiles: any[] = [];
  showDeleteColumn = false;

  private onDestroy$ = new Subject();

  @Input("clients") clients: any;

  private messageSource = new BehaviorSubject("");
  activatedComp: string;

  constructor(
    private procService: ProcService,
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    private confirmationService: ConfirmationService,
    private userRightsService: UserRightsService,
    private data: DataService,
    public config: AppConfig
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.clients;
    if (currentItem) {
      this.loadData();
    }
  }

  ngOnInit() {
    this.data.currentMessage.subscribe(
      (message) => (this.activatedComp = message)
    );
    if (
      (this.clients || this.clients == 0) &&
      this.activatedComp == "Processus"
    ) {
      this.loadData();
      this.currentUser = JSON.parse(localStorage.getItem("user")).data.email;
      this.cols = [
        { field: "name", header: "Libellé: " },
        { field: "description", header: "Commentaires/Précisions: " },
        { field: "dtcreate", header: "Création: " },
        { field: "dtmodif", header: "Modification: " },
      ];
      interval(this.config.RefreshMilisecs)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((x) => {
          if (this.timeoutCounter < this.config.RefreshTimer && this.clients) {
            this.loadData();
            this.timeoutCounter++;
          }
        });
    }
  }
  @HostListener("document:mousemove")
  onMouseMove() {
    this.timeoutCounter = 0;
  }

  showDialogToAdd() {
    if (this.proc != null && this.proc.id != null && !this.proc) {
      this.closeProcs();
    }
    this.newProc = true;
    this.proc = {} as Proc;
    this.displayDialog = true;
  }
  save() {
    const procs = this.procs ? [...this.procs] : ([] as Proc[]);
    this.proc.id_client = this.clients;
    const jsonproc = JSON.stringify(this.proc);
    if (this.proc.name && this.proc.description) {
      if (this.newProc) {
        procs.push(this.proc);
        this.procService.createProc(this.proc).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Risque créé!");
            this.trace.createTrace({
              action: "CREATE RISK",
              data: jsonproc,
              description: "CREATION RISQUE",
            } as Log);
          } else {
            this.msgs.showError(res.message);
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "CREATE RISK",
              data: jsonmsg,
              description: "ERREUR CREATION RISQUE",
            } as Log);
          }
        });
      } else {
        procs[this.procs.indexOf(this.selectedProc)] = this.proc;
        this.procService.updateProc(this.proc).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Risque mise à jour!");
            this.trace.createTrace({
              action: "UPDATE RISK",
              data: JSON.stringify(procs),
              description: "Mise a jour risques",
            } as Log);
          } else {
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              action: "UPDATE RISK",
              data: jsonmsg,
              description: "ERREUR MAJ RISQUES",
            } as Log);
          }
          this.userBlock
            .unblockUser("bo_proc", this.proc ? this.proc.id : this.lastIDProc)
            .then((data) => {
              console.log("Unblock users");
            });
        });
      }
    } else {
      this.msgs.showError("Merci de remplir tous les champs!");
    }
    this.lastIDProc = this.proc.id;
    this.procs = procs;
    this.proc = null;
    this.displayDialog = false;
  }
  delete() {
    this.procService.deleteProc(this.proc.id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Procédure supprimé!");
        this.trace.createTrace({
          action: "DELETE PROC",
          data: JSON.stringify(this.proc),
          description: "SUPPRESSION PROC",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.userBlock
      .unblockUser("bo_proc", this.proc.id ? this.proc.id : this.lastIDProc)
      .then((data) => {
        console.log("Unblock users");
      });
    this.lastIDProc = this.proc.id;
    const index = this.procs.indexOf(this.selectedProc);
    this.procs = this.procs.filter((val, i) => i !== index);
    this.proc = null;
    this.displayDialog = false;
  }

  deleteLine(id) {
    this.procService.deleteProc(id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Procédure supprimé!");
        this.trace.createTrace({
          action: "DELETE PROC",
          data: JSON.stringify(this.proc),
          description: "SUPPRESSION PROC",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
  }

  onRowSelect(event) {
    this.user_block = 0;
    this.newProc = false;
    this.proc = this.cloneProc(event.data);
    this.displayDialog = true;
    this.userBlock.verifUserBlock("bo_proc", this.proc.id).then((userblock) => {
      if (userblock.data != "" && userblock.data != 0) {
        this.user_block = userblock.data;
        alert("Data en cours d'utilisation par : " + this.user_block);
      } else {
        this.userBlock.blockUser("bo_proc", this.proc.id);
        this.lastIDProc = this.proc.id;
      }
    });
  }
  cloneProc(r: Proc): Proc {
    const proc = {} as Proc;
    for (const prop in r) {
      proc[prop] = r[prop];
    }
    return proc;
  }
  @HostListener("window:beforeunload")
  closeProcs() {
    if (
      (this.user_block == this.currentUser || this.user_block == 0) &&
      !this.newProc &&
      this.proc != null
    ) {
      this.userBlock
        .unblockUser("bo_proc", this.proc ? this.proc.id : this.lastIDProc)
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
  }

  async loadData() {
    this.procs = await this.procService.getProcs(this.clients).then((data) => {
      return data.data ? data.data : [];
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeProcs();
  }

  openImportDialog() {
    this.displayDialogImport = true;
  }

  importData(event) {
    this.loadData();
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }

  deleteAllLines() {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer toutes les lignes?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.procService.deleteAll(this.clients).then((res) => {
          this.loadData();
          this.msgs.showSuccess("Ligne supprimée!");
        });
      },
    });
  }
}
