import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { Person } from "./person.model";

@Injectable({
  providedIn: "root",
})
export class PersonService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  getPersons(client: any) {
    return this.http
      .get(this.config.API_Host + "persons/get/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  createPerson(person: Person) {
    return this.http
      .post(this.config.API_Host + "persons/create", JSON.stringify({ person }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  updatePerson(person: Person) {
    return this.http
      .post(this.config.API_Host + "persons/update", JSON.stringify({ person }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deletePerson(id: number) {
    return this.http
      .post(this.config.API_Host + "persons/delete", JSON.stringify({ id: id }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteAll(id: number) {
    return this.http
      .post(
        this.config.API_Host + "persons/deleteAll",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
