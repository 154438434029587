/* tslint:disable:max-line-length */
import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AnalyseLine} from '../models/analyseline.model';
import {AnalyseLineService} from '../services/analyse-line.service';
import {User} from '../users/user.model';
import {ObjectTypes} from '../models/object_types';
import {ColorRangeService} from '../color-range/color-range.service';
import {Column} from '../columns/column.model';
import {ColorRange} from '../color-range/colorRange.model';
import {ConfirmationService} from 'primeng/api';
import {MsgService} from '../services/msg.service';
import {ColumnsService} from '../columns/columns.service';
import saveAs from 'file-saver';
import {AppConfig} from '../config';
import {ListService} from '../list-object/list.service';
import {RiskService} from '../risk/risk.service';
import {FormulaService} from '../formula-object/formula.service';
import {any} from 'codelyzer/util/function';
import {WorkspaceDetailsService} from './workspace-details.service';
import {UserRightsService} from '../services/user-rights.service';
import {AnalyseService} from '../analyse/analyse.service';
import {Analyse} from '../analyse/analyse.model';
import {PlanService} from '../plan/plan.service';
import {StatusPlan} from '../plan/plan.model';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import {ActionType} from '../action/actiontype.model';
import {ActionService} from '../action/action.service';

@Component({
  selector: 'app-workspace-details',
  templateUrl: './workspace-details.component.html',
  styleUrls: ['./workspace-details.component.css'],
  styles: [`

    :host ::ng-deep .p-datatable .p-datatable-thead .groupCol > th {
      position: -webkit-sticky !important;
      position: sticky !important;
      top: 113px !important;
      font-size: 13px !important;
      z-index: 1000;
    }


    :host ::ng-deep table {
      margin-bottom: 30px !important;
    }

    :host ::ng-deep .p-datatable .p-datatable-thead .col > th {
      position: -webkit-sticky !important;
      position: sticky !important;
      top: 140px !important;
      font-size: 13px !important;
      z-index: 1000;
    }

    :host ::ng-deep .p-datatable .p-datatable-thead .colHidden > th {
      position: -webkit-sticky !important;
      position: sticky !important;
      top: 170px !important;
      font-size: 13px !important;
      z-index: 1000;
    }


    @media screen and (max-width: 64em) {
      :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
        top: 100px;
      }
    }

    :host ::ng-deep .p-datatable-header {
      font-size: 14px !important;
      line-height: 1em;
      position: -webkit-sticky !important;
      position: fixed !important;
      width: 100%;
      top: 75px !important;
      padding: 6px !important;
      z-index: 1000;
    }

    :host ::ng-deep #float-input {
      padding: 4px;
      margin-left: 1em;
    }


    :host ::ng-deep .p-paginator-bottom {
      font-size: 14px !important;
      line-height: 1em;
      position: -webkit-sticky !important;
      position: fixed !important;
      width: 100%;
      margin-top: 20px;
      bottom: 0px !important;
      z-index: 2000;
    }

    :host ::ng-deep .highlight {
      background-color: yellow;
    }

    :host ::ng-deep .p-calendar {
      width: 100% !important;
    }

    :host ::ng-deep .p-datepicker {
      width: 250px !important;
    }

  `]
})
export class WorkspaceDetailsComponent implements OnInit, OnDestroy {

  id = 0;
  id_client = 0;
  columns: Column[];
  analyseLines: AnalyseLine[] = [] as AnalyseLine[];
  filteredAnalyseLines: AnalyseLine[] = [] as AnalyseLine[];
  cols: any[];
  colGroups: any[];
  allColGroups: any[] = [];
  dateObject: Date = new Date();
  newValsObjects: any;
  selectedColumns: any[];
  selectedColumnsGroup: any[];
  selectedFormula: any;
  selectedList: any;
  listObjects: any[] = [];
  listObjectsForm: any[] = [];

  riskObjectsForm: any[] = [];

  riskObjects: any[] = [];
  colorRanges: ColorRange[];
  currentUser: User;
  showDeleteColumn = false;
  showModifyColumn = false;
  lineStatuses = [];
  displayDialogImport = false;
  importFiles: any[] = [];
  reader: FileReader = new FileReader();
  today: Date = new Date();
  listLists: any[];
  listRisks: any[];
  listFormulas: any[];
  nameAnalyse: string;
  idAnalyse: number;
  colFilters = false;
  loading = false;
  totalRecords: number;
  showFormDialog = false;
  showPlanDialog = false;
  showExportActionDialog = false;
  selectedActionTypeList: ActionType[];
  actionTypes: ActionType[] = [] as ActionType[];
  id_line_plan: number;
  analysePlanCals: any[];
  listUniqueActionsCheckbox = false;
  disabledEditingPlan: boolean;

  dataForForm: any;
  analyse: Analyse;

  private sub: any;
  @ViewChild('wddt') table;
  first = 0;
  proposeScenarioDialogVisible: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private columnsService: ColumnsService,
    private analyseLinesService: AnalyseLineService,
    private analyseService: AnalyseService,
    private colorRangeService: ColorRangeService,
    private confirmationService: ConfirmationService,
    private workspaceService: WorkspaceDetailsService,
    private listService: ListService,
    private riskService: RiskService,
    private formulaService: FormulaService,
    public userRightsService: UserRightsService,
    private actionService: ActionService,
    private planService: PlanService,
    private msgs: MsgService,
    public config: AppConfig,
    private objectTypes: ObjectTypes
  ) {
  }

  async ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('user')).data;
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.id_client = params['id_client'];


      this.loadData().then(() => {
        this.actionService.getActionTypes(this.id_client).then(data => {
          this.actionTypes = data.data ? data.data : [];
        });
        this.analyseService.getById(this.id).then(dt => {
          this.analyse = dt.data[0];
        });
        this.listService.getListForList(this.id_client).then(res => {
          this.listLists = res.data;
          this.listLists.unshift(
            {label: 'Aucune', name: 'none'}
          );
          this.makeNewLineValues();
        });

        this.riskService.getRiskForList(this.id_client).then(res => {
          this.listRisks = res.data;
          this.listRisks.unshift(
            {label: 'Aucune', name: 'none'}
          );
          this.makeNewLineValues();
        });

        this.formulaService.getFormulaForList(this.id_client, this.id).then(res => {
          this.listFormulas = res.data;
          this.listFormulas.unshift(
            {label: 'Aucune', name: 'none'}
          );
          this.makeNewLineValues();
        });
      }).catch((err: ExceptionInformation) => {
        this.msgs.showError('Chargement de données en erreur! Veuillez contacter l\'administrateur !');
        this.loading = false;
      });
      this.colorRangeService.getColorRanges(this.id_client).then(res => {
        this.colorRanges = res.data ? res.data : [] as ColorRange[];
      });

      this.workspaceService.getNameAnalyse(this.id).then(res => {
        this.nameAnalyse = res.data.name;
        this.idAnalyse = res.data.id;
      });
    });


  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async loadData() {
    this.loading = true;
    this.columnsService.get(this.id_client, this.id).then(columns => {
      if (columns.data != null) {
        this.columns = columns.data;

        this.loadCols();
        this.analyseLinesService.get(this.id).then(analyselines => {
          this.analyseLines = analyselines.data ? analyselines.data : [] as AnalyseLine[];
          this.totalRecords = this.analyseLines.length;
          this.filteredAnalyseLines = this.analyseLines;
          this.dataForForm = this.filteredAnalyseLines[this.filteredAnalyseLines.length - 1];
          this.checkIfColumnsAreNotSet();
          this.createLineStatuses();
          this.reorderCols(false);
          this.recalculateAllFormulas();
          this.loading = false;
          this.planService.getCalc(this.id).then(planCalc => {
            this.analysePlanCals = planCalc.data ? planCalc.data : [];
          });
        });
      } else {
        this.loading = false;
      }

    });

  }

  findLinePlanCalc(id_line: number) {
    if (this.analysePlanCals) {
      const res = this.analysePlanCals.find(i => i.id_analyse_line === id_line);
      return res ? Math.round(res.calc * 100) / 100 : 0;
    }
    return 0;
  }

  formuleToShow(rowData, id_column) {
    const object = this.findValueInArray(rowData.values, 'id_column', id_column);
    const form = this.listFormulas ? this.listFormulas.find(i => object.selectedValueList.value === i.value) : null;
    const plan = this.findLinePlanCalc(rowData.id);
    return form ? (form.action_done == 0 || (form.action_done == 1 && plan == 100)) : false;
  }


  findCountPlanActions(id_line: number) {
    if (this.analysePlanCals) {
      const res = this.analysePlanCals.find(i => i.id_analyse_line === id_line);
      return res ? res.countActions : 0;
    }
    return 0;
  }


  createLineStatuses() {
    this.lineStatuses = this.analyseLines.map(i => {
      return {id: i.id, modify: false};
    });
  }

  changeLocalStorageSelectedColumns() {
    localStorage.setItem('workspace_selectedColumns_' + this.id + '_' + this.id_client, JSON.stringify(this.selectedColumns));
    localStorage.setItem('workspace_selectedColumnsGroups_' + this.id + '_' + this.id_client, JSON.stringify(this.selectedColumnsGroup));
  }

  loadCols() {
    const cols: any[] = [];
    this.loadColGroups();
    for (const prop in this.columns) {
      cols.push({
        field: this.id,
        id_column: this.columns[prop].id,
        order: this.columns[prop].order_num,
        color_range: this.columns[prop].color_range,
        header: this.columns[prop].name,
        group_name: this.columns[prop].group_name,
        group_color: this.columns[prop].group_color,
        id_subobject: this.columns[prop].id_subobject,
        authorized_groups: this.columns[prop].authorized_groups,
        form: this.columns[prop].form,
        pixel: this.columns[prop].pixel,
        type_object: this.columns[prop].type_object
      });
    }
    this.cols = cols;

    this.selectedColumns = this.cols;
    // const selectedCols = JSON.parse(localStorage.getItem('workspace_selectedColumns_' + this.id + '_' + this.id_client));
    // if (selectedCols && selectedCols.length > 0) {
    //   this.selectedColumns = JSON.parse(localStorage.getItem('workspace_selectedColumns_' + this.id + '_' + this.id_client));
    // } else {
    //   this.selectedColumns = this.cols;
    //   localStorage.setItem('workspace_selectedColumns_' + this.id + '_' + this.id_client, JSON.stringify(this.selectedColumns));
    // }

  }

  loadColGroupsOptions() {
    const colGroups = this.cols ? this.cols.map(i => {
      return i.group_name;
    }) : [];
    return [...Array.from(new Set(colGroups))].map(i => ({header: i}));
  }

  loadColGroups() {
    const colGroups: any[] = [];
    const allColGroups: any[] = [];
    for (const prop in this.columns) {
      const existingCol = allColGroups.find(i => i.group_name === this.columns[prop].group_name);

      if (existingCol) {
        existingCol.colspan += 1;
        existingCol.group_color = existingCol.group_color ? existingCol.group_color : this.columns[prop].group_color;
      } else {
        allColGroups.push({
          header: this.columns[prop].group_name,
          group_name: this.columns[prop].group_name,
          colspan: this.columns[prop].group_name ? 1 : 0,
          group_color: this.columns[prop].group_color
        });
      }
    }
    this.allColGroups = allColGroups;

    for (const prop in this.selectedColumns ? this.selectedColumns : this.columns) {
      const existingCol = colGroups.find(i => i['group_name'] === (this.selectedColumns ? this.selectedColumns : this.columns)[prop].group_name);
      if (existingCol) {
        existingCol.colspan += 1;
        existingCol.group_color = existingCol.group_color ? existingCol.group_color : (this.selectedColumns ? this.selectedColumns : this.columns)[prop].group_color;
      } else {
        colGroups.push({
          header: (this.selectedColumns ? this.selectedColumns : this.columns)[prop].group_name,
          group_name: (this.selectedColumns ? this.selectedColumns : this.columns)[prop].group_name,
          colspan: (this.selectedColumns ? this.selectedColumns : this.columns)[prop].group_name ? 1 : 0,
          group_color: (this.selectedColumns ? this.selectedColumns : this.columns)[prop].group_color
        });
      }


    }
    this.colGroups = colGroups;
    const selectedColGroup = JSON.parse(localStorage.getItem('workspace_selectedColumnsGroups_' + this.id + '_' + this.id_client));
    if (selectedColGroup && selectedColGroup.length > 0) {
      this.selectedColumnsGroup = JSON.parse(localStorage.getItem('workspace_selectedColumnsGroups_' + this.id + '_' + this.id_client));
    } else {
      this.selectedColumnsGroup = colGroups;
      localStorage.setItem('workspace_selectedColumnsGroups_' + this.id + '_' + this.id_client, JSON.stringify(this.selectedColumnsGroup));
    }
  }

  reorderCols(header) {
    this.selectedColumns = header ? this.cols : this.selectedColumns;

    if (header) {
      this.selectedColumns = this.selectedColumns.filter(i => {
        return this.selectedColumnsGroup.find(z => z.header === i.group_name);
      });
    }

    this.selectedColumns.sort(function (obj1, obj2) {
      // Ascending: first age less than the previous
      return obj1.order - obj2.order;
    });
    this.changeLocalStorageSelectedColumns();
    this.loadColGroups();
  }

  addLine() {
    const options = {
      idLigne: 'undefined',
      name: 'vals',
      value: JSON.stringify(this.newValsObjects),
      id_analyse: this.id
    };
    this.analyseLinesService.addupdate(options).then(res => {
      this.loadData();
    });


  }


  addLineByForm() {
    this.showFormDialog = true;
    const options = {
      idLigne: 'undefined',
      name: 'vals',
      value: JSON.stringify(this.newValsObjects),
      id_analyse: this.id
    };
    this.analyseLinesService.addupdate(options).then(res => {
      this.loadData();
    });

  }

  makeNewLineValues() {
    const vals = [];

    for (const prop in this.columns) {
      const currentCol = this.columns[prop];
      let newSelectedValueList = {name: any, value: any, label: any};
      const template = this.findValueInArray(this.analyseLines.length > 0 ? this.analyseLines[0].values : [], 'id_column', this.columns[prop].id);
      const resultArray = [];

      if (currentCol.id_subobject != 0) {
        if (currentCol.type_object == 'Risk') {
          const currentObject = this.loadSelectedValue(this.listRisks, currentCol.id_subobject);
          newSelectedValueList = {value: currentObject.value, name: currentObject.value, label: currentObject.label};
        }
        if (currentCol.type_object == 'List' || currentCol.type_object == 'Formule') {
          const currentObject = this.loadSelectedValue(this.listLists, currentCol.id_subobject);
          newSelectedValueList.value = currentObject.value;
          newSelectedValueList.name = currentObject.data ? JSON.parse(currentObject.data) : [];
          newSelectedValueList.label = currentObject.label;
          const arr = newSelectedValueList.name;
          for (const i in arr) {
            resultArray.push(
              {
                label: arr[i].replace('"', ''),
                value: arr[i].replace('"', '')
              }
            );
          }
        }

        if (currentCol.type_object == 'Formule') {
          const currentObject = this.loadSelectedValue(this.listFormulas, currentCol.id_subobject);
          newSelectedValueList.value = currentObject.value;
          newSelectedValueList.name = currentObject.data ? JSON.parse(currentObject.data) : [];
          newSelectedValueList.label = currentObject.label;
        }


      }

      vals.push(
        {
          'id_column': currentCol.id,
          'type_object': currentCol.type_object,
          'value_id': currentCol.type_object == 'List' || currentCol.type_object == 'Formule' ? this.loadSelectedValue(this.listLists, currentCol.id_subobject).value : '',
          'value': resultArray.length > 0 ? resultArray : '',
          'selectedValue': '',
          'selectedValueList': currentCol.type_object == 'Formule' || currentCol.type_object == 'Risk' || currentCol.type_object == 'List' ? newSelectedValueList : ''
        }
      );
    }
    this.newValsObjects = vals;
  }

  changeValueList(options) {
    const listObjects = this.findValueInArray(this.listObjects, 'id_column', options.value.id_column);
    this.findValueInArray(
      this.findValueInArray(this.analyseLines, 'id', options.idLigne).values, 'id_column', options.value.id_column
    ).value = {
      id: this.findValueInArray(listObjects.data, 'value', options.value.value_id).label,
      name: this.findValueInArray(listObjects.data, 'value', options.value.value_id).name
    };


    const newOptions = {
      idLigne: options.idLigne,
      name: 'vals',
      value: JSON.stringify(this.findValueInArray(this.analyseLines, 'id', options.idLigne).values),
      id_analyse: this.id
    };
    this.analyseLinesService.addupdate(newOptions).then(res => {
      // this.loadData();
      this.recalculateFormulas(options);
    });
  }

  changeValueListForm(options) {
    const listObjects = this.findValueInArray(this.listObjectsForm, 'id_column', options.value.id_column);
    this.findValueInArray(
      this.findValueInArray(this.analyseLines, 'id', options.idLigne).values, 'id_column', options.value.id_column
    ).value = {
      id: this.findValueInArray(listObjects.data, 'value', options.value.value_id).label,
      name: this.findValueInArray(listObjects.data, 'value', options.value.value_id).name
    };


    const newOptions = {
      idLigne: options.idLigne,
      name: 'vals',
      value: JSON.stringify(this.findValueInArray(this.analyseLines, 'id', options.idLigne).values),
      id_analyse: this.id
    };
    this.analyseLinesService.addupdate(newOptions).then(res => {
      // this.loadData();
      this.recalculateFormulas(options);
    });
  }


  changeValueListList(options) {
    this.findValueInArray(
      this.findValueInArray(this.analyseLines, 'id', options.idLigne).values, 'id_column', options.id_column
    ).selectedValue = options.value;
    const newOptions = {
      idLigne: options.idLigne,
      name: 'vals',
      value: JSON.stringify(this.findValueInArray(this.analyseLines, 'id', options.idLigne).values),
      id_analyse: this.id
    };
    this.analyseLinesService.addupdate(newOptions).then(res => {

      // this.loadData();

    });
  }

  changeValueListRisk(options) {
    const newOptions = {
      idLigne: options.idLigne,
      name: 'vals',
      value: JSON.stringify(this.findValueInArray(this.analyseLines, 'id', options.idLigne).values),
      id_analyse: this.id
    };
    this.analyseLinesService.addupdate(newOptions).then(res => {

      // this.loadData();

    });
  }

  changeValueText(options) {

    if (options.type_object == 'Date') {
      options.value.value = this.dateObject ? this.dateObject.toISOString() : '';
    }

    this.recalculateFormulas(options);
    const newOptions = {
      idLigne: options.idLigne,
      name: 'vals',
      value: JSON.stringify(this.findValueInArray(this.analyseLines, 'id', options.idLigne).values),
      id_analyse: this.id,
      type_object: options.type_object
    };
    this.analyseLinesService.addupdate(newOptions).then(res => {

    });
  }

  makeList(value, id_analyse?, col?) {
    // listObjects
    const table = this.objectTypes.types.find(i => i.label === value.type_object).name;
    const val = this.objectTypes.types.find(i => i.label === value.type_object).value;
    const header = value.header ? value.header : false;
    this.analyseLinesService.getListObject(table, val, this.id_client, header, col.id_subobject).then(res => {
      let listObjects = res.data ? res.data : [];
      if (value.type_object == 'List' ||
        value.type_object == 'Formula' ||
        value.type_object == 'Probabilité' ||
        value.type_object == 'Processus') {

        for (const i in listObjects) {
          const n = listObjects[i].name;
          const l = listObjects[i].label;
          const v = listObjects[i].value;
          listObjects[i].name = l;
          listObjects[i].label = n;
          listObjects[i].value = v;
        }
      }

      if (value.type_object == 'Risk' && header == true) {
        for (const i in listObjects) {
          if (listObjects.find(z => z.name == listObjects[i].name)) {
            const n = listObjects[i].name;
            const l = listObjects[i].label;
            const v = listObjects[i].value;
            listObjects[i].name = l;
            listObjects[i].label = n;
            listObjects[i].value = v;
          }
        }

      }

      if (value.type_object == 'Sous-Theme') {

        const theme = this.findValueInArray(this.findValueInArray(this.analyseLines, 'id', id_analyse).values, 'type_object', 'Theme');
        const newListObjects = [];
        for (const i in listObjects) {
          if (listObjects[i].id_theme == theme.value_id) {
            newListObjects.push(listObjects[i]);
          }
        }
        listObjects = newListObjects;
      }

      if (value.type_object == 'Risk' && header == false) {
        const newListObjects = [];
        for (const i in listObjects) {
          if (listObjects[i].id_risk == value.selectedValueList.value) {
            const n = listObjects[i].name;
            const l = listObjects[i].label;
            const v = listObjects[i].value;
            listObjects[i].name = l;
            listObjects[i].label = n;
            listObjects[i].value = v;
            newListObjects.push(listObjects[i]);
          }
        }
        listObjects = newListObjects;

      }

      const index = this.listObjects.findIndex(i => i.id_column == value.id_column);
      if (index > -1) {
        this.listObjects.splice(index, 1);
      }
      this.listObjects.push({
        id_column: value.id_column,
        type_object: value.type_object,
        data: listObjects
      });
    });
  }

  makeListForm(id) {
    this.analyseLines.find(x => x.id === id).values.forEach(item => {

      // listObjects
      const table = this.objectTypes.types.find(i => i.label === item.type_object).name;
      const val = this.objectTypes.types.find(i => i.label === item.type_object).value;
      const header = item.header ? item.header : false;

      this.analyseLinesService.getListObjectForm(table, val, this.id_client, header).then(res => {
        let listObjects = res.data ? res.data : [];
        if (item.type_object == 'List' ||
          item.type_object == 'Formula' ||
          item.type_object == 'Probabilité' ||
          item.type_object == 'Processus') {

          for (const i in listObjects) {
            const n = listObjects[i].name;
            const l = listObjects[i].label;
            const v = listObjects[i].value;
            listObjects[i].name = l;
            listObjects[i].label = n;
            listObjects[i].value = v;
          }
        }

        if (item.type_object == 'Risk' && header == true) {
          for (const i in listObjects) {
            if (listObjects.find(z => z.name == listObjects[i].name)) {
              const n = listObjects[i].name;
              const l = listObjects[i].label;
              const v = listObjects[i].value;
              listObjects[i].name = l;
              listObjects[i].label = n;
              listObjects[i].value = v;
            }
          }

        }

        if (item.type_object == 'Sous-Theme') {

          const theme = this.findValueInArray(this.findValueInArray(this.analyseLines, 'id', id).values, 'type_object', 'Theme');
          const newListObjects = [];
          for (const i in listObjects) {
            if (listObjects[i].id_theme == theme.value_id) {
              newListObjects.push(listObjects[i]);
            }
          }
          listObjects = newListObjects;
        }

        if (item.type_object == 'Risk' && header == false) {
          const newListObjects = [];
          for (const i in listObjects) {
            if (listObjects[i].id_risk == item.selectedValueList.value) {
              const n = listObjects[i].name;
              const l = listObjects[i].label;
              const v = listObjects[i].value;
              listObjects[i].name = l;
              listObjects[i].label = n;
              listObjects[i].value = v;
              newListObjects.push(listObjects[i]);
            }
          }
          listObjects = newListObjects;

        }


        const index = this.listObjectsForm.findIndex(i => i.id_column == item.id_column);
        if (index > -1) {
          this.listObjects.splice(index, 1);
        }
        this.listObjectsForm.push({
          id_column: item.id_column,
          type_object: item.type_object,
          data: listObjects
        });
      });
    });
  }


  findValueInArray(arr: any[], search, value) {
    let obj;
    if (arr) {
      obj = arr.find(i => i[search] == value);
    }
    if (obj) {
      if (typeof obj.value !== 'undefined') {
        obj.value = typeof obj.value === 'number' ? Math.round(obj.value * 100) / 100 : obj.value;
      }
    }
    return obj ? obj : false;
  }

  findValueInArrayForm(arr: any[], search, value) {

    let obj;
    if (arr) {
      obj = arr.find(i => i[search] == value);
    }
    if (obj) {
      if (typeof obj.value !== 'undefined') {
        obj.value = typeof obj.value === 'number' ? Math.round(obj.value * 100) / 100 : obj.value;
      }
    }
    return obj ? obj : false;
  }

  findValueInArrayFormOptions(arr: any[], search, value) {
    let obj;
    if (arr) {
      obj = arr.find(i => i[search] == value);
    }

    if (obj) {
      if (typeof obj.value !== 'undefined') {
        obj.value = typeof obj.value === 'number' ? Math.round(obj.value * 100) / 100 : obj.value;
      }
    }
    return obj ? obj : false;
  }

  getRangeColor(obj, column) {
    const valueToNum = obj.type_object == 'Nombre' || obj.type_object == 'Formule' ? +obj.value : (obj.value ? obj.value.name : '');
    const colorRange = this.colorRanges ? this.colorRanges.filter(function (v, i) {
      const from = +v.range_from;
      const to = +v.range_to;
      return (valueToNum >= from && valueToNum <= to && v.group_name == column.color_range);
    }) : [];
    return colorRange.length > 0 ? colorRange[0].color : 'none';
  }

  getRangeName(obj, column) {

    const valueToNum = obj.type_object == 'Nombre' || obj.type_object == 'Formule' ? +obj.value : (obj.value ? obj.value.name : '');
    const colorRange = this.colorRanges ? this.colorRanges.filter(function (v, i) {
      const from = +v.range_from;
      const to = +v.range_to;
      return (valueToNum >= from && valueToNum <= to && v.group_name == column.color_range);
    }) : [];
    return colorRange.length > 0 ? colorRange[0].name : 'none';
  }

  checkIfColumnsAreNotSet() {
    for (const i in this.analyseLines) {
      for (const z in this.cols) {

        if (!this.findValueInArray(this.analyseLines[i].values, 'id_column', this.cols[z].id_column)) {

          this.analyseLines[i].values.push(
            {
              'id_column': this.cols[z].id_column,
              'type_object': this.cols[z].type_object,
              'value_id': '',
              'value': '',
              'selectedValue': '',
              'selectedValueList': ''
            }
          );

        }
      }
    }
  }

  recalculateFormulas(options) {
    const lineValues = this.findValueInArray(this.analyseLines, 'id', options.idLigne).values;
    for (const i in lineValues) {
      if (lineValues[i].type_object == 'Formule') {
        let result = 0;
        const listOptions = typeof lineValues[i].selectedValueList.name == 'string' ? JSON.parse(lineValues[i].selectedValueList.name) : lineValues[i].selectedValueList.name;
        for (const y in listOptions) {
          let val = 0;
          const currentObject = this.findValueInArray(lineValues, 'id_column', listOptions[y].selectedValue);
          val = currentObject.type_object == 'Risk' || currentObject.type_object == 'Probabilité' ? +(currentObject.value.name ? currentObject.value.name : 0) : +(currentObject.value ? currentObject.value : 0);
          switch (listOptions[y].selectedOperateur) {
            case 'plus':
              result = result + val;
              break;
            case 'minus':
              result = result - val;
              break;
            case 'division':
              result = result / val;
              break;
            case 'multiplication':
              result = result * val;
              break;
            default:
              result = result + val;
              break;
          }
        }
        this.findValueInArray(this.analyseLines, 'id', options.idLigne).values[i].value = result;
      }
    }

    const newOptions = {
      idLigne: options.idLigne,
      name: 'vals',
      value: JSON.stringify(this.findValueInArray(this.analyseLines, 'id', options.idLigne).values),
      id_analyse: this.id
    };
    this.analyseLinesService.addupdate(newOptions).then(res => {
      // this.loadData();
    });
  }


  recalculateAllFormulas() {
    for (const line in this.analyseLines) {
      let uploadChange = false;
      for (const i in this.analyseLines[line].values) {
        if (this.analyseLines[line].values[i].type_object == 'Formule') {
          this.loading = true;
          let result = 0;
          const listOptions = typeof this.analyseLines[line].values[i].selectedValueList.name == 'string' ? JSON.parse(this.analyseLines[line].values[i].selectedValueList.name) : this.analyseLines[line].values[i].selectedValueList.name;
          for (const y in listOptions) {
            let val = 0;
            const currentObject = this.findValueInArray(this.analyseLines[line].values, 'id_column', listOptions[y].selectedValue);
            val = currentObject.type_object == 'Risk' || currentObject.type_object == 'Probabilité' ? +(currentObject.value.name ? currentObject.value.name : 0) : +(currentObject.value ? currentObject.value : 0);
            switch (listOptions[y].selectedOperateur) {
              case 'plus':
                result = result + val;
                break;
              case 'minus':
                result = result - val;
                break;
              case 'division':
                result = result / val;
                break;
              case 'multiplication':
                result = result * val;
                break;
              default:
                result = result + val;
                break;
            }
          }
          if (this.analyseLines[line].values[i].value != result) {
            uploadChange = true;
            this.analyseLines[line].values[i].value = result;
          }
        }
      }
      if (uploadChange) {
        const newOptions = {
          idLigne: this.analyseLines[line].id,
          name: 'vals',
          value: JSON.stringify(this.findValueInArray(this.analyseLines, 'id', this.analyseLines[line].id).values),
          id_analyse: this.id
        };
        this.analyseLinesService.addupdate(newOptions).then(res => {
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    }


  }

  changeOrder(rowData) {
    this.analyseLinesService.changeOrder(rowData).then(res => {
      this.loadData();
    });
  }

  changeAllValues(options, type_object) {
    this.loading = true;
    for (const i in this.analyseLines) {
      for (const z in this.analyseLines[i].values) {
        if (this.analyseLines[i].values[z].type_object == type_object && this.analyseLines[i].values[z].id_column == options.id_column) {
          this.analyseLines[i].values[z].value_id = options.selectedValue.value;
          this.analyseLines[i].values[z].value = options.selectedValue.name;
          this.analyseLines[i].values[z].selectedValueList = options.selectedValue;

          if (type_object == 'List') {
            const listOptions = JSON.parse(options.selectedValue.name);
            this.analyseLines[i].values[z].value = [];
            this.analyseLines[i].values[z].selectedValue = '';
            for (const y in listOptions) {
              this.analyseLines[i].values[z].value.push(
                {
                  label: listOptions[y],
                  value: listOptions[y]
                }
              );
            }
          }

          if (type_object == 'Risk') {
            this.analyseLines[i].values[z].value = '';
          }


          if (type_object == 'Formule') {
            const listOptions = JSON.parse(options.selectedValue.name);
            let result = 0;
            for (const y in listOptions) {
              let val = 0;
              const currentObject = this.findValueInArray(this.analyseLines[i].values, 'id_column', listOptions[y].selectedValue);
              val = currentObject.type_object == 'Risk' || currentObject.type_object == 'Probabilité' ? +(currentObject.value.name ? currentObject.value.name : 0) : +(currentObject.value ? currentObject.value : 0);
              switch (listOptions[y].selectedOperateur) {
                case 'plus':
                  result = result + val;
                  break;
                case 'minus':
                  result = result - val;
                  break;
                case 'division':
                  result = result / val;
                  break;
                case 'multiplication':
                  result = result * val;
                  break;
                default:
                  result = result + val;
                  break;
              }
            }
            this.analyseLines[i].values[z].value = result;
          }
        }
      }
      const newOptions = {
        idLigne: this.analyseLines[i].id,
        name: 'vals',
        value: JSON.stringify(this.analyseLines[i].values),
        id_analyse: this.id
      };
      this.analyseLinesService.addupdate(newOptions).then(res => {
        this.loadData();
      });
    }
  }

  deleteLine(id) {
    this.confirmationService.confirm({
      message: 'Etes-vous sur de vouloir supprimer cette ligne?',
      header: 'Confirmation suppression',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.analyseLinesService.delete(id).then(res => {
          this.loadData();
          this.msgs.showSuccess('Ligne supprimée!');
        });
      }
    });

  }

  deleteAllLines() {
    const count = this.analyseLines.length;
    if (count > 0) {
      this.confirmationService.confirm({
        message: 'Êtes-vous sûr de vouloir supprimer toutes les lignes?',
        header: 'Confirmation suppression',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.analyseLinesService.deleteAll(this.id).then(res => {
            this.loadData();
            this.msgs.showSuccess(count + ' lignes supprimées!');
          });
        }
      });
    }
  }

  deleteAllLinesDuplicates() {

    const count = this.analyseLines.filter(i => i.duplication == 1).length;

    if (count > 0) {
      this.confirmationService.confirm({
        message: 'Êtes-vous sûr de vouloir supprimer toutes les lignes de simulation?',
        header: 'Confirmation suppression',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.analyseLinesService.deleteAllDuplicates(this.id).then(res => {
            this.loadData();
            this.msgs.showSuccess(count + ' lignes de simulation supprimées!');
          });
        }
      });
    }
  }


  exportCsv() {

    const columnsToExport = this.selectedColumns.filter(i => i.type_object != 'Plan');

    const header = 'ID;' + columnsToExport.map(i => {
        if (this.findValueInArray(this.filteredAnalyseLines.length > 0 ? this.filteredAnalyseLines[0].values : [], 'id_column', i.id_column).type_object == 'Risk') {
          return i.header;
        } else if (this.findValueInArray(this.filteredAnalyseLines.length > 0 ? this.filteredAnalyseLines[0].values : [], 'id_column', i.id_column).type_object == 'List') {
          return i.header;
        } else {
          if (this.findValueInArray(this.filteredAnalyseLines.length > 0 ? this.filteredAnalyseLines[0].values : [], 'id_column', i.id_column).type_object != 'Plan') {
            return i.header;
          }
        }
      }
    ).join(';');
    const data = [];
    this.filteredAnalyseLines
      .filter(i => i.duplication === 0)
      .forEach(analyse => {
        const result = [];
        columnsToExport.forEach(column => {
          const value = this.findValueInArray(analyse.values, 'id_column', column.id_column);
          if (value && typeof value.value !== 'undefined') {
            let exportValue = '';
            if (!value.value) {
              exportValue = '';
            } else if (typeof value.value === 'object') {
              if (value.type_object === 'Risk') {
                exportValue = value.value.id;
              } else if (value.type_object === 'List') {
                exportValue = value.selectedValue;
              } else if (/\n/gi.exec(value.value.id)) {
                exportValue = '"' + value.value.id + '"';
              } else {
                exportValue = value.value.id;
              }
            } else {
              if (value.type_object !== 'Plan') {
                if (/\n/gi.exec(value.value)) {
                  exportValue = '"' + value.value + '"';
                } else {
                  exportValue = value.value;
                }
              }
            }
            result.push(exportValue);
          } else {
            result.push('');
          }
        });
        result.unshift(analyse.order_num);
        data.push(result.join(';'));
    });

    data.unshift(header);
    const csvArray = data.join('\r\n');

    const blob = new Blob(['\ufeff', csvArray], {type: 'text/csv'});
    saveAs(blob, 'export_workspace.csv');
  }

  exportPDFLine(line) {
    let html = '<html>' +
      '<style>' +
      'td {' +
      'border: 1px solid black' +
      '}' +
      '' +
      'table {' +
      'width : 100%;' +
      'text-align: center;' +
      'font-weight: bold;' +
      '}' +
      '</style>' +
      '<table>' +
      '<colgroup span = "1">' +
      '            <col style="width: 100%"></col>' +
      '            </colgroup>' +
      '<tr style="background-color: yellow; width: 100%">' +
      '<td style="width: 100%">Fiche de suivi du risque</td>' +
      '</tr>' +
      '' +
      '</table>' +
      '<table>';
    let count = 0;
    let colspan = 0;
    let first = true;

    this.allColGroups.forEach(i => {
      const isFound = this.colGroups.some(element => {
        return element.header === i.header;
      });
      colspan += i.colspan;
      if (isFound) {
        let htmlToReturn = '<tr><td style="background-color: ' + i.group_color + '" rowspan="' + i.colspan + '">' + i.header + '</td>';
        let currentCount = 0;
        for (let z = count ; z >= count && z < colspan; z++) {
            const isFoundCols = this.selectedColumns.some(element => {
                return element.id_column === this.cols[z].id_column;
            });
            if (isFoundCols) {
              const currentVal = line.values.find(y => y.id_column == this.cols[z].id_column);
              let value = '';
              if (typeof currentVal.value === 'object') {
                value = currentVal.value.id;
                if (currentVal.type_object === 'Risk') {
                  value = currentVal.value.id;
                } else if (currentVal.type_object === 'List') {
                  value = currentVal.selectedValue;
                } else if (/\n/gi.exec(currentVal.value.id)) {
                  value = '"' + currentVal.value.id + '"';
                }
              } else {
                if (currentVal.type_object != 'Plan') {
                  if (/\n/gi.exec(currentVal.value)) {
                    value = '"' + currentVal.value + '"';
                  }
                  value = currentVal.value;
                } else {
                  console.log(currentVal);
                  value = this.findLinePlanCalc(line.id) + '%';
                }
              }

              if (currentCount === 0) {
                htmlToReturn += '<td style="background-color: ' + i.group_color + '"> ' + this.cols[z].header + ' </td>';
                htmlToReturn += '<td> ' + value + ' </td>';
                htmlToReturn += '</tr>';
              } else {
                htmlToReturn += '<tr>';
                htmlToReturn += '<td style="background-color: ' + i.group_color + '"> ' + this.cols[z].header + ' </td>';
                htmlToReturn += '<td> ' + value + ' </td>';
                htmlToReturn += '</tr>';
              }



              currentCount++;
              ++count;
            }
        }

        html += htmlToReturn;
      } else {
        count += i.colspan;
      }
      first = false;
    });
    html += '</table>' +
      '</html>';
    this.downloadAsPDF(html);
  }


  public downloadAsPDF(htmlPur) {
    const doc = new jsPDF();

    const html = htmlToPdfmake(htmlPur);

    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();

  }

  exportCsvPlan() {
    const statusesArray = [
      {label: 'A faire / 0%', value: StatusPlan.TODO, percent: 0},
      {label: 'En cours / 25%', value: StatusPlan.INPROGRES25, percent: 25},
      {label: 'En cours / 50%', value: StatusPlan.INPROGRES50, percent: 50},
      {label: 'En cours / 75%', value: StatusPlan.INPROGRES75, percent: 75},
      {label: 'Fait / 100%', value: StatusPlan.DONE, percent: 100}
    ];
    this.planService.getPlanForExport(this.analyse.id).then(data => {
      let header = 'ID;Nom action;Description action;Responsable;Poste;Commentaire;Progression;Consommation;Budget;Date butoir;Date de création';
      if (this.selectedActionTypeList && this.selectedActionTypeList.length > 0) {
        data.data = data.data.filter(z => z.action_types.some(i => this.selectedActionTypeList.map(x => x.id).includes(i.id)));
      }
      let result = [];
      if (this.listUniqueActionsCheckbox) {
        header = 'Nom action;Description action;Responsable;Poste;Commentaire;Progression;Consommation;Budget;Date butoir';
        result = data.data.map(val => {
          return val.action_name + ';' + val.action_description + ';' + val.responsible_name + ';' + val.responsible_job + ';' + val.description +
            ';' + statusesArray.find(z => z.value = val.status).label + ';' + val.conso + ';' + val.budget + ';' + val.max_date ;
        }).filter(function(elem, index, self) {
          return index === self.indexOf(elem);
        });
      } else {
        result = data.data.map(val => {
          return val.order_num + ';' + val.action_name + ';' + val.action_description + ';' + val.responsible_name + ';' + val.responsible_job + ';' + val.description +
            ';' + statusesArray.find(z => z.value = val.status).label + ';' + val.conso + ';' + val.budget + ';' + val.max_date + ';' + val.dt_create;
        });
      }
      result.unshift(header);
      const csvArray = result.join('\r\n');

      const blob = new Blob(['\ufeff', csvArray], {type: 'text/csv'});
      saveAs(blob, 'export_actions.csv');
    });
  }


  exportCsvForGraphAriskan() {


    const header = 'ID;' + this.selectedColumns.map(i => {
        if (this.findValueInArray(this.filteredAnalyseLines.length > 0 ? this.filteredAnalyseLines[0].values : [], 'id_column', i.id_column).type_object == 'Risk') {
          return i.header + (i.color_range ? ';Qualification ' + i.header : '');
        } else if (this.findValueInArray(this.filteredAnalyseLines.length > 0 ? this.filteredAnalyseLines[0].values : [], 'id_column', i.id_column).type_object == 'List') {
          return i.header;
        } else if (this.findValueInArray(this.filteredAnalyseLines.length > 0 ? this.filteredAnalyseLines[0].values : [], 'id_column', i.id_column).type_object == 'Formule') {
          return i.header + (i.color_range ? ';Qualification ' + i.header : '');
        } else if (this.findValueInArray(this.filteredAnalyseLines.length > 0 ? this.filteredAnalyseLines[0].values : [], 'id_column', i.id_column).type_object == 'Nombre') {
          return i.header + (i.color_range ? ';Qualification ' + i.header : '');
        } else {
          return i.header;
        }
      }
    ).join(';');
    const data = this.analyseLines.filter(i => i.duplication == 0).map(analyse => {
      const result = analyse.values.map(val => {
        const col = this.selectedColumns.find(value => value.id_column == val.id_column);
        if (this.selectedColumns.some(value => value.id_column == val.id_column)) {
          if (typeof val.value === 'object') {
            if (val.type_object === 'Risk') {
              const colorRange = this.getRangeName(val, col);
              return val.value.id + (typeof colorRange === 'object' ? ';' + colorRange : '');
            } else if (val.type_object === 'List') {
              return val.selectedValue;
            } else if (/\n/gi.exec(val.value.id)) {
              return '"' + val.value.id + '"';
            }
            return val.value.id;
          } else {
            if (val.type_object == 'Formule') {
              const colorRange = this.getRangeName(val, col);
              return val.value + (typeof colorRange === 'object' ? ';' + colorRange : '');
            } else if (val.type_object == 'Nombre') {
              const colorRange = this.getRangeName(val, col);
              return val.value + (typeof colorRange === 'object' ? ';' + colorRange : '');
            } else if (/\n/gi.exec(val.value)) {
              return '"' + val.value + '"';
            }
            return val.value;
          }
        }
      });
      result.unshift(analyse.order_num);
      return result.join(';');
    });


    data.unshift(header);
    const csvArray = data.join('\r\n');

    const blob = new Blob(['\ufeff', csvArray], {type: 'text/csv'});
    saveAs(blob, 'export_workspace.csv');
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }

  showModifyLine() {
    this.showModifyColumn = !this.showModifyColumn;
  }

  checkLineStatus(id) {
    const lineStatus = this.lineStatuses.find(i => i.id == id);
    return lineStatus ? lineStatus.modify : false;
  }

  modifyLine(id) {
    this.lineStatuses.find(i => i.id == id).modify = !(this.lineStatuses.find(i => i.id == id).modify);
  }

  modifyLineForm(rowData) {
    this.makeListForm(rowData.id);
    this.dataForForm = rowData;
    this.showFormDialog = true;
  }

  importCsv() {
    this.displayDialogImport = !this.displayDialogImport;
  }


  importData(event) {
    if (event.originalEvent.body.code == 999) {
      this.msgs.showError(event.originalEvent.body.message);
    }
    this.loadData();
  }

  searchInAnalyse(searchValue) {
    this.filteredAnalyseLines = this.analyseLines;
    if (searchValue !== '') {
      const keywords = searchValue.split(',');

      for (const i in keywords) {
        this.filteredAnalyseLines = this.filteredAnalyseLines.filter(item => {
          const res = item.values.find(vals => {
              let found = false;
              if (vals.type_object == 'List') {
                found = vals.selectedValue && vals.selectedValue != '' && vals.selectedValue ? vals.selectedValue.toLowerCase().indexOf(keywords[i].toLowerCase().trimLeft()) > -1 : false;
              } else if (vals.type_object == 'Formule' || vals.type_object == 'Risk') {
                const colorName = this.getRangeName(this.findValueInArray(item.values, 'id_column', vals.id_column), this.columns.find(i => i.id == vals.id_column));
                found = vals.value && vals.value != '' && (vals.value.id || vals.value.name) ?
                  (vals.value.id.toLowerCase().indexOf(keywords[i].toLowerCase().trimLeft()) > -1) ||
                  (('' + vals.value.name).toLowerCase().indexOf(keywords[i].toLowerCase().trimLeft()) > -1) : false;
                if (!found && colorName) {
                  found = colorName.toLowerCase().indexOf(keywords[i].toLowerCase().trimLeft()) > -1;
                }
              } else {
                found = vals.value && vals.value != '' ? (typeof vals.value === 'object' && vals.value.id && vals.value != '' ? vals.value.id.toLowerCase().indexOf(keywords[i].toLowerCase().trimLeft()) > -1 : vals.value.toString().toLowerCase().indexOf(keywords[i].toLowerCase().trimLeft()) > -1) : false;
              }
              return found;
            }
          );
          return res;
        });
      }

    }
    this.first = 0;
  }

  searchInAnalyseByColumn(searchValue, col) {
    const keywords = [];
    const inputFilters = document.getElementsByClassName('searchFilters');

    for (const el in inputFilters) {
      const elem = (<HTMLInputElement>inputFilters[el]);

      if (typeof elem.getAttribute === 'function') {
        const inputValue = elem.value;
        const col = JSON.parse(elem.getAttribute('colInfo'));
        if (inputValue && inputValue != '') {
          keywords.push({inputValue, col});
        }
      }
    }

    this.filteredAnalyseLines = this.analyseLines;
    if (keywords.length > 0) {
      for (const i in keywords) {
        const keyword = keywords[i];
        if (keyword.inputValue.charAt(0) == '=') {
          keyword.inputValue = keyword.inputValue.substring(1, keyword.length);
          this.filterColumnsExactMatch(keyword);
        } else if (keyword.inputValue.charAt(0) == '>') {
          keyword.inputValue = keyword.inputValue.substring(1, keyword.length);
          this.filterColumnsSuperiorMatch(keyword);
        } else if (keyword.inputValue.charAt(0) == '<') {
          keyword.inputValue = keyword.inputValue.substring(1, keyword.length);
          this.filterColumnsInferiorMatch(keyword);
        } else {
          this.filterColumnsNormalMatch(keywords[i]);
        }
      }

    }
    this.first = 0;
  }


  filterColumnsNormalMatch(keyword) {

    this.filteredAnalyseLines = this.filteredAnalyseLines.filter(item => {
      const res = item.values.find(vals => {

        let found = false;
        if (vals.id_column == keyword.col.id_column) {
          if (vals.type_object == 'List') {
            found = vals.selectedValue && vals.selectedValue != '' && vals.selectedValue ? vals.selectedValue.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1 : false;
          } else if (vals.type_object == 'Date') {
            const dt = vals.value && vals.value != '' ? new Date(Date.parse(vals.value)) : false;
            found = dt ? (dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear()).indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1 : false;
          } else if (vals.type_object == 'Formule' || vals.type_object == 'Risk' || vals.type_object == 'Probabilité') {
            const colorName = this.getRangeName(this.findValueInArray(item.values, 'id_column', vals.id_column), this.columns.find(i => i.id == vals.id_column));
            found = vals.value && vals.value != '' && vals.value.id ?
              (vals.value.id.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1) ||
              (vals.value.name == keyword.inputValue.toLowerCase().trimLeft()) : false;
            if (!found && typeof vals.value != 'object') {
              found = vals.value && vals.value != '' ?
                (vals.value.toString().toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1) : false;
            }
            if (!found && colorName) {
              found = colorName.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1;
            }
          } else {
            const colorName = this.getRangeName(this.findValueInArray(item.values, 'id_column', vals.id_column), this.columns.find(i => i.id == vals.id_column));
            found = vals.value && vals.value != '' ? (typeof vals.value === 'object' && vals.value.id && vals.value != '' ? vals.value.id.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1 : vals.value.toString().toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1) : false;
            if (!found && colorName) {
              found = colorName.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1;
            }
          }
          return found;
        }
      });
      return res;
    });
    this.first = 0;
  }

  filterColumnsExactMatch(keyword) {
    this.filteredAnalyseLines = this.filteredAnalyseLines.filter(item => {
      const res = item.values.find(vals => {

        let found = false;
        if (vals.id_column == keyword.col.id_column) {
          if (vals.type_object == 'List') {
            found = vals.selectedValue && vals.selectedValue != '' && vals.selectedValue ? vals.selectedValue.toLowerCase() == keyword.inputValue.toLowerCase().trimLeft() : false;
          } else if (vals.type_object == 'Date') {
            const dt = vals.value && vals.value != '' ? new Date(Date.parse(vals.value)) : false;
            found = dt ? (dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear()).indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1 : false;
          } else if (vals.type_object == 'Formule' || vals.type_object == 'Risk' || vals.type_object == 'Probabilité') {
            const colorName = this.getRangeName(this.findValueInArray(item.values, 'id_column', vals.id_column), this.columns.find(i => i.id == vals.id_column));
            found = vals.value && vals.value != '' && vals.value.id ?
              (vals.value.id.toLowerCase() == keyword.inputValue.toLowerCase().trimLeft()) : false;
            if (!found && vals.value.name) {
              found = vals.value && vals.value != '' && vals.value.name ?
                (+vals.value.name == +keyword.inputValue) : false;
            }
            if (!found && typeof vals.value != 'object') {
              found = vals.value && vals.value != '' ?
                (+vals.value as number) == (+keyword.inputValue as number) : false;
            }
            if (!found && colorName) {
              found = colorName.toLowerCase() == keyword.inputValue.toLowerCase().trimLeft();
            }
          } else {
            const colorName = this.getRangeName(this.findValueInArray(item.values, 'id_column', vals.id_column), this.columns.find(i => i.id == vals.id_column));
            found = vals.value && vals.value != '' ? (typeof vals.value === 'object' && vals.value.id && vals.value != '' ? vals.value.id.toLowerCase() == keyword.inputValue.toLowerCase().trimLeft() : vals.value.toString().toLowerCase() == keyword.inputValue.toLowerCase().trimLeft()) : false;
            if (!found && colorName) {
              found = colorName.toLowerCase() == keyword.inputValue.toLowerCase().trimLeft();
            }
          }
          return found;
        }
      });
      return res;
    });
    this.first = 0;
  }


  filterColumnsSuperiorMatch(keyword) {
    this.filteredAnalyseLines = this.filteredAnalyseLines.filter(item => {
      const res = item.values.find(vals => {

        let found = false;
        if (vals.id_column == keyword.col.id_column) {
          if (vals.type_object == 'List') {
            found = vals.selectedValue && vals.selectedValue != '' && vals.selectedValue ? vals.selectedValue.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1 : false;
          } else if (vals.type_object == 'Date') {
            const dt = vals.value && vals.value != '' ? new Date(Date.parse(vals.value)) : false;
            found = dt ? (dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear()).indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1 : false;
          } else if (vals.type_object == 'Formule' || vals.type_object == 'Risk' || vals.type_object == 'Probabilité') {
            const colorName = this.getRangeName(this.findValueInArray(item.values, 'id_column', vals.id_column), this.columns.find(i => i.id == vals.id_column));
            found = vals.value && vals.value != '' && vals.value.id ?
              (vals.value.id.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1)
              : false;
            if (!found) {
              found = vals.value && vals.value != '' && vals.value.name ?
                (+vals.value.name as number) > (+keyword.inputValue as number) : false;
            }
            if (!found && typeof vals.value != 'object') {
              found = vals.value && vals.value != '' ?
                (+vals.value as number) > (+keyword.inputValue as number) : false;
            }
            if (!found && colorName) {
              found = colorName.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1;
            }
          } else {
            const colorName = this.getRangeName(this.findValueInArray(item.values, 'id_column', vals.id_column), this.columns.find(i => i.id == vals.id_column));
            found = vals.value && vals.value != '' ? (typeof vals.value === 'object' && vals.value.id && vals.value != '' ? vals.value.id.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1 : (+vals.value as number) > (+keyword.inputValue as number)) : false;
            if (!found && colorName) {
              found = colorName.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1;
            }
          }
          return found;
        }
      });
      return res;
    });
    this.first = 0;
  }


  searchByValidation(checked) {
    this.filteredAnalyseLines = this.analyseLines.filter(item => {
      return item.validation == checked;
      this.first = 0;
    });

  }


  filterColumnsInferiorMatch(keyword) {
    this.filteredAnalyseLines = this.filteredAnalyseLines.filter(item => {
      const res = item.values.find(vals => {

        let found = false;
        if (vals.id_column == keyword.col.id_column) {
          if (vals.type_object == 'List') {
            found = vals.selectedValue && vals.selectedValue != '' && vals.selectedValue ? vals.selectedValue.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1 : false;
          } else if (vals.type_object == 'Date') {
            const dt = vals.value && vals.value != '' ? new Date(Date.parse(vals.value)) : false;
            found = dt ? (dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear()).indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1 : false;
          } else if (vals.type_object == 'Formule' || vals.type_object == 'Risk' || vals.type_object == 'Probabilité') {
            const colorName = this.getRangeName(this.findValueInArray(item.values, 'id_column', vals.id_column), this.columns.find(i => i.id == vals.id_column));
            found = vals.value && vals.value != '' && vals.value.id ?
              (vals.value.id.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1)
              : false;
            if (!found) {
              found = vals.value && vals.value != '' && vals.value.name ?
                (+vals.value.name as number) < (+keyword.inputValue as number) : false;
            }
            if (!found && typeof vals.value != 'object') {
              found = vals.value && vals.value != '' ?
                (+vals.value as number) < (+keyword.inputValue as number) : false;
            }
            if (!found && colorName) {
              found = colorName.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1;
            }
          } else {
            const colorName = this.getRangeName(this.findValueInArray(item.values, 'id_column', vals.id_column), this.columns.find(i => i.id == vals.id_column));
            found = vals.value && vals.value != '' ? (typeof vals.value === 'object' && vals.value.id && vals.value != '' ? vals.value.id.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1 : (+vals.value as number) < (+keyword.inputValue as number)) : false;
            if (!found && colorName) {
              found = colorName.toLowerCase().indexOf(keyword.inputValue.toLowerCase().trimLeft()) > -1;
            }
          }
          return found;
        }
      });
      return res;
    });
    this.first = 0;
  }


  isColumnFiltered(searchCol) {
    const inputFilters = document.getElementsByClassName('searchFilters');

    for (const el in inputFilters) {
      const elem = (<HTMLInputElement>inputFilters[el]);

      if (typeof elem.getAttribute === 'function') {
        const inputValue = elem.value;
        const col = JSON.parse(elem.getAttribute('colInfo'));
        if (inputValue && inputValue != '' && col.id_column == searchCol.id_column) {
          return true;
        }
      }
    }

    return false;
  }


  duplicateLine(id) {
    this.analyseLinesService.addduplicateline(id);
    this.loadData();
  }

  loadSelectedValue(array, value) {
    const searchVal = array ? array.find(i => i.value_id == value) : {};
    return searchVal ? searchVal : {value: '', label: ''};
  }

  importError(event) {
    this.msgs.showError('Format de fichier erroné!');
    this.loading = false;
  }

  doEnter() {
    return false;
  }

  tranformDate(dt) {
    let result;
    if (typeof dt !== 'object' && dt.indexOf('T') > -1) {
      result = new Date(Date.parse(dt));
    } else {
      if ((typeof dt === 'string') && (dt.indexOf('/') > -1)) {
        const str = dt.split('/');

        const year = Number(str[2]);
        const month = Number(str[1]) - 1;
        const date = Number(str[0]);

        result = new Date(year, month, date);
      }
    }

    if (result && typeof result.getMonth === 'function' && Object.prototype.toString.call(result) === '[object Date]') {
      return result;
    }

    return false;
  }

  replaceAllNewLines(str) {
    return typeof str === 'string' ? str.replace(/\\n/gi, '\n') : '';
  }

  showColFilters() {
    this.colFilters = !this.colFilters;
  }

  getJsonValue(data) {
    return JSON.stringify(data);
  }

  dateToString(date) {
    const dd = date.getDate();

    const mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    return dd + '/' + mm + '/' + yyyy;
  }


  clearFiltersColumns() {
    const inputFilters = document.getElementsByClassName('searchFilters');

    for (const el in inputFilters) {
      const elem = (<HTMLInputElement>inputFilters[el]);

      if (typeof elem.getAttribute === 'function') {
        elem.value = '';
      }

    }

    this.filteredAnalyseLines = this.analyseLines;
  }


  closeFormDialog() {
    this.showFormDialog = false;
  }


  validateLine(rowData, loadData = true) {
      this.analyseLinesService.validateLine(rowData.id).then(res => {
      if (loadData) {
        this.loadData();
      }
    });
  }

  openPlanDialog(rowdata, col) {
    this.disabledEditingPlan = !this.userRightsService.userInGroups(col.authorized_groups);
    this.id_line_plan = rowdata.id;
    this.showPlanDialog = true;
  }

  openExportActionDialog() {
    this.selectedActionTypeList = [];
    this.showExportActionDialog = true;
  }

  doNothing(event: any) {
    event.stopPropagation();
    return;
  }

  showProposeScenarioDialog() {
    this.proposeScenarioDialogVisible = true;
  }
}

