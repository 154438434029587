<div style="text-align: center">
  Selectionner un analyse:
  <p-dropdown
    appendTo="body"
    [filter]="true"
    (onChange)="reloadData()"
    [options]="analyseObjects"
    [style]="{ width: '100%' }"
    [(ngModel)]="selectedAnalyse"
    optionLabel="name"
  >
  </p-dropdown>
</div>

<br />
<br />
<!--<div class="ui-inputgroup">-->
<!--  <button type="button" pButton icon="fa fa-check" (click)="makeDefaultValues()" label="Valeurs par défaut"></button>-->
<!--</div>-->
<br />
<br />
<div class="ui-inputgroup" *ngFor="let ln of mappings; let i = index">
  <table>
    <tr>
      <td>
        <button
          type="button"
          style="height: 2em"
          pButton
          (click)="mappings.splice(i, 1)"
          class="p-button-danger"
          icon="fa fa-close"
        ></button>
      </td>
      <td>
        <p-dropdown
          appendTo="body"
          [filter]="true"
          [options]="columns"
          [(ngModel)]="mappings[i].validationStart"
          [style]="{ width: '100%' }"
          optionLabel="name"
          placeholder="Colonne 1 :"
        ></p-dropdown>
      </td>
      <td>
        <p-dropdown
          appendTo="body"
          [filter]="true"
          [options]="columns"
          [(ngModel)]="mappings[i].validationEnd"
          [style]="{ width: '100%' }"
          optionLabel="name"
          placeholder="Colonne 2 :"
        ></p-dropdown>
      </td>
    </tr>
  </table>
</div>
<br /><br />
<div class="ui-inputgroup">
  <button
    type="button"
    pButton
    icon="fa fa-check"
    (click)="addLine()"
    label="Ajouter"
  ></button>
</div>
<br />
<br />
<div class="ui-inputgroup align-content-center">
  <button
    type="button"
    pButton
    icon="fa fa-check"
    class="p-button-success"
    (click)="saveMapping()"
    label="Sauvgarder"
  ></button>
</div>
