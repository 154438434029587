import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { AppConfig } from "../config";
import { MsgService } from "../services/msg.service";
import { CartoService } from "./carto.service";

@Component({
  selector: "app-carto",
  templateUrl: "./carto.component.html",
  styleUrls: ["./carto.component.css"],
})
export class CartoComponent implements OnInit, OnChanges {
  @Input("clients") clients: any;
  myfiles: any;
  cartos: any;
  imgSrc: any;
  showImg = true;

  constructor(public config: AppConfig, private msgs: MsgService) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    const d = new Date();
    const n = d.getMilliseconds();
    this.imgSrc = this.config.API_Host + "get/carto/" + this.clients + "/" + n;
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.clients;
    if (currentItem) {
      this.loadData();
    }
  }

  myUploader(event) {
    this.msgs.showSuccess("Le fichier est bien enregistré!");
    this.imgSrc = "";
    this.showImg = false;
    this.showImg = true;
    const d = new Date();
    const n = d.getMilliseconds();
    this.imgSrc = this.config.API_Host + "get/carto/" + this.clients + "/" + n;
  }

  wait(ms) {
    const start = new Date().getTime();
    let end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }
}
