import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { ActionType } from "../action/actiontype.model";

@Injectable({
  providedIn: "root",
})
export class PlanService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  get(id_line: number) {
    return this.http
      .get(this.config.API_Host + "plan/get/" + id_line)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getPlan(id_user: number) {
    return this.http
      .get(this.config.API_Host + "plan/getPlanByUser/" + id_user)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getPlanForExport(id_analyse: number) {
    return this.http
      .get(this.config.API_Host + "/plan/getPlanForExport/" + id_analyse)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getCalc(id_analyse: number) {
    return this.http
      .get(this.config.API_Host + "plan/getcalc/" + id_analyse)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  createupdate(plan: any) {
    return this.http
      .post(this.config.API_Host + "plan/createupdate", JSON.stringify(plan))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  approveAction(plan: any) {
    return this.http
      .post(this.config.API_Host + "plan/approveByManager/" + plan.id, JSON.stringify(plan))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  delete(id: number) {
    return this.http
      .post(this.config.API_Host + "plan/delete", JSON.stringify({ id: id }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteAll(id: number) {
    return this.http
      .post(this.config.API_Host + "plan/deleteAll", JSON.stringify({ id: id }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
