import { Component, OnInit } from "@angular/core";
import { UserConfig } from "../models/userconfig.model";
import { UserConfigurationService } from "../services/user-configuration.service";
import { MsgService } from "../services/msg.service";
import { Log } from "../models/log.model";
import { TracesService } from "../traces/traces.service";
import { UserRightsService } from "../services/user-rights.service";

@Component({
  selector: "app-user-configuration",
  templateUrl: "./user-configuration.component.html",
  styleUrls: ["./user-configuration.component.css"],
})
export class UserConfigurationComponent implements OnInit {
  userconfigmodule: UserConfig = {};
  userinfo: any;
  showPassword = false;

  constructor(
    private userConfig: UserConfigurationService,
    private msgs: MsgService,
    private userRightsService: UserRightsService,
    private trace: TracesService
  ) {
    this.userinfo = JSON.parse(localStorage.getItem("user"));
  }

  ngOnInit() {
    this.userConfig
      .getUserConfiguration(this.userinfo.data.id)
      .then((userConfig) => {
        this.userconfigmodule = userConfig.data;
      });
  }

  saveProfileInfo() {
    this.userConfig
      .updateUserConfiguration(this.userconfigmodule)
      .then((userConfig) => {
        if (userConfig.code === 200) {
          this.msgs.showSuccess("Le compte est mis à jour!");
          this.trace.createTrace({
            action: "UPDATE USER",
            data: JSON.stringify(this.userconfigmodule),
            description: "UPDATE UTILISATEUR",
          } as Log);
        } else {
          this.msgs.showError(userConfig.message);
          const jsonmsg = JSON.stringify(userConfig.message);
          this.trace.createTrace({
            action: "UPDATE USER",
            data: jsonmsg,
            description: "ERREUR UPDATE UTILISATEUR",
          } as Log);
        }
      });
  }

  changeShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
