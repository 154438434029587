import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { MessageService } from "primeng/api";

@Injectable()
export class MsgService {
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;

  constructor(private router: Router, private msgs: MessageService) {
    // clear alert message on route change
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
        }
      }
    });
  }

  showInfo(message: any, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.msgs.add({
      key: "msg",
      severity: "info",
      summary: "Info Message",
      detail: message,
    });
  }
  showSuccess(message: any, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.msgs.add({
      key: "msg",
      severity: "success",
      summary: "Success Message",
      detail: message,
    });
  }

  showWarn(message: any, keepAfterNavigationChange = false) {
    this.msgs.add({
      key: "msg",
      severity: "warn",
      summary: "Warn Message",
      detail: "There are unsaved changes",
    });
  }

  showError(message: any, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.msgs.add({
      key: "msg",
      severity: "error",
      summary: "Error Message",
      detail: message,
    });
  }

  showMultiple(message: any, keepAfterNavigationChange = false) {
    this.msgs.add({
      key: "msg",
      severity: "info",
      summary: "Message 1",
      detail: "PrimeNG rocks",
    });
    this.msgs.add({
      key: "msg",
      severity: "info",
      summary: "Message 2",
      detail: "PrimeUI rocks",
    });
    this.msgs.add({
      key: "msg",
      severity: "info",
      summary: "Message 3",
      detail: "PrimeFaces rocks",
    });
  }

  clear() {
    this.msgs.clear();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
