import { Injectable } from "@angular/core";
import { ApiResponse } from "../models/api-response.model";
import { AppConfig } from "../config";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ForgottenPasswordService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  sendForgottenPassword(email) {
    return this.http
      .post(this.config.API_Host + "forgottenPassword/sendchangepassword", {
        email: email,
      })
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
