import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { User } from "../users/user.model";

@Injectable({
  providedIn: "root",
})
export class SetPasswordService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  checkIfTokenIsValide(token) {
    return this.http
      .get(this.config.API_Host + "setPassword/verifyToken/" + token)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  changePassword(token, password) {
    return this.http
      .post(
        this.config.API_Host + "setPassword/changePassword",
        JSON.stringify({ token: token, password: password })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
