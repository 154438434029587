import { User } from "../users/user.model";

export class UserGroup {
  id: number;
  name: string;
  description: string;
  id_structure: number;

  users: User[];
}
