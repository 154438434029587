<div class="p-grid p-fluid"></div>
<div class="p-col-6 pull-left" *ngFor="let param of paramGraphs; let i = index">
  <h3 style="text-align: center">
    {{ param.name }}
    <button pButton icon="pi pi-table" (click)="displayTable(data[i])"></button>
  </h3>
  <p-chart [type]="param.typegraph" [data]="data[i]"></p-chart>
  <br /><br />
</div>
<p-dialog header="Détails Graphique" [(visible)]="displayDetailsTable">
  <table *ngIf="dataForDetailsTable" class="table table-striped">
    <thead>
      <tr>
        <th>Data</th>
        <th *ngFor="let dataset of dataForDetailsTable.datasets">
          {{ dataset.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let label of dataForDetailsTable.labels; let i = index">
        <td>{{ label }}</td>
        <td *ngFor="let dataset of dataForDetailsTable.datasets">
          {{ dataset.data[i] }}
        </td>
      </tr>
    </tbody>
  </table>
</p-dialog>
