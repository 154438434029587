import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AuthGuard } from "./guards/auth.guard";
import { UsersComponent } from "./users/users.component";
import { TracesComponent } from "./traces/traces.component";
import { AdminGuard } from "./guards/admin.guard";
import { DataTableContainerComponent } from "./data-table-container/data-table-container.component";
import { AnalyseComponent } from "./analyse/analyse.component";
import { HomeComponent } from './home/home.component';
import { ClientComponent } from "./client/client.component";
import { UserConfigurationComponent } from "./user-configuration/user-configuration.component";
import { ColumnsComponent } from "./columns/columns.component";
import { UserClientsComponent } from "./user-clients/user-clients.component";
import { WorkspaceComponent } from "./workspace/workspace.component";
import { WorkspaceDetailsComponent } from "./workspace-details/workspace-details.component";
import { SetPasswordComponent } from "./set-password/set-password.component";
import { ForgottenPasswordComponent } from "./forgotten-password/forgotten-password.component";
import { ParamGraphComponent } from "./param-graph/param-graph.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { StructureClientComponent } from "./structure-client/structure-client.component";
import { MappingValidationComponent } from "./mapping-validation/mapping-validation.component";
import { DashboardsComponent } from "./dashboards/dashboards.component";
import { SuperUserGuard } from "./guards/super-user.guard";
import { ActionManagementComponent } from "./action-management/action-management.component";
import { ParentAnalyseComponent } from "./parent-analyse/parent-analyse.component";
import { ManagementScenarioProposalsComponent } from "./management-scenario-proposals/management-scenario-proposals.component";

const routes: Routes = [
  { path: '', component: HomeComponent , canActivate: [AuthGuard]},
  { path: 'dashboards', component: DashboardsComponent, canActivate: [AuthGuard]},
  { path: 'actions', component: ActionManagementComponent, canActivate: [AuthGuard]},
  { path: 'scenarioProposals', component: ManagementScenarioProposalsComponent, canActivate: [AuthGuard]},
  { path: 'setPassword/:token', component: SetPasswordComponent},
  { path: 'forgottenPassword', component: ForgottenPasswordComponent},
  { path: 'login', component: LoginComponent},
  { path: 'workspace', component: WorkspaceComponent , canActivate: [AuthGuard]},
  { path: 'analyses', component: AnalyseComponent , canActivate: [AuthGuard]},
  { path: 'workspace_details/:id/:id_client', component: WorkspaceDetailsComponent , canActivate: [AuthGuard]},
  { path: 'parent-analyse/:id_client', component: ParentAnalyseComponent , canActivate: [AuthGuard]},
  { path: 'param_graph/:id/:id_client/:type_object', component: ParamGraphComponent , canActivate: [AuthGuard]},
  { path: 'dashboard/:id/:id_client/:type_object', component: DashboardComponent , canActivate: [AuthGuard]},
  { path: 'params/users', component: UsersComponent , canActivate: [AdminGuard]},
  { path: 'params/userclient', component: UserClientsComponent , canActivate: [AdminGuard]},
  { path: 'params/client', component: ClientComponent , canActivate: [AdminGuard]},
  { path: 'params/analyse', component: AnalyseComponent , canActivate: [AdminGuard]},
  { path: 'params/column', component: ColumnsComponent , canActivate: [AdminGuard]},
  { path: 'params/datatable', component: DataTableContainerComponent , canActivate: [SuperUserGuard]},
  { path: 'params/structureclient', component: StructureClientComponent , canActivate: [SuperUserGuard]},
  { path: 'params/mappingvalidation', component: MappingValidationComponent , canActivate: [AdminGuard]},
  { path: 'params/traces', component: TracesComponent , canActivate: [AdminGuard]},
  { path: 'params/userprofile', component: UserConfigurationComponent , canActivate: [AuthGuard]},
  { path: '**', component: PageNotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
