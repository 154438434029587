import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { Formula } from "./formula.model";

@Injectable({
  providedIn: "root",
})
export class FormulaService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  get(client: number) {
    return this.http
      .get(this.config.API_Host + "formulas/get/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getColumnsForFormula(client: number) {
    return this.http
      .get(this.config.API_Host + "formulas/getcolumnsforformula/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  create(formula: Formula) {
    return this.http
      .post(
        this.config.API_Host + "formulas/create",
        JSON.stringify({ formula })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  update(formula: Formula) {
    return this.http
      .post(
        this.config.API_Host + "formulas/update",
        JSON.stringify({ formula })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  delete(id: number) {
    return this.http
      .post(
        this.config.API_Host + "formulas/delete",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getFormulaForList(client: number, id_analyse: number) {
    return this.http
      .get(
        this.config.API_Host +
          "formulas/getformulaforlist/" +
          client +
          "/" +
          id_analyse
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteAll(id: number) {
    return this.http
      .post(
        this.config.API_Host + "formulas/deleteAll",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
