import { Component, OnInit } from "@angular/core";
import { MsgService } from "../services/msg.service";
import { AuthService } from "../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  siteKey: string = "6LfhEq0UAAAAANMTRu9dK0VFd2ahnyJNY8x0Ccqh";
  lang : string = "fr";

  aFormGroup: FormGroup;
  userlogin = {
    email: "",
    password: "",
  };

  constructor(
    private msg: MsgService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.aFormGroup = this.formBuilder.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
      // TODO
      // recaptcha: ['', Validators.required]
    });
  }
  login(event: any) {
    event.preventDefault();
    if (this.aFormGroup.value.email !== '' && this.aFormGroup.value.password !== '' && this.aFormGroup.status === 'VALID') {
      this.auth.login(this.aFormGroup.value.email, this.aFormGroup.value.password).then(
          data => {
                this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                this.router.navigate([this.returnUrl]);
          },
          (error) => {
            this.msg.showError(error);
          }
        );
    } else {
      this.msg.showError(
        "Merci de saisir votre email et votre mot de passe, ainsi que la captcha!"
      );
    }
  }

  handleSuccess(event) {}
}
