import { Injectable } from "@angular/core";
import { ApiResponse } from "../models/api-response.model";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";

@Injectable({
  providedIn: "root",
})
export class WorkspaceService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  get(id_user) {
    return this.http
      .get(this.config.API_Host + "workspace/get/" + id_user)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getAnalyses(id_user) {
    return this.http
      .get(this.config.API_Host + "workspace/getanalyses/" + id_user)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getClients(id_user) {
    return this.http
      .get(this.config.API_Host + "workspace/getclients/" + id_user)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  addupdate(options) {
    return this.http
      .post(
        this.config.API_Host + "workspace/addupdate",
        JSON.stringify(options)
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  delete(id: number) {
    return this.http
      .post(
        this.config.API_Host + "workspace/delete",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
