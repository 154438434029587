import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";
import { Risk } from "./risk.model";

@Injectable({
  providedIn: "root",
})
export class RiskService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  getRisks(client: number) {
    return this.http
      .get(this.config.API_Host + "risks/getRisks/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  createRisk(risk: Risk) {
    return this.http
      .post(this.config.API_Host + "risks/addrisk", JSON.stringify({ risk }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  updateRisk(risk: Risk) {
    return this.http
      .post(this.config.API_Host + "risks/updaterisk", JSON.stringify({ risk }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteRisk(id: number) {
    return this.http
      .post(this.config.API_Host + "risks/delrisk", JSON.stringify({ id: id }))
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getRiskForList(client: number) {
    return this.http
      .get(this.config.API_Host + "risks/getriskforlist/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  deleteAll(id: number) {
    return this.http
      .post(
        this.config.API_Host + "risks/deleteAll",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
