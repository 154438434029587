<h1 style="font-size: 18px !important; text-align: center !important">
  Paramètrages des analyses
</h1>
<br />
<div style="width: 100%; text-align: center">
  View Table
  <p-inputSwitch
    (onChange)="changeTableLayout()"
    ariaLabelledBy="View Table"
  ></p-inputSwitch>
</div>
<div *ngIf="tableLayout">
  <p-table
    csvSeparator=";"
    #dt
    [columns]="cols"
    [value]="analyses"
    selectionMode="single"
    [(selection)]="selectedAnalyse"
    (onRowSelect)="goToDetails($event)"
    [paginator]="true"
    [rows]="15"
    [responsive]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button
          pButton
          pRipple
          *ngIf="userRightsService.getUserCreate() == 1"
          pTooltip="Ajouter"
          tooltipPosition="top"
          icon="fa fa-plus"
          (click)="showDialogToAdd()"
          style="float: left"
        ></button>
        <!--<button pButton pRipple *ngIf="userRightsService.getUserCreate() == 1" pTooltip="Importer" tooltipPosition="top" icon="fa fa-download" iconPos="left" class="p-button-info" (click)="openImportDialog()" style="float:left"></button>
      -->
        <button
          pButton
          pRipple
          *ngIf="userRightsService.getUserRead() == 1"
          pTooltip="Exporter"
          tooltipPosition="top"
          icon="fa fa-upload"
          iconPos="left"
          class="p-button-info"
          (click)="dt.exportCSV()"
          style="float: left"
        ></button>
        <button
          pButton
          pRipple
          *ngIf="userRightsService.getUserRead() == 1"
          pTooltip="Cartographie"
          tooltipPosition="top"
          icon="pi pi-sitemap"
          iconPos="left"
          class="p-button-success"
          (click)="redirectToCarto()"
          style="float: left"
        ></button>
        <button
          pButton
          pRipple
          *ngIf="userRightsService.getUserRead() == 1"
          pTooltip="Actions Management"
          tooltipPosition="top"
          icon="pi pi-bars"
          iconPos="left"
          class="p-button-warning"
          (click)="showActionManagement()"
          style="float: left"
        ></button>
        <button
          pButton
          pRipple
          *ngIf="userRightsService.getUserRead() == 1"
          pTooltip="Gestion des propositions de scénarios"
          tooltipPosition="top"
          icon="pi pi-file-o"
          iconPos="left"
          class="p-button-warning"
          routerLink="/scenarioProposals"
          style="float: left"
        ></button>
        <button
          pButton
          pRipple
          *ngIf="userRightsService.getUserRead() == 1"
          pTooltip="Exporter le nombre d'actions par personne"
          tooltipPosition="top"
          icon="pi pi-upload"
          iconPos="left"
          class="p-button-secondary"
          (click)="exportActionPerson()"
          style="float: left"
        ></button>

        <div class="offset-5">
          Client :
          <p-dropdown
            appendTo="body"
            (onChange)="changeClientCache()"
            optionLabel="name"
            [filter]="true"
            [options]="client"
            [(ngModel)]="selectedClient"
          ></p-dropdown>
        </div>

        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input
            type="text"
            pInputText
            size="50"
            placeholder="Global Filter"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            style="width: auto"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 3em" *ngIf="showDeleteColumn">X</th>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{ col.header }}
          <p-sortIcon
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          ></p-sortIcon>
        </th>
        <th>Types d'actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr
        [pSelectableRow]="rowData"
        [style]="{ 'background-color': rowData.valid ? '#6fcc03a6' : rowData.parent ? 'blue' : 'white' }"
      >
        <td style="width: 3em; padding: 5px" *ngIf="showDeleteColumn">
          <button
            *ngIf="userRightsService.getUserDelete() == 1"
            pButton
            (click)="deleteLine(rowData.id)"
            class="p-button-danger"
            icon="fa fa-close"
          ></button>
        </td>
        <td *ngFor="let col of columns">
          <div
            *ngIf="
              col.field == 'create' ||
                col.field == 'read' ||
                col.field == 'update' ||
                col.field == 'delete' ||
                col.field == 'valide' ||
                col.field == 'admin';
              else normalContent
            "
          >
            <input
              type="checkbox"
              id="checkbox"
              [checked]="rowData[col.field] ? 'checked' : ''"
              binary="true"
              disabled
            />
          </div>
          <ng-template #normalContent>
            {{ rowData[col.field] }}
          </ng-template>
        </td>
        <td>
          <div *ngIf="!rowData.valid && !rowData.parent">
            <button
              pButton
              pRipple
              *ngIf="userRightsService.getUserDelete() == 1"
              type="button"
              class="p-button-rounded p-button-danger"
              icon="fa fa-close"
              (click)="deleteAnalyseLine(rowData.id)"
            ></button>
            <button
              pButton
              pRipple
              *ngIf="userRightsService.getUserRead() == 1"
              (click)="onRowSelect(rowData)"
              class="p-button-rounded p-button-warning"
              pTooltip="Paramétrages analyse"
              type="button"
              icon="fa fa-gear"
            ></button>
            <button
              pButton
              pRipple
              *ngIf="userRightsService.getUserRead() == 1"
              (click)="goToDashboard(rowData.id)"
              class="p-button-rounded p-button-success"
              pTooltip="Graphiques"
              type="button"
              icon="fa fa-bar-chart"
            ></button>
            <button
              pButton
              pRipple
              *ngIf="userRightsService.getUserCreate() == 1"
              (click)="archivate(rowData.id)"
              class="p-button-rounded p-button"
              pTooltip="Archiver"
              type="button"
              icon="pi pi-save"
            ></button>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="summary" let-rowData>
      <div style="text-align: left">
        <button
          type="button"
          pButton
          icon="fa fa-plus"
          *ngIf="userRightsService.getUserCreate() == 1"
          (click)="showDialogToAdd()"
          label="Ajouter"
        ></button>
      </div>
    </ng-template>
  </p-table>
</div>

<div *ngIf="!tableLayout">
  <p-dataView
    #dv
    [value]="analyses"
    [paginator]="true"
    [rows]="9"
    filterBy="name"
    [layout]="selectedDVLayout"
    (onChangeLayout)="changeDVLayout($event)"
  >
    <ng-template pTemplate="header">
      <button
        pButton
        pRipple
        *ngIf="userRightsService.getUserCreate() == 1"
        pTooltip="Ajouter"
        tooltipPosition="top"
        icon="fa fa-plus"
        (click)="showDialogToAdd()"
        style="float: left"
      ></button>
      <!--<button pButton pRipple *ngIf="userRightsService.getUserCreate() == 1" pTooltip="Importer" tooltipPosition="top" icon="fa fa-download" iconPos="left"  (click)="openImportDialog()" style="float:left"></button>
      -->
      <button
        pButton
        pRipple
        *ngIf="userRightsService.getUserRead() == 1"
        pTooltip="Cartographie"
        tooltipPosition="top"
        icon="pi pi-sitemap"
        iconPos="left"
        class="p-button-success"
        (click)="redirectToCarto()"
        style="float: left"
      ></button>
      <button
        pButton
        pRipple
        *ngIf="userRightsService.getUserRead() == 1"
        pTooltip="Exporter"
        tooltipPosition="top"
        icon="fa fa-upload"
        iconPos="left"
        (click)="dt.exportCSV()"
        style="float: left"
      ></button>
      <button
        pButton
        pRipple
        *ngIf="userRightsService.getUserRead() == 1"
        pTooltip="Gestion des propositions de scénarios"
        tooltipPosition="top"
        icon="pi pi-file-o"
        iconPos="left"
        class="p-button-warning"
        routerLink="/scenarioProposals"
        style="float: left"
      ></button>
      <button
        pButton
        pRipple
        *ngIf="userRightsService.getUserRead() == 1"
        pTooltip="Exporter le nombre d'actions par personne"
        tooltipPosition="top"
        icon="pi pi-upload"
        iconPos="left"
        class="p-button-secondary"
        (click)="exportActionPerson()"
        style="float: left"
      ></button>

      <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
        <span class="p-input-icon-left p-mb-2 p-mb-md-0">
          <i class="pi pi-search"></i>
          <input
            type="search"
            pInputText
            placeholder="Search by Name"
            (input)="dv.filter($event.target.value)"
          />
        </span>
        <div>
          Client :
          <p-dropdown
            appendTo="body"
            (onChange)="changeClientCache()"
            optionLabel="name"
            [filter]="true"
            [options]="client"
            [(ngModel)]="selectedClient"
          ></p-dropdown>
        </div>
        <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
      </div>
    </ng-template>

    <ng-template let-analyse pTemplate="listItem">
      <div class="p-col-12">
        <div
          class="analyse-list-item"
          [style]="{
            'background-color': analyse.valid ? '#6fcc03a6' : analyse.parent ? '#6fcc03a6' : 'white'
          }"
        >
          <img style="text-align: center" [src]="imgLogo" />
          <div
            class="analyse-list-detail"
            (click)="analyse.parent ? goToDetailsCard(analyse) : goToParentAnalyse(analyse.id_client)"
          >
            <div class="analyse-name">{{ analyse.name }}</div>
            <div class="analyse-description">{{ analyse.description }}</div>
            <div class="analyse-nomClient">{{ analyse.nameClient }}</div>
          </div>
          <div class="analyse-list-action" *ngIf="!analyse.valid && !analyse.parent">
            <button
              pButton
              pRipple
              *ngIf="userRightsService.getUserDelete() == 1"
              type="button"
              class="p-button-rounded p-button-danger"
              icon="fa fa-close"
              (click)="deleteAnalyseLine(analyse.id)"
            ></button>
            <button
              pButton
              pRipple
              *ngIf="userRightsService.getUserRead() == 1"
              (click)="onRowSelect(analyse)"
              class="p-button-rounded p-button-warning"
              pTooltip="Paramétrages analyse"
              type="button"
              icon="fa fa-gear"
            ></button>
            <button
              pButton
              pRipple
              *ngIf="userRightsService.getUserRead() == 1"
              (click)="goToDashboard(analyse.id)"
              class="p-button-rounded p-button-success"
              pTooltip="Graphiques"
              type="button"
              icon="fa fa-bar-chart"
            ></button>
            <button
              pButton
              pRipple
              *ngIf="userRightsService.getUserCreate() == 1"
              (click)="archivate(analyse.id)"
              class="p-button-rounded p-button"
              pTooltip="Archiver"
              type="button"
              icon="pi pi-save"
            ></button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template let-analyse pTemplate="gridItem">
      <div class="p-col-12 p-md-4">
        <div
          class="analyse-grid-item card"
          [style]="{ 'background-color': analyse.valid ? '#666' : analyse.parent ? '#6fcc03a6' : 'white' }"
        >
          <div class="analyse-grid-item-top">
            <div>
              <i class="pi pi-tag analyse-category-icon"></i>
              <span class="analyse-category">{{ analyse.parent ? "Analyse mère" : "Analyse" }}</span>
            </div>
          </div>
          <div
            class="analyse-grid-item-content"
            (click)="analyse.parent ? goToParentAnalyse(analyse.id_client) : goToDetailsCard(analyse)"
          >
            <img style="text-align: center" [src]="imgLogo" />
            <div class="analyse-name">{{ analyse.name }}</div>
            <div class="analyse-description">{{ analyse.description }}</div>
            <div class="analyse-description">{{ analyse.nameClient }}</div>
          </div>
          <div class="analyse-grid-item-bottom" *ngIf="!analyse.valid && !analyse.parent">
            <button
              pButton
              pRipple
              *ngIf="userRightsService.getUserDelete() == 1"
              type="button"
              class="p-button-rounded p-button-danger"
              icon="fa fa-close"
              (click)="deleteAnalyseLine(analyse.id)"
            ></button>
            <button
              pButton
              pRipple
              *ngIf="userRightsService.getUserRead() == 1"
              (click)="onRowSelect(analyse)"
              class="p-button-rounded p-button-warning"
              pTooltip="Paramétrages analyse"
              type="button"
              icon="fa fa-gear"
            ></button>
            <button
              pButton
              pRipple
              *ngIf="userRightsService.getUserRead() == 1"
              (click)="goToDashboard(analyse.id)"
              class="p-button-rounded p-button-success"
              pTooltip="Graphiques"
              type="button"
              icon="fa fa-bar-chart"
            ></button>
            <button
              pButton
              pRipple
              *ngIf="userRightsService.getUserCreate() == 1"
              (click)="archivate(analyse.id)"
              class="p-button-rounded p-button"
              pTooltip="Archiver"
              type="button"
              icon="pi pi-save"
            ></button>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>

<p-dialog
  appendTo="body"
  [header]="'Detail / ' + (analyse ? analyse.name : '')"
  (onHide)="closeAnalyses()"
  [(visible)]="displayDialog"
  showEffect="fade"
  [modal]="true"
>
  <p-tabView *ngIf="displayDialog">
    <p-tabPanel
      header="Paramètrage Analyse"
      leftIcon="pi pi-calendar"
      [selected]="true"
    >
      <div class="p-fluid" *ngIf="analyse">
        <div class="p-field p-grid" *ngIf="!newAnalyse">
          <div class="p-col-4">
            <label for="id">ID: </label>
          </div>
          <div class="p-col-8">
            <input pInputText id="id" [(ngModel)]="analyse.id" disabled />
          </div>
        </div>
        <div class="p-field p-grid">
          <div class="p-col-4">
            <label for="name">Libellé: </label>
          </div>
          <div class="p-col-8">
            <input
              #nameInput
              pInputText
              id="name"
              [(ngModel)]="analyse.name"
              required
              autoFocus
            />
          </div>
        </div>
        <div class="p-field p-grid">
          <div class="p-col-4">
            <label for="name">Commentaires/Précisions: </label>
          </div>
          <div class="p-col-8">
            <input
              pInputText
              id="description"
              [(ngModel)]="analyse.description"
              required
            />
          </div>
        </div>
        <div class="p-field p-grid" *ngIf="false">
          <div class="p-col-4">
            <label>Type Evaluation Risque: </label>
          </div>
          <div class="p-col-8">
            <p-dropdown
              appendTo="body"
              [filter]="true"
              [dataKey]="id"
              [options]="listTypeEvalRisks"
              [(ngModel)]="selectedTypeEvalRiskList"
              placeholder="Selectionner une type de risque"
              optionLabel="name"
              [showClear]="true"
            ></p-dropdown>
          </div>
        </div>
        <div class="p-field p-grid" *ngIf="!newAnalyse">
          <div class="p-col-4">
            <label for="dtcreate">Date de création: </label>
          </div>
          <div class="p-col-8">
            <input
              pInputText
              id="dtcreate"
              [(ngModel)]="analyse.dtcreate"
              disabled
            />
          </div>
        </div>
        <div class="p-field p-grid" *ngIf="!newAnalyse">
          <div class="p-col-4">
            <label for="dtmodif">Date de modification: </label>
          </div>
          <div class="p-col-8">
            <input
              pInputText
              id="dtmodif"
              [(ngModel)]="analyse.dtmodif"
              disabled
            />
          </div>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel
      header="Colonnes"
      leftIcon="pi pi-inbox"
      [disabled]="newAnalyse"
    >
      <app-columns
        [refresh]="refreshColumns"
        [id_analyse]="analyse ? analyse.id : 0"
        [id_client]="selectedClient ? selectedClient.code : 0"
      ></app-columns>
    </p-tabPanel>
    <p-tabPanel
      header="Graphiques"
      leftIcon="pi pi-chart-bar"
      [disabled]="newAnalyse"
    >
      <app-param-graph
        [refresh]="refreshColumns"
        [id]="analyse ? analyse.id : 0"
        [id_client]="selectedClient ? selectedClient.code : 0"
        type_object="analyse"
      ></app-param-graph>
    </p-tabPanel>
  </p-tabView>

  <p-footer>
    <div
      *ngIf="user_block == 0 || user_block == currentUser"
      class="ui-dialog-buttonpane ui-helper-clearfix"
    >
      <button
        type="button"
        *ngIf="userRightsService.getUserUpdate() == 1"
        pButton
        icon="fa fa-check"
        (click)="save()"
        label="Sauvegarder"
      ></button>
    </div>
  </p-footer>
</p-dialog>
<p-progressSpinner *ngIf="!responseAnalyses"></p-progressSpinner>

<p-dialog
  appendTo="body"
  [focusOnShow]="false"
  header="Importation de données"
  [(visible)]="displayDialogImport"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
>
  <h3 class="first">Importation analyses</h3>
  <h5>Format de fichier:</h5>
  <p>Nom ; Description</p>
  <p>Séparateur standard: ";"</p>
  <p-fileUpload
    name="file"
    url="{{ config.API_Host }}import/{{
      selectedClient ? selectedClient.code : 0
    }}/bo_analyse"
    (onUpload)="importData($event)"
    chooseLabel="Sélectionner"
    uploadLabel="Importer"
    cancelLabel="Annuler"
    accept=".csv"
    maxFileSize="1000000"
  >
    <ng-template pTemplate="content">
      <ul *ngIf="importFiles.length">
        <li *ngFor="let file of importFiles">
          {{ file.name }} - {{ file.size }} bytes
        </li>
      </ul>
    </ng-template>
  </p-fileUpload>
</p-dialog>

<p-dialog
  appendTo="body"
  [focusOnShow]="false"
  [header]="'Cartographie des processus / ' + selectedClientName"
  [(visible)]="displayDialogCarto"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  [resizable]="true"
>
  <img [src]="imgSrc" />
</p-dialog>
<p-dialog
  header="Action management"
  [(visible)]="displayPlanManagement"
  showEffect="fade"
  [modal]="true"
  [resizable]="true"
>
  <app-plan *ngIf="selectedClient" [id_client]="selectedClient.id_structure" [page]="1" [buttons]="false"></app-plan>
</p-dialog>

<p-toast></p-toast>
