export class Analyse {
  id: number;
  name: string;
  nameClient: string;
  description: string;
  id_type: number;
  id_client: number;
  valid: boolean;
  parent: boolean = false;
  dtcreate: string;
  dtmodif: string;
}
