<p-table #wddt csvSeparator=";" [columns]="selectedColumns" [value]="filteredAnalyseLines" editMode="row" [paginator]="true"
         [rows]="50" [totalRecords]="totalRecords" [loading]="loading" [(first)]="first"
         [style]="{width:'100%'}" dataKey="id">
    <ng-template pTemplate="caption" class="workspaceClass">
        <div class="container-fluid">
            <div class="row">
              <div *ngIf="analyse && !analyse.valid">
                <button *ngIf="userRightsService.getUserCreate() == 1" type="button" pTooltip="Ajouter" tooltipPosition="top" pButton icon="fa fa-plus"
                        (click)="addLine()" [disabled]="!columns"></button>
                <button *ngIf="userRightsService.getUserCreate() == 1" type="button" pTooltip="Ajouter par formulaire" tooltipPosition="top" pButton icon="fa fa-plus-circle"
                      (click)="addLineByForm()" [disabled]="!columns"></button>
                <button *ngIf="userRightsService.getUserCreate() == 1 || userRightsService.getUserUpdate() == 1" type="button" pTooltip="Options ligne" tooltipPosition="top" pButton icon="fa fa-edit"
                        class="p-button-success" (click)="showDelete()"></button>
                <button *ngIf="userRightsService.getUserDelete() == 1" type="button" pTooltip="Supprimer toutes les lignes" tooltipPosition="top" pButton icon="fa fa-trash"
                        class="p-button-danger" (click)="deleteAllLines()" [disabled]="analyseLines.length == 0"></button>
                <button *ngIf="userRightsService.getUserDelete() == 1" type="button" pTooltip="Supprimer toutes les lignes dupliquées" tooltipPosition="top" pButton icon="fa fa-clone"
                        class="p-button-danger" (click)="deleteAllLinesDuplicates()" [disabled]="analyseLines.length == 0"></button>
                <button *ngIf="userRightsService.getUserCreate() == 1" type="button" pTooltip="Importer" tooltipPosition="top" pButton icon="fa fa-download"
                        class="p-button-info" (click)="importCsv()"></button>
              </div>
              <button *ngIf="userRightsService.getUserRead() == 1" type="button" pTooltip="Exporter" tooltipPosition="top" pButton icon="fa fa-upload"
                      class="p-button-info" (click)="exportCsv()"></button>
              <button *ngIf="userRightsService.getUserRead() == 1" type="button" pTooltip="Exporter les actions" tooltipPosition="top" pButton icon="pi pi-file-excel"
                    class="p-button-success" (click)="openExportActionDialog()"></button>
<!--                <button *ngIf="userRightsService.getUserRead() == 1" type="button" pTooltip="Exporter pour graphiques Ariskan" tooltipPosition="top" pButton icon="fa fa-upload"-->
<!--                        class="p-button-raised" (click)="exportCsvForGraphAriskan()"></button>-->
              <button type="button" pTooltip="Recharger" tooltipPosition="top" pButton icon="fa fa-refresh"
                      class="p-button-warning" (click)="loadData()"></button>
<!--                <button type="button" pTooltip="Recalcul formules" tooltipPosition="top" pButton icon="fa fa-calculator"-->
<!--                        class="p-button-warning" (click)="recalculateAllFormulas()"></button>-->
              <button type="button" pTooltip="Activer les filtres des colonnes" tooltipPosition="top" pButton icon="fa fa-filter"
                    class="p-button-warning" (click)="showColFilters()"></button>

              <p-multiSelect appendTo="body" (onChange)="reorderCols(false)" [options]="cols"
                             [(ngModel)]="selectedColumns" [filter]="true"
                             optionLabel="header"
                             selectedItemsLabel="{0} colonnes sélectionnées" [style]="{minWidth: '150px'}"
                             defaultLabel="Choix colonnes">
              </p-multiSelect>
              <p-multiSelect appendTo="body" (onChange)="reorderCols(true)" [options]="loadColGroupsOptions()"
                             [(ngModel)]="selectedColumnsGroup" [filter]="true"
                             optionLabel="header"
                             selectedItemsLabel="{0} colonnes sélectionnées" [style]="{minWidth: '150px'}"
                             defaultLabel="Choix groups colonnes">
              </p-multiSelect>
              <span class="ui-float-label">
                  <input class="offset-1" id="float-input" type="text" placeholder="Rechercher + Entrer" size="30" pInputText (keyup.enter)="searchInAnalyse($event.target.value)">
              </span>
              <div style="padding-top: 8px; padding-left: 50px;">{{ nameAnalyse }}</div>
              <button pButton type="button" label="Proposer un scénario" icon="pi pi-send" style="margin-left: auto;" (click)="showProposeScenarioDialog()"></button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
        <colgroup appendTo="body">
            <col *ngIf="showDeleteColumn || showModifyColumn"  style="width:3em">
            <col style="width:4em">
            <col *ngFor="let col of columns" [ngStyle]="{'width' : col.pixel != 0 ? col.pixel + 'px' : '150px'}">
            <col style="width:7em">
        </colgroup>
    </ng-template>
    <ng-template let-rowData pTemplate="header" let-columns>
        <tr *ngIf="colGroups" class="groupCol" appendTo="body">
            <th *ngIf="showDeleteColumn || showModifyColumn" style="width:3em;">X</th>
            <th style="width:3em !important;">ID</th>
          <th *ngFor="let colGroup of colGroups" [attr.colspan]="colGroup.colspan"
                [ngStyle]="{ 'background-color' : colGroup.group_color, 'text-align' : 'center'}">
                {{ colGroup.group_name }}
            </th>
          <th style="width:3em !important;"></th>
        </tr>
        <tr class="col">
            <th *ngIf="showDeleteColumn || showModifyColumn" style="width:3em;">X</th>
            <th style="width:3em !important;">ID</th>
            <th *ngFor="let col of columns"
                [ngStyle]="{'background-color' : findValueInArray(colGroups, 'group_name', col.group_name).group_color, 'text-align' : 'center'}">
                {{col.header}}<i style="color: yellowgreen" *ngIf="isColumnFiltered(col)" class="fa fa-filter"></i>
            </th>
            <th [ngStyle]="{'background-color': '#34A835 !important'}">Validation</th>
        </tr>
      <tr class="colHidden" [ngStyle]="{'display': colFilters ? 'contents' :'none'}">
        <th *ngIf="showDeleteColumn || showModifyColumn" style="width:3em;">X</th>
        <th style="padding: 4px"><button type="button" pTooltip="Supprimer tous les filtres" tooltipPosition="top" pButton icon="fa fa-close"
                    class="p-button-danger" (click)="clearFiltersColumns()"></button>
        </th>
        <th *ngFor="let col of columns"
            [ngStyle]="{'background-color' : findValueInArray(colGroups, 'group_name', col.group_name).group_color, 'text-align' : 'center'}">
            <div class="ui-inputgroup">
              <input class="offset-1 searchFilters" type="text" placeholder="Rechercher + Entrer" size="30" [attr.colInfo]="getJsonValue(col)" pInputText (keyup.enter)="searchInAnalyseByColumn($event.target.value, col)">
            </div>
        </th>
        <th>
          <p-checkbox (onChange)="searchByValidation($event.checked)" binary="true" inputId="binary"></p-checkbox>
        </th>

      </tr>
    </ng-template>
    <ng-template id="workspace_table" pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr style="height: 50px;" [pEditableRow]="rowData" [ngStyle]="{'background-color' : rowData.duplication == 1 ? '#ffffaa4a' : 'none'}">
            <td style="width:3em !important;padding: 5px;" *ngIf="showDeleteColumn || showModifyColumn">
                <button style="height: 2em" *ngIf="userRightsService.getUserDelete() == 1" pButton type="button" pTooltip="Supprimer la ligne" tooltipPosition="right" (click)="deleteLine(rowData.id)" class="p-button-danger"
                        icon="fa fa-close"></button>
                <button style="height: 2em" *ngIf="userRightsService.getUserCreate() == 1" pButton type="button" pTooltip="Duplication de la ligne pour test" tooltipPosition="right" (click)="duplicateLine(rowData.id)" class="p-button-warning"
                        icon="fa fa-clone"></button>
                <button style="height: 2em" pButton type="button" *ngIf="editing && userRightsService.getUserUpdate() == 1" pTooltip="Activer la modification de la ligne" tooltipPosition="right"  (click)="modifyLine(rowData.id)" pCancelEditableRow
                        class="p-button-success" icon="fa fa-pencil"></button>
              <button style="height: 2em" pButton type="button" *ngIf="!editing && userRightsService.getUserUpdate() == 1" pTooltip="Activer la modification de la ligne" tooltipPosition="right"  (click)="modifyLine(rowData.id)" pInitEditableRow
                      class="p-button-success" icon="fa fa-pencil"></button>
                <button style="height: 2em" pButton type="button" *ngIf="userRightsService.getUserUpdate() == 1" pTooltip="Activer la modification de la ligne par formulaire" tooltipPosition="right"  (click)="modifyLineForm(rowData)"
                      class="p-button-warning" icon="fa fa-pencil"></button>
                <button style="height: 2em" pButton type="button" *ngIf="userRightsService.getUserRead() == 1" pTooltip="Exporter fiche PDF" tooltipPosition="right"  (click)="exportPDFLine(rowData)"
                        class="p-button-secondary" icon="fa fa-upload"></button>
            </td>
            <td style=" padding: 0px">
                <input *ngIf="userRightsService.getUserUpdate() == 1" style="width: 3em; font-size: 12px" type="text" [(ngModel)]="rowData.order_num" (ngModelChange)="changeOrder(rowData)" pInputText>
            </td>
            <td *ngFor="let col of columns" [ngStyle]="{'background-color' : (col.color_range != '' && col.color_range != 'Aucune') ?
                ((col.type_object == 'Date' && findValueInArray(rowData.values, 'id_column', col.id_column).value != '' && tranformDate(findValueInArray(rowData.values, 'id_column', col.id_column).value) < today) ? '#ff7878' :
                (getRangeColor(findValueInArray(rowData.values, 'id_column', col.id_column), col)))
                : ''}"

                pEditableColumn>
                <p-cellEditor  *ngIf="analyse && !analyse.valid">
                    <ng-template pTemplate="input" *ngIf="
                       ( findValueInArray(rowData.values   , 'id_column', col.id_column).type_object == 'Nombre')
                    ">
                        <input *ngIf="userRightsService.getUserUpdate() == 1" type="number" style="width: 50% !important" pInputText [disabled]="!userRightsService.userInGroups(col.authorized_groups)"
                               [(ngModel)]="findValueInArray(rowData.values , 'id_column', col.id_column).value"
                               [placeholder]="findValueInArray(rowData.values , 'id_column', col.id_column).value"
                               (keydown.enter)="changeValueText({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values   , 'id_column', col.id_column)})"
                               (change)="changeValueText({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values   , 'id_column', col.id_column)})"
                               (keydown.tab)="changeValueText({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values   , 'id_column', col.id_column)})"
                               >
                    </ng-template>
                  <ng-template pTemplate="input" *ngIf="
                       (findValueInArray(rowData.values,'id_column', col.id_column).type_object == 'Booléen')
                    ">
                  <p-inputSwitch (onChange)="changeValueText({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values,'id_column', col.id_column)})"
                                 [disabled]="!userRightsService.userInGroups(col.authorized_groups) || !userRightsService.getUserUpdate()" [(ngModel)]="findValueInArray(rowData.values , 'id_column', col.id_column).value"></p-inputSwitch>

                  </ng-template>
                    <ng-template pTemplate="input" *ngIf="
                       ( findValueInArray(rowData.values   , 'id_column', col.id_column).type_object == 'Texte' )
                    ">
                        <textarea *ngIf="userRightsService.getUserUpdate() == 1" rows="4" pInputTextarea [disabled]="!userRightsService.userInGroups(col.authorized_groups)"
                               [(ngModel)]="findValueInArray(rowData.values , 'id_column', col.id_column).value"
                               [placeholder]="findValueInArray(rowData.values , 'id_column', col.id_column).value"
                               (keydown.arrowRight)="doNothing($event)"
                               (keydown.arrowLeft)="doNothing($event)"
                               (keydown.arrowUp)="doNothing($event)"
                               (keydown.arrowDown)="doNothing($event)"
                               (keydown.enter)="changeValueText({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values   , 'id_column', col.id_column)})"
                               (change)="changeValueText({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values   , 'id_column', col.id_column)})"
                               (keydown.tab)="changeValueText({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values   , 'id_column', col.id_column)})"
                               ></textarea>
                    </ng-template>
                    <ng-template pTemplate="input"
                                 *ngIf="findValueInArray(rowData.values   , 'id_column', col.id_column).type_object == 'Date'">
                        <p-calendar *ngIf="userRightsService.getUserUpdate() == 1" [style]="{width : '250px'}" [(ngModel)]="dateObject" [disabled]="!userRightsService.userInGroups(col.authorized_groups)"
                                    [monthNavigator]="true"
                                    [yearNavigator]="true"
                                    (onFocus)="dateObject = tranformDate(findValueInArray(rowData.values, 'id_column', col.id_column).value)"
                                    (ngModelChange)="changeValueText({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values   , 'id_column', col.id_column)})"
                                    (onClearClick)="changeValueText({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values   , 'id_column', col.id_column)})"
                                    yearRange="2000:2030" showButtonBar="true" dateFormat="dd/mm/yy"></p-calendar>
                    </ng-template>
                  <ng-template pTemplate="input"
                               *ngIf="findValueInArray(rowData.values, 'id_column', col.id_column).type_object == 'Plan'">
                    <div style="text-align: center">
                      <div style="float: left;">{{findCountPlanActions(rowData.id)}}</div>
                      <div>{{findLinePlanCalc(rowData.id)}}%</div>
                      <button style="height: 3em" type="button" pTooltip="Ajouter" tooltipPosition="top" pButton icon="pi pi-book"
                              (click)="openPlanDialog(rowData, col)" [disabled]="!columns || !userRightsService.userInGroups(col.authorized_groups)"></button>
                    </div>
                  </ng-template>
                    <ng-template pTemplate="input" *ngIf="
                        findValueInArray(rowData.values, 'id_column', col.id_column).type_object != 'Nombre'
                        && findValueInArray(rowData.values   , 'id_column', col.id_column).type_object != 'Texte'
                        && findValueInArray(rowData.values   , 'id_column', col.id_column).type_object != 'Plan'
                        && findValueInArray(rowData.values   , 'id_column', col.id_column).type_object != 'Date'
                        && findValueInArray(rowData.values   , 'id_column', col.id_column).type_object != 'Formule'
                        && findValueInArray(rowData.values   , 'id_column', col.id_column).type_object != 'List'
                        && findValueInArray(rowData.values   , 'id_column', col.id_column).type_object != 'Risk'
                        && findValueInArray(rowData.values   , 'id_column', col.id_column).type_object != 'Booléen'
                        ">
                        <p-dropdown *ngIf="userRightsService.getUserUpdate() == 1" appendTo="body" [filter]="true"
                                    [options]="findValueInArray(listObjects, 'id_column', col.id_column).data"
                                    [(ngModel)]="findValueInArray(rowData.values   , 'id_column', col.id_column).value_id"
                                    [style]="{'width':'100%'}"
                                    placeholder="Selectionnez valeur:"
                                    (onFocus)="makeList(findValueInArray(rowData.values, 'id_column', col.id_column), rowData.id, col)"
                                    (onChange)="changeValueList({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values   , 'id_column', col.id_column)})"
                                    (keydown.enter)="changeValueList({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values   , 'id_column', col.id_column)})"
                                    (keydown.tab)="changeValueList({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values   , 'id_column', col.id_column)})"
                                    [showClear]="true" [autoDisplayFirst]="false" [disabled]="!userRightsService.userInGroups(col.authorized_groups)"
                        ></p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="input" *ngIf="
                            findValueInArray(rowData.values   , 'id_column', col.id_column).type_object == 'List'
                        ">
                        <p-dropdown *ngIf="userRightsService.getUserUpdate() == 1" appendTo="body" #selectedValueList [filter]="true"
                                    [options]="findValueInArray(rowData.values, 'id_column', col.id_column).value"
                                    [(ngModel)]="findValueInArray(rowData.values, 'id_column', col.id_column).selectedValue"
                                    [style]="{'width':'100%'}"
                                    placeholder="Selectionnez valeur:"
                                    (onChange)="changeValueListList({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, id_column: col.id_column, value: (selectedValueList.selectedOption ? selectedValueList.selectedOption.label : '')})"
                                    (keydown.enter)="changeValueListList({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, id_column: col.id_column, value: (selectedValueList.selectedOption ? selectedValueList.selectedOption.label : '')})"
                                    (keydown.tab)="changeValueListList({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, id_column: col.id_column, value: (selectedValueList.selectedOption ? selectedValueList.selectedOption.label : '')})"
                                    [showClear]="true" [autoDisplayFirst]="false" [disabled]="!userRightsService.userInGroups(col.authorized_groups)"
                        ></p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="input" *ngIf="
                            findValueInArray(rowData.values , 'id_column', col.id_column).type_object == 'Risk'
                        ">
                        <p-dropdown *ngIf="userRightsService.getUserUpdate() == 1" appendTo="body" [filter]="true"
                                    [options]="findValueInArray(listObjects, 'id_column', col.id_column).data"
                                    [(ngModel)]="findValueInArray(rowData.values, 'id_column', col.id_column).value_id"
                                    [style]="{'width':'100%'}"
                                    placeholder="Selectionnez valeur:"
                                    (onFocus)="makeList(findValueInArray(rowData.values, 'id_column', col.id_column), rowData.id, col)"
                                    (onChange)="changeValueList({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values   , 'id_column', col.id_column)})"
                                    (keydown.enter)="changeValueList({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values   , 'id_column', col.id_column)})"
                                    (keydown.tab)="changeValueList({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values   , 'id_column', col.id_column)})"
                                    [showClear]="true" [autoDisplayFirst]="false" [disabled]="!userRightsService.userInGroups(col.authorized_groups)"
                        ></p-dropdown>
                    </ng-template>
                    <ng-template
                            *ngIf="col.type_object != 'Formule' && col.type_object != 'Plan' && col.type_object != 'Texte' && col.type_object != 'List' && col.type_object != 'Risk' && col.type_object != 'Probabilité' && col.type_object != 'Processus' && col.type_object != 'Date' && col.type_object != 'Theme' && col.type_object != 'Sous-Theme'"
                            pTemplate="output">
                        <div style="text-align: right">{{ (col.color_range != '' && col.color_range != 'Aucune') ? getRangeName(
                            findValueInArray(rowData.values, 'id_column', col.id_column), col) : '' }}
                        </div>
                        <div style=" white-space: pre-line;">
                            {{ findValueInArray(rowData.values, 'id_column', col.id_column).value }}
                        </div>
                    </ng-template>
                    <ng-template
                            *ngIf="col.type_object == 'Formule'"
                            pTemplate="output">
                        <div style="text-align: right">{{ (col.color_range != '' && col.color_range != 'Aucune') ? getRangeName(
                            findValueInArray(rowData.values, 'id_column', col.id_column), col) : '' }}
                        </div>
                        <div *ngIf="formuleToShow(rowData, col.id_column)">
                            {{ (col.id_subobject != 0 ? findValueInArray(rowData.values, 'id_column', col.id_column).value : "") }}
                        </div>
                    </ng-template>
                    <ng-template
                            *ngIf="col.type_object == 'Texte'"
                            pTemplate="output">
                        <div style="text-align: right">{{ (col.color_range != '' && col.color_range != 'Aucune') ? getRangeName(
                            findValueInArray(rowData.values, 'id_column', col.id_column), col) : '' }}
                        </div>
                        <div style=" white-space: pre-line;">
                            {{ replaceAllNewLines(findValueInArray(rowData.values, 'id_column', col.id_column).value) }}
                        </div>
                    </ng-template>
                  <ng-template
                    *ngIf="col.type_object == 'Plan'"
                    pTemplate="output">
                    <div style="text-align: center">
                      <div style="float: left;">{{findCountPlanActions(rowData.id)}}</div>
                      <div>{{findLinePlanCalc(rowData.id)}}%</div>
                      <button style="height: 3em" type="button" pTooltip="Ajouter" tooltipPosition="top" pButton icon="pi pi-book"
                              (click)="openPlanDialog(rowData, col)" [disabled]="!columns || !userRightsService.userInGroups(col.authorized_groups)"></button>
                    </div>
                  </ng-template>
                  <ng-template *ngIf="col.type_object == 'Risk' || col.type_object == 'Probabilité'"
                               pTemplate="output">
                      <div style="text-align: right">{{ (col.color_range != '' && col.color_range != 'Aucune') ? getRangeName(
                          findValueInArray(rowData.values, 'id_column', col.id_column), col) : '' }}
                      </div>
                      {{findValueInArray(rowData.values, 'id_column', col.id_column).value.id}}<br>
                      {{findValueInArray(rowData.values, 'id_column', col.id_column).value.name}}
                  </ng-template>
                  <ng-template *ngIf="col.type_object == 'List'" pTemplate="output">
                      {{findValueInArray(rowData.values, 'id_column', col.id_column).selectedValue}}
                  </ng-template>
                  <ng-template
                          *ngIf="col.type_object == 'Processus' || col.type_object == 'Theme' || col.type_object == 'Sous-Theme'"
                          pTemplate="output">
                      {{findValueInArray(rowData.values, 'id_column', col.id_column).value.name}}
                  </ng-template>
                  <ng-template *ngIf="col.type_object == 'Date'" pTemplate="output">
                      {{
                    tranformDate(findValueInArray(rowData.values, 'id_column', col.id_column).value) &&
                    tranformDate(findValueInArray(rowData.values, 'id_column', col.id_column).value) != ''
                      ? dateToString(tranformDate(findValueInArray(rowData.values, 'id_column', col.id_column).value)) : ''}}
                  </ng-template>
                  <ng-template *ngIf="col.type_object == 'Booléen'" pTemplate="output">
                    <p-inputSwitch (onChange)="changeValueText({idLigne: rowData.id, type_object: findValueInArray(rowData.values, 'id_column', col.id_column).type_object, value: findValueInArray(rowData.values,'id_column', col.id_column)})"
                                   [disabled]="userRightsService.getUserUpdate() !== 1 || !userRightsService.userInGroups(col.authorized_groups)" [(ngModel)]="findValueInArray(rowData.values , 'id_column', col.id_column).value"></p-inputSwitch>

                  </ng-template>
                </p-cellEditor>

              <div *ngIf="analyse && analyse.valid">
                <div
                  *ngIf="col.type_object != 'Formule' && col.type_object != 'Booléen' && col.type_object != 'Plan' && col.type_object != 'Texte' && col.type_object != 'List' && col.type_object != 'Risk' && col.type_object != 'Probabilité' && col.type_object != 'Processus' && col.type_object != 'Date' && col.type_object != 'Theme' && col.type_object != 'Sous-Theme'"
                  >
                  <div style="text-align: right">{{ (col.color_range != '' && col.color_range != 'Aucune') ? getRangeName(
                    findValueInArray(rowData.values, 'id_column', col.id_column), col) : '' }}
                  </div>
                  <div style=" white-space: pre-line;">
                    {{ findValueInArray(rowData.values, 'id_column', col.id_column).value }}
                  </div>
                </div>
                <div
                  *ngIf="col.type_object == 'Formule'">
                  <div style="text-align: right">{{ (col.color_range != '' && col.color_range != 'Aucune') ? getRangeName(
                    findValueInArray(rowData.values, 'id_column', col.id_column), col) : '' }}
                  </div>
                  <div>
                    {{ (col.id_subobject != 0 ? findValueInArray(rowData.values, 'id_column', col.id_column).value : "") }}
                  </div>
                </div>
                <div
                  *ngIf="col.type_object == 'Plan'">
                  <div style="text-align: right">
                    <button type="button" pTooltip="Ajouter" tooltipPosition="top" pButton icon="fa fa-plus"
                            (click)="openPlanDialog(rowData, col)" [disabled]="!columns"></button>
                  </div>
                  <div>
                    {{ (col.id_subobject != 0 ? findValueInArray(rowData.values, 'id_column', col.id_column).value : "") }}
                  </div>
                </div>
                <div
                  *ngIf="col.type_object == 'Texte'">
                  <div style="text-align: right">{{ (col.color_range != '' && col.color_range != 'Aucune') ? getRangeName(
                    findValueInArray(rowData.values, 'id_column', col.id_column), col) : '' }}
                  </div>
                  <div style=" white-space: pre-line;">
                    {{ replaceAllNewLines(findValueInArray(rowData.values, 'id_column', col.id_column).value) }}
                  </div>
                </div>
                <div *ngIf="col.type_object == 'Risk' || col.type_object == 'Probabilité'">
                  <div style="text-align: right">{{ (col.color_range != '' && col.color_range != 'Aucune') ? getRangeName(
                    findValueInArray(rowData.values, 'id_column', col.id_column), col) : '' }}
                  </div>
                  {{findValueInArray(rowData.values, 'id_column', col.id_column).value.id}}<br>
                  {{findValueInArray(rowData.values, 'id_column', col.id_column).value.name}}
                </div>
                <div *ngIf="col.type_object == 'List'">
                  {{findValueInArray(rowData.values, 'id_column', col.id_column).selectedValue}}
                </div>
                <div
                  *ngIf="col.type_object == 'Processus' || col.type_object == 'Theme' || col.type_object == 'Sous-Theme'">
                  {{findValueInArray(rowData.values, 'id_column', col.id_column).value.name}}
                </div>
                <div *ngIf="col.type_object == 'Date'">
                  {{
                  tranformDate(findValueInArray(rowData.values, 'id_column', col.id_column).value) &&
                  tranformDate(findValueInArray(rowData.values, 'id_column', col.id_column).value) != ''
                    ? dateToString(tranformDate(findValueInArray(rowData.values, 'id_column', col.id_column).value)) : ''}}
                </div>
                <div *ngIf="col.type_object == 'Booléen'">
                  <p-inputSwitch disabled [(ngModel)]="findValueInArray(rowData.values , 'id_column', col.id_column).value"></p-inputSwitch>
                </div>
              </div>
            </td>
            <td>
              <p-checkbox (onChange)="validateLine(rowData)" [(ngModel)]="rowData.validation" binary="true" inputId="binary"></p-checkbox>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-dialog appendTo="body" [focusOnShow]="false" header="Importation de données" [(visible)]="displayDialogImport" [responsive]="true"
          showEffect="fade" [modal]="true">

    <p-tabView>
        <p-tabPanel header="Insertion" leftIcon="pi pi-calendar">
            <h3 class="first">Importer nouvelles lignes dans le tableau</h3>
            <h5>Format de fichier:</h5>
            <p>Séparateur: ";"</p>
            <p-fileUpload name="file" url="{{config.API_Host}}importLinesAnalyse/{{id_client}}/{{id}}"
                          (onProgress)="loading = true"
                          (onError)="importError($event)"
                          (onUpload)="importData($event)"
                          chooseLabel="Sélectionner"
                          uploadLabel="Importer"
                          cancelLabel="Annuler"
                          accept=".csv" maxFileSize="1000000">
                <ng-template pTemplate="content">
                    <ul *ngIf="importFiles.length">
                        <li *ngFor="let file of importFiles">{{file.name}} - {{file.size}} bytes</li>
                    </ul>
                </ng-template>
            </p-fileUpload>
        </p-tabPanel>
        <p-tabPanel header="Modification" leftIcon="pi pi-inbox">
            <h3 class="first">Modifier les lignes existants du tableau</h3>
            <h5>Format de fichier:</h5>
            <p>Séparateur: ";"</p>
            <p-fileUpload name="file" url="{{config.API_Host}}importModifyAnalyse/{{id_client}}/{{id}}"
                          (onError)="importError($event)"
                          (onProgress)="loading = true"
                          (onUpload)="importData($event)"
                          chooseLabel="Sélectionner"
                          uploadLabel="Importer"
                          cancelLabel="Annuler"
                          accept=".csv" maxFileSize="1000000">
                <ng-template pTemplate="content">
                    <ul *ngIf="importFiles.length">
                        <li *ngFor="let file of importFiles">{{file.name}} - {{file.size}} bytes</li>
                    </ul>
                </ng-template>
            </p-fileUpload>
        </p-tabPanel>
    </p-tabView>
</p-dialog>
<p-dialog appendTo="body" [focusOnShow]="false" header="Nouveau scénario" (onHide)="closeFormDialog()" [(visible)]="showFormDialog" [responsive]="true" showEffect="fade" [modal]="true">
  <div class="p-fluid" *ngIf="showFormDialog">
    <div *ngFor="let col of selectedColumns">
      <div *ngIf="col.form"  class="p-field p-grid">
        <div class="p-col-4">
          <label [for]="col.id_column"> {{col.header}} </label>
        </div>
        <div class="p-col-8" *ngIf="dataForForm">
          <div *ngIf="findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object == 'Nombre'">
            <input *ngIf="userRightsService.getUserUpdate() == 1" [id]="col.id_column" type="number" style="width: 50% !important" pInputText
                   [(ngModel)]="findValueInArray(dataForForm.values, 'id_column', col.id_column).value"
                   [placeholder]="findValueInArray(dataForForm.values, 'id_column', col.id_column).value"
                   (keydown.enter)="changeValueText({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values, 'id_column', col.id_column).type_object, value: findValueInArray(dataForForm.values, 'id_column', col.id_column)})"
                   (change)="changeValueText({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values, 'id_column', col.id_column).type_object, value: findValueInArray(dataForForm.values, 'id_column', col.id_column)})"
                   (keydown.tab)="changeValueText({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values, 'id_column', col.id_column).type_object, value: findValueInArray(dataForForm.values, 'id_column', col.id_column)})"
                  >
          </div>

          <div *ngIf="findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object == 'Texte'">
                          <textarea *ngIf="userRightsService.getUserUpdate() == 1" [id]="col.id_column" rows="4" pInputTextarea
                                    [(ngModel)]="findValueInArray(dataForForm.values , 'id_column', col.id_column).value"
                                    [placeholder]="findValueInArray(dataForForm.values , 'id_column', col.id_column).value"
                                    (keydown.enter)="changeValueText({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object, value: findValueInArray(dataForForm.values   , 'id_column', col.id_column)})"
                                    (change)="changeValueText({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object, value: findValueInArray(dataForForm.values   , 'id_column', col.id_column)})"
                                    (keydown.tab)="changeValueText({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object, value: findValueInArray(dataForForm.values   , 'id_column', col.id_column)})"
                                    ></textarea>
          </div>
          <div *ngIf="findValueInArray(dataForForm.values, 'id_column', col.id_column).type_object == 'Date'">
            <p-calendar *ngIf="userRightsService.getUserUpdate() == 1" [id]="col.id_column" [style]="{width : '250px'}" [(ngModel)]="dateObject"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        (onFocus)="dateObject = tranformDate(findValueInArray(dataForForm.values, 'id_column', col.id_column).value)"
                        (ngModelChange)="changeValueText({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object, value: findValueInArray(dataForForm.values   , 'id_column', col.id_column)})"
                        (onClearClick)="changeValueText({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object, value: findValueInArray(dataForForm.values   , 'id_column', col.id_column)})"
                        yearRange="2000:2030" showButtonBar="true" dateFormat="dd/mm/yy"></p-calendar>
          </div>
          <div *ngIf="findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object != 'Nombre'
                          && findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object != 'Texte'
                          && findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object != 'Date'
                          && findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object != 'Formule'
                          && findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object != 'List'
                          && findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object != 'Risk'
                          && findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object != 'Booléen'
                          ">
            <p-dropdown *ngIf="userRightsService.getUserUpdate() == 1" [id]="col.id_column"  appendTo="body" [filter]="true"
                        [options]="findValueInArray(listObjectsForm, 'id_column', col.id_column).data"
                        [(ngModel)]="findValueInArrayForm(dataForForm.values, 'id_column', col.id_column).value_id"
                        [style]="{'width':'100%'}"
                        placeholder="Selectionnez valeur:"
                        (onFocus)="makeListForm(dataForForm.id)"
                        (onChange)="changeValueListForm({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object, value: findValueInArray(dataForForm.values   , 'id_column', col.id_column)})"
                        (keydown.enter)="changeValueListForm({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object, value: findValueInArray(dataForForm.values   , 'id_column', col.id_column)})"
                        (keydown.tab)="changeValueListForm({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object, value: findValueInArray(dataForForm.values   , 'id_column', col.id_column)})"
            ></p-dropdown>
          </div>
          <div *ngIf="findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object == 'List'
                          ">
            <p-dropdown *ngIf="userRightsService.getUserUpdate() == 1" [id]="col.id_column" optionValue="label" appendTo="body" #selectedValueList [filter]="true"
                        [options]="findValueInArray(dataForForm.values, 'id_column', col.id_column).value"
                        [(ngModel)]="findValueInArrayFormOptions(dataForForm.values, 'id_column', col.id_column).selectedValue"
                        [style]="{'width':'100%'}"
                        placeholder="Selectionnez valeur:"
                        (onChange)="changeValueListList({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values, 'id_column', col.id_column).type_object, id_column: col.id_column, value: selectedValueList.selectedOption.label})"
                        (keydown.enter)="changeValueListList({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values, 'id_column', col.id_column).type_object, id_column: col.id_column, value: selectedValueList.selectedOption.label})"
                        (keydown.tab)="changeValueListList({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values, 'id_column', col.id_column).type_object, id_column: col.id_column, value: selectedValueList.selectedOption.label})"
            ></p-dropdown>
          </div>
          <div *ngIf="findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object == 'Risk'
                          ">
            <p-dropdown *ngIf="userRightsService.getUserUpdate() == 1" [id]="col.id_column"  appendTo="body" [filter]="true"
                        [options]="findValueInArray(listObjectsForm, 'id_column', col.id_column).data"
                        [(ngModel)]="findValueInArrayForm(dataForForm.values, 'id_column', col.id_column).value_id"
                        [style]="{'width':'100%'}"
                        placeholder="Selectionnez valeur:"
                        (onFocus)="makeListForm(dataForForm.id)"
                        (onChange)="changeValueListForm({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object, value: findValueInArray(dataForForm.values   , 'id_column', col.id_column)})"
                        (keydown.enter)="changeValueListForm({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object, value: findValueInArray(dataForForm.values   , 'id_column', col.id_column)})"
                        (keydown.tab)="changeValueListForm({idLigne: dataForForm.id, type_object: findValueInArray(dataForForm.values   , 'id_column', col.id_column).type_object, value: findValueInArray(dataForForm.values   , 'id_column', col.id_column)})"
            ></p-dropdown>
          </div>

          <div *ngIf="findValueInArray(dataForForm.values, 'id_column', col.id_column).type_object == 'Formule'">
            <div style="text-align: right">{{ (col.color_range != '' && col.color_range != 'Aucune') ? getRangeName(
              findValueInArray(dataForForm.values, 'id_column', col.id_column), col) : '' }}
            </div>
            <div>
              {{ (col.id_subobject != 0 ? findValueInArray(dataForForm.values, 'id_column', col.id_column).value : "") }}
            </div>
          </div>
          <div *ngIf="findValueInArray(dataForForm.values, 'id_column', col.id_column).type_object == 'Booléen'">
            <p-inputSwitch *ngIf="userRightsService.getUserUpdate() == 1" [id]="col.id_column"  appendTo="body"
                        [(ngModel)]="findValueInArrayForm(dataForForm.values, 'id_column', col.id_column).value"></p-inputSwitch>
          </div>
        </div>
      </div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col-4">
        <label>Validation</label>
      </div>
      <div class="p-col-8" *ngIf="dataForForm">
        <p-checkbox (onChange)="validateLine(dataForForm, false)" [(ngModel)]="dataForForm.validation" binary="true" inputId="binary"></p-checkbox>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog header="Plan d'action" [(visible)]="showPlanDialog" appendTo="body" [modal]="true" (onHide)="loadData()">
  <div style="margin-bottom: 5em" class="p-fluid" *ngIf="showPlanDialog">
    <app-plan [id_line]="id_line_plan" [id_client]="id_client" [id_analyse]="id" [disabledEditing]="disabledEditingPlan" [page]=0></app-plan>
  </div>
</p-dialog>

<p-dialog header="Exports actions" [(visible)]="showExportActionDialog" appendTo="body" [modal]="true">
  <p-header>
    <p-button type="button" (onClick)="exportCsvPlan()" class="p-button-success">Exporter la liste des actions</p-button>
  </p-header>
  <ng-template pTemplate="body">
    <p-multiSelect [style]="{'width' : '100%', 'margin-bottom' : '1em'}" appendTo="body" [filter]="true" [options]="actionTypes" [(ngModel)]="selectedActionTypeList" placeholder="Sélectionner un type" optionLabel="name"></p-multiSelect>
    <div [style]="{'width' : '90%', 'margin-bottom' : '1em', 'margin-left' : '1em'}" >Liste unique des actions : <p-checkbox [(ngModel)]="listUniqueActionsCheckbox" [binary]="true" inputId="binary"></p-checkbox></div>
  </ng-template>
    </p-dialog>

<p-dialog
  header="Proposer un scénario de risque"
  styleClass="dialog-propose-scenario"
  [(visible)]="proposeScenarioDialogVisible"
  [modal]="true"
>
  <app-propose-scenario *ngIf="id_client" [id_client]="id_client" [id_analyse]="id" (proposeScenarioSuccess)="proposeScenarioDialogVisible = false"></app-propose-scenario>
</p-dialog>

<p-toast></p-toast>

<p-progressSpinner *ngIf="loading"></p-progressSpinner>
