import { Component, Input, OnInit } from "@angular/core";
import { TreeNode } from "primeng/api";
import { StructureClientService } from "./structure-client.service";
import { UserRightsService } from "../services/user-rights.service";
import { UserClientsService } from "../user-clients/user-clients.service";
import { Client } from "../client/client.model";
import { MsgService } from "../services/msg.service";
import { User } from "../users/user.model";

@Component({
  selector: "app-structure-client",
  templateUrl: "./structure-client.component.html",
  styleUrls: ["./structure-client.component.css"],
})
export class StructureClientComponent implements OnInit {
  data: TreeNode[];
  data2: any = [];

  selectedNode: TreeNode;
  title: string;
  currentId: number;
  selectedClient: any;
  updateValue = false;
  clients: Client[];

  displayDialog = false;
  user: User;
  newStructure = false;
  structures: any[];
  selectedStructureSource: any;
  isSuperUser: any;

  constructor(
    private structureClientService: StructureClientService,
    public userRightsService: UserRightsService,
    private userClientService: UserClientsService,
    private msgs: MsgService
  ) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("user")).data;
    this.selectedClient = JSON.parse(localStorage.getItem("client"));
    if (!this.selectedClient) {
      this.userClientService.getByUserId(this.user.id).then((res) => {
        this.selectedClient = res.data ? res.data[0] : ([] as Client[]);
      });
    }
    this.loadData();
  }

  list_to_tree(list) {
    let map = {},
      node,
      roots = [],
      i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.id_parent !== null) {
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node.id_parent]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  add(node) {
    this.title = node.label + " - ";
    this.currentId = node.id;
    this.displayDialog = true;
    this.newStructure = true;
  }

  delete(id) {
    this.title = "";
    this.structureClientService.delete(id).then((res) => {
      if (res.code === 200) {
        this.msgs.showSuccess("Structure client suprimée!");
        this.loadData();
      } else {
        this.msgs.showError(res.message);
      }
    });
  }

  update(node) {
    this.title = node.label;
    this.updateValue = true;
    this.displayDialog = true;
    this.currentId = node.id;
    this.newStructure = false;
  }

  save() {
    const exists = this.findByLabel(this.data, this.title);
    if (!exists) {
      if (this.updateValue) {
        this.structureClientService
          .update(this.selectedClient.id_client, this.currentId, this.title)
          .then((res) => {
            if (res.code === 200) {
              this.msgs.showSuccess("Structure client mis à jour!");
              this.loadData();
            } else {
              this.msgs.showError(res.message);
            }
          });
      } else {
        this.structureClientService
          .create(this.selectedClient.id_client, this.currentId, this.title, this.selectedStructureSource ? this.selectedStructureSource.id : 0)
          .then((res) => {
            if (res.code === 200) {
              this.msgs.showSuccess("Structure client créée!");
              this.loadData();
            } else {
              this.msgs.showError(res.message);
            }
          });
      }
      this.updateValue = false;
      this.displayDialog = false;
      this.title = "";
    } else {
      this.msgs.showError("La structure existe déjà!");
    }
  }

  findByLabel(data, label) {
    function iter(a) {
      if (a.label === label) {
        result = a;
        return true;
      }
      return Array.isArray(a.children) && a.children.some(iter);
    }

    let result;
    data.some(iter);
    return result;
  }

  closeDialog() {
    this.displayDialog = false;
  }

  changeClientCache() {
    localStorage.setItem("client", JSON.stringify(this.selectedClient));
  }

  loadData() {
    this.changeClientCache();

    this.userClientService.getByUserId(this.user.id).then((res) => {
      this.clients = res.data;
      this.selectedClient = this.selectedClient
        ? this.selectedClient
        : this.clients
        ? this.clients[0]
        : 0;

      this.structureClientService
        .get(this.selectedClient.id_client)
        .then((res) => {
          this.data2 = res.data;
          const graphObject = [];
          const level = 0;
          const lastLeaf = null;
          this.data = this.list_to_tree(this.data2);
        });
    });

    this.isSuperUser = JSON.parse(localStorage.getItem("user")).data.super_user;
    this.structureClientService.getAll().then((structures) => {
      if (structures.data == null) {
        this.structures = [] as any[];
      } else {
        this.structures = structures.data;
      }
    });
  }

  isNotFirst(item: any) {
    return (
      this.data
        .filter((i) => i.label === item.label)
        .map((i) => i.label)
        .indexOf(item.label) !== 0
    );
  }
}
