import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ApiResponse } from "../models/api-response.model";

@Injectable({
  providedIn: "root",
})
export class StructureClientService {
  constructor(private http: HttpClient, public config: AppConfig) {}

  getAll() {
    return this.http
      .get(this.config.API_Host + "structureClients/get")
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  get(client: number) {
    return this.http
      .get(this.config.API_Host + "structureClients/get/" + client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  getById(id_client: number) {
    return this.http
      .get(this.config.API_Host + "structureClients/getById/" + id_client)
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  create(client: number, parentId: number, title: string, structureSource ?: number) {
    return this.http
      .post(
        this.config.API_Host + "structureClients/create/" + client,
        JSON.stringify({ parentId: parentId, title: title, structureSource: structureSource })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  update(client: number, parentId: number, title: string) {
    return this.http
      .post(
        this.config.API_Host + "structureClients/update/" + parentId,
        JSON.stringify({ title: title })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  delete(id: number) {
    return this.http
      .post(
        this.config.API_Host + "structureClients/delete",
        JSON.stringify({ id: id })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }
}
