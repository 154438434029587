import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AnalyseLineService } from "../services/analyse-line.service";
import { StructureClientService } from "../structure-client/structure-client.service";

@Component({
  selector: 'app-parent-analyse',
  templateUrl: './parent-analyse.component.html',
  styleUrls: ['./parent-analyse.component.css']
})
export class ParentAnalyseComponent implements OnInit {

  private currentUser: any;
  id_client: number;
  headerColumns: any;
  headerGroups: any;
  analyseLines: any[];
  showPlanDialog: boolean = false;
  analyseLineId: number;
  analyseId: number;
  selectedColumns: any[];
  selectedGroups: any[];
  filteredAnalyseLines: any[];
  colFilters: any;
  searchParams: { [key: string]: string } = {'Analyse source': ''};
  clientName: any;
  colGroups: any[];

  constructor(
    private analyseLineService: AnalyseLineService,
    private route: ActivatedRoute,
    private structureClientService: StructureClientService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('user')).data;
    this.route.params.subscribe(params => {
      this.id_client = params['id_client'];
      this.structureClientService.getById(params['id_client']).then(client => {
          this.clientName = client.data ? client.data.title : '';
      });
    });

    this.loadData();
  }

  loadData() {
    this.analyseLineService.getParentAnalyseLines(this.id_client).then(res => {
      this.headerColumns = res.data ? res.data.columns : [];
      this.headerGroups = res.data ? res.data.columnsGroup : [];
      this.analyseLines = res.data ? res.data.analyseLines : [];

      this.selectedColumns = this.headerColumns;
      this.selectedGroups = this.headerGroups;

      this.analyseLines.forEach((analyseLine: { values ?: any }) => {
        if (typeof analyseLine !== 'undefined' && analyseLine.values) {
          analyseLine.values.forEach(data => {
            data.missingValue = data.display_value == 'N/A';
            data.color = data.color_range ? {'background-color': data.color_range} : null;
            data.isPlan = data.type_object == 'Plan';
            data.isFormula = data.type_object == 'Formule';
            data.isBool = data.type_object == 'Booléen';
            data.isRisk = data.type_object == 'Risk';
            data.isProbability = data.type_object == 'Probabilité';
            data.isOther = !data.isPlan && !data.isFormula && !data.isRisk && !data.isProbability && !data.isBool;
            data.colorName = data.color_range_name ? data.color_range_name : '';
            data.displayValue = data.display_value;
            data.visible = true;
          });
        }
      });
      this.filteredAnalyseLines = this.analyseLines;

      this.headerColumns.forEach(column => {
        this.searchParams[column.name] = '';
      });

      this.reorderCols(false);
    });
  }
  openPlanDialog(analyseLine) {
    this.analyseId = analyseLine.id_analyse;
    this.analyseLineId = analyseLine.id;
    this.showPlanDialog = true;
  }

  exportCsv() {
    const csvData = this.filteredAnalyseLines.map((analyseLine) => ({
      id: analyseLine.order_num,
      analyse: analyseLine.name_analyse + ' / ' + analyseLine.name_client,
      values: analyseLine.values.map((data) => {
        if (data.isPlan) {
          return `${analyseLine.nb_actions} - ${analyseLine.status_actions}%`;
        } else if (data.isFormula) {
          return data.colorName;
        } else if (data.isRisk || data.isProbability) {
          return data.value.id;
        } else if (data.isBool) {
          return data.value ? 'Oui' : 'Non';
        } else if (data.isOther) {
          return typeof data.displayValue === 'string'
            ? data.displayValue.replace(/[\r\n]+/g, ' ')
            : data.displayValue;
        } else {
          return '';
        }
      }),
    }));
    const csvLines = csvData.map((line) => `${line.id};${line.analyse};${line.values.join(';')}`);
    const csv = `ID;Analyse;${this.selectedColumns.map((column) => column.name).join(';')}\r\n${csvLines.join('\r\n')}`;

    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
    link.setAttribute('download', 'analyse.csv');
    document.body.appendChild(link);
    link.click();
  }


  searchKeyword(searchValue) {
    this.filteredAnalyseLines = this.analyseLines;
    if (searchValue !== '') {
      const keywords = searchValue.split(',');

      for (const keyword in keywords) {
        this.filteredAnalyseLines = this.filteredAnalyseLines.filter(item => {
          const res = item.values.find(vals => {
              let found = false;
              if (vals.isFormula) {
                found = vals.color_range_name && vals.color_range_name != '' ? vals.color_range_name.toString().toLowerCase().indexOf(keywords[keyword].toLowerCase().trimLeft()) > -1 : false
              } else if (vals.isRisk || vals.isProbability) {
                found = vals.value && vals.value != '' && (vals.value.id || vals.value.name) ?
                  (vals.value.id.toLowerCase().indexOf(keywords[keyword].toLowerCase().trimLeft()) > -1) : false;
                if (!found && vals.color_range_name) {
                  found = vals.color_range_name.toLowerCase().indexOf(keywords[keyword].toLowerCase().trimLeft()) > -1;
                }
              } else {
                found = vals.display_value && vals.display_value != '' ? vals.display_value.toString().toLowerCase().indexOf(keywords[keyword].toLowerCase().trimLeft()) > -1 : false;
              }
              return found;
            });

          const nameClientFound = item.name_client && item.name_client != '' ? item.name_client.toString().toLowerCase().indexOf(keywords[keyword].toLowerCase().trimLeft()) > -1 : false;

          return res || nameClientFound;
        });
      }
    }
  }

  showColFilters() {
    this.colFilters = !this.colFilters;
  }

  searchInAnalyseByColumn() {
    const keywords = Object.entries(this.searchParams)
      .filter(([key, value]) => value !== '')
      .map(([key, value]) => ({ inputValue: value, col: key }));

    this.filteredAnalyseLines = this.analyseLines;
    if (keywords.length > 0) {
      for (const keyword of keywords) {
        this.filterColumns(keyword);
      }
    }
  }

  filterColumns(keyword: any) {
    const { inputValue, col } = keyword;
    this.filteredAnalyseLines = this.filteredAnalyseLines.filter(item => {
      if (col === 'Analyse source') {
        return (item.name_analyse + ' ' + item.name_client).toLowerCase().includes(inputValue.toLowerCase());
      }
      const columnValue = item.values.find(value => value.name_column === col);
      if (columnValue) {
        if (columnValue.isFormula) {
          return columnValue.colorName.toLowerCase().includes(inputValue.toLowerCase());
        } else if (columnValue.isRisk || columnValue.isProbability) {
          if (columnValue.value.id) {
            return columnValue.value.id.toLowerCase().includes(inputValue.toLowerCase());
          }
        } else {
          return columnValue.displayValue.toLowerCase().includes(inputValue.toLowerCase());
        }
      }
      return false;
    });
  }

  reorderCols(header) {
    this.selectedColumns = header ? this.headerColumns : this.selectedColumns;

    if (header) {
      this.selectedColumns = this.selectedColumns.filter(i => {
        return this.selectedGroups.find(z => z.name === i.group_name);
      });
    }

    this.selectedColumns.sort(function (obj1, obj2) {
      return obj1.order_num - obj2.order_num;
    });

    this.filteredAnalyseLines.forEach(line => {
      line.values.forEach(value => {
        value.visible = this.selectedColumns.some(column => column.name === value.name_column);
      });
    });

    this.loadColGroups();
  }

  loadColGroups() {
    const colGroups: any[] = [];

    for (const prop in this.selectedColumns ? this.selectedColumns : this.headerColumns) {
      const existingCol = colGroups.find(i => i['name'] === (this.selectedColumns ? this.selectedColumns : this.headerColumns)[prop].group_name);
      if (existingCol) {
        existingCol.columns += 1;
        existingCol.color = existingCol.color ? existingCol.color : (this.selectedColumns ? this.selectedColumns : this.headerColumns)[prop].group_color;
      } else {
        colGroups.push({
          header: (this.selectedColumns ? this.selectedColumns : this.headerColumns)[prop].group_name,
          name: (this.selectedColumns ? this.selectedColumns : this.headerColumns)[prop].group_name,
          columns: (this.selectedColumns ? this.selectedColumns : this.headerColumns)[prop].group_name ? 1 : 0,
          color: (this.selectedColumns ? this.selectedColumns : this.headerColumns)[prop].group_color
        });
      }
    }
    this.colGroups = colGroups;
  }

  getVisibleValues(values) {
    return values.filter(value => value.visible);
  }

}
