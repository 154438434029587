<form [formGroup]="proposeScenarioForm" (ngSubmit)="proposeScenario()">
  <div class="p-fluid">
    <div class="p-field p-grid">
      <div class="p-col-3">
        <label for="description">Description :</label>
      </div>
      <div class="p-col-9">
        <textarea pInputTextarea id="description" class="propose-scenario-input" formControlName="description" rows="3" required></textarea>
      </div>
    </div>

    <div class="p-field p-grid">
      <div class="p-col-3">
        <label for="consequences">Conséquences probables :</label>
      </div>
      <div class="p-col-9">
        <textarea pInputTextarea id="consequences" class="propose-scenario-input" formControlName="consequences" rows="5" required></textarea>
      </div>
    </div>

    <div class="p-field p-grid">
      <div class="p-col-3">
        <label for="action_plan">Mesures à mettre en place :</label>
      </div>
      <div class="p-col-9">
        <textarea pInputTextarea id="action_plan" class="propose-scenario-input" formControlName="action_plan" rows="5" required></textarea>
      </div>
    </div>
  </div>

  <p-footer>
    <div class="p-d-flex p-jc-end">
      <button
        type="submit"
        [disabled]="proposeScenarioForm.hasError('required', 'description')"
        pButton
        icon="fa fa-check"
        label="Proposer"
      ></button>
    </div>
  </p-footer>
</form>

