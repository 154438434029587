import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { Person } from "./person.model";
import { BehaviorSubject, interval, Subject } from "rxjs";
import { PersonService } from "./person.service";
import { MsgService } from "../services/msg.service";
import { TracesService } from "../traces/traces.service";
import { UserBlockService } from "../services/user-block.service";
import { ConfirmationService } from "primeng/api";
import { UserRightsService } from "../services/user-rights.service";
import { DataService } from "../services/data.service";
import { AppConfig } from "../config";
import { takeUntil } from "rxjs/operators";
import { Log } from "../models/log.model";
import { UsersService } from "../users/users.service";
import { User } from "../users/user.model";

@Component({
  selector: "app-person",
  templateUrl: "./person.component.html",
  styleUrls: ["./person.component.css"],
})
export class PersonComponent implements OnInit, OnChanges, OnDestroy {
  displayDialog: boolean;
  displayDialogImport: boolean;
  person: Person = {} as Person;
  selectedPerson: Person;
  newPerson: boolean;
  persons: Person[];
  cols: any[];
  user_block: any = 0;
  lastIDPerson: number;
  currentPerson: any;
  currentUser: any;
  timeoutCounter = 0;
  importFiles: any[] = [];
  showDeleteColumn = false;
  selectedPersonList: Person;
  listPersons: Person[];
  selectedUserList: User;
  listUsers: any[];

  private onDestroy$ = new Subject();

  @Input("clients") clients: any;

  private messageSource = new BehaviorSubject("");
  activatedComp: string;

  constructor(
    private personService: PersonService,
    private msgs: MsgService,
    private trace: TracesService,
    private userBlock: UserBlockService,
    private confirmationService: ConfirmationService,
    private userRightsService: UserRightsService,
    private usersService: UsersService,
    private data: DataService,
    public config: AppConfig
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.clients;
    if (currentItem) {
      this.loadData();
    }
  }

  ngOnInit() {
    this.data.currentMessage.subscribe(
      (message) => (this.activatedComp = message)
    );
    if (
      (this.clients || this.clients === 0) &&
      this.activatedComp === "Persons"
    ) {
      this.loadData();

      this.currentUser = JSON.parse(localStorage.getItem("user")).data.email;
      this.cols = [
        { field: "first_name", header: "Prénom: " },
        { field: "last_name", header: "Nom: " },
        { field: "work", header: "Poste: " },
        { field: "email", header: "Email: " },
        { field: "dtcreate", header: "Création: " },
        { field: "dtmodif", header: "Modification: " },
      ];
      interval(this.config.RefreshMilisecs)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((x) => {
          if (this.timeoutCounter < this.config.RefreshTimer && this.clients) {
            this.loadData();
            this.timeoutCounter++;
          }
        });
    }
  }
  @HostListener("document:mousemove")
  onMouseMove() {
    this.timeoutCounter = 0;
  }

  showDialogToAdd() {
    if (this.person != null && this.person.id != null && !this.person) {
      this.closePersons();
    }
    this.newPerson = true;
    this.person = {} as Person;
    this.displayDialog = true;
  }
  save() {
    const persons = this.persons ? [...this.persons] : ([] as Person[]);
    this.person.id_client = this.clients;
    this.person.id_manager = this.selectedPersonList
      ? this.selectedPersonList.id
      : null;
    this.person.id_user = this.selectedUserList
      ? this.selectedUserList.id
      : null;
    const jsonperson = JSON.stringify(this.person);
    if (this.person.first_name && this.person.last_name) {
      if (this.newPerson) {
        persons.push(this.person);
        this.personService.createPerson(this.person).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Personne créée!");
            this.trace.createTrace({
              person: "CREATE PERSON",
              data: jsonperson,
              description: "CREATION PERSON",
            } as unknown as Log);
          } else {
            this.msgs.showError(res.message);
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              person: "CREATE PERSON",
              data: jsonmsg,
              description: "ERREUR CREATION PERSON",
            } as unknown as Log);
          }
        });
      } else {
        persons[this.persons.indexOf(this.selectedPerson)] = this.person;
        this.personService.updatePerson(this.person).then((res) => {
          if (res.code === 200) {
            this.loadData();
            this.msgs.showSuccess("Personne mise à jour!");
            this.trace.createTrace({
              person: "UPDATE PERSON",
              data: JSON.stringify(persons),
              description: "Mise a jour persons",
            } as unknown as Log);
          } else {
            const jsonmsg = JSON.stringify(res.message);
            this.trace.createTrace({
              person: "UPDATE PERSON",
              data: jsonmsg,
              description: "ERREUR MAJ PERSON",
            } as unknown as Log);
          }
          this.userBlock
            .unblockUser(
              "bo_persons",
              this.person ? this.person.id : this.lastIDPerson
            )
            .then((data) => {
              console.log("Unblock users");
            });
        });
      }
    } else {
      this.msgs.showError("Merci de remplir tous les champs!");
    }
    this.lastIDPerson = this.person.id;
    this.persons = persons;
    this.person = null;
    this.displayDialog = false;
  }
  delete() {
    this.personService.deletePerson(this.person.id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Person supprimé!");
        this.trace.createTrace({
          person: "DELETE PERSON",
          data: JSON.stringify(this.person),
          description: "SUPPRESSION PERSON",
        } as unknown as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.userBlock
      .unblockUser(
        "bo_persons",
        this.person.id ? this.person.id : this.lastIDPerson
      )
      .then((data) => {
        console.log("Unblock users");
      });
    this.lastIDPerson = this.person.id;
    const index = this.persons.indexOf(this.selectedPerson);
    this.persons = this.persons.filter((val, i) => i !== index);
    this.person = null;
    this.displayDialog = false;
  }

  deleteLine(id) {
    this.personService.deletePerson(id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("Person supprimé!");
        this.trace.createTrace({
          person: "DELETE PERSON",
          data: JSON.stringify(this.person),
          description: "SUPPRESSION PERSON",
        } as unknown as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
  }

  onRowSelect(event) {
    this.user_block = 0;
    this.newPerson = false;
    this.person = this.clonePerson(event.data);
    this.selectedPersonList = this.listPersons.find((i) => {
      return i.id === this.person.id_manager;
    });
    this.selectedUserList = this.listUsers.find((i) => {
      return i.id === this.person.id_user;
    });
    this.displayDialog = true;
    this.userBlock
      .verifUserBlock("bo_persons", this.person.id)
      .then((userblock) => {
        if (userblock.data != "") {
          this.user_block = userblock.data;
          alert("Data en cours d'utilisation par : " + this.user_block);
        } else {
          this.userBlock.blockUser("bo_persons", this.person.id);
          this.lastIDPerson = this.person.id;
        }
      });
  }
  clonePerson(r: Person): Person {
    const person = {} as Person;
    for (const prop in r) {
      person[prop] = r[prop];
    }
    return person;
  }
  @HostListener("window:beforeunload")
  closePersons() {
    if (
      (this.user_block === this.currentUser || this.user_block === 0) &&
      !this.newPerson &&
      this.person !== null
    ) {
      this.userBlock
        .unblockUser(
          "bo_persons",
          this.person ? this.person.id : this.lastIDPerson
        )
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
  }

  loadData() {
    this.personService.getPersons(this.clients).then((data) => {
      this.persons = data.data ? data.data : [];
      this.listPersons = data.data ? data.data : [];

      this.usersService.getUsersByIdClient(this.clients).then((data) => {
        this.listUsers = data.data ? data.data : [];
      });
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closePersons();
  }

  openImportDialog() {
    this.displayDialogImport = true;
  }

  importData(event) {
    this.loadData();
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }

  deleteAllLines() {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer toutes les lignes?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.personService.deleteAll(this.clients).then((res) => {
          this.loadData();
          this.msgs.showSuccess("Ligne supprimée!");
        });
      },
    });
  }
}
