<div class="home">
  <a routerLink="/analyses" *ngIf="userRightsService.getUserAnalyse()">
    <i class="pi pi-chart-line"></i>
    <span>Analyse</span>
  </a>
  <a routerLink="/actions">
   <i class="pi pi-pencil"></i>
    <span>Plan d'action</span>
  </a>
  <a routerLink="/dashboards" *ngIf="userRightsService.getUserDashboard()">
    <i class="pi pi-chart-bar"></i>
    <span>Indicateurs</span>
  </a>
</div>
