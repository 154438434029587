import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiResponse } from "../models/api-response.model";
import { AppConfig } from "../config";

@Injectable()
export class UserBlockService {
  username: string;

  constructor(private http: HttpClient, public config: AppConfig) {
    this.username = JSON.parse(localStorage.getItem("user")).data.email;
  }

  verifUserBlock(table, id) {
    return this.http
      .post(
        this.config.API_Host + "userblock/getuserblock",
        JSON.stringify({
          table: table,
          id: id,
          username: this.username,
        })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  blockUser(table, id) {
    return this.http
      .post(
        this.config.API_Host + "userblock/blockuser",
        JSON.stringify({
          table: table,
          id: id,
          username: this.username,
        })
      )
      .toPromise()
      .then((res) => <ApiResponse>res)
      .then((data) => data);
  }

  unblockUser(table, id = 0) {
    if (id != 0) {
      return this.http
        .post(
          this.config.API_Host + "userblock/unblockuser",
          JSON.stringify({
            table: table,
            id: id,
            username: this.username,
          })
        )
        .toPromise()
        .then((res) => <ApiResponse>res)
        .then((data) => data);
    }
    return new Promise((resolve, reject) => {});
  }
}
