import { Component, HostListener, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, interval, Subject } from "rxjs";
import { Paramgraph } from "./paramgraph.model";
import { Log } from "../models/log.model";
import { ConfirmationService, MessageService } from "primeng/api";
import { TracesService } from "../traces/traces.service";
import { ParamGraphService } from "./param-graph.service";
import { MsgService } from "../services/msg.service";
import { UserBlockService } from "../services/user-block.service";
import { UserRightsService } from "../services/user-rights.service";
import { AppConfig } from "../config";
import { takeUntil } from "rxjs/operators";
import { ColumnsService } from "../columns/columns.service";
import { FormulaService } from "../formula-object/formula.service";
import saveAs from "file-saver";
import { Column } from "../columns/column.model";
import { UserClientsService } from "../user-clients/user-clients.service";
import { Analyse } from "../analyse/analyse.model";
import { AnalyseService } from "../analyse/analyse.service";

@Component({
  selector: "app-param-graph",
  templateUrl: "./param-graph.component.html",
  styleUrls: ["./param-graph.component.css"],
})
export class ParamGraphComponent implements OnInit {
  @Input() id: number;
  @Input() id_client: number;
  @Input() type_object: string;
  @Input() refresh;
  private sub: any;
  displayDialog: boolean;
  displayDialogImport: boolean;
  paramGraph: Paramgraph = {} as Paramgraph;
  selectedparamGraph: Paramgraph;
  newparamGraph: boolean;
  paramGraphs: Paramgraph[] = [] as Paramgraph[];
  cols: any[];
  user_block: any = 0;
  lastIDparamGraph: number;
  currentparamGraph: any;
  currentUser: any;
  timeoutCounter = 0;
  importFiles: any[] = [];
  showDeleteColumn = false;

  analyses: Analyse[];

  loadingScreen = false;

  private onDestroy$ = new Subject();

  private messageSource = new BehaviorSubject("");
  activatedComp: string;

  listColumns: any[] = [];
  listFormulas: any[] = [];

  selectedAnalyse: any;

  selectedLabel: any;
  selectedFormula: any;
  selectedGraphData: any;
  selectedGraphs: any;
  selectedData: any;

  typesAgregats: any[];
  typesGraphs: any[];
  selectedAgregation: any;

  structureClients: any;
  selectedStructureClient: any;

  columnsToShow: any;
  formulasToShow: any;

  stopmousemove = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private msgs: MsgService,
    private trace: TracesService,
    private analyseService: AnalyseService,
    private paramGraphService: ParamGraphService,
    private formulaService: FormulaService,
    private userBlock: UserBlockService,
    private userRightsService: UserRightsService,
    private columnService: ColumnsService,
    private userClientService: UserClientsService,
    public config: AppConfig
  ) {}

  ngOnInit() {
    this.typesAgregats = [
      { name: "sum", label: "Somme" },
      { name: "avg", label: "Moyenne" },
      { name: "num", label: "Nombre" },
      { name: "min", label: "Min" },
      { name: "max", label: "Max" },
    ];
    this.typesGraphs = [
      { name: "radar", label: "Radar" },
      { name: "bar", label: "Bar Chart" },
      { name: "line", label: "Line Chart" },
      { name: "polarArea", label: "Polar Chart" },
      { name: "doughnut", label: "Doughnut Chart" },
      { name: "pie", label: "Pie Chart" },
    ];

    /*this.sub = this.route.params.subscribe(params => {

      if (this.type_object == 'dashboard') {
        this.id = params['id'];
        this.id_client = params['id_client'];
      }
    });*/

    this.currentUser = JSON.parse(localStorage.getItem("user")).data;
    this.cols = [];
    if (this.type_object == "dashboard") {
      this.cols.push({ field: "id_structure", header: "Structure Client: " });
      this.cols.push({ field: "id_analyse", header: "Analyse: " });
    }
    this.cols.push({ field: "name", header: "Libellé: " });
    this.cols.push({
      field: "description",
      header: "Commentaires / Précisions: ",
    });
    this.cols.push({ field: "typegraph", header: "Type graphique: " });
    this.cols.push({ field: "agregat", header: "Agrégation: " });
    this.cols.push({ field: "labels", header: "Entêtes des graphiques: " });
    this.cols.push({ field: "data", header: "Données: " });

    interval(200)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((x) => {
        if (this.refresh) {
          this.loadData();
          this.refresh = false;
        }
      });
    this.loadData();
  }

  @HostListener("document:mousemove")
  onMouseMove() {
    if (this.stopmousemove == false) {
      this.timeoutCounter = 0;
    }
  }

  stopRefresh() {
    this.timeoutCounter = this.config.RefreshTimer;
    this.stopmousemove = true;
  }

  showDialogToAdd() {
    if (
      this.paramGraph != null &&
      this.paramGraph.id != null &&
      !this.paramGraph
    ) {
      this.closeparamGraphs();
    }
    this.newparamGraph = true;
    this.paramGraph = {} as Paramgraph;
    this.displayDialog = true;
  }

  delete() {
    this.paramGraphService.delete(this.paramGraph.id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("paramGraphédure supprimé!");
        this.trace.createTrace({
          action: "DELETE paramGraph",
          data: JSON.stringify(this.paramGraph),
          description: "SUPPRESSION paramGraph",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
    this.userBlock
      .unblockUser(
        "bo_param_graph",
        this.paramGraph.id ? this.paramGraph.id : this.lastIDparamGraph
      )
      .then((data) => {
        console.log("Unblock users");
      });
    this.lastIDparamGraph = this.paramGraph.id;
    const index = this.paramGraphs.indexOf(this.selectedparamGraph);
    this.paramGraphs = this.paramGraphs.filter((val, i) => i !== index);
    this.paramGraph = null;
    this.displayDialog = false;
  }

  deleteLine(id) {
    this.paramGraphService.delete(id).then((res) => {
      if (res.code === 200) {
        this.loadData();
        this.msgs.showSuccess("paramGraphédure supprimé!");
        this.trace.createTrace({
          action: "DELETE paramGraph",
          data: JSON.stringify(this.paramGraph),
          description: "SUPPRESSION paramGraph",
        } as Log);
      } else {
        this.msgs.showError(res.message);
      }
    });
  }

  cloneparamGraph(r: Paramgraph): Paramgraph {
    const paramGraph = {} as Paramgraph;
    for (const prop in r) {
      paramGraph[prop] = r[prop];
    }
    return paramGraph;
  }

  @HostListener("window:beforeunload")
  closeparamGraphs() {
    if (
      (this.user_block == this.currentUser.email || this.user_block == 0) &&
      !this.newparamGraph &&
      this.paramGraph != null
    ) {
      this.userBlock
        .unblockUser(
          "bo_param_graph",
          this.paramGraph ? this.paramGraph.id : this.lastIDparamGraph
        )
        .then((data) => {
          console.log("Unblock user");
        });
    }
    this.loadData();
  }

  loadData() {
    this.reloadAnalyses(this.id_client);
    this.paramGraphService.get(this.id, this.type_object).then((data) => {
      this.paramGraphs = data.data ? data.data : [];
      this.userClientService
        .getByStructureId(this.currentUser.id, this.id_client)
        .then((data1) => {
          this.structureClients = data1.data ? data1.data : [];
          this.columnService.getAll(this.id_client).then((res) => {
            this.listColumns = res.data ? res.data : [];
            this.listFormulas = res.data
              ? res.data.filter(
                  (i) => i.type_object == "Formule" || i.type_object == "Nombre"
                )
              : [];
          });
        });
    });
  }

  reloadAnalyses(id_client) {
    this.analyseService.get(id_client).then((analyses) => {
      const firstAnalyse = new Analyse();
      firstAnalyse.id = 0;
      firstAnalyse.name = "*";
      this.analyses = analyses.data ? analyses.data : ([] as Analyse[]);
      this.analyses.unshift(firstAnalyse);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.closeparamGraphs();
  }

  openImportDialog() {
    this.displayDialogImport = true;
  }

  importData(event) {
    this.loadData();
  }

  showDelete() {
    this.showDeleteColumn = !this.showDeleteColumn;
  }

  deleteAllLines() {
    this.confirmationService.confirm({
      message: "Etes-vous sur de vouloir supprimer toutes les lignes?",
      header: "Confirmation suppression",
      icon: "pi pi-info-circle",
      accept: () => {
        this.paramGraphService.deleteAll(this.id_client).then((res) => {
          this.loadData();
          this.msgs.showSuccess("Ligne supprimée!");
        });
      },
    });
  }

  addLigne() {
    const newIndex =
      this.paramGraphs.length > 0
        ? Math.max.apply(
            Math,
            this.paramGraphs.map(function (o) {
              return o.order_num;
            })
          ) + 1
        : 1;
    const newparam = {} as Paramgraph;
    newparam.order_num = newIndex;
    const options = {
      idLigne: "undefined",
      name: "order_num",
      value: newIndex,
      id_client: this.id_client,
      id_object: this.id ? this.id : 0,
      type_object: this.type_object,
    };

    this.paramGraphs.push(newparam);
    this.paramGraphService.addupdate(options).then((res) => {
      if (res) {
        this.loadData();
        this.stopmousemove = false;
      }
    });
  }

  changeValue(options) {
    if (options.name == "id_structure") {
      this.reloadAnalyses(options.value);
    }
    this.loadingScreen = true;
    options.id_client = this.id_client;
    options.id_object = this.id;
    options.type_object = this.type_object;
    this.paramGraphService.addupdate(options).then((res) => {
      this.stopmousemove = false;
      this.loadingScreen = false;
      this.loadData();
    });
  }

  getColumnsFromIdAnalyse(id_analyse) {
    let resArray;
    if (id_analyse == 0) {
      resArray = this.listColumns.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.name === thing.name)
      );
    } else {
      resArray = this.listColumns.filter((i) => i.id_analyse == id_analyse);
    }
    return resArray ? resArray : [];
  }

  getFormulasFromIdAnalyse(id_analyse) {
    let resArray;
    if (id_analyse == 0) {
      resArray = this.listFormulas.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.name === thing.name)
      );
    } else {
      resArray = this.listFormulas.filter((i) => i.id_analyse == id_analyse);
    }
    return resArray ? resArray : [];
  }

  getOnlyFormulas() {
    return this.listColumns
      ? this.listColumns.filter((i) => i.type_object == "Formule")
      : [];
  }

  getLabelFromName(array, search) {
    const resArray = array.filter((i) => i.name == search)[0];
    return resArray ? resArray.label : "";
  }

  getNameFromId(array, search) {
    let resArray;
    if (array) {
      resArray = array.filter((i) => i.id == search)[0];
    }
    return resArray ? resArray.name : "";
  }

  getObjectFromId(array, search) {
    let resArray;
    if (array) {
      resArray = array.filter((i) => i.id == search);
    }
    return resArray ? resArray : {};
  }

  getNameFromCode(array, search) {
    let resArray;
    if (array) {
      resArray = array.filter((i) => i.code == search)[0];
    }
    return resArray ? resArray.name : "";
  }

  getObjectFromCode(array, search) {
    const resArray = array.filter((i) => i.code == search)[0];
    return resArray ? resArray : {};
  }

  getObjectFromClientId(array, search) {
    const resArray = array.filter((i) => i.id_client == search);
    return resArray ? resArray : {};
  }

  findItemsInArray(array, position) {
    return array[position];
  }

  getLabelFromValue(array, search) {
    const resArray = array.filter((i) => i.value == search)[0];
    return resArray ? resArray.label : "";
  }

  exportCsv() {
    let header = this.cols
      .map((i) => {
        return i.header;
      })
      .join(";");

    header = "Ordre:;" + header;

    const data = this.paramGraphs.map((paramGraph) => {
      const currentLabel = this.listColumns.find(
        (cols) => cols.id === paramGraph.labels
      ).name;
      const currentData = this.listFormulas.find(
        (forms) => forms.id === paramGraph.data
      ).name;
      const typeGraph = this.typesGraphs.find(
        (type) => type.name === paramGraph.typegraph
      ).label;
      const typeAgregat = this.typesAgregats.find(
        (type) => type.name === paramGraph.agregat
      ).label;

      return (
        paramGraph.order_num +
        ";" +
        paramGraph.name +
        ";" +
        paramGraph.description +
        ";" +
        typeGraph +
        ";" +
        typeAgregat +
        ";" +
        currentLabel +
        ";" +
        currentData
      );
    });

    data.unshift(header);
    const csvArray = data.join("\r\n");

    const blob = new Blob(["\ufeff", csvArray], { type: "text/csv" });
    saveAs(blob, "param_graph.csv");
  }
}
